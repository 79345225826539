import React from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import { useTranslation, withTranslation } from 'react-i18next';
import {Row,Col,Table} from 'reactstrap'
import axios from 'axios'
import SearchInput, {createFilter} from 'react-search-input'

class About extends React.Component{
  constructor(props){
    super(props)
    this.state={
      fs : [],
      ms : [],
      searchTerm : '',
      mode : 1,
      s: '',
      g :''
    }

  }
componentWillMount(){
  window.scroll(0,0)
  this.getdata()
}
getdata = ()=>{
  axios.get('/api/market').then(response=>{
    let d = response.data
    this.setState({ms : d.markets})
  })
}
getfees = ()=>{
  if(this.state.mode === 2){
    return
  }
  axios.get('/api/fees').then(response=>{
    let d = response.data
    this.setState({fs : d , mode : 2})
  })
}
setsd1 = (e)=>{
  this.setState({s : e.target.value.toUpperCase()})
}
setsd = (e)=>{
  this.setState({g : e.target.value.toUpperCase()})
}
  render(){
      const { t } = this.props;
      const d1 = this.state.ms.filter(item => item.baseCurrency.includes(this.state.s));
      const d2 = this.state.fs.filter(item => item.baseCurrency.includes(this.state.g));

    return(
      <>
      <Helmet>
        <title> {t('cryprto-list-title')} | رپیتکس</title>
      </Helmet>
      <div className='h-600 pt-10'>
      <Row xl='1' lg='1' md='1' sm='1' xs='1'>
        <Col xl='12' className='text-dark bg-light lh-xl rtl px-3'>
          <div className='lh-xl pt-5 px-3'>
          <p className='font-weight-bold text-dark'>
            نکات قابل توجه :
          </p>
          <p className='text-dark'>
          سطح کارمزد بر اساس حجم مبادلات ۳۰ روز گذشته شما در بازار معاملات محاسبه می‌شود.
          </p>
          <p className='text-dark'>
          کارمزد از هر دو طرف معامله گرفته می‌شود.
          </p>
          <p className='text-dark'>
          کارمزد به صورت درصد از حجم دارایی درخواستی محاسبه می‌شود. به طور مثال اگر به عنوان فروشنده، بخواهید در برابر بیت‌کوین، ریال دریافت کنید کارمزد به صورت درصد از ریال دریافت می‌شود، و بالعکس اگر به عنوان خریدار بخواهید با ریال خود بیت‌کوین خریداری نمایید، کارمزد به صورت درصد از بیت‌کوین دریافت خواهد شد.
          </p>
          <p className='text-dark'>
          در هنگام ثبت معاملات از طریق پلتفرم رپیتکس، مبلغ دقیق کارمزد برای آن معامله برای شما نمایش داده خواهد شد.
          </p>
          <p className='text-dark'>
          کارمزد معاملات در تمامی سطوح کاربری براساس حجم مبادلات سی روزه مطابق جدول زیر محاسبه می‌شود:
          </p>
          <p className='text-dark'>
          واریز و برداشت رمزارز:
          </p>
          <p>
          کارمزد واریز و برداشت دارایی‌های دیجیتال به نوع کوین و شبکه مربوط به آن بستگی دارد.
          </p>
          <p className='text-dark'>
          لازم به ذکر است در واریز رمزارز باید دقت زیادی داشته باشید،‌ همچنین اطمینان حاصل کنید که اطلاعات کافی درباره شبکه رمزارز ها داشته و مبدا و مقصد مورد نظر از آن پشتیبانی میکنند. در صورت هر گونه اشتباه در ثبت اطلاعات یا شبکه‌ای که رپیتکس از آن پشتیبانی نمی‌کند ممکن است دارایی شما از بین رفته و عملا بازیابی آن غیر ممکن بوده و رپیتکس هیچ ضمانتی درباره آن ارائه نمیدهد.
          </p>
          <p className='text-dark'>
          واریز و برداشت ریال:
          </p>
          <p className='text-dark'>
          کارمزد واریز 0.02 درصد با حداقل 120 تومان و حداکثر 4000 تومان است که توسط شبکه شاپرک از مبلغ واریزی شما کسر و مابقی مبلغ به کیف پول شما اضافه خواهد شد.
          </p>
          <p className='text-dark'>
          پردازش تسویه ریالی:
          </p>
          <p className='text-dark'>
          در رپیتکس، ما تمام تلاش خود را می‌کنیم تا درخواست‌های برداشت ریالی را در کمترین زمان ممکن پردازش کنیم. ما با همکاری شرکتهای پرداخت‌یار تلاش می‌کنیم تا تسویه کوتاه‌مدت مبالغ ریالی را انجام دهیم. با این حال، باید توجه داشت که اختلالات زمانی به صورت موردی رخ می‌دهد و ما نمی‌توانیم تسویه فوری ریال را تضمین کنیم. تسویه به صورت مستقیم به حساب مقصد انجام می‌شود و برخی از بانک‌ها از جمله بانک آینده، ملت، سامان، ملی و ... شامل آن می‌شوند.
          </p>
          <p className='text-dark'>
          در صورت عدم امکان انتقال مستقیم، یک روش دیگر توسط شرکتهای پرداخت‌یار برای برداشت ریالی استفاده می‌شود که با استفاده از سیکل‌های پایای بانکی کشور (ساعت 4 تا 7 صبح، 11، 14 و 19 در روزهای غیرتعطیل) تسویه ریالی انجام می‌شود. لطفاً توجه داشته باشید که زمان "پردازش سیکل پایا از سمت بانک مرکزی" با زمان "واریز مبلغ درخواستی به حساب کاربر" متفاوت است و به طور عمومی با تأخیر 30 تا 40 دقیقه انجام می‌شود.
          </p>
          <p className='text-dark'>
          همچنین با توجه به عدم تسویه وجوه واریزی توسط درگاه پرداخت شاپرک با شرکتهای پرداخت‌یار در روزهای تعطیل، پردازش تسویه ریالی در اولین روز کاری پس از تعطیلات و در سیکل سوم پایا انجام خواهد شد. بنابراین در سیکل اول و دوم، اولین روز کاری پس از تعطیلات تسویه های ریالی پردازش نخواهند شد.
          </p>
          <p className='text-dark'>
          همواره امکان ایجاد اختلال پیش‌بینی نشده در عملیات تسویه ریالی وجود دارد که از جمله آنها می‌توان به مسدود بودن حساب مقصد و بازگشت وجه به حساب مبدا، عدم تسویه پایا در روزهای تعطیل، تاخیر در تسویه شرکت شاپرک با شرکتهای پرداخت‌یار و ... اشاره کرد. در چنین مواقعی تعیین وضعیت نهایی تسویه ریالی کاربران ممکن است بین 3 تا 7 روز کاری زمان‌بر باشد.
          </p>
          </div>
        </Col>
        <Col xl={{size:8 , offset:2}} lg={{size:8 , offset:2}} md='12' sm='12' xs ='12'  className='rtl'>
          <div className='d-flex'>
           <p onClick={()=>this.setState({mode : 1 })} className='lead pointer text-dark py-3 mr-0 '>کارمزد معاملات </p>
           <p onClick={this.getfees} className='lead pointer text-dark py-3 mx-3'>کارمزد برداشت</p>
           </div>
          {this.state.mode === 1 ?
            <>
            <input onChange={this.setsd1} className='w-100 py-2 input-sm ltr mb-5' />
              <Table responsive borderless className='lh-xl w-100 text-small small' size='small'>
            <thead className='text-small'>
              <tr>
                <td>
                </td>
                <td>
                <span>جفت ارز</span>
                </td>
                <td><span>کارمزد : پایه</span>
                </td>
                <td><span>یک</span>
                </td>
                <td><span>دو</span>
                </td>
                <td><span>سه</span>
                </td>
              </tr>
            </thead>
            <tbody>
            {d1.map((item , index)=>
              <tr className=''>
                <td>
                <span className={item.quoteCurrency +'-icon small-icon  table-icon'}></span>
                </td>
                <td>{item.symbolName}
                </td>

                <td><span className='px-1'>{0.2 * item.makerCoefficient}%</span>
                </td>
                <td><span className='px-1'>{0.18 * item.makerCoefficient}%</span>
                </td>
                <td><span className='px-1'>{0.16 * item.makerCoefficient}%</span>
                </td>
                <td><span className='px-1'>{0.15 * item.makerCoefficient}%</span>
                </td>
              </tr>
            )}

            </tbody>
          </Table> </>:
          <>
          <input onChange={this.setsd} className='w-100 py-2 input-sm ltr mb-5' />
          <Table responsive borderless>
        <thead className='font-weight-bold'>
          <tr className='text-medium text-dark bg-light'>
            <td><div>ارز </div>
            </td>
            <td><p className=''>شبکه انتقال</p>
            </td>
            <td><p className=''>کارمزد انتقال</p>
            </td>
            <td><p className=''>Min</p>
            </td>
          </tr>
        </thead>
        <tbody>
        {d2.map((item , index)=>
            JSON.parse(item.chains).map(ch=>
          ch.isWithdrawEnabled === true ?
          <tr className=''>
            <td className=''>
            <span className={item.baseCurrency +'-icon small-icon absolute'}></span>
            <span className='text-medium mr-5 mt-2'> {item.baseCurrency}</span>
            </td>
            <td><p className='mb-3 mt-2 text-medium'>{ch.chainName}</p>
            </td>
            <td><p className='mb-3 mt-2 text-medium'>{ch.withdrawalMinFee}</p>
            </td>
            <td><p className='mb-3 mt-2 text-medium'>{ch.withdrawalMinSize}</p>
            </td>

          </tr> : <></>
        ))}

        </tbody>
      </Table> </>}
        </Col>
      </Row>
</div>
      </>
    )
  }
}
export default withTranslation()(About)
