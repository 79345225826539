import React from 'react'
import Footer from './footer'
import {Navbar} from 'reactstrap'
import {Row,Col,DropdownItem} from 'reactstrap'
import Sidebarcontent from './market-sidebar'
import cookie from 'react-cookies'
import {
  Switch,Route
} from "react-router-dom"
import axios from 'axios'
import {GrUserSettings} from 'react-icons/gr'
import { GoHome,GoTable,GoArrowSwitch } from "react-icons/go";
import { CiMoneyBill } from "react-icons/ci";
import AOS from "aos";
import SimpleLineIcon from 'react-simple-line-icons'
import Dashboard from './dashboard'
import Rewards from './rewards'
import Profile from './profile'
import Wallet from './wallet'
import Transactions from './transactions'
import Transaction from './transactions/tx'
import Depositall from './deposit/all'
import Depositirt from './deposit/irt'
import Import from './import'
import Withdraw from './withdraw'
import Withdrawall from './withdraw/all'
import Submit from './withdraw/submit'
import Withdrawirt from './withdraw/irt'
import Report from './report'
import Accountdetail from './accountdetail'
import Exchange from './exchange'
import Exit from './exit'
import {Link} from 'react-router-dom'
import { CiCoinInsert,CiBitcoin,CiInboxOut } from "react-icons/ci";
import { useTranslation, withTranslation } from 'react-i18next';
import { RiUserSettingsLine } from "react-icons/ri"
import { LuUser2 } from "react-icons/lu";
import { RiTokenSwapLine } from "react-icons/ri";
import {PiWalletThin,PiSwapThin,PiStackThin,PiListDashesThin} from 'react-icons/pi'

class PanelLayer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      coin : 'BTC',
      coinname : 'بیت کوین',
      coinPrice : 16800,
      buysell : 'buy',
      fee : '',
      list : [],
      active : '',
      sub1 : false,
      themes : 'light' | 'dark',
      isrtl : true,
      collapsed : false,
      isOpen : true
    }
  }
  toggle = ()=>{
    console.log('sss');
    this.setState({isOpen : !this.state.isOpen})
  }
  getstatus =(x)=>{
    const {t} = this.props
    let status
    switch (x) {
      case '300001':
        status =  t('deposit')
        break;
      case '300002':
        status =  t('withdraw')
        break;
      case '300003':
        status =  t('depositirr')
        break;
      case '300004':
        status =  t('withdrawirr')
        break;
      case '300005':
        status =  t('swap')
        break;
      case '300006':
        status =  t('marketorder')
        break;
      case '300007':
        status =  t('advancedorder')
        break;
      case '300008':
        status =  t('open')
        break;
      case '300009':
        status =  t('close')
        break;
      case '300010':
        status =  t('cancel')
        break;
      case '300011':
        status =  t('waiting')
        break;
      case '300012':
          return t('successful')
        break;
      case '300013':
          return t('error')
        break;
      case '300014':
          return t('buy')
        break;
      case '300015':
          return t('sell')
        break;
      case '300016':
          return t('level-0')
        break;
      case '300017':
          return t('level-01')
        break;
      case '300018':
          return t('level-02')
        break;
      case '300019':
          return t('level-03')
        break;
      case '300020':
          return t('vip')
        break;
      case '300021':
          return t('inprogressdeposit')
        break;
      case '300022':
          return t('inprogressdepositcrypto')
        break;
      default:

    }
    return status

  }
  componentWillUnmount(){
    let id = this.state.active
    localStorage.setItem('active' , id)
  }
  componentWillMount(){
    window.scrollTo(0, 0)
    let path = this.props.location.pathname.toLowerCase()
    switch (path) {
      case '/panel/dashboard':
        this.setState({active : 'dashboard'})
        break;
      case '/panel/transations':
        this.setState({active : 'transations'})
        break;
      case '/panel/wallet':
        this.setState({active : 'wallet'})
        break;
      case '/panel/exchange':
        this.setState({active : 'exchange'})
        break;
      case '/panel/depositcrypto':
        this.setState({subactive : 'dpcr' , active : 'dw'})
        break;
      case '/panel/depositirt':
        this.setState({active : 'dpir', active : 'dw'})
        break;
      case '/panel/withdrawall':
        this.setState({active : 'wdcr', active : 'dw'})
        break;
      case '/panel/withdrawirt':
        this.setState({active : 'wdir', active : 'dw'})
        break;
      case '/panel/submit_bank_account':
        this.setState({active : 'dp'})
        break;
      case '/panel/perfect':
        this.setState({active : 'perfect'})
        break;
      case '/panel/profile':
        this.setState({active : 'profile'})
        break;
      default:
      this.setState({active : 'dashboard'})
    }
    this.updatecustomer()
    // axios.get('/api/exchange/get_list').then(response=>{
    //   this.setState({list : response.data})
    // })
  }
  componentDidMount(){

      }
  updatecustomer = ()=>{
  }
  setlist = ()=>{

  }

  setemail = (email , name , ip , device)=>{
    this.setState({email : email , name : name , ip : ip , device : device})
  }

  setcointaker = (item) =>{
    this.setState({coinname : item.name , coinPrice : item.irprice , fee : item.fee,c1 : item})
  }
  setcointicker = (item) =>{
    this.setState({coinname2 : item.name , coinPrice2 : item.irprice , fee2 : item.fee,c2 : item})
  }
  setactive =(i)=>{
    this.setState({active : i})
  }
  setsubactive =(i)=>{
    this.setState({subactive : i , sub1 : true,active : 'dw'})
  }
  opensub1 =(i)=>{
    this.setState({active : 'dw' , sub1 : !this.state.sub1})
  }
  setsubactive2 =(i)=>{
    this.setState({subactive2 : i , sub2 : true,active : 'perfect'})
  }
  opensub2 =(i)=>{
    this.setState({active : 'perfect'})
  }
  hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };
  render(){
    const {match} = this.props
    const {t} = this.props
    const themes = {
      light: {
        sidebar: {
          backgroundColor: '#fff',
          color: '#000',
        },
        menu: {
          menuContent: '#fff',
          icon: '#000',
          hover: {
            backgroundColor: '#d3d3d3',
            color: '#000',
          },
          disabled: {
            color: '#000',
          },
        },
      },
      dark: {
        sidebar: {
          backgroundColor: '#161924',
          color: '#fff',
        },
        menu: {
          menuContent: '#161924',
          icon: '#ffc101',
          hover: {
            backgroundColor: '#000518',
            color: '#ffffff',
          },
          disabled: {
            color: '#fff',
          },
        },
      },
    };
    const menuItemStyles: MenuItemStyles = {
      root: {
        fontSize: '13px',
        fontWeight: 400,
      },
      icon: {
        color: themes.dark.menu.icon,
      },
      SubMenuExpandIcon: {
        color: '#fff',
      },
      subMenuContent: ({ level }) => ({
        backgroundColor:
          level === 0
            ? this.hexToRgba(themes.dark.menu.menuContent, this.state.hasImage ? 1 : 1)
            : 'transparent',
      }),
      button: {

        '&:hover': {
          backgroundColor: this.hexToRgba(themes.dark.menu.hover.backgroundColor, this.state.hasImage ? 1 : 1),
          color: themes.dark.menu.hover.color,
        },
      },
      label: ({ open }) => ({
        fontWeight: open ? 600 : undefined,
      }),
    };
    const menuItemStyleslight: MenuItemStyles = {
      root: {
        fontSize: '13px',
        fontWeight: 400,
      },
      icon: {
        color: themes.light.menu.icon,
      },
      SubMenuExpandIcon: {
        color: '#fff',
      },
      subMenuContent: ({ level }) => ({
        backgroundColor:
          level === 0
            ? this.hexToRgba(themes.light.menu.menuContent, this.state.hasImage ? 1 : 1)
            : 'transparent',
      }),
      button: {
        '&:hover': {
          backgroundColor: this.hexToRgba(themes.light.menu.hover.backgroundColor, this.state.hasImage ? 1 : 1),
          color: themes.light.menu.hover.color,
        },
      },
      label: ({ open }) => ({
        fontWeight: open ? 600 : undefined,
      }),

    };

    return(
    <>

      <div className={this.state.collapsed === true ? 'main-wrapper-full' : 'main-wrapper'}>
      <div className='d-none d-lg-block  sidebar'>
      <Sidebarcontent loggedin={true}/>
      </div>
            <div >
              {this.props.usdt}
              {this.props.children}
            <Switch>
              <Route
                path={`${match.url}/Dashboard`}
                render={(props) => <Dashboard setemail = {this.setemail}  theme={this.props.theme} {...props} />}
              />
              <Route
                path={`${match.url}/profile`}
                render={(props) => <Profile theme={this.props.theme} {...props} />}
              />
              <Route
                path={`${match.url}/submit_bank_account`}
                render={(props) => <Submit {...props} />}
              />
              <Route
                path={`${match.url}/exchange/:type/:id`}
                render={(props) => <Exchange darkMode={this.props.darkMode} theme={this.props.theme} {...props} />}
              />
              <Route
                path={`${match.url}/wallet`}
                render={(props) => <Wallet theme={this.props.theme} {...props} />}
              />


              <Route
                path={`${match.url}/depositcrypto`}
                render={(props) => <Depositall {...props} />}
              />

              <Route
                path={`${match.url}/depositirt`}
                render={(props) => <Depositirt {...props} />}
              />
              <Rewards
                path={`${match.url}/rewards`}
                render={(props) => <Rewards {...props} />}
              />

              <Route
                path={`${match.url}/withdrawall`}
                render={(props) => <Withdrawall {...props} />}
              />
              <Route
                path={`${match.url}/withdrawirt`}
                render={(props) => <Withdrawirt {...props} />}
              />

              <Route
                path={`${match.url}/Account-Details/:id`}
                render={(props) => <Accountdetail {...props} />}
              />
              <Route
                path={`${match.url}/transactions`}
                render={(props) => <Transactions getstatus={this.getstatus} theme={this.props.theme} {...props} />}
              />
              <Route
                path={`${match.url}/transaction/:id`}
                render={(props) => <Transaction  theme={this.props.theme} {...props} />}
              />
              <Route
                path={`${match.url}/Logout`}
                render={(props) => <Exit {...props} />}
              />
              <Route
                path={`${match.url}/exit`}
                render={(props) => <Exit {...props} />}
              />
            </Switch>

          </div>

      </div>


    </>
    )
  }
  }
  export default withTranslation()(PanelLayer)
