import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Modal,ModalHeader,ModalBody ,ModalFooter,Card,CardHeader,ButtonDropdown,InputGroup,CardBody,Row,Form,FormGroup,Label,Input,Col,Button,Table,Container,UncontrolledDropdown, FormText ,Dropdown,DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import {Link} from 'react-router-dom'
import SearchInput, {createFilter} from 'react-search-input'
import {Helmet} from 'react-helmet'
import { withTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import NumericFormat from 'react-number-format';
import NumberFormatBase from 'react-number-format';
import Loader from '../../landing/loader'
import { GiChart } from "react-icons/gi";
import { LiaCartPlusSolid } from "react-icons/lia";
import { CiEraser } from "react-icons/ci";
import { IoReturnDownBack,IoReturnUpForwardOutline } from "react-icons/io5";
import { CryptoCoinsHeatmap,TechnicalAnalysis ,AdvancedRealTimeChart} from "react-ts-tradingview-widgets";
import F from 'float'
import TradingViewWidget from 'react-tradingview-widget'
import Toast from 'awesome-toast-component'


const MySwal = withReactContent(Swal);

class Wallet extends React.PureComponent{
  constructor(props){
    super(props);
    this.state = {
      loader : true,
      rangeValues: [0, 25, 50, 75, 100],
      currentRangeValue: 0,
      list :[],
      irrmarket :[],
      markets :[],
      coinname : '',
      coinname2 : '',
      fee : 0.004,
      all : 0,
      response : [],
      merged : [],
      accounts : [],
      c1 : [],
      c2 : [],
      rest : false,
      erroeamount : false,
      bds : true,
      input1 : true,
      btn : false,
      selectedOption: [],
      selectedOption2: [],
      a1 : {},
      a2 : {},
      s1 : '',
      s2 : '',
      modal : false,
      mw1 : false,
      modalquote : false,
      modalbase : false,
      quote : 'IRR',
      base : 'USDT',
      mode : 3,
      res : '',
      responsebase : '',
      responsequote : '',
      symbol : 'KUCOIN:BTC-USDT'
        }
    this.validator = new SimpleReactValidator();
    // this.getvc
      }
      handleInputChange = (e) => {
   this.setState({ currentRangeValue: e.target.value });
 };
      handleChange = (selectedOption) => {
        if(selectedOption === this.state.selectedOption2){
          console.log('گزینه های انتخابی اشتباه است')
          return
        }
       this.setState({ selectedOption });

         this.setState({amount : '', res : '' , fee : '' , rest : false})

     }
      handleChange2 = (selectedOption2) => {
        if(this.state.selectedOption === selectedOption2){
          console.log('گزینه های انتخابی اشتباه است')
          return
        }
       this.setState({ selectedOption2 });
       this.setState({c : true})

     }
  setcointaker = (item) =>{
    this.setState({coinname : item.name ,slug : item.slug, coinPrice : item.irprice , fee : item.fee,c1 : item})
  }
  setcointicker = (item) =>{
    if(this.state.coinname === '') {

      return
    }
    this.setState({coinname2 : item.name , input1 : false ,slug2 : item.slug , coinPrice2 : item.irprice , fee2 : item.fee,c2 : item})
  }

  onChange = (e)=>{
    console.log(this.state);
    var value = e.target.value
    this.setState({amount : parseFloat(value)})
    if(this.state.coinname === ''){
      console.log('ارزی انتخاب نکرده اید')
      return
    }
    if(this.state.coinname2 === ''){
      console.log('ارزی انتخاب نکرده اید')
      return
    }
   var c1 =  this.state.selectedOption
   var c2  =  this.state.selectedOption2
   const {t} = this.props
  var type = this.props.match.params.type.toUpperCase()
   var a1 =   parseFloat(c1.buy)
   var a2  =  parseFloat(c2.sell)
   var f1 =  parseFloat(c1.fee)
   var f2 =  parseFloat(c2.fee)
   var all = e.target.value * a1
   var res = all/a2
   var fee = all * f2
   if(parseFloat(all) < 1000000-1){
     new Toast(`${t('min-quote-1000000-error')}`, {
       style: {
           container: [
               ['background-color', 'red']
           ],
           message: [
               ['color', '#eee'],
           ],
           bold: [
               ['font-weight', '900'],
           ]
       },
       timeout : 3000,
         position : 'top'
   })
     return
   }
     this.setState({res : res ,all : all})
     // this.setState({res : res ,all : all, btn : false})
   // }else{
   //   this.setState({btn : true})
   // }

  }
  componentDidMount(){
  const intervalId = setInterval(this.getdatas, 10000)
  this.setState({ intervalId : intervalId,loader : false})
}

componentWillUnmount(){
  clearInterval(this.state.intervalId)
}
componentWillMount(){
  if(!cookie.load('uid')){
    this.props.history.push('/auth/login')
    return
  }

  var id = this.props.match.params.id.toUpperCase()
  var type =this.props.match.params.type.toUpperCase()
  this.setState({base : id , side: type , symbol : 'KUCOIN:'+id+"-USDT"})

  this.getdata()

}

settheme =()=>{
  const theme = document.body.getAttribute('class')
  if(theme === 'dark'){
    this.setState({theme : theme})
  }

}
getstate = ()=>{
  var id = this.props.match.params.id.toUpperCase()
  var quote = this.state.quote
  var type =this.props.match.params.type.toUpperCase()
  this.setState({coin : id })
  var url = '/api/exchange/check_order/'+id+'/'+quote
  axios.get(url).then(response=>{
    if(response.data.status === 'error-fatal'){
      this.props.history.push('/auth/login')
      return
    }
    if(response.data.status === 'success'){
      if(type === 'BUY'){
        this.setState({
          baseprice : response.data.baseprice,
          quoteprice : response.data.quoteprice,
          fee : response.data.fee,
        })
      }else{
        this.setState({
          quoteprice : response.data.baseprice,
          baseprice : response.data.quoteprice,
          fee : response.data.fee,
        })
      }

    }
  })
}
getdata = ()=>{
  axios.get('/api/exchange/list').then(response=>{
    var data = response.data
    if(response.data.status === 'error-fatal'){
      // console.log('توکن شما منقضی شده است.لطفا مجددا وارد شوید')
      this.props.history.push('/auth/login')
      return
    }

    if(response.data.status === 'success'){
    this.setState({accounts : response.data.accounts,list : response.data.list ,markets : response.data.markets ,irrmarket : response.data.list})
    let d = response.data.list.filter(item => item.quoteCurrency.includes('USDT'));
    let x = d.map(itm => ({
        ...response.data.accounts.find((item) => (item.symbol === itm.baseCurrency) && (itm.availablebalance = item.availablebalance)),
        ...itm
    }));

    this.setState({merged : x})
    var id = this.props.match.params.id.toUpperCase()
    var type =this.props.match.params.type
    const found = x.find(element => element.baseCurrency === id);
    // console.log(found);
    const foundquote = response.data.list.find(element => element.baseCurrency === this.state.quote && element.quoteCurrency === 'USDT' );
    console.log(this.state.quote);
    this.setState({c2 : found ,base : found.baseCurrency ,baseprice : found.irsell, quoteprice : foundquote.irbuy, c1 : foundquote ,input1 : false  })
   }
  })
}
getdatas = ()=>{
  axios.get('/api/exchange/list').then(response=>{
    var data = response.data
    if(response.data.status === 'error-fatal'){
      // console.log('توکن شما منقضی شده است.لطفا مجددا وارد شوید')
      this.props.history.push('/auth/login')
      return
    }

    if(response.data.status === 'success'){
    this.setState({accounts : response.data.accounts,list : response.data.list ,markets : response.data.markets ,irrmarket : response.data.list})
    let x = response.data.list.map(itm => ({
        ...response.data.accounts.find((item) => (item.symbol === itm.baseCurrency) && (itm.availablebalance = item.availablebalance)),
        ...itm
    }));

    this.setState({merged : x})
    var id = this.state.base.toUpperCase()
    var type =this.props.match.params.type
    const found = x.find(element => element.baseCurrency === id);
    const foundquote = x.find(element => element.baseCurrency === this.state.quote);
    this.setState({c2 : found ,base : found.baseCurrency ,baseprice : found.irsell, quoteprice : foundquote.irbuy, c1 : foundquote ,input1 : false  })
   }
  })
}
checkmarket = (e)=>{
  e.preventDefault()
  if(this.state.selectedOption.slug === this.state.selectedOption2.value){
    console.log('گزینه های انتخابی اشتباه است')
    return
  }
  let market = this.state.selectedOption2.value +'-'+this.state.selectedOption.slug
  const formdata = new FormData();
  formdata.append('market',market)
  formdata.append('base',this.state.selectedOption2.taker)
  formdata.append('quote',this.state.selectedOption.slug)
  axios.post('/api/exchange/checkswap',formdata).then(response=>{
    if(response.data.status === 'error-fatal'){
      this.props.history.push('/auth/login')
      return
    }
     this.setState({fee : response.data.fee ,type : response.data.status ,rest : true , basebalance : response.data.basebalance  , quotebalance : response.data.quotebalance})
  }

  )
}
swap = (e)=>{
  e.preventDefault()
  this.setState({btn : true})
  // console.log(this.state);
  // return
if(this.state.amount === ''){
  this.setState({inputerror : true })

  console.log('مقدار ارز را وارد کنید')
  return
}
  var all = this.state.all
  var amount = this.state.amount
  var a2 = this.state.res
  var type = this.state.type
  if(parseFloat(amount) > parseFloat(this.state.quotebalance)){
    console.log('موجودی شما کافی نمیباشد')
    this.setState({btn : false})
    return
  }
  const formdata = new FormData();
  formdata.append('amount',amount)
  formdata.append('market',this.state.market)
  formdata.append('allpay',all)
  formdata.append('takeramount',a2)
  formdata.append('type',type)
  formdata.append('c1',this.state.selectedOption.taker)
  formdata.append('c2',this.state.selectedOption2.taker)
  this.setState({bds : false})
  axios.post('/api/exchange/swap',formdata).then(response=>{
    // console.log();
    this.setState({bds : true})
    if(response.data.status === 'success'){
      this.getdata()
    }
    if(response.data.status === 'error-fatal'){
      this.props.history.push('/auth/login')
    }
    if(response.data.status === 'error'){
    }
    this.setState({btn : false})



  })
}
getvc = ()=>{
  var base = this.state.base
  var quote = this.state.quote
  var type =this.props.match.params.type.toUpperCase()
  this.setState({coin : base})
  var url = '/api/exchange/check_order/'+base+'/'+quote
  axios.get(url).then(response=>{
    if(response.data.status === 'error-fatal'){
      console.log('توکن شما منقضی شده است.لطفا مجددا وارد شوید')
      this.setState({loginmodal : true})
      this.props.history.push('/auth/login')
          return
    }
    if(response.data.status === 'success'){
      this.setState({
        baseprice : response.data.baseprice,
        quoteprice : response.data.quoteprice,
        fee : response.data.fee
      })
    }
  })
}
check = (base , quote)=>{
  this.setState({a1 : '' , x : '',res : ''})
  // var base = this.state.base
  // var quote = this.state.quote
  var type =this.props.match.params.type.toUpperCase()
  // this.setState({coin : base})
  var url = '/api/exchange/check_order/'+base+'/'+quote
  axios.get(url).then(response=>{
    if(response.data.status === 'error-fatal'){
      this.props.history.push('/auth/login')
      return
    }
    if(response.data.status === 'success'){
      this.setState({
        baseprice : response.data.baseprice,
        quoteprice : response.data.quoteprice,
        fee : response.data.fee
      })
    }
  })
}

seti1 = (i)=>{
  this.setState({c1 : i ,s1 : '' ,quoteprice : i.irbuy, amount : 0 , res : 0 , quote : i.baseCurrency})
  let a = i.baseCurrency
  let b = this.state.base
    this.setfee(b,a)
  // this.check(this.state.base , i.symbol)
  // console.log(i);
}
changea = (i)=>{
  this.setState({amount : i ,a1: i})
  let xx = this.convertor.convert(i/10)
  this.setState({x : xx})
  var a1 =   parseFloat(this.state.quoteprice)
  var a2  =  parseFloat(this.state.baseprice)
  var fee = this.state.fee
  var a = i * a1
  var b = a / a2
  var x = fee * b
  var y = b - x
  if(y > 1){
    this.setState({res : y.toFixed(2),all : a })
  }else{
    this.setState({res : y.toFixed(8),all : a  })
  }

    let g = this.convertor.convert(y/10)
    this.setState({g : g})

  // console.log(i);
}
seti2 = (i)=>{
  this.setState({c2 : i , s2 : '' ,base : i.baseCurrency,baseprice : i.irsell, amount : '' , fee : '' , res : '', irrfe : '' , feeamount : ''})
  let a = i.baseCurrency
  let b = this.state.quote
    this.setfee(a,b)
  // this.check(i.taker , this.state.quote)
  // console.log(i);
}
changea1 =(value)=>{
  this.setState({amount : value  , error : ''})

  if(this.state.i1 === ''){
    this.setState({input1error : 'ارزی انتخاب نکرده اید'})
    return
  }
  if(this.state.i2 === ''){
      this.setState({input2error : 'ارزی انتخاب نکرده اید'})
    return
  }
 let amount = value
 var type =this.props.match.params.type.toUpperCase()
 var c1 =  this.state.c1
 var c2 =  this.state.c2
 var a1 =   this.findquoteprice()
 var a2 =  this.findbaseprice()

 var fee = this.state.fee
 var a = amount * a1
 var b = a / a2
 var x = fee * b
 var y = b - x

 // var y = y.toFixed(9)
 if(x < 0.0000001){
   this.setState({feeamount : x.toFixed(9)})
 }else{
   this.setState({feeamount : x.toFixed(6)})
 }
var x = this.convertor.convert(value/10)
 // let  addCommas = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 // let  removeNonNumeric =  num.toString().replace(/[^0-9]/g, "");
 // let amount = addCommas(removeNonNumeric)
 // let amount = this.thousand(e.target.value)
 if(y > 1){
   this.setState({res : y.toFixed(2),all : a , x : x })
 }else{
   this.setState({res : y.toFixed(8),all : a , x : x })
 }
 let g = this.convertor.convert(y/10)
 this.setState({g : g})
}
findquoteprice = ()=>{
  var quote = this.state.i1.toUpperCase()
  const found = this.state.list.find(element => element.taker === quote);
  // console.log(found);
  // return
  this.setState({quoteprice : parseFloat(found.irbuy)})
  return parseFloat(found.buy)
}
findbaseprice = ()=>{
  var quote = this.state.i2.toUpperCase()
  const found = this.state.list.find(element => element.taker === quote);
  // console.log(found);
  this.setState({quoteprice : parseFloat(found.irsell)})
  return parseFloat(found.sell)
}

lowercase =(i)=>{
  let x = i
  return(
    <span>{x}</span>
  )
}
convert = ()=>{
  this.a = this.state.c1
  this.a1 = this.state.i1
  this.b = this.state.c2
  this.b2 = this.state.i2
  this.setState({c1 : this.b})
  this.setState({i1 : this.b2})
  this.setState({c2 : this.a})
  this.setState({i2 : this.a1})
  var type =this.props.match.params.type.toUpperCase()
  this.setState({a1 : '' , res : ''})
  if(type === 'BUY'){
  this.props.history.push('/panel/exchange/sell/'+this.props.match.params.id.toUpperCase())}else{
  this.props.history.push('/panel/exchange/buy/'+this.props.match.params.id.toUpperCase())
  }
}
 convertor = (function() {
  var convertor = {
    convert: function(num) {
      if (isNaN(num)) return "عدد وارد شده معتبر نمی باشد.";
      num = Math.round(num);
      var yekan = ["یک", "دو", "سه", "چهار", "پنج", "شش", "هفت", "هشت", "نه"];
      var dahgan1 = [
        "ده",
        "یازده",
        "دوازده",
        "سیزده",
        "چهارده",
        "پانزده",
        "شانزده",
        "هفده",
        "هجده",
        "نوزده"
      ];
      var dahgan2 = [
        "بیست",
        "سی",
        "چهل",
        "پنجاه",
        "شصت",
        "هفتاد",
        "هشتاد",
        "نود"
      ];
      var sadgan = [
        "صد",
        "دویست",
        "سیصد",
        "چهارصد",
        "پانصد",
        "ششصد",
        "هفتصد",
        "هشتصد",
        "نهصد"
      ];

      var _num = num.toString();
      var _count = 0;
      var _yekan = "";
      var _dahgan = "";
      var _sadgan = "";
      var _hezargan = "";
      var _hezargan2 = "";
      var _milion = "";
      var _milion2 = "";
      var _miliard = "";
      var _miliard2 = "";
      var _terilion = "";
      var _triliard = "";

      for (var i = _num.length - 1; i >= 0; i--) {
        _count++;

        //-1 => برای اینکه با توجه به موقعیت پیدا میکنم و جون از یک شروع میکنم پس منهای یک میکنم
        // که موقعیت درست رو به من بده
        var _s = parseInt(_num[i]) - 1;
        switch (_count) {
          case 1:
            if (_s >= 0) _yekan = yekan[_s];
            break;
          case 2:
            if (_s >= 0) {
              if (_s === 0) {
                // زمانی که عدد کوچکتر از 20 باشد
                var _d = parseInt(_num[i + 1]);
                _dahgan = dahgan1[_d];
                _yekan = "";
              } else {
                _dahgan = dahgan2[_s - 1];

                if (_yekan.trim() !== "") _dahgan += " و ";
              }
            }
            break;
          case 3:
            //صدگان
            if (_s >= 0) {
              _sadgan = sadgan[_s];
              if (_yekan.trim() !== "" || _dahgan.trim() !== "")
                _sadgan += " و ";
            }
            break;
          case 4:
            //هزارگان
            if (_s >= 0) {
              _hezargan = yekan[_s];
              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== ""
              )
                _hezargan += " هزار و ";
              else _hezargan += " هزار ";
            }
            break;
          case 5:
            if (_s >= 0) {
              var _d = parseInt(_num[i + 1]);
              if (_s === 0) {
                // زمانی که عدد کوچکتر از 20 باشد
                _hezargan = dahgan1[_d] + " هزار ";
                // _yekan = '';
              } else {
                if (yekan[_d - 1] == undefined)
                  _hezargan = dahgan2[_s - 1] + " هزار ";
                else
                  _hezargan =
                    dahgan2[_s - 1] + " و " + yekan[_d - 1] + " هزار ";
              }

              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== ""
              )
                _hezargan += " و ";
            }
            break;
          case 6:
            if (_s >= 0) {
              _hezargan2 = sadgan[_s];
              if (_hezargan.trim() !== "") _hezargan2 += " و ";
              else {
                _hezargan2 += " هزار ";
                if (
                  _yekan.trim() !== "" ||
                  _dahgan.trim() !== "" ||
                  _sadgan.trim() !== ""
                )
                  _hezargan2 += " و ";
              }
            }
            break;
          case 7:
            //میلیون
            if (_s >= 0) {
              _milion = yekan[_s] + " میلیون ";
              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== "" ||
                _hezargan.trim() !== "" ||
                _hezargan2.trim() !== ""
              )
                _milion += " و ";
            }
            break;
          case 8:
            if (_s >= 0) {
              var _d = parseInt(_num[i + 1]);
              if (_s === 0) {
                // زمانی که عدد کوچکتر از 20 باشد
                _milion = dahgan1[_d] + " میلیون ";
                // _yekan = '';
              } else {
                if (yekan[_d - 1] === undefined)
                  _milion = dahgan2[_s - 1] + " میلیون ";
                else
                  _milion =
                    dahgan2[_s - 1] + " و " + yekan[_d - 1] + " میلیون ";
              }

              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== "" ||
                _hezargan.trim() !== "" ||
                _hezargan2.trim() !== ""
              )
                _milion += " و ";
            }
            break;
          case 9:
            //میلیون
            if (_s >= 0) {
              _milion2 = sadgan[_s];
              if (_milion.trim() !== "") _milion2 += " و ";
              else {
                _milion2 += " میلیون ";
                if (
                  _yekan.trim() !== "" ||
                  _dahgan.trim() !== "" ||
                  _sadgan.trim() !== ""
                )
                  _milion2 += "و ";
              }
            }
            break;
          case 10:
            //میلیارد
            if (_s >= 0) {
              _miliard = yekan[_s] + " میلیارد ";
              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== "" ||
                _hezargan.trim() !== "" ||
                _hezargan2.trim() !== "" ||
                _milion.trim() !== "" ||
                _milion2.trim() !== ""
              )
                _miliard += "و ";
            }
            break;
          case 11:
            if (_s >= 0) {
              var _d = parseInt(_num[i + 1]);
              if (_s === 0) {
                // زمانی که عدد کوچکتر از 20 باشد
                _miliard = dahgan1[_d] + " میلیارد ";
              } else {
                if (yekan[_d - 1] == undefined)
                  _miliard = dahgan2[_s - 1] + " میلیارد ";
                else
                  _miliard =
                    dahgan2[_s - 1] + " و " + yekan[_d - 1] + " میلیارد ";
              }

              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== "" ||
                _hezargan.trim() !== "" ||
                _hezargan2.trim() !== "" ||
                _milion.trim() !== "" ||
                _milion2.trim() !== ""
              )
                _miliard += "و ";
            }
            break;
          case 12:
            //میلیون
            if (_s >= 0) {
              _miliard2 = sadgan[_s];
              if (_miliard.trim() !== "") _miliard2 += " و ";
              else {
                _miliard2 += " میلیارد ";
                if (
                  _yekan.trim() !== "" ||
                  _dahgan.trim() !== "" ||
                  _sadgan.trim() !== ""
                )
                  _miliard2 += "و ";
              }
            }
            break;
          default:
            break;
        }
      }

      return (
        _miliard2 +
        _miliard +
        _milion2 +
        _milion +
        _hezargan2 +
        _hezargan +
        _sadgan +
        _dahgan +
        _yekan +
        ' '+
        'تومان'
      );
    }
  };

  return convertor;
})();
submit = (e)=>{
  console.log(this.state);
  e.preventDefault()
  const {t} = this.props
  let c1 = this.state.c1
  let c2 = this.state.c2
  if(c1.availablebalance === '0' || c1.availablebalance === 0){
    new Toast(`${t('insufficient.balance')}`, {
      style: {
          container: [
              ['background-color', 'blue']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
    return
  }
  if(this.state.amount < 0){
    new Toast(`${t('insufficient.balance')}`, {
      style: {
          container: [
              ['background-color', 'orange']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
    return
  }
  if(this.state.amount === 0){
    new Toast(`${t('insufficient.balance')}`, {
      style: {
          container: [
              ['background-color', 'green']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
    return
  }

  if(parseFloat(this.state.c1.availablebalance) < this.state.amount){
    new Toast(`${t('insufficient.balance')}`, {
      style: {
          container: [
              ['background-color', 'blue']
          ],
          message: [
              ['color', '#fff'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
  this.setState({error : `${t('insufficient.balance')}` , erroeamount : true})
    return
  }
  if(!c1.availablebalance){
    new Toast(`${t('insufficient.balance')}`, {
      style: {
          container: [
              ['background-color', 'green']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
    return
  }
  if(parseFloat(c1.availablebalance) < parseFloat(this.state.c1.availablebalance)){
    new Toast(`${t('insufficient.balance')}`, {
      style: {
          container: [
              ['background-color', 'green']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
    return
  }
  var all = this.state.all
  if(parseFloat(all) < 1000000-1){
    new Toast(`${t('min-quote-1000000-error')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
        position : 'top'
  })
    return
  }
  let bp = this.state.baseprice
  let qp = this.state.quoteprice
  let fee = this.state.fee
  let p = fee * 60/100
  let feeamount = fee * this.state.all
  let pa = p * this.state.all
  const formdata = new FormData()
  formdata.append('base',this.state.c2.baseCurrency)
  formdata.append('baseprice',this.state.baseprice)
  formdata.append('quote',this.state.c1.baseCurrency)
  formdata.append('quoteprice',this.state.quoteprice)
  formdata.append('fee',this.state.fee)
  formdata.append('value',this.state.all)
  formdata.append('baseamount',this.state.res)
  formdata.append('quoteamount',this.state.amount)
  formdata.append('feeamount',feeamount)
  formdata.append('p',p)
  formdata.append('pa',pa)
  formdata.append('type',this.state.side)
  formdata.append('mamount',this.state.r)
  this.setState({bds : false})
  axios.post('/api/exchange/swap'  , formdata).then(response=>{
    this.setState({bds : true})

    if(response.data.status === 'success'){
      // console.log(response.data.message)
      this.getdata()
      this.setState({modal : !this.state.modal , amount : '' , response : response.data, res : '' ,
      responsebase : response.data.base,
      responsequote : response.data.quote,
    })

    }
    if(response.data.status === 'error-fatal'){
      this.props.history.push('/auth/login')
    }
    if(response.data.status === 'error'){
    }
    this.setState({btn : false})

  })
}
search1 =(e)=>{
  this.setState({s1 : e.target.value.toUpperCase()})
}
serach2=(e)=>{
  this.setState({s2 : e.target.value.toUpperCase()})
}
closemodal =()=>{
  this.setState({modal : !this.state.modal})
}
serachquote =(e)=>{
  let q = e.target.value

}
float =(n)=>{
  switch (parseFloat(n)) {
    case 0.000000001:
    return 9
      break;
    case 0.00000001:
    return 8
      break;
    case 0.0000001:
    return 7
      break;
    case 0.000001:
    return 6
      break;
    case 0.00001:
    return 5
      break;
    case 0.0001:
    return 4
      break;
    case 0.001:
    return 3
      break;
    case 0.01:
    return 2
      break;
    case 0.1:
    return 1
      break;
    default:
      return 0
  }
}

setallquoteamount =(i)=>{
if(i > 0){
  this.setState({amount : i})
}else{ return}
}
togglemodalquote =()=>{
  this.setState({modalquote : !this.state.modalquote})
}
togglemodalbase =()=>{
  this.setState({modalbase : !this.state.modalbase})
}
mw1 =()=>{
  this.setState({mw1 : !this.state.mw1})
}
erasedata =()=>{
    this.setState({
      amount : '',
      res : '',
      x : ''
    })
}

setfee =(base,quote)=>{
  let x = this.state.list
  let market = base+'-'+quote
  let find = x.find((element)=>element.symbolName === market)
  if(find){
    let fee = find.fee * find.zarib
    this.setState({side : 'buy',fee : fee })
    return

  }else{
    let reverse = x.find((element)=>element.symbolName === quote+'-'+base)
    if(reverse){
      let fee = reverse.fee * reverse.zarib

      this.setState({side :'sell', fee : fee  })
      return
    }else{
      // new Toast(`${t('this.market.not.available')}`, {
      //           style: {
      //               container: [
      //                   ['background-color', 'red']
      //               ],
      //               message: [
      //                   ['color', '#eee'],
      //               ],
      //               bold: [
      //                   ['font-weight', '900'],
      //               ]
      //           },
      //           timeout : 3000,
      //           position : 'top'
      //       })
      let m1 = quote+'-USDT'
      let m2 = base+'-USDT'
      let list = this.state.list
      let f1 = list.find((element)=>element.baseCurrency === quote)
      let f2 = list.find((element)=>element.baseCurrency === base)
      console.log(f1);
      console.log(f2);
      let f1fee = f1.fee * f1.zarib
      let f2fee = f2.fee * f2.zarib
      let fee = f1fee + f2fee
      this.setState({side :'swap', fee : fee  })
    }
  }

}
//
// changebase =(e)=>{
//   let x = this.state.markets.concat(this.state.list.slice(1))
//   let amount = e.target.value
//   let quote = this.state.c1
//   let base = this.state.c2
//   let market = base.baseCurrency+'-'+quote.baseCurrency
//   let find = x.find((element)=>element.symbolName === market)
//   if(find){
//     let fee = find.fee * find.zarib
//     let a = amount * base.irsell
//     let b = quote.irbuy
//     let c = a
//     let d = c / b
//     let f = fee * d
//     let g = d + f
//     let inc = this.float(base.baseIncrement)
//     let res = F.round(g,inc)
//     let fa = F.round(f,inc)
//     let ifee = fa * quote.irbuy
//     let irfee = F.round(ifee,0)
//     this.setState({all : a,side : 'buy',amount : res ,res : amount , fee : fee , feeamount : fa , irfee :irfee ,feeCurrency : quote.baseCurrency })
//     return
//
//   }else{
//     let reverse = x.find((element)=>element.symbolName === quote.baseCurrency+'-'+base.baseCurrency)
//     if(reverse){
//       let fee = reverse.fee * reverse.zarib
//       let a = amount * base.irsell
//       let b = quote.irbuy
//       let c = a
//       let d = c / b
//       let f = fee * d
//       let g = d - f
//       let p = quote.precision
//       let q = parseInt(p) + 3
//       let res = F.round(g,p)
//       let fa = F.round(f,q)
//       let ifee = fa * quote.irbuy
//       let irfee = F.round(ifee,0)
//       this.setState({all : a,side :'sell',amount: res ,res : amount , fee : fee , feeamount : fa , irfee :irfee ,feeCurrency : quote.baseCurrency })
//       return
//     }else{
//       // new Toast(`${t('this.market.not.available')}`, {
//       //           style: {
//       //               container: [
//       //                   ['background-color', 'red']
//       //               ],
//       //               message: [
//       //                   ['color', '#eee'],
//       //               ],
//       //               bold: [
//       //                   ['font-weight', '900'],
//       //               ]
//       //           },
//       //           timeout : 3000,
//       //           position : 'top'
//       //       })
//       let m1 = quote.baseCurrency+'-IRR'
//       let m2 = base.baseCurrency+'-IRR'
//       let list = this.state.list
//       let f1 = list.find((element)=>element.symbolName === m1)
//       let f2 = list.find((element)=>element.symbolName === m2)
//       let f1fee = f1.fee * f1.zarib
//       let f2fee = f2.fee * f2.zarib
//       let fee = f1fee + f2fee
//       let a = amount * base.irsell
//       let b = quote.irbuy
//       let c = a
//       let d = c / b
//       let f = fee * d
//       let g = d - f
//       let p = base.precision
//       let q = parseInt(p) + 3
//       let res = F.round(g,p)
//       let fa = F.round(f,q)
//       let ifee = fa * base.irsell
//       let irfee = F.round(ifee,0)
//       this.setState({all : a,side :'swap',amount : res ,res : amount , fee : fee , feeamount : fa , irfee :irfee ,feeCurrency : quote.baseCurrency })
//     }
//   }
//
// }
change =(values)=>{
  let val = values.value
  this.setState({amount : val , erroeamount : false})
}
 round=(v)=> {
    return (v >= 0 || -1) * Math.round(Math.abs(v));
}
setres = ()=>{
  let amount = this.state.amount
  let fee = this.state.fee
  let all = amount * this.state.quoteprice
  let b = all / this.state.baseprice
  let d = fee * b
  let c = b - d
  let p = this.state.c2.bi - 2
  let q = p
  let r = F.floor(c,q)
  let res = F.floor(c,this.state.c2.bi)

  this.setState
  ({res : res ,all : all , r : r})
  return res
}
  render(){
    const res = this.setres()
    let tm  = this.props.darkMode
    let bgcolor  = this.props.theme === true ? '#0b1a2f' : '#f9f9f9'
    const { rangeValues, currentRangeValue } = this.state

    let all = this.state.merged.filter(item => item.baseCurrency.includes(this.state.s1) && item.quoteCurrency === 'USDT');
    let list = this.state.merged.filter(item => item.baseCurrency.includes(this.state.s2)  && item.quoteCurrency === 'USDT');
    const { t } = this.props;
    let baseprice= parseInt(this.state.c2.irsell)
    return(
    <>
      <Modal isOpen={this.state.modalquote} toggle={this.togglemodalquote} className='marketmodal'>
        <div className='d-flex rtl px-3 pt-3 -bottom pb-2'>
        {this.state.c1.symbol === 'IRR' || this.state.c1.symbol === 'USDT' || this.state.c1.symbol === 'FTN' || this.state.c1.symbol === 'PERFECT' ? <></>  :
          <Button onClick={this.mw1} color='primary' size='sm' className='mx-2'>
            {t('show-widget-for-coin')}
          </Button> }
          <Button onClick={this.mw1} color='primary' size='sm' >
          {t('show-widget-for-market')}
          </Button>

          <div className='rtl mr-auto d-flex my-auto'>
            <span className='my-auto ml-2 slug'>{this.state.c1.symbol}</span>
            <span className={this.state.c1.icon +'-icon'}></span>
          </div>

          </div>
        <ModalBody >

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.togglemodalquote}>{t('close')}</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={this.state.modalbase} toggle={this.togglemodalbase} className='marketmodal'>
        <div className='d-flex rtl px-3 pt-3 -bottom pb-2'>
          <Button onClick={this.mw1} color='primary' size='sm' className='mx-2'>
            {t('show-widget-for-coin')}
          </Button>
          <Button onClick={this.mw1} color='primary' size='sm' >
          {t('show-widget-for-market')}
          </Button>

          <div className='rtl mr-auto d-flex my-auto'>
            <span className='my-auto ml-2'>{this.state.c2.symbol}</span>
            <span className={this.state.c2.icon +'-icon'}></span>
          </div>

          </div>
        <ModalBody >

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.togglemodalbase}>{t('close')}</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={this.state.modal}  className={this.state.className}>
        <ModalHeader className='rtl   '>
        <div className='d-flex text-medium mt-2 rtl justify-content-space-between'>
        <span className=' mr-2 text-success text-medium'>{t('success-transaction')}</span>

        </div>
        </ModalHeader>
        <ModalBody className='radius ltr h-400 bg-light'>
        <Row xs='1' className='rtl px-2 mt-4 lh-xl'>
        <Col xl='12'>
        <div className='d-flex mt-3'>
          <p className='rtl  text-medium  mr-2 my-auto'>کد تراکنش : </p><p className='mr-auto my-auto text-medium'>{this.state.response.orderid} </p>
        </div>
        <div className='d-flex mt-3'>
          <span className={this.state.responsequote +'-icon icon my-auto'}></span><p className='rtl  text-medium  mr-2 my-auto'>مقدار پرداختی : </p><p className='mr-auto my-auto text-medium'><NumericFormat value={this.state.response.quoteamount} thousandSeparator="," displayType="text"  /></p>
        </div>

        <div className='d-flex mt-3'>
          <span className={this.state.responsebase +'-icon icon my-auto'}></span><p className='rtl  text-medium  mr-2 my-auto'>مقدار دریافتی : </p><p className='mr-auto my-auto text-medium'><NumericFormat value={this.state.response.baseamount} thousandSeparator="," displayType="text"  /></p>
        </div>
        <div className='d-flex mt-3'>
          <p className='rtl   text-medium mr-2'>تاریخ :  </p><p className='mr-auto  text-medium'>{this.state.response.date}</p>
        </div>

        </Col>
        <Col>

        </Col>


        </Row>

        </ModalBody >
        <ModalFooter className='rtl'>
        <Button color='primary' onClick={this.closemodal}>بستن</Button>
        </ModalFooter>
      </Modal>
    <Helmet>
       <title>سوآپ | رپیتکس</title>
    </Helmet>


    {this.state.loader === true ?
      <>

        <div className='loader-home bg-white py-5'>
          <h3 className='text-center bg-white m-auto mt-auto'><Loader /></h3>
        </div>
      </> :<></>}
    <Card className='rtl'>
    <CardHeader>
    {t('swap')}</CardHeader>{this.state.q}
    <CardBody>
        <Row className='pb-5 rtl'>
        <Col xl='5' className='mt-4 p-3'>
          <div className='radius ml-2 exchange-container'>

          <div className='text-center'>
                <div className='py-2'>
                <FormGroup>
                  <InputGroup size='lg'   className= {this.state.erroeamount === false ?
                     '' : 'invalid-input-group'}>
                  <UncontrolledDropdown>
                    <DropdownToggle size='lg' color='light'  className='px-2  input-custome-button'>
                        <div className='d-flex' >
                        <span className='my-auto'>{t('pay')}</span>
                        <span className='mx-auto'>
                          <span className={this.state.c1.baseCurrency+ '-icon icon my-auto'} ></span>
                        </span>
                        <span className='mr-1 slug my-auto mr-auto'>
                          {this.state.c1.baseCurrency}
                        </span>
                        </div>
                    </DropdownToggle>
                  <DropdownMenu right className='bg-white  px-2'>

                    <div className='search rtl' >
                      <Input onChange={this.search1} value={this.state.s1}  placeholder='' type='search' dir='ltr' className='search-input ltr radius' />
                    </div>
                    <DropdownItem divider />
                    <div className='historylist-container custom-overflow'>
                    {all.map(item=>
                      <DropdownItem  className={item.baseCurrency === this.state.c2.baseCurrency ? 'd-none': 'd-flex py-2'} onClick={()=>this.seti1(item)} >
                      <span className=' ml-5'>
                        <span className={item.baseCurrency+ '-icon'} ></span>
                      </span>
                      <span className=' mr-2'>
                        <NumberFormat value={item.availablebalance} displayType='text' thousandSeparator=',' />
                      </span>
                      <span className=' mr-auto'>
                        {item.baseCurrency}
                      </span>

                      </DropdownItem>
                    )}
                    </div>
                  </DropdownMenu>
                    </UncontrolledDropdown>
                  <NumberFormat
                   className='swap-input form-control ltr'
                   name='amount'
                   inputMode="decimal"
                   thousandSeparator=','
                   value={this.state.amount}
                   onValueChange={(values)=>this.change(values)}
                   />
                  </InputGroup>
                 <div className='d-flex text-muted mt-1'>
                 <small color='success' >
                 <NumericFormat value={this.state.quoteprice} prefix='قیمت :  '   thousandSeparator="," displayType="text"  />
                 </small >
                 <FormText color='success' className='ltr pointer mr-auto' onClick={()=>this.setallquoteamount(this.state.c1.availablebalance)}>
                  <NumericFormat value={this.state.c1 !== '' ? this.state.c1.availablebalance : 0 }   thousandSeparator="," displayType="text"    suffix={' '+ this.state.c1.baseCurrency + ' '} />
                 </FormText >
                 {this.state.c1.baseCurrency === 'IRR' ?
                 <Link to='/panel/depositirt'>
                 <LiaCartPlusSolid size='1.3rem' className='wallet mr-2 text-muted pointer' />
                 </Link> : <Link to='/panel/depositcrypto'>
                 <LiaCartPlusSolid size='1.3rem' className='wallet mr-2 text-muted pointer' />
                 </Link>}
                 </div>
                 <FormText className='rtl  text-muted'>
                 {this.state.c1.symbol === 'IRR' ? <small className='text-primary rtl' >{this.state.x}</small>: <></> }
                 </FormText>

                  </FormGroup>

                </div>

                <div className='mt-5'>
                <FormGroup>
                <InputGroup size='lg' className='mt-3'>
                    <UncontrolledDropdown>
                  <DropdownToggle size='lg'  color='light' className='px-2  input-custome-button'>
                  <div className='d-flex' >
                  <span className='my-auto'>{t('recieve')}</span>
                  <span className='mx-auto'>
                    <span className={this.state.c2.baseCurrency + '-icon icon my-auto'} ></span>
                  </span>
                  <span className='mr-1 slug my-auto mr-auto'>
                    {this.state.c2.baseCurrency}
                  </span>

                  </div>

                  </DropdownToggle>
                  <DropdownMenu right className='bg-white  px-2'>

                    <div className='search rtl' >
                      <Input onChange={this.serach2} value={this.state.s2} placeholder='' type='search' dir='ltr' className='search-input ltr radius' />
                    </div>
                    <DropdownItem divider />
                    <div className='historylist-container custom-overflow'>
                    {list.map(item=>
                      <DropdownItem className={item.baseCurrency === this.state.c1.baseCurrency ? 'd-none': 'd-flex py-2'} onClick={()=>this.seti2(item)} >
                      <span className=''>
                        <span className={item.baseCurrency+ '-icon'} ></span>
                      </span>
                      <span className=' mx-5'>
                        <NumberFormat value={item.availablebalance} displayType='text' thousandSeparator=',' />
                      </span>
                      <span className=' mr-auto '>
                        {item.baseCurrency}
                      </span>

                      </DropdownItem>
                    )}
                    </div>
                  </DropdownMenu>
                    </UncontrolledDropdown>
                    <NumberFormat
                    onClick={this.setbaseactive}
                     className='swap-input form-control ltr'
                     thousandSeparator=','
                     name='res'
                     inputMode="decimal"
                     value={res}
                    disabled
                     />
                 </InputGroup>
                 <div className='rtl mt-1  text-muted'>
                   <small color='success' >
                   <NumericFormat value={this.state.baseprice} prefix='قیمت :  '   thousandSeparator="," displayType="text"  />
                   </small >
                 </div>
                 <div className='d-flex' >
                 { this.state.i2 === 'irr' ? <small className='text-primary rtl' >{this.state.g}</small> : <></> }
                 </div>
                 </FormGroup>

                <FormGroup className='px-3'>
                <small className='text-danger rtl' >{this.state.error}</small>
                </FormGroup>
              </div>
              <DropdownItem divider />

              <div className='tx-detail-section'>
              <p className='lead rtl my-3'>{t('transaction-details :')}</p>
                <small className='d-flex'>
                  <span className=' w-25 rtl'>
                    {t('payment :')}
                  </span>
                  <span className='text-muted w-25 ltr'>
                    <NumberFormat value={this.state.amount} thousandSeparator=',' displayType='text' />
                  </span>
                  <span className='text-muted mr-3'>
                    {this.state.c1.baseCurrency}
                  </span>
                </small>
                <small className='d-flex my-2'>
                  <span className=' w-25 rtl'>
                    {t('recieve :')}
                  </span>
                  <span className='text-muted w-25  ltr'>
                    <NumberFormat value={res} thousandSeparator=',' displayType='text' />
                  </span>
                  <span className='ml-0 mr-3 text-muted'>
                  {this.state.c2.baseCurrency}
                  </span>
                </small>


                <small className='d-flex'>
                  <span className=' w-25 rtl'>
                    {t('feerate :')}
                  </span>
                  <span className='text-muted w-25 ltr' >
                    <NumberFormat value={this.state.fee * 100} thousandSeparator=',' displayType='text' />%
                  </span>
                  <span className='text-muted mr-3 ltr' >
                    <NumberFormat value={this.state.fee} thousandSeparator=',' displayType='text' />
                  </span>

                </small>
              </div>

          </div>
          <DropdownItem divider />
          <div className='mt-5 py-2 px-3 d-flex'>
            <Button className='w-50 py-2 ml-auto' disabled={!this.state.bds} onClick={this.submit} color='primary'>تبدیل</Button>
            <CiEraser className='mr-auto my-auto  pointer' onClick={this.erasedata} size='1.3rem'/>
          </div>
          </div>
          <div className='d-block d-sm-none py-5'>
              <DropdownItem divider />
          </div>
        </Col>
        <Col className='' xl='7' lg='7' md='12' sm='12' xs='12'>
        <div className='d-flex mt-3 mr-3'>
          <small onClick={()=>this.setState({mode : 2})} className={this.state.mode === 2 ? 'active-tab mx-3' : 'mx-3' }>{t('marketoverview')}</small>
          <small onClick={()=>this.setState({mode : 3})} className={this.state.mode === 3 ? 'active-tab' : '' }>{t('TechnicalAnalysis')}</small>
        </div>
        <div className='mt-3'>

        {this.state.mode === 2 ?
        this.state.c2.symbol === 'IRR' || this.state.c2.symbol === 'USDT' || this.state.c2.symbol === 'FTN' || this.state.c2.symbol === 'PERFECT' ? <>
        </> :
        this.props.darkMode === true ?
        <CryptoCoinsHeatmap gridColor = "#18203e00"
        backgroundColor = "#18203e00" isTransparent={true} colorTheme="dark"></CryptoCoinsHeatmap>
          :
          <CryptoCoinsHeatmap gridColor = "#18203e00"
          backgroundColor = "#18203e00" isTransparent={true} colorTheme="light"></CryptoCoinsHeatmap>
         :
         <></>}
        {this.state.mode === 3 ?
        this.state.c2.symbol === 'IRR' || this.state.c2.symbol === 'USDT' || this.state.c2.symbol === 'FTN' || this.state.c2.symbol === 'PERFECT' ? <>
        </> :
        this.props.darkMode === true ?
        <TechnicalAnalysis symbol={this.state.base+'USDT'} height='100%'  backgroundColor = "#0b1a2f" isTransparent={true} colorTheme="dark" width="100%"></TechnicalAnalysis>

          :
          <TechnicalAnalysis symbol={this.state.base+'USDT'} height='100%' backgroundColor = "#f9f9f9" colorTheme="light" width="100%"></TechnicalAnalysis>

         :
         <></>}
         </div>

        </Col>

        </Row>
    </CardBody>
    </Card>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
