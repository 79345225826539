import React from 'react'
import {pnk} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import { useTranslation, withTranslation } from 'react-i18next';
import {Row,Col} from 'reactstrap'
class About extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }
componentDidMount(){
  window.scroll(0,0)
}

  render(){
      const { t } = this.props;
    return(
      <>
      <Helmet>
        <title>{t('about-us.title')}</title>
      </Helmet>
      <div className='h-400 pt-10'>
      <Row className='pb-5' xl='1' lg='1' md='1' sm='1' xs='1'>
        <Col className='header-bg-dark'>
        </Col>
        <Col>
          <div className=''>
              <h1 className='rtl text-dark'>{t('about.p1')}</h1>
              <Row   className='pb-5 ' lg='1' md='1' xl='1' sm='1' xs='1'>

              <Col className='rtl px-3 '>
                  <p className='lead my-3 rtl text-dark'>{t('about.p2')}
                  </p>
              </Col>


              </Row>
              <hr/>
              <Row>
                <Col>
                  <div className='rtl lead text-dark px-2 mt-5 lh-xl'>
                    <p>{t('about.p3')}
                    </p>
                    <p>{t('about.p4')}
                    </p>
                    <p>{t('about.p5')}
                    </p>
                    <p>{t('about.p6')}
                    </p>
                  </div>
                </Col>
              </Row>
          </div>
        </Col>
      </Row>
</div>
      </>
    )
  }
}
export default withTranslation()(About)
