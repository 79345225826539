import React from 'react';
import {Row,Col,Form,FormGroup,Button,Table} from 'reactstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'
import cookie from 'react-cookies'
import SimpleLineIcon from 'react-simple-line-icons'

import NumberFormat from 'react-number-format';
import { Carousel } from 'react-responsive-carousel'

class Fees extends React.Component{
  constructor(props){
    super(props);
    this.state={
  markets : [],
      s1 : ''
    }



  }
componentWillMount(){
  axios.get('/api/market/').then(res=>{
    this.setState({markets : res.data.irrmarket , loader : false})
    this.markets = res.data
  })
}
setspread =(values , sourceInfo)=>{
let s = values.value
const data = new FormData()
data.append('spread' , s)
data.append('coin' , sourceInfo)
setTimeout(()=>
axios.post('/api/admin/edit_coin_spread' , data).then(res=>{

}) , 1)
return
}
render(){
// console.log(this.props);
let data = this.state.markets.filter(item => item.baseCurrency.includes(this.state.s1));


  return(
    <>
      <div className='rtl h-600 pt-10'>
        <h4>Coins Fee</h4>
        <Table size='sm' borderless responsive striped>
          <thead>
            <tr>
              <td>coin
              </td>

              <td>irbuy
              </td>
              <td>sell
              </td>
              <td>buy
              </td>
              <td>irsell
              </td>
              <td>spread
              </td>
              <td>coin
              </td>
              <td>irspread
              </td>
              <td>action
              </td>
            </tr>
          </thead>
          <tbody>
            {data.map(i=>
              <tr>
                <td>{i.baseCurrency}
                </td>

                <td><NumberFormat value={i.irbuy} displayType='text' thousandSeparator=',' />
                </td>
                <td><NumberFormat value={i.sell} displayType='text' thousandSeparator=',' />
                </td>
                <td><NumberFormat value={i.buy} displayType='text' thousandSeparator=',' />
                </td>
                <td><NumberFormat value={i.irsell} displayType='text' thousandSeparator=',' />
                </td>
                <td>
                <NumberFormat name={i.baseCurrency} value={i.spread}   onValueChange={(values)=>this.setspread(values , `${i.baseCurrency}`)} displayType='input' thousandSeparator=',' className='rtl text-center' />
                </td>
                <td>{i.baseCurrency}
                </td>
                <td><NumberFormat value={i.spread} displayType='text' thousandSeparator=',' />
                </td>
                <td><SimpleLineIcon name='list' color='#3992b5' />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  )
}
}
export default Fees
