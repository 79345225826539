import React from 'react'
import {Table,Modal,ModalBody,ModalFooter,ModalHeader,UncontrolledDropdown,UncontrolledButtonDropdown,Row,Form,FormGroup,Label,Input,Col,Card,CardBody,InputGroup,InputGroupText,FormText,Button, Dropdown,DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import { GoAlert } from "react-icons/go";
class Homepage extends React.Component{
  constructor(props){
    super(props);
    this.state={

      mainmodal : true,
    }

  }

render(){

  return(
    <>
    <Modal fade='false'  className='rtl' isOpen={this.state.mainmodal} >
      <ModalHeader>
      <div className='d-flex'>
      <GoAlert className='mt-2 mx-3' color='orange' />
        <p className='mt-2'>هشدار امنیتی</p>
      </div>
      </ModalHeader>
      <ModalBody className='lh-xl'>
      <li className='text-medium'>طبق قوانین سایت رپیتکس متذکر میشویم از در اختیار گزاشتن حساب کاربری خود به افراد دیگر پرهیز کنید.</li>
      <li className='text-medium'>در صورت محرز شدن تخلف تمام دارایی های شما بلوکه و مراتب به مراجع قانونی اطلاع داده خواهد شد.</li>
      <li className='text-medium'>هرگونه اعتراض بعدی به هیچ وجه قابل قبول نمیباشد.</li>
      </ModalBody>
      <ModalFooter onClick={()=>this.setState({mainmodal : false})} className='bg-primary pointer  text-light'>
        <p className='text-center mx-auto pointer' >متوجه شدم</p>
      </ModalFooter>
    </Modal>
    </>
)
}
}
export default Homepage
