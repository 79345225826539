import React from 'react'
import Navbar from './navbar'
import {
  Route,Switch,withRouter
  } from "react-router-dom";
import axios from 'axios'
  import {Button ,List , Row,Col,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import Footer from './footer'
import {Link} from 'react-router-dom'
import SimpleLineIcon from 'react-simple-line-icons'

class Homelayer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      coin : 'BTC',
      coinname : 'بیت کوین',
      coinPrice : 16800,
      buysell : 'buy',
      sidebar : false
    }
  }
componentWillMount(){
  axios.get('/api/address/get_address').then(res=>{
    this.setState({address : res.data.address , phone : res.data.phone})
  })
}
  render(){

    const {match} = this.props
    return(
      <>
      <div className=''>
        {this.props.children}
      </div>
      {this.state.sidebar === true ? <>
        <div className='sidebar'>
          <p>menu</p>
        </div>
        </> : <></>
      }
      <Footer address={this.state.address} phone={this.state.phone} />
      <div className='mobile-nav d-none d-sm-none bg-dark'>

     </div>
     </>
    )
  }
}
export default withRouter(Homelayer)
