import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useHistory } from "react-router-dom";
import NumberFormat from 'react-number-format'
import {Table} from 'reactstrap'
const Socket = (props) => {
  //Public API that will echo messages sent to it back to the client
  const [socketUrl, setSocketUrl] = useState(props.url);
  const [messageHistory, setMessageHistory] = useState([]);
  const [asks, setAsks] = useState([]);
  const [bids , setbids] = useState([]);
  const [t , setType] = useState(3);
  const [wsevent , setEvent] = useState('loding');
  const [currentprice , setCurrenntprice] = useState(0);
  var c = {
      "id": props.id,
      "type": "subscribe",
      "topic": "/spotMarket/level2Depth50:" + props.market,
      "privateChannel": false,
      "response": true
    }

    const history = useHistory();

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => sendMessage(JSON.stringify(c)),
    onMessage : (event)=>{

    var data = JSON.parse(event.data)
    setEvent(data.type)
    if(data.type === 'message'){
      setAsks(data.data.asks)
      setbids(data.data.bids)
      if(data.data.asks.length > 2){
        setCurrenntprice(data.data.asks[0][0])
      }else{

      }
    }else{
    }


    } ,
    // onClose : (event)=> history.push("/"),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });



  const settype=(n) =>setType(n)
  const handleClickSendMessage = useCallback(() => sendMessage('Hello'), []);


  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];
  return(
    <>
    {wsevent == 'message' ?
    <div className='rtl'>
    <div className='mc-header-2 rtl'>
      <div className='pointer' onClick={()=>settype(3)} >
      <svg   width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1759_17137)"><rect x="0.7" y="0.7" width="14.6" height="14.6" rx="1.3" stroke="#01BC8D" stroke-width="1.4"></rect><rect x="3" y="8.5" width="2" height="5" fill="#01BC8D"></rect><rect x="3" y="2.5" width="2" height="5" fill="#F66754"></rect><rect x="6" y="2.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect><rect x="6" y="5.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect><rect x="6" y="8.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect><rect x="6" y="11.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect></g><defs><clipPath id="clip0_1759_17137"><rect width="16" height="16" fill="white"></rect></clipPath></defs></svg>
      </div>


      <div className='mx-2 pointer' onClick={()=>settype(2)} >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1759_17145)"><rect x="0.7" y="0.7" width="14.6" height="14.6" rx="1.3" stroke="#F3F3F3" stroke-opacity="0.12" stroke-width="1.4"></rect><rect x="3" y="2.5" width="2" height="11" fill="#F66754"></rect><rect x="6" y="2.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect><rect x="6" y="5.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect><rect x="6" y="8.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect><rect x="6" y="11.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect></g><defs><clipPath id="clip0_1759_17145"><rect width="16" height="16" fill="white"></rect></clipPath></defs></svg>
      </div>
      <div className='pointer' onClick={()=>settype(1)} >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1759_17152)"><rect x="0.7" y="0.7" width="14.6" height="14.6" rx="1.3" stroke="#F3F3F3" stroke-opacity="0.12" stroke-width="1.4"></rect><rect x="3" y="2.5" width="2" height="11" fill="#01BC8D"></rect><rect x="6" y="2.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect><rect x="6" y="5.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect><rect x="6" y="8.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect><rect x="6" y="11.5" width="7" height="2" fill="#F3F3F3" fill-opacity="0.12"></rect></g><defs><clipPath id="clip0_1759_17152"><rect width="16" height="16" fill="white"></rect></clipPath></defs></svg>
      </div>


      </div>
      <div className='o-l-c'>
      <div id='ask-bid' className='custom-overflow'>

      {t=== 3 ?<>
              <div className='asks rtl'>
              {asks.map(i=>
                <div  className='d-flex'>


                  <p data-amount= {i[1]} onClick={((e)=>props.set(e))} className='w-50 rtl ml-auto'>{i[1]}</p>
                  {props.usdt !== 1 ?
                  <NumberFormat  displayType='text' decimalScale={props.decimalbi} thousandSeparator=',' data-price= {i[0]}  className='rtl text-success' value={i[0]} /> : <></> }

                  <NumberFormat displayType='text' decimalScale={props.decimal} thousandSeparator=',' data-price= {i[0] * props.usdt} data-priceusd= {i[0]} onClick={((e)=>props.setp(e))} className='ltr mr-auto text-success' value={i[0] * props.usdt} />
                </div>
              )}
              </div>
              <div className='mc-header-3'>
              <div className='ltr'>

              {props.usdt !== 1 ?
                <div className='d-flex ltr pt-1 pb-2'>
                <NumberFormat id='current-price' data-priceusd= {currentprice} data-price= {currentprice  * props.usdt}  decimalScale={props.decimal} onClick={props.setp}  allowNegative={false}   className='' displayType='text' thousandSeparator=',' value={currentprice * props.usdt} />
                <span className='ltr mx-1'>&#8771;     </span>

                 <NumberFormat className='ltr' displayType='text' data-priceusd= {currentprice} data-price= {currentprice  * props.usdt}  decimalScale={props.decimal} thousandSeparator=','  value={currentprice} />

                 </div> :
                <p className='ltr'>
                  <NumberFormat id='current-price' data-priceusd={currentprice} data-price={currentprice * props.usdt} decimalScale={props.decimal} onClick={((e)=>props.setp(e))}  allowNegative={false}   className='' displayType='text' thousandSeparator=',' value={currentprice * props.usdt} />
                </p> }
              </div>
              </div>
              <div className='bids'>

              {bids.map(i=>
                <div className='d-flex'>


               <p data-amount= {i[1]} onClick={((e)=>props.set(e))} className='w-50 rtl ml-auto'>{i[1]}</p>
              {props.usdt !== 1 ? <NumberFormat  displayType='text' decimalScale={props.decimal} thousandSeparator=',' data-price= {i[0]} className='rtl text-danger' value={i[0]} /> : <></> }

            <NumberFormat displayType='text' decimalScale={props.decimal} thousandSeparator=',' data-priceusd= {i[0]} data-price= {i[0]  * props.usdt} onClick={((e)=>props.setp(e))} className='ltr mr-auto text-danger' value={i[0] * props.usdt} />
                </div>
              )}

              </div>
              </> : <></> }
      {t=== 1 ?
              <div className='ask-bid-full'>
              {asks.map(i=>
                <div data-amount= {i[1]} data-price= {i[0]} className='px-3 py-1 d-flex '>


                  <p data-amount= {i[1]} onClick={((e)=>props.set(e))} className='ml-auto'>{i[1]}</p>
                  <NumberFormat displayType='text'   decimalScale={props.decimal} thousandSeparator=',' data-priceusd= {i[0]} data-price= {i[0]  * props.usdt} onClick={((e)=>props.setp(e))} className='mr-auto text-success' value={i[0] * props.usdt} />
                </div>
              )}

              </div> : <></> }
      {t=== 2 ?
              <div className='ask-bid-full'>
              {bids.map(i=>
                <div data-amount= {i[1]} data-price= {i[0]} className='px-3 py-1 d-flex'>


                <p data-amount= {i[1]} onClick={((e)=>props.set(e))} className='ml-auto'>{i[1]}</p>
                <NumberFormat displayType='text' decimalScale={props.decimal} thousandSeparator=',' data-priceusd= {i[0]} data-price= {i[0]  * props.usdt} onClick={((e)=>props.setp(e))} className='mr-auto text-danger' value={i[0] * props.usdt} />
                </div>
              )}

              </div> : <></> }

      </div>
      </div>
    </div> : <>{props.loading}</> }
    </>
  )
};

export  {Socket}
