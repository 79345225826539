import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import F from 'float'
import Select from 'react-select';
import {FormText,
   Modal,ModalFooter, ModalBody,Card,Alert, CardHeader,FormGroup,Form,Label, CardBody, InputGroup, InputGroupText, Input,Col,Row ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Button} from 'reactstrap'
import {Helmet} from 'react-helmet'
import { withTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
import NumberFormat from 'react-number-format';
import { PatternFormat } from 'react-number-format';
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data : [],
      mode : 2 ,
      list : [],
      chains : [],
      currntchain : [],
      small : [],
      c1 : [],
      coin : [],
      txs : [],
      error : '',
      CardNo : '',
      cname : '',
      modal : false,
      button : true,
      chain : true,
      n : 0,
      fee : 0,
      des : '',
      key : '3'
    }
this.validator = new SimpleReactValidator();
  }
  componentWillMount(){

    this.getstate()
  }
  getstate = ()=>{
    const {t} =  this.props
    axios.get('/api/Finance/get_bank_accounts').then(response=>{
      if(response.data.status === 'error-fatal'){
        this.props.history.push('/Auth/Login')
        return
      }
      if(response.data.status === 'error'){
        MySwal.fire({
          position: 'center',
         icon: 'error',
         title: <p className='rtl text-center'>{t(response.data.message)}</p>,
         showConfirmButton: false,
         timer: 4000,
     })
      }
      this.setState({accounts : response.data.banks , nationalID : response.data.nationalID})
    })
  }
  setCardno =(value)=>{
    this.setState({CardNo : value.value})
  }
  step1 =(e)=>{
    const {t} = this.props
    e.preventDefault();
    if(this.state.CardNo.length !== 16){
      this.setState({error : true , message : `${t('card-no-length-error')}`})
      MySwal.fire({
        position: 'center',
       icon: 'error',
       title: <p className='rtl text-center'>{t('card-no-length-error')}</p>,
       showConfirmButton: false,
       timer: 4000,
   })
    return
    }
    if(this.state.CardNo < 0 ){
      MySwal.fire({
        position: 'center',
       icon: 'error',
       title: <p className='rtl text-center'>{t('card-no-length-error')}</p>,
       showConfirmButton: false,
       timer: 4000,
   })
    return
    }
    this.setState({button : false})
    const formdata = new FormData()
    formdata.append('CardNo' , this.state.CardNo)
    formdata.append('nationalID' , this.state.nationalID)
    axios.post('/api/finance/check_new_account',formdata).then(response=>{
      this.setState({button : true})
      if(response.data.status === 'success'){
        // alert(response.data.status)
        MySwal.fire({
          position: 'center',
         icon: 'success',
         title: <p className='rtl text-center'>{t('new-bank-account-added')}</p>,
         html :
         <div>
          <p className='rtl mt-3 text-medium'><span className='text-muted ltr'>{t('card-number : ')}</span><NumberFormat format="####    ####    ####    ####" displayType='text' className='mr-3' value={response.data.accountNo} /></p>
          <p className='rtl my-2 text-medium'><span className='text-muted ltr'>{t('shaba')}</span>IR<NumberFormat  format=" ##################################" displayType='text' className='mr-3' value={response.data.shaba} /></p>
          <p className='rtl text-medium'><span className='text-muted rtl'>{t('bank-name')}</span><span displayType='text' className='mr-3'>{response.data.bankname}</span></p>

         </div>,
         showConfirmButton: false,
         timer: 4000,
     })
      }

      if(response.data.status === 'error-fatal'){
        this.props.history.push('/Auth/Login')
        return
      }
      if(response.data.status === 'error'){
        MySwal.fire({
          position: 'center',
         icon: 'info',
         title: <p className='rtl text-center'>{t(response.data.message)}</p>,
         showConfirmButton: false,
         timer: 4000,
     })
      }


    })


  }
  render(){

    const { t } = this.props;
    // console.log(this.state);
    return(
    <>
    <Helmet>
       <title>ثبت حساب بانکی  | رپیتکس</title>
    </Helmet>
    <Card className='rtl'>
    <CardHeader>
      <div className='d-flex rtl'>
       <p onClick={()=>this.setState({type : 1})} className={this.state.type === 1 ? 'pointer' : 'pointer' }>ثبت کارت بانکی</p>
      </div>
    </CardHeader>
            <CardBody >
            <Row>
            <Col xl={{size:6 , offset:3}} lg={{size:8 , offset:2}} md='12' sm='12' xs='12' className='mx-auto pt-5'>
              <div className='add-containe mt-3' dir='rtl'>
                <Form>
                  <FormGroup>
                  <Label className='required'>شماره کارت 16 رقمی
                  </Label>
                    <NumberFormat style={{    'text-align': 'left',
    'direction': 'initial'
}}

                    className='form-control ltr font-weight-bold'
                     displayType='input'
                     name='CardNo'
                     value={this.state.CardNo}
                     onValueChange={(value)=>this.setCardno(value)}
                     format="####    ####    ####    ####"
                     inputMode="decimal"
                     />

                    <FormText className='rtl'>
                      {t('card-submit-note1')}
                    </FormText>

                  </FormGroup>
                  <FormGroup>
                    <Input  disabled name='' value={this.state.nationalID}  type='text'/>

                  </FormGroup>
                  <FormGroup>
                    <Button  className='px-3 mt-5' disabled={!this.state.button} color='primary' block onClick={this.step1}>
                      {t('submit-card')}
                    </Button>
                  </FormGroup>
                </Form>
              </div>
              </Col>
              <Col className='text-dark info-col'>
                <p className='p-3 mt-3 text-dark'>
              {t('submit.card.header-note')}
                </p>
                <li className='p-3 text-medium text-dark'>
                  {t('submit.card.note1')}
                </li>
                <li className='p-3 text-medium text-dark'>
                  {t('submit.card.note2')}
                </li>
                <li className='p-3 text-medium text-dark'>
                  {t('submit.card.note3')}
                </li>
                <li className='p-3 text-medium text-dark'>
                  {t('submit.card.note4')}
                </li>
              </Col>
            </Row>

            </CardBody>
    </Card>

    <Modal isOpen={this.state.modal}  className='otp-modal'>
        <ModalBody>
          <p className='rtl'>برای تکمیل فرآیند برداشت کد اعتبارسنجی ارسال شده  را وارد نمائید</p>
          <FormGroup  >
          < div>
            <Input
              onChange={this.onchange}
              className=''
              id="otp"
              name="otp"
              placeholder="کد اعتبارسنجی"
              type="text"
              value = {this.state.otp}
            />
          </ div>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={this.withdraw}>برداشت</Button>{' '}
          <Button color="secondary" onClick={() => this.setState({modal : false})}>انصراف</Button>
        </ModalFooter>
      </Modal>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
