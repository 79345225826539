import React from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import { useTranslation, withTranslation } from 'react-i18next';
import {Row,Col,Table,Container} from 'reactstrap'
import axios from 'axios'
import SearchInput, {createFilter} from 'react-search-input'
import NumericFormat from 'react-number-format';
import {AiOutlineStock} from 'react-icons/ai'
import SimpleLineIcon from 'react-simple-line-icons'
import Switch from "react-switch";

import cookie from 'react-cookies'

class About extends React.Component{
  constructor(props){
    super(props)
    this.state={
      cs : [],
      ms : [],
      searchTerm : '',
      checked : false
    }

  }
componentWillMount(){
  window.scroll(0,0)
this.getdata()
}
getdata = ()=>{
  axios.get('/api/market').then(response=>{
    let d = response.data
    this.setState({ cs : d.markets , ms : d.irrmarket})
  })
}
forwardmarket =(i)=>{
  let slug = i.slug
  return '/market/marketplace/'+slug+'-USDT'
}
forwardbuysell =(i)=>{
   if(cookie.load('uid')){
  return '/panel/exchange/'+i.value
}else{
  return '#'
}
}
handleChange = (checked) =>{
    this.setState({ checked : !this.state.checked });
  }

serach = (e)=> {
   this.setState({searchTerm: e.target.value})
 }
  render(){
    const x = this.state.cs.slice(0).filter(createFilter(this.state.searchTerm , ['baseCurrency']  ))
    const data = x.sort(function(a, b) { return b.averagePrice - a.averagePrice; });
      const { t } = this.props;
    return(
      <>
      <Helmet>
        <title>Rapitex | {t('cryprto-list-title')} | رپیتکس</title>
      </Helmet>
      <div className='bg-light hl-xl rtl mt-5 px-3 pt-4'>
        <h5 className='text-center py-4'>
        لیست ارزهای رپیتکس
        </h5>
        <p className=' font-weight-b lh-xl old text-dark'>
        قیمت ارز دیجیتال در بازار رپیتکس چطور محاسبه می‌شود؟
        </p>
        <p className='font-weight-bold text-dark lh-xl'>
        قیمت ارز دیجیتال در بازار ایران، بر اساس قیمت جهانی ارزهای دیجیتال و نرخ تبدیل ارز به ریال در ایران تعیین می‌شود. بیشترین معاملات ارز دیجیتال به تتر صورت می‌گیرد؛ بنابراین قیمت فوری ارز دیجیتال به ریال از طریق ضرب قیمت آن به تتر در بازارهای جهانی حاصل می‌شود. برخلاف اعتقاد عمومی، نرخ تتر معادل نرخ دلار نقدی، که توسط بانک مرکزی یا حتی در بازار آزاد ایران اعلام می‌شود، نیست. در عوض، بر اساس عرضه و تقاضا در بازارهای ایران تعیین می‌شود. اما برای تخمین دقیق نرخ تتر، در تبدیل هر دلار آمریکا به تتر در بازارهای جهانی، معمولاً 3 تا 7 درصد از مبلغ به عنوان هزینه تبدیل، بسته به روش واریز، کسر می‌شود. به این ترتیب، قیمت لحظه‌ای ارز دیجیتال در رپیتکس با قیمت ارز دیجیتال در بازار جهانی تفاوت چندانی ندارد و قیمت لحظه‌ای ارز دیجیتال به ریال نیز بر اساس تغییرات نرخ تتر، تغییر خواهد کرد.
        </p>
      </div>
      {this.state.cs.length === 0 ?
        <div className='h-1000 py-5 px-3 mx-3'>
        <p className='text-dark text-center pt-10'>در حال دریافت  اطلاعات
        </p>
        </div>
       :<div className='hv-100 pt-10'>

     <Container className='py-4'>
     <div className='crypto-serach my-auto'>
     <input className='w-100 pl-4 py-3 ltr' onChange={this.serach} placeholder='جستجو' />

     </div>
       <Table size='small' borderless responsive className='rtl lh-xl'>
       <thead >
        <tr>
           <td>
            نماد
           </td>
           <td>قیمت خرید</td>
           <td>قیمت فروش</td>

           <td >
          عملیات
          </td>
         </tr>
       </thead>
       <tbody>
      {data.slice(1).map(i=>
        <tr className='rtl' >

          <td >
            <div className='d-flex'>
            <small className={i.baseCurrency+'-icon absolute'}></small>
            <small className='text-dark mr-5 my-auto'>{i.baseCurrency}</small>
            </div>
          </td>
          <td><NumericFormat value={this.state.checked === false ? parseFloat(i.irsell) : parseFloat(i.averagePrice)}  thousandSeparator="," displayType="text"  className='font-weight-bold text-dark my-auto' /></td>
          <td><NumericFormat value={this.state.checked === false ? parseFloat(i.irbuy) : parseFloat(i.averagePrice)}  thousandSeparator="," displayType="text"  className='font-weight-bold text-dark my-auto' /></td>

          <td>
            <div className='d-flex px-2'>
              <Link to={this.forwardmarket(i)} color='primary' block className='text-small radius btn-primary btn btn-small px-5'>معامله</Link>
              <Link to={this.forwardbuysell(i)} block className='text-small w-50 radius mr-2 btn-primary btn btn-small px-3'>واریز - برداشت</Link>
            </div>
          </td>

        </tr>
         )}
         </tbody>
         </Table>
         </Container>

         </div>}
      </>
    )
  }
}
export default withTranslation()(About)
