import React from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import { useTranslation, withTranslation } from 'react-i18next';
import {Row,Col,UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle} from 'reactstrap'
import axios from 'axios'
import Toggle from './toogle'
class About extends React.Component{
  constructor(props){
    super(props)
    this.state={
      data : [],

    }

  }
componentDidMount(){
  window.scroll(0,0)
  axios.get('/api/Admin/get_posts').then(response=>{
    var d = response.data
    this.setState({data : d})

  })
}

  render(){
    const options = { indent_size: 4, space_in_empty_paren: true ,end_with_newline: true,selector_separator_newline :':'}
      const { t } = this.props;
    return(
      <>
      <Helmet>
        <title>{t('سوالات متداول و راهنمایی  | صرافی رپیتکس')}</title>
      </Helmet>
      <div className='h-400 pt-10 help'>
      <h5 className='rtl alert alert-primary'>سوالات متداول</h5>
        <Row className='mt-3 text-dark rtl' xl='1'>
          {this.state.data.map(i=>
            <Col  xl='12' className='px-3'>
            <h3 className='my-5 mx-5'>{i.name}</h3>
              {i.data.map(s=>
                <div id={s.subject}>
                <Toggle id={i.subject} subject={s.subject} content={s.content} />
                </div>
              )}
            </Col>
          )
          }
        </Row>
      </div>
      </>
    )
  }
}
export default withTranslation()(About)
