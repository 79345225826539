import React from 'react'
import Footer from './footer'
import Navbar from './navbar'
import {
  Switch,Route,Link
} from "react-router-dom";
import AOS from "aos"
import SimpleReactValidator from 'simple-react-validator'
import Login from './login'
import Signup from './signup'
import Gc from './signup/gc'
import Fg from './forgotpass'
import Rs from './resetpass'
import logo from '../../style/images/xs-icon.png'
import SimpleLineIcon from 'react-simple-line-icons'
import {Row,Col} from 'reactstrap'
import {FaUncharted} from 'react-icons/fa'
import cookie from 'react-cookies'
import {useTranslation,withTranslation} from 'react-i18next'
class AuthLayer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      active : 2
    }
    this.validator = new SimpleReactValidator();
  }

componentWillMount(){
  window.scroll(0,0)
  if(cookie.load('uid')){
    this.props.history.push('/panel/dashboard')
  }
  var url = window.location.href
  // console.log(url)
  this.setState({url : url})
  if(url === 'https://rapitex.com/Auth/Login/'){
    this.setState({active : 2})
  }else{
      this.setState({active : 1})
  }
}
componentDidMount(){
  // console.log(this.props.getdata);
  // window.scrollTo(0, 0)
  AOS.init({
    offset: 200,
     duration: 1000,
     easing: 'zoom-in',
     delay: 300,
   });
  setTimeout(
    () =>this.setState({loader : false}),1500
  )

}
setitem = (e)=>{
  this.setState({active : e.index})
  this.props.history.push(e.value)
}
render(){
  const {t} = this.props
  const {match} = this.props
  return(
  <div className='main-auth hue rtl  px-1'>
   <Row>
    <Col sm={{size:10 ,offset:1}} xs='12' xl={{size : 4 , offset :4}} lg={{size : 6}} md={{size : 8}} className='mx-auto mt-5'>
    <div className='d-flex justify-content-space-between pb-3'>
    <Link to='/' className='mx-auto'>
    <div className='text-center'>
        <img src={logo} alt={t('logo-alt')} className='main-logo-dark' />
    </div>
    </Link>
    </div>
      <Switch>
        <Route
          path={`${match.url}/login`}
          render={(props) => <Login {...props} />}
        />
        <Route
          path={`${match.url}/signup`}
          render={(props) => <Signup {...props} />}
        />
        <Route
          path={`${match.url}/complete_signup/:email`}
          render={(props) => <Gc {...props} />}
        />
        <Route
          path={`${match.url}/recover-password`}
          render={(props) => <Fg {...props} />}
        />
        <Route
          path={`${match.url}/Reset_password/:email/:token`}
          render={(props) => <Rs {...props} />}
        />
      </Switch>
  </Col>
  </Row>
  </div>
  )
}
}
export default withTranslation()(AuthLayer)
