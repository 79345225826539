import React from 'react'
import {Helmet} from 'react-helmet'
import { useTranslation, withTranslation } from 'react-i18next';
import {Row,Col} from 'reactstrap'
import img from '../../../style/images/gatewaypayment.svg'
import {GiWallet,GiDungeonGate,GiPayMoney,GiAutoRepair,GiShieldEchoes} from 'react-icons/gi'
import {ImQrcode} from 'react-icons/im'
import {BiBarChartAlt2} from 'react-icons/bi'
import {RiIncreaseDecreaseLine} from 'react-icons/ri'
class Layer extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }


  render(){
      const { t } = this.props;
    return(
      <>
      <Helmet>
         <title>{t('merchant.title')}</title>
         <link rel="canonical" href="https://zarrinpay.com/demo/form" />
     </Helmet>
      <Row xl='1' lg='1' md='1' sm='1' xs='1'>
      <Col className='header-bg-dark'>
      </Col>
      <Col>
        <div className='content-section-1'>
            <p className='ltr title-m'>{t('merchant.p1')}</p>
            <hr className='d-none d-sm-block w-75'/>
            <Row lg='1' md='1' xl='2' sm='1' xs='1'>
              <Col className='ltr'>
              <div>
                <div className='mt-2 d-flex justify-content-space'>
                <h5 className='text-primary  mt-2'>
                {t('merchant.p2')}
                </h5>
                <h5 className='mx-3 mt-2'>{t('merchant.p3')}</h5>
                </div>
                <div className='d-flex justify-content-space'>
                <h5 className='text-primary  mt-5'>
                {t('merchant.p4')}
                </h5>
                <h5 className='mx-3 mt-5'>{t('merchant.p5')}</h5>
                </div>
                <li className='mt-5 lh-xl'>{t('merchant.p6')}
                 </li>
                </div>
              </Col>
              <Col>
                <div className='text-center'>
                  <img src={img}  className='mt-5 img-section-sized'  width='400px'/>
                </div>
              </Col>
            </Row>

            <div className='py-5 mt-5 container'>
            <h4 className='text-center mb-5 c-i ltr'>{t('merchant.p7')}</h4>
              <Row className='text-center'  xl='3' lg='3' md='1' sm='1' xs='1'>
                <Col>
                <div className='card-small border shadow radius'>
                <div className='mt-5 mb-3  gradiant-border'>
                  <span className='icon-back m-auto'>
                    <GiWallet className='icon-section-2' size='3rem'/>
                    </span>
                  </div>
                  <h6>{t('merchant.p8')}</h6>
                  <div className='ltr p-2 text-medium mt-3'>
                    <p>{t('merchant.p9')}</p>
                  </div>
                </div>
                </Col>
                <Col>
                <div className='card-small border shadow radius'>
                <div className='mt-5 mb-3  gradiant-border'>
                  <span className='icon-back m-auto'>
                    <RiIncreaseDecreaseLine className='icon-section-2' size='3rem'/>
                    </span>
                  </div>
                  <h6>{t('merchant.p10')}</h6>
                  <div className='ltr p-2 text-medium mt-3'>
                    <p>{t('merchant.p11')}
                    </p>
                  </div>
                  </div>
                </Col>
                <Col>
                <div className='card-small border shadow radius'>
                <div className='mt-5 mb-3  gradiant-border'>
                  <span className='icon-back m-auto'>
                    <GiShieldEchoes className='icon-section-2' size='3rem'/>
                    </span>
                  </div>
                  <h6>{t('merchant.p12')}</h6>
                  <div className='ltr p-2 text-medium mt-3'>
                    <p>{t('merchant.p13')}</p>
                  </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className='py-5 container'>
            <h4 className='text-center mb-5 c-i ltr'>{t('merchant.p14')}</h4>
              <Row className='text-center'  xl='3' lg='3' md='1' sm='1' xs='1'>
                <Col>
                <div className='card-small border shadow radius'>
                <div className='mt-5 mb-3  gradiant-border'>
                  <span className='icon-back m-auto'>
                    <BiBarChartAlt2 className='icon-section-2' size='3rem'/>
                    </span>
                  </div>
                  <h6>{t('merchant.p15')}</h6>
                  <div className='ltr p-2 text-medium mt-3'>
                    <p>{t('merchant.p16')}
                    </p>
                  </div>
                </div>
                </Col>
                <Col>
                <div className='card-small border shadow radius'>
                <div className='mt-5 mb-3  gradiant-border'>
                  <span className='icon-back m-auto'>
                    <GiPayMoney className='icon-section-2' size='3rem'/>
                    </span>
                  </div>
                  <h6>{t('merchant.p17')}</h6>
                  <div className='ltr p-2 text-medium mt-3'>
                    <p>{t('merchant.p18')}
                    </p>
                  </div>
                  </div>
                </Col>
                <Col>
                <div className='card-small border shadow radius'>
                <div className='mt-5 mb-3  gradiant-border'>
                  <span className='icon-back m-auto'>
                    <GiAutoRepair className='icon-section-2' size='3rem'/>
                  </span>
                </div>
                  <h6>{t('merchant.p19')}</h6>
                  <div className='ltr p-2 text-medium mt-3'>
                    <p>{t('merchant.p20')}
                    </p>
                  </div>
                  </div>
                </Col>
              </Row>
            </div>
        </div>
      </Col>
      </Row>
      </>
    )
  }
}
export default withTranslation()(Layer)
