import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import Logotext from '../../style/images/logo-text.svg'
import {Link} from 'react-router-dom'
import {useTranslation,withTranslation} from 'react-i18next'
const Example = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation()
  const toggle = () => setIsOpen(!isOpen);

  return (
      <div className='d-block d-md-none mobile-nav'>
      
      </div>
  );
}

export default withTranslation()(Example);
