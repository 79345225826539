import React from 'react'
import Footer from './footer'
import Navbar from './navbar'
import Mobnav from './mobnav'
import {Row,Col} from 'reactstrap'
import Websocket from './socket'
import {
  Switch,Route
} from "react-router-dom"
import axios from 'axios'
import SimpleLineIcon from 'react-simple-line-icons'

import Market from './kcmarket'
import Exit from './exit'
import {Link} from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next';
class Marketlayer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      theme : true
    }
  }
  componentWillMount(){

  }
  componentDidMount(){
  }
setTheme =()=>{
  this.setState({theme : !this.state.theme})
}
  render(){
    const {match} = this.props
    const {t} = this.props
    return(
    <>
      <div className={this.state.theme === true ? 'market-dashboard-light market' : 'market-dashboard-dark market'}>

        <div >
          {this.props.children}
          <Switch>
            <Route
              path={`${match.url}/Marketplace/:symbol`}
              render={(props) => <Market theme={this.props.theme} darkMode={this.props.darkMode} {...props} />}
            />


            <Route
              path={`${match.url}/Logout`}
              render={(props) => <Exit {...props} />}
            />
            <Route
              path={`${match.url}/socket`}
              render={(props) => <Websocket {...props} />}
            />
          </Switch>
        </div>
      </div>
    </>
    )
  }
  }
  export default withTranslation()(Marketlayer)
