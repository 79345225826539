import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import {Button,CardBody,Card,CardHeader,CardFooter,Row,Col
} from 'reactstrap'
import {Helmet} from 'react-helmet'

import { withTranslation } from 'react-i18next';

import withReactContent from 'sweetalert2-react-content'
import {Link} from 'react-router-dom'
import NumericFormat from 'react-number-format';
class Profile extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data:[],

    }
  }
  backtopanel =()=>{
    this.props.history.push('/panel/dashboard')
  }
  componentWillMount(){

    let id =this.props.match.params.id;
    this.id = id
    this.setState({id : id})
    this.gepata()
  }
  gepata =()=>{
    axios.get('/api/payment/success/'+this.id).then(response=>{

      this.setState({data : response.data})
    })

  }
numeric =(i)=>{
  <NumericFormat value={i}  thousandSeparator="," displayType="text"  className='font-weight-bold text-light mx-1' />


}

  render(){
const { t } = this.props;
    return(
    <>
      <Helmet>
         <title>{t('success-payment|Rapitex')}</title>
      </Helmet>
      <div className='rtl mt-5'>
      <Row >
        <Col xl={{size:4}} lg={{size:4}} md={{size:4}} sm='12' xs='12' className=' mx-auto my-auto'>
          <Card className='rtl mt-5'>
            <CardHeader >واریز موفق
            </CardHeader>
              <CardBody>
                <div className='py-4 px-2 lh-xl '>
                        <div className='d-flex justify-content-space-between'>
                          <p>مبلغ تراکنش
                          </p>
                          <p><NumericFormat value={this.state.data.amount}  thousandSeparator="," displayType="text"  className='font-weight-bold text-success mx-1' />
 ریال
                          </p>
                        </div>
                        <div className='d-flex justify-content-space-between'>
                          <p>تاریخ تراکنش :
                          </p>
                          <p className='date'>{this.state.data.date}
                          </p>
                        </div>

                        <div className='d-flex justify-content-space-between'>
                          <p>وضعیت تراکنش :
                          </p>
                          <p>{this.props.getstatus(this.state.data.status)}
                          </p>
                        </div>
                        <div className='d-flex justify-content-space-between'>
                          <p>کد رهگیری :
                          </p>
                          <p>{this.state.data.transId}
                          </p>
                        </div>
                        <div className='d-flex justify-content-space-between'>
                          <p>کارت پرداخت کننده :
                          </p>
                          <p>{this.state.data.CardNo}
                          </p>
                        </div>
                        <div className='text-center mt-5'>
                        <Link to='/panel/dashboard' className='text-center mt-5 pb-1'>
                          <Button color='primary'  className='px-5 mx-auto text-center'>
                              بازگشت به پنل کاربری
                          </Button>
                        </Link>
                </div>
                </div>

              </CardBody>

            </Card>
            </Col>
          </Row>
          </div>

    </>
    )
  }
  }
  export default withTranslation()(Profile)
