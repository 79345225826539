import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslation,withTranslation} from 'react-i18next'
import {Row,Col} from 'reactstrap'
import Logotext from '../../style/images/logo-text.svg'
const Section1 =()=>{
  const { t } = useTranslation();
  return(
    <>
      <section id='footer' className=' rtl  border-top'>

        <Row xl='3' lg='3'  md='3' sm='1' xs='1' className='rtl logo-shadow2'>
          <Col>
          <h5>تماس با ما</h5>
            <div>
              <p>Email : info [at] zarrintether.com</p>
              <p>041-33191672</p>
            </div>
          </Col>
          <Col>
          <h5>آدرس دفتر</h5>
            <div>
              <p>تبریز - ولیعصر - فلکه همافر - گلگشت 2 - پلاک 6</p>
            </div>
          </Col>

          <Col>
          <div className='text-center'>
          <a href='https://zarrintether.com' className='text-center'>
          <img className='mx-auto' src={Logotext} alt={t('alt-footer-logo')} width='250'/>
          <span className='bg-shadow'/>
          </a>

          </div>
          </Col>
        </Row>

      </section>

    </>
  )
}
export default withTranslation()(Section1);
