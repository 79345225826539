import {Row,Col,Button} from 'reactstrap'
import {Link} from 'react-router-dom'
import { useTranslation, withTranslation,Trans } from 'react-i18next';

const Slide1 =()=>{
const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide1-bg h-300' />
    <div className='slider-slide1'>

    <Row>


      <Col>
      <h3 className='slide-title'>
        {t('s1h1')}
      </h3>
      <p className="text-white-fix slider-p rtl" >{t('s1p1')}</p>


      </Col>
    </Row>

    </div>
    </>
  )
}
export {Slide1};
const Slide2 =()=>{
  const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide2-bg h-300' />
    <div className='slider-slide2'>
    <h3 className='slide-title'>
      {t('s2h1')}
    </h3>
    <p className="text-white-fix slider-p rtl" >{t('s2p1')}</p>
    <p className="text-white-fix slider-p rtl" >{t('s2p2')}</p>


    </div>
    </>
  )
}
export {Slide2};
const Slide3 =()=>{
  const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide3-bg h-300' />
    <div className='slider-slide3'>
    <h3 className='slide-title'>
      {t('s3h1')}
    </h3>
    <p className="text-white-fix slider-p rtl" >{t('s3p1')}</p>

      <div>
      <Link to='PAXG_مارکت_طلا_در_صرافی_رپیتکس _PAXG_GOLD'>
        <Button color='light' outline className='px-5 mt-3 mx-auto'>{t('read-more')}</Button>
      </Link>
      </div>
    </div>

    </>
  )
}
export {Slide3};
const Slide4 =()=>{
  const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide4-bg h-300' />
    <div className='slider-slide4'>
    <h3 className='slide-title'>
      {t('s4h1')}
    </h3>
    <p className="text-white-fix slider-p rtl" >{t('s4p1')}</p>
    <p className="text-white-fix slider-p rtl" >{t('s4p2')}</p>

    </div>
    </>
  )
}
export {Slide4};
const Slide5 =()=>{
  const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide5-bg' />
    <div className='slider-slide4'>
    <h3 className='slide-title'>
      {t('s5h1')}
    </h3>
    <p className="text-white-fix slider-p rtl" >{t('s5p1')}</p>
    <p className="text-white-fix slider-p rtl" >{t('s5p2')}</p>

    </div>
    </>
  )
}
export {Slide5};
const Slide6 =()=>{
  const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide2-bg' />
    <div className='slider-slide2'>
    <h3 className='slide-title'>
      {t('s6h1')}
    </h3>
    <p className="text-white-fix slider-p rtl" >{t('s6p1')}</p>
    </div>
    </>
  )
}
export {Slide6};
