import React from "react";
import cookie from 'react-cookies'
import axios from 'axios'
import { widget } from './charting_library';
class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state={
      theme : 'dark'
    }
}
componentWillMount(){
  const theme = document.body.getAttribute('class')
  if(theme === 'dark'){
    this.setState({theme : 'dark'})
  }else{
    this.setState({theme : 'light'})
  }
}
componentDidMount(){
  this.setChart(this.props.market , this.state.theme)
}
getLanguageFromURL=()=> {
 const regex = new RegExp('[\\?&]lang=([^&#]*)');
 const results = regex.exec(window.location.search);
 return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

setChart =(market , theme)=>{
      const defaultProps = {
        interval: '15',
        containerId: 'tv_chart_container',
        datafeedUrl: 'https://rapitex.com/api',
        libraryPath: 'https://rapitex.com/charting_library/',
        chartsStorageApiVersion: '1.1',
        clientId: 'rapitex.com',
        fullscreen: false,
        autosize: true,
        studiesOverrides: {},
      };
      const widgetOptions = {
        debug: false,
        container: 'tv_chart_container',
        allow_symbol_change: false,
        symbol: market,
        datafeed: new window.Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
        interval: defaultProps.interval,
        library_path: defaultProps.libraryPath,
        locale: this.getLanguageFromURL() || "en",
        disabled_features: ['header_compare','header_undo_redo', 'header_symbol_search'],
        show_symbol_logos :true,
        show_exchange_logo : true,
        enabled_features: ["study_templates" , "show_symbol_logos" ,"show_exchange_logo","show_symbol_logo_in_legend" ],
        charts_storage_url: defaultProps.chartsStorageUrl,
        charts_storage_api_version: defaultProps.chartsStorageApiVersion,
        client_id: defaultProps.clientId,
        user_id: defaultProps.userId,
        fullscreen: defaultProps.fullscreen,
        autosize: defaultProps.autosize,
        studies_overrides: defaultProps.studiesOverrides,
        hide_legends : false,
        theme : this.state.theme,

      };
      this.tvWidget = new widget(widgetOptions);
      let container = document.getElementById('tv_chart_container')
      this.tvWidget = new widget(widgetOptions);
      this.tvWidget.onChartReady(() => {

       this.tvWidget.headerReady().then(() => {
         const button = this.tvWidget.createButton();
         button.setAttribute('title', 'market change');
         button.addEventListener('click',()=> this.setState({marketmodal : !this.state.marketmodal}));
         // button.innerHTML = this.props.fee;

       });
     });

     return () => {
       this.tvWidget.remove();
     };
      // document.body.appendChild(container);
      return(
        <div id='tv_chart_container' />
      )
    }
render(){
  return(
    <>
    <div id='tv_chart_container'>
    </div>
    </>
  )
}
}
export default Wallet
