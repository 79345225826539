import React from 'react'
import cookie from 'react-cookies'
import {Link,withRouter} from 'react-router-dom'
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import {Helmet} from 'react-helmet'
import {List,Row,Col,Container,Button, Card,CardBody} from 'reactstrap'
import image1 from '../../../style/images/security.svg'
class Layer extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }
componentDidMount(){
    window.scrollTo(0, 0)
}
  render(){
      const { t } = this.props;

    return(
      <>
      <Helmet>
         <title>{t('security.title')}</title>
     </Helmet>
          <Row xl='1' lg='1' md='1' sm='1' xs='1'>
          <Col className='header-bg-dark'>
          </Col>
          <Col>
            <div className=''>
            <h1 className='rtl'>{t('security.p1')}</h1>
            <hr/>
              <div className='ltr lh-xl'>
              <Row xl='2' lg='1' md='1' sm='1' xs='1' dir='ltr'>
              <Col>
              <p className='ul-li ul mt-5'>{t('security.p2')}
               </p>
              </Col>
              </Row>
              <p className='ltr text-subject2 px-4 mt-3'>{t('security.p3')}</p>
              <hr/>
              <li>{t('security.p4')}
               </li>
              <p className='ltr text-subject2 px-4 mt-3'>{t('security.p5')}</p>
              <hr/>
              <li>{t('security.p6')}
              </li>
              <p className='ltr text-subject2 px-4 mt-3'>{t('security.p7')}</p>
              <hr/>
              <li>{t('security.p8')}
              </li>
              <List>
                <ul>
                  <li>{t('security.p9')}
                  </li>
                  <li>{t('security.p10')}
                  </li>
                  <li>{t('security.p11')}
                  </li>
                  <li>{t('security.p12')}
                  </li>
                </ul>
              </List>
              <p className='ltr text-subject2 px-4 mt-3'>{t('security.p13')}</p>
              <hr/>
              <li>{t('security.p14')}
              </li>
              </div>
            </div>
          </Col>
          </Row>
      </>
    )
  }
}
export default withRouter(withTranslation()(Layer))
