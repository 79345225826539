import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import {DropdownItem,
  Card, Button, CardHeader, CardFooter, CardBody,ModalBody,Modal,
ModalHeader,
  ModalFooter,
CardTitle, CardText,Nav,NavLink, Form,FormText, FormGroup, Label, Input,Container,Col,Row,Table,List} from 'reactstrap'
import {Helmet} from 'react-helmet'
import {RiFileListLine,RiFileForbidLine} from "react-icons/ri";
import {FaHandHoldingUsd,FaHandHoldingMedical} from "react-icons/fa";
import {BiDotsVertical} from "react-icons/bi";
import {FcGoogle} from "react-icons/fc";
import {AiOutlinePlus} from "react-icons/ai";
import {BsApple,BsGooglePlay,BsEyeFill ,BsEyeSlashFill} from "react-icons/bs";
import SimpleLineIcon from 'react-simple-line-icons'
import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator'
import Dropzone from 'react-dropzone'
import authdoc from '../../../style/images/authdoc.svg'
import dv from '../../../style/images/dv.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Link} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import Loader from '../../landing/loader'
import QRCode from "react-qr-code";
import { PiCopyThin } from "react-icons/pi";
import Toast from 'awesome-toast-component'

const MySwal = withReactContent(Swal)

class Profile extends React.Component{
  constructor(props){
    super(props);
    const {t} = this.props
    this.state = {
      data:[],
      referal:[],
      docid : [],
      id : [],
      banks : [],
      docs : [],
      verified  : '',
      completed : '',
      activetab : 0,
      show : false,
      btn : false,
      lm : false,
      lt : false,
      tm : false,
      tl : false,
      de : false,
      dt : false,
      lle : false,
      llfa : false,
      lwe : false,
      selfie : false,
      lwfa : false,
      lwsms : false,
      lte : false,
      ltfa : false,
      loader : true,
      btn : false,
      oldpasserror : '',
      newpasserror : '',
      btndoc : `${t('send.docs')}` ,
      btndoc2 : `${t('wait.to.send')}`
    }
    this.validator = new SimpleReactValidator();
  }
  setinfomodal = ()=>{
    this.setState({modal : !this.state.modal})
  }
  componentWillMount(){
    let uid = cookie.load('uid')
    if(!uid){
      this.props.history.push('/Auth/login')
      return
    }
    this.getdashbord()
  }
  getdashbord =()=>{
    axios.get('/api/profile/getstate').then(response=>{
      if(response.data.status === "error-fatal"){
        document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        cookie.remove('uid')
        axios.get('/Users/logout')
        setTimeout(
        () => this.props.history.push('/auth/login'),
        1000
      );
      }
  this.setState({
    referal : response.data.referal.users,
    rc : response.data.referal.referal_code,
    rccount : response.data.referal.count,
    message : response.data.message, completed : response.data.verificatin ,banks : response.data.banklist , loader : false})
  this.set(response.data.notification)
    })

  }
set =(i)=>{
    let lm = i.LE ;
      if(lm === 'yes'){
      this.setState({lm : true})
    }else{
        this.setState({lm : false})
      }
    let lt = i.LT ;
      if(lt === 'yes'){
      this.setState({lt : true})
    }else{
        this.setState({lt : false})
      }
    let tm = i.TE ;
      if(tm === 'yes'){
      this.setState({tm : true})
    }else{
        this.setState({tm : false})
      }
    let tl = i.TT ;
      if(tl === 'yes'){
      this.setState({tl : true})
    }else{
        this.setState({tl : false})
      }
    let de = i.TXE ;
      if(de === 'yes'){
      this.setState({de : true})
    }else{
        this.setState({de : false})
      }
    let dt = i.TXT ;
      if(dt === 'yes'){
      this.setState({dt : true})
    }else{
        this.setState({dt : false})
      }
    let lle = i.LES ;
      if(lle === 'yes'){
        this.setState({lle : true})
      }else{
          this.setState({lle : false})
        }
    let lwsms = i.WES ;
      if(lwsms === 'yes'){
        this.setState({lwsms : true})
      }else{
          this.setState({lwsms : false})
        }
    let llfa = i.L2FA ;
      if(llfa === 'yes'){
        this.setState({llfa : true}
        )}else{
          this.setState({llfa : false})
        }
    let lwem = i.WEM ;
      if(lwem === 'yes'){
        this.setState({lwem : true})
      }else{
          this.setState({lwem : false})
        }
    let lwfa = i.W2FA ;
      if(lwfa === 'yes'){
        this.setState({lwfa : true}
        )}else{
          this.setState({lwfa : false})
        }
    let lte = i.TES ;
      if(lte === 'yes'){
        this.setState({lte : true})
      }else{
          this.setState({lte : false})
        }
    let ltfa = i.T2FA ;
      if(ltfa === 'yes'){
        this.setState({ltfa : true}
        )}else{
          this.setState({ltfa : false})
        }

}
  setinfomodal = ()=>{
    this.setState({modal : !this.state.modal})
  }
  check = ()=>{
    if(!cookie.load('uid')){
      this.props.history.push('/auth/login')
    }
  }

  onDropdocid = (file) => {
    this.check()
    let u = URL.createObjectURL(file[0])
    this.setState({docid : file , docurl :u})

   }
  onDropdoc = (docs) => {
    this.check()
    let u = URL.createObjectURL(docs[0])

     this.setState({docs : docs , surl :u})
   }
   senddocid = (e)=>{
     this.check()
     const { t } = this.props;
     e.preventDefault()
     if(this.state.docs.length === 0){
       console.log(`${t('erf1')}`)
       return
     }
     if(this.state.docs.length > 1){
       console.log(`${t('erf2')}`)
      this.setState({docs : []})
       return
     }
     if(this.state.docs[0].size > 5000000){
       console.log(`${t('erf3')}`)
      this.setState({docs : []})
       return
     }

     if(this.state.docs[0].type === 'image/jpg' || this.state.docs[0].type === 'image/png' || this.state.docs[0].type === 'image/jpeg' ){

     }else{
       console.log(`${t('erf4')}`)
       return
     }
     this.disableb()
      const formData = new FormData();
      formData.append('avatar',this.state.docs[0])
      axios.post('/api/Profile/Upload_Document_id', formData,{
          headers: {
              'content-type': 'multipart/form-data'
          }
      }).then(response=>{
        if(response.data.status === "error-fatal"){
          document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          cookie.remove('uid')
          axios.get('/Users/logout')
          setTimeout(
          () => this.props.history.push('/auth/login'),
          1000
        );}
        if(response.data.status === 'success'){
          console.log('فایل با موفقیت ارسال شد.')
          // this.getprofile();
          this.setState({docs : []})
          this.getdashbord()
          // this.props.update()
        }else{
          console.log('فایل ارسال نشد.')
        }
      })
   }
   senddoc = ()=>{
     const {t} = this.props
     // console.log('ddff')
     // console.log(this.state);
     this.check()
     if(this.state.docid.length === 0){
       new Toast(`مدارک هویتی خود را بارگذاری کنید`, {
         style: {
             container: [
                 ['background-color', 'red']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })

       return
     }
     if(this.state.docid.length > 1){
       new Toast(`فقط یک فایل میتوانید انتخاب کنید`, {
         style: {
             container: [
                 ['background-color', 'red']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })

      this.setState({docs : []})
       return
     }
     if(this.state.docid[0].size > 5000000){
       new Toast(`حجم فایل بیشتر از حد مجاز است`, {
         style: {
             container: [
                 ['background-color', 'red']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })

      this.setState({docs : []})
       return
     }
     if(this.state.docs.length === 0){
       new Toast(`تصویر سلفی انتخاب نشده است`, {
         style: {
             container: [
                 ['background-color', 'red']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })

       return
     }
     if(this.state.docid[0].type === 'image/jpg' || this.state.docs[0].type === 'image/png' || this.state.docs[0].type === 'image/jpeg' ){

     }else{
       new Toast(`فرمت فایل ارسالی اشتباه است`, {
         style: {
             container: [
                 ['background-color', 'red']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })

       return
     }
     if(this.state.docs.length === 0){
       new Toast(`تصویر سلفی انتخاب نشده است`, {
         style: {
             container: [
                 ['background-color', 'red']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })

       return
     }
     if(this.state.docs.length > 1){
       new Toast(`فقط یک فایل میتوانید انتخاب کنید`, {
         style: {
             container: [
                 ['background-color', 'red']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })


      this.setState({docs : []})
       return
     }
     if(this.state.docs[0].size > 5000000){
       new Toast(`حجم فایل بیشتر از حد مجاز است`, {
         style: {
             container: [
                 ['background-color', 'red']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })

      this.setState({docs : []})
       return
     }

     if(this.state.docs[0].type === 'image/jpg' || this.state.docs[0].type === 'image/png' || this.state.docs[0].type === 'image/jpeg' ){

     }else{
       new Toast(`فرمت فایل ارسالی اشتباه است`, {
         style: {
             container: [
                 ['background-color', 'red']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })

       return
     }
     this.disableb()
      const formData = new FormData();
      formData.append('doc1',this.state.docid[0])
      formData.append('doc2',this.state.docs[0])
      axios.post('/api/Profile/Upload_Documents', formData,{
          headers: {
              'content-type': 'multipart/form-data'
          }
      }).then(response=>{
         this.disableb()
        if(response.data.status === "error-fatal"){
          document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          cookie.remove('uid')
          axios.get('/Users/logout')
          setTimeout(
          () => this.props.history.push('/auth/login'),
          1000
        );}
        if(response.data.status === 'success'){
          new Toast(`فایل ها با موفقیت ارسال شدند`, {
            style: {
                container: [
                    ['background-color', 'green']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
            position : 'top'
        })
          this.setState({docs : [] , docid : [] , url1 : response.data.url ,url2 : response.data.url2 , verified : '0' , completed : '0'})
          this.getdashbord()
          // this.props.update()
        }else{
          new Toast(`فایل ارسال نشد`, {
            style: {
                container: [
                    ['background-color', 'red']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
            position : 'top'
        })

          return
        }
      })
   }
   setid = (docs) => {

      this.setState({id : docs})
    }
uploadid = (e)=>{
  e.preventDefault()
  const formData = new FormData();
  formData.append('img',this.state.id[0])
  axios.post('/api/Profile/read_idcard', formData,{
      headers: {
          'content-type': 'multipart/form-data'
      }
  }).then(response=>{
     console.log(response);
  })
}
 disableb = ()=>{
   this.setState({btn : true})
 }

 changelm =(e)=>{
   let id = e.target.getAttribute("id")
   this.setState({ lm : !this.state.lm})
   axios.get('/api/profile/change_notification/LE')
 }
changelt =(e)=>{
  let id = e.target.getAttribute("id")
  this.setState({ lt : !this.state.lt})
  axios.get('/api/profile/change_notification/LT')
}
changetm =(e)=>{
  let id = e.target.getAttribute("id")
  this.setState({ tm : !this.state.tm})
  axios.get('/api/profile/change_notification/TE')
}
changetl =(e)=>{
  let id = e.target.getAttribute("id")
  this.setState({ tl : !this.state.tl})
  axios.get('/api/profile/change_notification/TT')
}
changede =(e)=>{
  let id = e.target.getAttribute("id")
  this.setState({ de : !this.state.de})
  axios.get('/api/profile/change_notification/TXE')
}
changedt =(e)=>{
  let id = e.target.getAttribute("id")
  this.setState({ dt : !this.state.dt})
  axios.get('/api/profile/change_notification/TXT')
}
changelle =(e)=>{
let id = e.target.getAttribute("id")
  this.setState({ lle : !this.state.lle})
  axios.get('/api/profile/change_notification/LES')
}
changellfa =(e)=>{
let id = e.target.getAttribute("id")>
  this.setState({ llfa : !this.state.llfa})
  axios.get('/api/profile/change_notification/L2FA')
}
changelwem =(e)=>{
let id = e.target.getAttribute("id")
  this.setState({ lwem : !this.state.lwem})
  axios.get('/api/profile/change_notification/WEM')
}
changelwfa =(e)=>{
let id = e.target.getAttribute("id")
  this.setState({ lwfa : !this.state.lwfa})
  axios.get('/api/profile/change_notification/W2FA')
}
changelwsms =(e)=>{
let id = e.target.getAttribute("id")
  this.setState({ lwsms : !this.state.lwsms})
  axios.get('/api/profile/change_notification/WES')
}
changelte =(e)=>{
let id = e.target.getAttribute("id")
  this.setState({ lte : !this.state.lte})
  axios.get('/api/profile/change_notification/TES')
}
changeltfa =(e)=>{
let id = e.target.getAttribute("id")
  this.setState({ ltfa : !this.state.ltfa})
  axios.get('/api/profile/change_notification/T2FA')
}
set2fa =()=>{
  axios.get('/api/profile/auth_detail').then(response=>{
    if(response.data.status === "error-fatal"){
      document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      cookie.remove('uid')
      axios.get('/Users/logout')
      setTimeout(
      () => this.props.history.push('/auth/login'),
      1000
    );
  }else{
    let qr = response.data.qr
  this.setState({qr : qr , qrm : true})

  }
  })
}
show=()=>{
  this.setState({show : !this.state.show})
}
setactive =(e)=>{
  this.setState({activetab : e})
}
copyaddress =()=>{
  const { t } = this.props

    navigator.clipboard.writeText(this.state.qr)
    new Toast(`${t('کپی شد')}`, {
      style: {
          container: [
              ['background-color', 'green']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })


}
setlogo =(i)=>{
  var number = i.substring(6,-16)
  if(number === '603799'){return "https://rapitex.com/images/logo/banks/meli.png"}
  if(number === '589210'){return "https://rapitex.com/images/logo/banks/sepah.png"}
  if(number === '627961'){return "https://rapitex.com/images/logo/banks/sanatmadan.png"}
  if(number === '603770'){return "https://rapitex.com/images/logo/banks/keshavarsi.png"}
  if(number === '628023'){return "https://rapitex.com/images/logo/banks/maskan.png"}
  if(number === '627760'){return "https://rapitex.com/images/logo/banks/postbank.png"}
  if(number === '502908'){return "https://rapitex.com/images/logo/banks/tosehe.png"}
  if(number === '627412'){return "https://rapitex.com/images/logo/banks/eghtesad.png"}
  if(number === '622106'){return "https://rapitex.com/images/logo/banks/parsian.png"}
  if(number === '502229'){return "https://rapitex.com/images/logo/banks/pasargad.png"}
  if(number === '627488'){return "https://rapitex.com/images/logo/banks/karafarin.png"}
  if(number === '621986'){return "https://rapitex.com/images/logo/banks/saman.png"}
  if(number === '639346'){return "https://rapitex.com/images/logo/banks/sina.png"}
  if(number === '639607'){return "https://rapitex.com/images/logo/banks/sarmaye.png"}
  if(number === '502806'){return "https://rapitex.com/images/logo/banks/shahr.png"}
  if(number === '502938'){return "https://rapitex.com/images/logo/banks/day.png"}
  if(number === '603769'){return "https://rapitex.com/images/logo/banks/saderat.png"}
  if(number === '610433'){return "https://rapitex.com/images/logo/banks/mellat.png"}
  if(number === '627353'){return "https://rapitex.com/images/logo/banks/tejarat.png"}
  if(number === '589463'){return "https://rapitex.com/images/logo/banks/refah.png"}
  if(number === '627381'){return "https://rapitex.com/images/logo/banks/sepah.png"}
  if(number === '639370'){return "https://rapitex.com/images/logo/banks/mehreqtesad.png"}
  if(number === '639599'){return "https://rapitex.com/images/logo/banks/sepah.png"}
  if(number === '504172'){return "https://rapitex.com/images/logo/banks/resalat.png"}
}
changepassword = (e)=>{
  const { t } = this.props;
  e.preventDefault()
  if (!this.validator.fieldValid('oldpass')) {
    this.setState({oldpasserror : `${t('olpasserror')}`})
    return
  }else{
  }  if (!this.validator.fieldValid('newpass')) {
      this.setState({newpasserror : `${t('newpasserror')}`})
      return
    }else{
    }
  let oldpass = this.state.oldpass
  let newpass = this.state.newpass
  const data = new FormData()
  data.append('oldpass' , oldpass)
  data.append('newpass' , newpass)
  axios.post('/api/profile/change_password' ,data).then(res=>{
    if(res.data.status === "error-fatal"){
      document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      cookie.remove('uid')
      axios.get('/Users/logout')
      setTimeout(
      () => this.props.history.push('/auth/login'),
      1000
    );
  }
  if(res.data.status === "error"){
    new Toast(`${t(res.data.message)}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
  }

  if(res.data.status === "success"){
    new Toast(`${t(res.data.message)}`, {
      style: {
          container: [
              ['background-color', 'green']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
  }

  })
}
onChange =(e)=>{
  this.setState({[e.target.name] : e.target.value , oldpasserror : '' , newpasserror : ''})
}
showpass =()=>{
  this.setState({show : !this.state.show})
}
copyrc =()=>{
  const {t} = this.props
  navigator.clipboard.writeText(this.state.rc)
  new Toast(`${t('copied')}`, {
    style: {
        container: [
            ['background-color', 'green']
        ],
        message: [
            ['color', '#eee'],
        ],
        bold: [
            ['font-weight', '900'],
        ]
    },
    timeout : 3000,
      position : 'top'
})
}
viewexapmleselfie =()=>{
  this.setState({modal : !this.state.modal})
}
  render(){
    const { t } = this.props;

    const nodata = <div className='m-5'>
    <p className='text-muted '>{t('no-referal-data')}</p>
    </div>
    const thumbsContainer = {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 16
    };

    return(
    <>
    <Helmet>
<title>پروفایل کاربری | رپیتکس</title>
</Helmet>
{this.state.loader === true ?
<>
<div className='loader-home bg-white py-5'>
<h3 className='text-center bg-white m-auto mt-auto'><Loader /></h3>
</div>
</> :
  <div >
    <Card className='rtl'>
      <CardHeader className='rt '>
        <div class="d-flex my-auto">
        <a className={this.state.activetab === 0 ?  'mr-3 active-tab pointer text-center' : 'mr-3 text-center pointer'} onClick={()=>this.setactive(0)} >
        {t('profile-tab1')}
        </a>
        <a className={this.state.activetab === 1 ? 'active-tab pointer text-center mx-2 ' : ' mx-2 text-center pointer'} onClick={()=>this.setactive(1)} >
        {t('profile-tab2')}
        </a>
        <a className={this.state.activetab === 2 ? 'active-tab pointer text-center mx-2 ' : ' mx-2 text-center pointer'} onClick={()=>this.setactive(2)} >
        {t('profile-tab3')}
        </a>
        <a className={this.state.activetab === 4 ? 'active-tab pointer text-center mx-2 ' : ' mx-2 text-center pointer'} onClick={()=>this.setactive(4)} >
        {t('profile-tab5')}
        </a>
        <a className={this.state.activetab === 3? 'active-tab pointer text-center mx-2' : 'mx-2 text-center pointer'} onClick={()=>this.setactive(3)} >
        {t('profile-tab4')}
        </a>
        <Link to='/panel/submit_bank_account' className='' >
        {t('add-new-account')}
        </Link>
      </div>
      </CardHeader>
      <CardBody>
      {this.state.activetab === 0 ?

        <div className=''>
          <Row xl='2' lg='2' md='1' xs='1' sm='1'>
            <Col className='' sm='12' xl='6' xs='12'>
            {this.state.completed === '0'  ? <>
              <Row xl='1' xs="1"  className='rtl '>
                <Col xl='12'>
                <h5 className='rtl p-3 bg-light'>
                {t('verify-user')}
                </h5>
                  <div className=''>
                  <Dropzone  onDrop={this.onDropdocid.bind(this)}  multiple={false}>
                  {({getRootProps, getInputProps}) => (
                  <section className= 'dropzone pointer my-4'>
                  <div {...getRootProps({})}>
                  <input {...getInputProps()} />
                  <span className='px-3 py-5 text-muted'>تصویر کارت ملی</span>
                  </div>
                  {this.state.errordoc1 === true ? <small className='text-danger'>{this.state.error1messgae}</small> : <></>}

                  </section>
                  )}
                  </Dropzone>
                  {this.state.docid.length === 1 ?
                  <Row className=' mt-5' xl='2' xs='2' lg='2' md='2' sm='2'>
                    <Col xl='8' xs='6' sm='6' md='6' lg='6' >
                      <img className='mr-auto thumb-img'
                      src={this.state.docurl}
                      />
                    </Col>
                    <Col xl='8' xs='6' sm='6' md='6' lg='6' >
                      <li className='pl-3 ltr text-medium'>{this.state.docid[0].name}</li>
                      <li className='pl-3 ltr mt-3 text-medium'>{((parseInt(this.state.docid[0].size /1024)/1024).toFixed(2))}  Mb</li>
                    </Col>
                  </Row>
                  : <>
                  <li className='text-muted rtl mt-5 mx-3 mt-3 text-small'>فرمت های مجاز : jpg,jpeg,png</li>
                  <li className='text-muted rtl mt-3  mx-3 text-small'>حداکثر حجم مجاز : 4 مگا بایت</li>
                  </>}
                  </div>
                  <Dropzone onDrop={this.onDropdoc} multiple={false}>
                  {({getRootProps, getInputProps}) => (
                  <section className= 'dropzone  pointer my-4'>
                  <div {...getRootProps({})}>
                  <input {...getInputProps()} />
                  <span className='px-3 py-5 text-muted'>تصویر سلفی</span>
                  </div>
                  {this.state.errordoc2 === true ? <small className='text-danger'>{this.state.error2messgae}</small> : <></>}

                  </section>
                  )}
                  </Dropzone>
                  {this.state.docs.length === 1 ?
                  <Row className=' mt-5'>
                    <Col xl='8' xs='6' sm='6' md='6' lg='6' >
                      <img className='mr-auto thumb-img'
                      src={this.state.surl}
                      />
                    </Col>
                    <Col xl='8' xs='6' sm='6' md='6' lg='6' >
                      <li className='pl-3 ltr text-medium'>{this.state.docs[0].name}</li>
                      <li className='pl-3 ltr mt-3 text-medium'>{((parseInt(this.state.docs[0].size /1024)/1024).toFixed(2))}  Mb</li>
                    </Col>
                  </Row>
                  : <>
                  <li className='text-muted rtl mt-5 mx-3 mt-3 text-small'>فرمت های مجاز : jpg,jpeg,png</li>
                  <li className='text-muted rtl mt-3  mx-3 text-small'>حداکثر حجم مجاز : 4 مگا بایت</li></> }

                </Col>
                <Col xl='12' className='mt-5  text-center'>
                <Button block disabled={this.state.btn} color='primary' type='submit' onClick={this.senddoc} className='mt-auto mb-0 btn-block'>{this.state.btndoc}</Button>
                </Col>
              </Row>
            </> :
            <></>}

            {this.state.completed === '1' ? <>
            <h5 className='rtl p-3 bg-light'>
            {t('verify-user')}
            </h5>
            <li className='mr-2 mt-5'>مدارک شما در حال بررسی است.نتیجه اعتبار سنجی اعلام خواهد شد.</li></>
            : <></>}


            {this.state.completed === '2' ?
            <div className='text-center'>
            <img src={dv} width='35%' className='mr-auto' />
            <li className='mr-2 mt-5 bg-cyan'>{t(this.state.message)}</li>
            </div> : <></>}

            {this.state.completed === '3' ? <>
            <li className='mr-2 mt-5'>لطفا مدارک خود را مجددا ارسال کنید.</li>
            <Row xl='1' xs="1"  className='rtl '>
              <Col xl='12'>

                <div className=''>
                <Dropzone  onDrop={this.onDropdocid.bind(this)}  multiple={false}>
                {({getRootProps, getInputProps}) => (
                <section className= 'dropzone pointer my-4'>
                <div {...getRootProps({})}>
                <input {...getInputProps()} />
                <span className='px-3 py-5 text-muted'>تصویر کارت ملی</span>
                </div>
                {this.state.errordoc1 === true ? <small className='text-danger'>{this.state.error1messgae}</small> : <></>}

                </section>
                )}
                </Dropzone>
                {this.state.docid.length === 1 ?
                <Row className=' mt-5'>
                  <Col xl='4' xs='6' sm='6' md='6' lg='6'>
                    <img className='mr-auto thumb-img'
                    src={this.state.docurl}
                    />
                  </Col>
                  <Col xl='8' xs='6' sm='6' md='6' lg='6' >
                    <li className='pl-3 ltr text-medium'>{this.state.docid[0].name}</li>
                    <li className='pl-3 ltr mt-3 text-medium'>{((parseInt(this.state.docid[0].size /1024)/1024).toFixed(2))}  Mb</li>
                  </Col>
                </Row>
                : <>
                <li className='text-muted rtl mt-5 mx-3 mt-3 text-small'>فرمت های مجاز : jpg,jpeg,png</li>
                <li className='text-muted rtl mt-3  mx-3 text-small'>حداکثر حجم مجاز : 4 مگا بایت</li>
                </>}
                </div>
                <Dropzone onDrop={this.onDropdoc} multiple={false}>
                {({getRootProps, getInputProps}) => (
                <section className= 'dropzone  pointer my-4'>
                <div {...getRootProps({})}>
                <input {...getInputProps()} />
                <span className='px-3 py-5 text-muted'>تصویر سلفی</span>
                </div>
                {this.state.errordoc2 === true ? <small className='text-danger'>{this.state.error2messgae}</small> : <></>}

                </section>
                )}
                </Dropzone>
                {this.state.docs.length === 1 ?
                <Row className=' mt-5'>
                  <Col xl='8' xs='6' sm='6' md='6' lg='6' >
                    <img className='mr-auto thumb-img'
                    src={this.state.surl}
                    />
                  </Col>
                  <Col xl='8' xs='6' sm='6' md='6' lg='6' >
                    <li className='pl-3 ltr text-medium'>{this.state.docs[0].name}</li>
                    <li className='pl-3 ltr mt-3 text-medium'>{((parseInt(this.state.docs[0].size /1024)/1024).toFixed(2))}  Mb</li>
                  </Col>
                </Row>
                : <>
                <li className='text-muted rtl mt-5 mx-3 mt-3 text-small'>فرمت های مجاز : jpg,jpeg,png</li>
                <li className='text-muted rtl mt-3  mx-3 text-small'>حداکثر حجم مجاز : 4 مگا بایت</li></> }

              </Col>
              <Col xl='12' className='mt-5  text-center'>
              <Button block disabled={this.state.btn} color='primary' type='submit' onClick={this.senddoc} className='mt-auto mb-0 btn-block'>{this.state.btndoc}</Button>
              </Col>
            </Row>
            </>
            : <></>}


            </Col>
            <Col className='info-section' sm='12' xl='6' xs='12'>
            <div className='d-flex justify-content-space-between'>
            <h5 className='rtl p-3 bg-light'>
            {t('verify-user-information')}
            </h5>
            <small onClick={this.viewexapmleselfie} className='d-flex' >{t('view-example-photo')}<SimpleLineIcon name='eye mr-3' /></small>
            </div>

            <ul className='text-medium lh-xl rtl'>
            <li>برای استفاده از خدمات رپیتکس احراز هویت کاربر الزامی میباشد .
            </li>
            <li>برای ارسال مدارک روی یک صفحه کاغذ با خطی خوانا بنویسید برای احراز هویت در سایت رپیتکس.
            </li>
            <li>کارت بانکی و کارت شناسایی(کارت ملی ،گواهینامه یا شناسنامه) را پایین متن بچسبانید و یک سلفی بگیرید و ارسال کنید .
            </li>
            <li>برای امنیت اطلاعات حتما cvv2 و تاریخ انقضاء روی کارت بانکی را بپوشانید.
            </li>
            <li>دقت کنید مدرک شناسایی باید عکسدار باشد .
            </li>
            <li>کیفیت عکس را کنترل کنید که کاملا خوانا باشد .
            </li>
            <li>از ویرایش عکس ارسالی با ابزارهای گرافیکی خودداری کنید .
            </li>
            <li>پس از تایید مدارک حساب کاربری شما قابل استفاده میباشد.
            </li>
            </ul>
            </Col>
          </Row>
        </div>
          :<></> }
      {this.state.activetab === 1 ?
        <div className='p-4'>
        <Table responsive  borderless >
        <thead>
          <tr>

            <td><p className='pl-4 py-4'>#</p></td>
            <td><p className='pl-4 py-4'>نام بانک</p>
            </td>
            <td><p>شماره کارت</p>
            </td>
            <td><p>شماره شبا</p>
            </td>
          </tr>
        </thead>
        <tbody>
          {this.state.banks.map((i,index)=>
            <tr key={i.id}>

              <td>
                  <img  width="60px" className='bank-logo my-auto' id="img0" src={this.setlogo(i.accountNo)} />

              </td>
              <td>
                <p>{i.bankname}</p>
              </td>
              <td className=''>
              <NumberFormat style={{
                "display": "block",
              }} className='rtl'  format="####  ####  ####  ####"  value={i.accountNo} displayType='text' />
              </td>
              <td>
              <p style={{
                "display": "block",
              }} >{i.shaba}</p>
              </td>
            </tr>
          )}
          </tbody>
          </Table>

        </div>
        :
        <></> }
                      {this.state.activetab === 2 ?
                        <>
                        <Row xs="1" sm="1" md="2" lg='2' xl='2' className='rtl py-3'>
                          <Col xs='12' xl='6' lg='6' md='6' sm='12'>
                            <div className='pb-5'>
                            <div className='header'>
                              <p className='my-auto'>تنظیمات ارسال ایمیل</p>
                            </div>
                              <div className='mt-3 security-panel'>
                                <Table borderless responsive size='small'>
                                  <tbody className='text-small'>
                                    <tr>
                                      <td>ارسال اعلان ورود
                                      </td>
                                      <td className='ltr'>
                                      <span>ایمیل</span>
                                      </td>
                                      <td>
                                      <input type='checkbox' checked={this.state.lm} onChange={this.changelm} value={this.state.lm} id='lm' className='ml-2'/>
                                      </td>

                                    </tr>
                                    <tr>
                                      <td>اعلان معاملات
                                      </td>
                                      <td className='ltr py-3'>
                                      ایمیل
                                      </td>
                                      <td>
                                      <input type='checkbox' checked={this.state.tm} onChange={this.changetm} id='tm' className='ml-2'/>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>ارسال تراکنش واریز - برداشت
                                      </td>
                                      <td className='ltr py-3'>
                                      <span>ایمیل</span>
                                      </td>
                                      <td>
                                      <input type='checkbox' checked={this.state.de} onChange={this.changede} id='de' className='ml-2'/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Col>
                          <Col xs='12' xl='6' lg='6' md='6' sm='12' className='pb-5'>
                            <div className=''>
                            <div className='header'>
                              <p className=''>2FA کد دومرحله ای</p>
                              <a className='info-badge mr-auto' color='success' onClick={this.set2fa}>فعالسازی</a>
                              </div>
                              <div className='mt-3'>
                              <Table borderless responsive size='small'>
                                <tbody className='text-small'>
                                  <tr>
                                    <td>ورود به پنل
                                    </td>

                                    <td className='ltr py-3'>
                                    <span>2FA</span>

                                    </td>
                                    <td>
                                    <input type='checkbox' checked={this.state.llfa} onChange={this.changellfa} id='llfa' className='ml-2'/>
                                    </td>
                                    <td className='ltr py-3'>
                                    <span>ایمیل</span>
                                    </td>
                                    <td>
                                    <input type='checkbox' checked={this.state.lle} onChange={this.changelle} id='llfa' className='ml-2'/>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>برداشت
                                    </td>

                                    <td className='ltr py-3'>
                                    <span>SMS</span>
                                    </td>
                                    <td>
                                    <input type='checkbox' checked={this.state.lwsms} onChange={this.changelwsms} id='lwsms' className='ml-2'/>
                                    </td>
                                    <td className='ltr py-3'>
                                    <span>2FA</span>
                                    </td>
                                    <td>
                                    <input type='checkbox' checked={this.state.lwfa} onChange={this.changelwfa} id='lwfa' className='ml-2'/>
                                    </td>
                                    <td className='ltr'>
                                    <span>ایمیل</span>
                                    </td>
                                    <td>
                                    <input type='checkbox' checked={this.state.lwem} onChange={this.changelwem} id='lwem' className='ml-2'/>
                                    </td>
                                  </tr>

                                </tbody>
                              </Table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        </>
                        :
                        <></> }
                      {this.state.activetab === 3 ?
                        <>
                        <Row  className=''>
                          <Col xl={{size:6 , offset:3}} lg={{size:8 , offset:2}} md='12' sm='12' xs='12' className='mx-auto'>
                          <p className='rtl py-3 bg-light px-3'>تغییر رمز عبور</p>
                          <Form>
                               <FormGroup className='mt-4'>
                               <Label className='required'>
                               {t('previous-password')}
                               </Label>
                               <Input
                                  component="input"
                                  name="oldpass"
                                  className="ltr py-2"
                                  type="password"
                                  onChange={this.onChange}
                                />
                                {this.validator.message('oldpass', this.state.oldpass, 'required|min:6')}
                                {this.state.oldpasserror !== '' ?
                                <FormText color='danger'>
                                  {this.state.oldpasserror}
                                </FormText> : <></>}
                                </FormGroup>

                               <FormGroup className='my-4'>
                               <Label className='required'>
                               {t('new-password')}
                               </Label>
                               <Input
                                  name="newpass"
                                  className="ltr py-2 pr-5"
                                  type={this.state.show === true ? 'text' : "password" }
                                  onChange={this.onChange}
                                />
                                <span className='input-span' onClick={this.showpass}>
                                {this.state.show === false ?
                                <BsEyeFill />
                                :
                                <BsEyeSlashFill />}
                                </span>
                                {this.validator.message('newpass', this.state.newpass, 'required|min:6')}
                                {this.state.newpasserror !== '' ?
                                <FormText color='danger'>
                                  {this.state.newpasserror}
                                </FormText> : <></>}
                                <div onClick={this.show} className='input-span  pointer'>

                                </div>
                                </FormGroup>
                                <Col xl='12'  className=' text-center'>
                                <Button className='mt-5' onClick={this.changepassword} color="primary"   block>{t('تغییر رمز عبور')}</Button>
                          </Col>
                          </Form>
                          </Col>


                        </Row>
                        </>
                        :
                        <></> }
      {this.state.activetab === 4 ?
        <div className='p-4'>
          <p className='mb-4'>{t('referal-report')}{this.state.referal.length > 0 ? '  (' + this.state.rccount+')' : null }</p>
          <div className='d-flex mt-4'>
          <p className='text-muted d-inline'>{t('referal-invite-note')}</p>
          <p onClick={this.copyrc} className='mx-2  rewards-badge'>{this.state.rc}</p>
          </div>
          <div className='mt-4'>
          {this.state.referal.length > 0 ? this.state.referal.map(u=>
            <div className='d-flex my-auto ltr mt-5 mb-3' >
            <li></li>
            <SimpleLineIcon name='user my-auto mr-2 mb-2' size='small' />
            <span>{u.email}</span><span className='mx-2'>{u.userid}</span></div>
          ) : <>{nodata}</>}
          </div>
        </div> : <></> }
      </CardBody>
    </Card>

  </div>}
          <Modal fade={false} data-aos='slide-right' isOpen={this.state.modal} className="bg-white text-dark">
            <ModalHeader className='rtl' ><span className='text-dark rtl'>{t('send-document-guide')}</span></ModalHeader>
            <ModalBody>
              <Row  className='rtl bg-white text-dark'>

                <Col  className='text-center'>
                <img src={authdoc} alt='ارسال-مدارک' className='w-75 sample-img'/>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
             <Button onClick={this.setinfomodal} color="primary" >{t('understand')}</Button>
            </ModalFooter>
          </Modal>
          <Modal fade={false} data-aos='slide-right' data-aos-duration='500' isOpen={this.state.qrm} className="tx-detail">
            <ModalBody>
              <Row  className='rtl px-3 pt-5'>
                <Col>
                  <div className='mt-3 text-center  qrcode-container'>
                    <QRCode value={this.state.qr}  />
                   </div>
                   <div className='my-4'>
                   <Button onClick={this.copyaddress} color='success' outline size='sm' block  className='my-2 d-flex'><span className='ml-auto my-auto'>{t('copy-qrcode')}</span><PiCopyThin size='2rem' className=' my-auto mr-auto' /></Button >
                    <p>برای دریافت کد دو مرحله ای -2fa -  با استفاده از اپلیکیشن Google Authenticator کد فوق را اسکن کنید.</p>
                   </div>
                   <Row xs='1' xl='2' lg='2'>
                   <Col xl='6'>
                    <a   target="_blank"  href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pli=1'>
                    <Button block outline color='dark' className='px-5 radius'>
                      <FcGoogle color='gray' size='2rem'/>
                      </Button>
                    </a></Col>
                    <Col>
                    <a className='mr-auto'   target="_blank"  href='https://apps.apple.com/app/google-authenticator/id388497605'>
                    <Button block outline color='dark' className='px-5 radius'>
                      <BsApple color='orange' size='2rem'/>
                      </Button>
                    </a></Col>

                   </Row>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className='bg-light text-white text-center'>
            <Button onClick={()=>this.setState({qrm : false})} size='sm' color="dark"className='rtl px-3' >بستن</Button>{' '}


            </ModalFooter>
          </Modal>

    </>
    )
  }
  }
  export default withTranslation()(Profile)
