import React from 'react'
import {Helmet} from 'react-helmet'
import { useTranslation, withTranslation } from 'react-i18next';
import {Row,Col} from 'reactstrap'
import img from '../../../style/images/gatewaypayment.svg'
import {GiWallet,GiDungeonGate,GiPayMoney,GiAutoRepair,GiShieldEchoes} from 'react-icons/gi'
import {ImQrcode} from 'react-icons/im'
import {BiBarChartAlt2} from 'react-icons/bi'
import {RiIncreaseDecreaseLine} from 'react-icons/ri'
class Wallet extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }


  render(){
      const { t } = this.props;
    return(
      <>
      <Helmet>
         <title>{t('wallet.title')}</title>
     </Helmet>
      <Row xl='1' lg='1' md='1' sm='1' xs='1'>
      <Col className='header-bg-dark'>
      </Col>
      <Col>
        <div className='content-section-1'>
            <p className='rtl title-m'>{t('wallet.p1')}</p>
            <hr/>
            <Row lg='1' md='1' xl='2' sm='1' xs='1' className='justify-content-space'>
              <Col>
                <div className='text-center'>
                  <img src={img}  className='mt-5 img-section-sized'/>
                </div>
              </Col>
              <Col className='rtl'>
                <div className='mt-2'>
                <h5 className='text-primary text-center mt-2'>
                {t('wallet.p2')}
                </h5>
                <li>{t('wallet.p3')}
                </li>
                </div>
              </Col>
            </Row>
            <div className='py-5 mt-10 container'>
            <h4 className='text-center mb-5 c-i rtl'>{t('wallet.p4')}</h4>
              <Row className='text-center'  xl='3' lg='3' md='1' sm='1' xs='1'>
                <Col>
                <div className='card-small border shadow radius'>
                <div className='my-3 gradiant-border'>
                  <span className='icon-back m-auto'>
                    <GiWallet className='icon-section-2' size='3rem'/>
                    </span>
                  </div>
                  <h6>{t('wallet.p5')}</h6>
                  <div className='rtl text-medium mt-3 p-2'>
                    <p>{t('wallet.p6')}
                    </p>
                  </div>
                </div>
                </Col>
                <Col>
                <div className='card-small border shadow radius'>
                <div className='my-3 gradiant-border'>
                  <span className='icon-back m-auto'>
                    <GiShieldEchoes className='icon-section-2' size='3rem'/>
                    </span>
                  </div>
                  <h6>{t('wallet.p7')}</h6>
                  <div className='rtl text-medium mt-3 p-2'>
                    <p>{t('wallet.p8')}
                    </p>
                  </div>
                  </div>
                </Col>
                <Col>
                <div className='card-small border shadow radius'>
                <div className='my-3 gradiant-border'>
                  <span className='icon-back m-auto'>
                    <GiWallet className='icon-section-2' size='3rem'/>
                  </span>
                  </div>
                  <h6>{t('wallet.p9')}</h6>
                  <div className='rtl text-medium mt-3 p-2'>
                    <p>{t('wallet.p10')}
                    </p>
                  </div>
                  </div>
                </Col>
              </Row>
            </div>
        </div>
      </Col>
      </Row>
      </>
    )
  }
}
export default withTranslation()(Wallet)
