import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import F from 'float'
import Select from 'react-select';
import Loader from '../../landing/loader'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {FormTextM ,FormText,Modal,ModalFooter,Table, ModalBody,Card,Alert, CardHeader,FormGroup,Form,Label, CardBody, InputGroup, InputGroupText, Input,Col,Row ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Button} from 'reactstrap'
import {Helmet} from 'react-helmet'
import { withTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
import NumericFormat from 'react-number-format';
import {FaAngleDoubleDown} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import QrReader from "react-qr-reader";
import NumberFormat from 'react-number-format';
import { PiUserSwitchThin,PiPlayLight ,PiStop ,PiQrCodeThin,PiCameraSlashThin} from "react-icons/pi";
import Toast from 'awesome-toast-component'
import {AiOutlineCopy} from "react-icons/ai";

const MySwal = withReactContent(Swal);

class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data : [],
      address : '',
      mode : 2 ,
      list : [],
      chains : [],
      currntchain : [],
      small : [],
      c1 : [],
      coin : [],
      txs : [],
      coinname : '',
      cname : '',
      scanner : false,
      btn : false,
      disabled : false,
      modal : false,
      record : true,
      chain : true,
      camera : true,
      loader : true,
      selected : "environment",
      n : 0,
      step : 0,
      successwithdrawal : false,
      orderid : '',
      fee : 0,
      des : '',
      key : '3',
      delay: 100,
     result: 'No result',
    }
this.validator = new SimpleReactValidator();
  }
  componentWillMount(){
    let uuid = cookie.load('uid')
    if(!uuid) {
      this.props.history.push('/auth/login')
    }
    this.getwallet()
  }
  getwallet =()=>{
    this.chekcookie()
    axios.get('/api/wallet/withdrawcrypto/all').then(response=>{
      if(response.data.status === "error-fatal"){
        document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        cookie.remove('uid')
        axios.get('/Users/logout')
        this.props.history.push('/auth/login')
      }
        if(response.data.status === 'error'){
          this.setState({loader : false,error : true , errormessage : response.data.message})
          return
        }
        this.setState({loader : false,list :  response.data.data, c1 : response.data.data[0],coinPrice : response.data.data[0].irprice , fee : response.data.data[0].fee,auth : response.data.auth})
      })
  }
  chekcookie = ()=>{
    let uid = cookie.load('uid')
    if(!uid){
      this.props.history.push('/')
      return
    }
  }
  toggle = ()=>{
    this.setState({dropdownOpen : !this.state.dropdownOpen})
  }

  // setcointaker = (item) =>{
  //   console.log(item);
  //   this.setState({coinname : item.irname ,cname : item.usname ,coinPrice : item.irprice , fee : F.round(item.fee,10),coin : item})
  // }
  copyaddress =()=>{
    const { t } = this.props
    if(this.state.address !== ''){
      navigator.clipboard.writeText(this.state.c1.index0)
      new Toast(`${t('copied')}`, {
        style: {
            container: [
                ['background-color', 'green']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })

      }
  }
  copytxid =()=>{
    const { t } = this.props
    if(this.state.orderid !== ''){
      this.setState({modal : false})
      navigator.clipboard.writeText(this.state.orderid)
      new Toast(`${t('copied')}`, {
        style: {
            container: [
                ['background-color', 'green']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })

      }
      this.setState({step : 1})
  }


  setdes =(e)=>{
    this.setState({address: e.target.value})
  }
  onchange =(e)=>{
    this.setState({[e.target.name] : e.target.value})
  }
  setamount =(values, sourceInfo)=>{
    let a = values.value

    this.setState({amount : a })
  }
  setamountall =()=>{
    let balance = this.state.c1.availablebalance
    let fee = parseFloat(this.state.currntchain.fee)
    let max = balance - fee
    this.setState({amount : max })
  }
  submit = (e) =>{
    e.preventDefault();
    const form = new FormData()
    form.append('dp',this.state.c1.index0)
    form.append('des',this.state.des)
    form.append('slug',this.state.c1.slug)
    form.append('amount',this.state.amount)
  }
  setwithdrawal = (e) =>{
    e.preventDefault()
    let fee = parseFloat(this.state.currntchain.fee)
    let balance = parseFloat(this.state.c1.availablebalance)
    let max = balance - fee
    if(this.state.coinname === '' || this.state.c1 === [] ){
      new Toast(`ارزی انتخاب نکرده اید`, {
        style: {
            container: [
                ['background-color', 'red']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })

      return
    }
    if(this.state.currntchain === [] ){
      new Toast(`شبکه برداشت را انتخاب کنید`, {
        style: {
            container: [
                ['background-color', 'red']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })

      return
    }
    if(parseFloat(this.state.amount) > max ){
      new Toast(`موجودی کافی نیست`, {
        style: {
            container: [
                ['background-color', 'green']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
      console.log('موجودی کافی نیست')
      return
    }
    if(parseFloat(this.state.currntchain.withdrawalMinSize) > parseFloat(this.state.amount)){
      new Toast(`'مقدار برداشت غیر مجاز است`, {
        style: {
            container: [
                ['background-color', 'green']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
      console.log('مقدار برداشت غیر مجاز است')
      return
    }
    if(this.state.address === ''){
      new Toast(`'آدرس را وارد نکرده اید`, {
        style: {
            container: [
                ['background-color', 'green']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
      console.log('آدرس را وارد نکرده اید')
      return
    }
    this.setState({btn : true})
    const  formdata =  new FormData()
    formdata.append('accid' ,this.state.c1.acc_id)
    formdata.append('amount' ,this.state.amount)
    formdata.append('chain' ,this.state.currntchain.chain)
    formdata.append('fee' ,this.state.currntchain.fee)
    formdata.append('from' ,this.state.c1.index0)
    formdata.append('to' ,this.state.address)
    formdata.append('tag' ,this.state.coin.tag)
    formdata.append('slug' ,this.state.c1.slug)
    formdata.append('chainName' ,this.state.currntchain.chainName)
    formdata.append('auth' ,this.state.auth)

    axios.post('/api/withdrawal/submit/',formdata).then(response=>{
        this.setState({btn : false})
      if(response.data.status === 'error'){

      }
      if(response.data.status === 'تائید برداشت'){
        this.setState({step : 1 , wid : response.data.Withdrawal_id})
      }
    })
  }
  withdraw = (e) =>{
     e.preventDefault();
     // this.setState({modal : !this.state.modal})
     const  formdata =  new FormData()
     formdata.append('accid' ,this.state.c1.acc_id)
     formdata.append('amount' ,this.state.amount)
     formdata.append('fee' ,this.state.currntchain.fee)
     formdata.append('from' ,this.state.c1.acc_id)
     formdata.append('to' ,this.state.address)
     formdata.append('tag' ,this.state.c1.tag)
     formdata.append('slug' ,this.state.c1.slug)
     formdata.append('otp' ,this.state.otp)
     formdata.append('withdrawal_id' ,this.state.wid)
     formdata.append('chain' ,this.state.currntchain.chain)
     formdata.append('chainName' ,this.state.currntchain.chainName)
     this.setState({disabled : true})
     axios.post('/api/withdrawal/submit_withdrawal/',formdata).then(response=>{
       setTimeout(()=>this.setState({disabled : false}) , 5000)
       if(response.data.status === 'error-fatal'){
         this.props.history.push('/')
         return
       }
       if(response.data.status === 'error'){
         new Toast(response.data.content, {
           style: {
               container: [
                   ['background-color', 'red']
               ],
               message: [
                   ['color', '#eee'],
               ],
               bold: [
                   ['font-weight', '900'],
               ]
           },
           timeout : 3000,
           position : 'top'
       })
         console.log(response.data.content)
         return
       }else{
         new Toast(response.data.content, {
           style: {
               container: [
                   ['background-color', 'green']
               ],
               message: [
                   ['color', '#eee'],
               ],
               bold: [
                   ['font-weight', '900'],
               ]
           },
           timeout : 3000,
           position : 'top'
       })
         this.setState({
           successwithdrawal : true ,
           orderid : response.data.orderid ,
           data : [],
           mode : 0 ,
           currntchain : [],
           small : [],
           c1 : [],
           coin : [],
           txs : [],
           address : '',
           coinname : '',
           amount : '',

       })
         this.getwallet()

         // this.props.history.push('transations')
       }
     })
   }

setcointaker = (item) =>{
  this.setState({coinname : item.slug , coinPrice : item.irprice , fee : item.fee,c1 : item , selected : false})
  var url = '/api/wallet/withdraw_chain/'+item.slug
   axios.get(url).then(response=>{
     this.setState({chains : response.data , chain : false})
   })
  // this.copyaddress()
}

togglemodal =()=>{
  this.setState({modal : !this.state.modal , successwithdrawal : false})
}


handleChange2 = (e)=>{
  this.setState({coin : e , selected : false})
  var maxamount = parseFloat(e.availablebalance)
  this.setState({maxamount : maxamount})
  var vc = e.slug
  var url = '/api/wallet/withdraw_chain/'+vc
   axios.get(url).then(response=>{
     this.setState({chains : response.data , chain : false})
   })
}


handlechain = (e) => {
  var chainName = e.chainName
  this.chain = e
  this.setState({
    a : e.withdrawalMinSize,
    b : e.fee,


  })
  this.setState({currntchain : e , selected : true})
  this.setState({ fee : e.fee });
  if(parseFloat(this.state.c1.availablebalance) < parseFloat(e.withdrawalMinSize)){
    this.setState({

      c : 'موجودی شما برای برداشت کافی نمیباشد',

    })
    new Toast(`موجودی شما برای برداشت کافی نمیباشد`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top',
  })
    return
  }else{
    this.setState({

      c : this.state.c1.availablebalance - e.fee,

    })
  }
}

onchangeamount=(e)=>{
  this.setState({amount : parseFloat(e.target.value) })
}
openscanner =()=>{
  this.setState({scanner : !this.state.scanner})
}

switchcamera =(e)=>{
  this.setState({camera : !this.state.camera})
}
switchrecord =(e)=>{
  this.setState({record : !this.state.record})
}
handleScan = (data) => {
  console.log(data);
    if (data) {
      this.setState({
        address: data , scanner : false
      })
      return
    }else{
      return
    }
  }

  handleChange = (values) => {

    let otp = values.value
    this.setState({ otp : values.value })
    return
    }
  submitotp =(e)=>{
    const {t} = this.props
    e.preventDefault()
    let otp = this.state.otp
      axios.get('/api/signup/verify_otp/'+otp).then(response=>{

        if(response.data.status === 'success'){
          new Toast(`${t('شماره همراه شما با موفقیت احراز شد')}`, {
            style: {
                container: [
                    ['background-color', 'green']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
            position : 'top'
          })

this.setState({step : 3})

        }
        if(response.data.satus === 'error'){
          console.log(response.data.message)
        }
      })
  }
returnd = ()=>{
  this.setState({
    step : 0 ,
    address : '',
    chains : [],
    currntchain : [],
    n : 0,
    step : 0,
    successwithdrawal : false,
    orderid : '',
    fee : 0,coinname :'',
    selected  :false,
    otp : '',
    successwithdrawal : true ,
    orderid : '' ,
    data : [],
    mode : 0 ,
    currntchain : [],
    small : [],
    c1 : [],
    coin : [],
    txs : [],
    address : '',
    coinname : '',
    amount : '',

  })
}


  render(){
    const previewStyle = {
      height: 240,
      width: 320,
    }
    const { t } = this.props;
    // console.log(this.state);
    var id =this.props.match.params.id
    return(
    <>
    <Helmet>
       <title>برداشت  | رپیتکس</title>
    </Helmet>
    {this.state.loader === true ?
      <>
        <div className='loader-home py-5'>
          <h3 className='text-center m-auto mt-auto'><Loader /></h3>
        </div>
      </> :
    <div className='rtl main-div mt-3'>

          <Card className=''>
          <CardHeader>
            <div className='d-flex rtl'>
             <p onClick={()=>this.setState({type : 1})} className={this.state.type === 1 ? 'pointer' : 'pointer' }>برداشت </p>
            </div>
          </CardHeader>
          <CardBody>
          {this.state.error === true ? <Alert color="info" className='rtl mt-3 mx-3'>
          <p><span>{this.state.errormessage}.</span><Link className='mx-1' to='/panel/profile' >{t('click-to-verify')}</Link></p>
           </Alert> :
            <Row>
              <Col  className='mx-auto py-3' xl='7' lg='12' xs='12'>
              {this.state.step === 1 ? <div>
              {this.state.successwithdrawal === true ?

                <>
                  <p className='my-5 rtl text-dark text-center'>برداشت شما با موفقیت انجام شد.</p>
                  <p className='mx-auto text-center  mt-5 py-2 '>
                    <span className=''>{this.state.orderid}</span>
                    <span>  <AiOutlineCopy className='my-auto pointer text-muted' color='purple' size='1rem' onClick={this.copytxid} /></span>
                  </p>
                  <div className='mt-5 text-center'>
                  <Button onClick={this.returnd} color='primary' className='px-5 py-2'>
                  {t('ok')}
                  </Button>

                  </div>
                </>

                : <>
              {this.state.auth === 'email' ? <>
                <p className='my-5 rtl text-dark text-center'>برای تکمیل فرآیند برداشت کد اعتبارسنجی ارسال شده به ایمیل خود را وارد کنید</p>
                </> : <>

                </>}
              {this.state.auth === '2fa' ? <>
                <p className='my-5 rtl text-dark text-center'>برای تکمیل فرآیند برداشت کد  اعتبارسنجی 2fa  را وارد کنید.</p>
                </> : <>

                </>}
              {this.state.auth === 'sms' ? <>
                <p className='my-5 rtl text-dark text-center'>برای تکمیل فرآیند برداشت کد ارسالی به شماره همراه را وارد کنید.</p>
                </> : <>

                </>}
                <Form>
                <FormGroup >
                <Label className='required'>{t('enter otp code')}</Label>
                <NumberFormat
                  placeholder={t('otp code')}
                    name='otp'
                    inputMode="decimal"
                    value={this.state.otp}
                    onValueChange={(values,sourceInfo) =>this.handleChange(values , sourceInfo)}
                    displayType="input"
                    className='font-weight-bold ltr text-center form-control w-100'
                  />
                {this.validator.message('otp', this.state.otp, 'required|integer|size:4')}
                </FormGroup>

                <FormGroup className='d-flex justify-content-space-between mt-5'>
                  <Button disabled={this.state.disabled} type='submit' color="primary" onClick={this.withdraw} className='px-5 py-2 ml-auto'>برداشت</Button>
                </FormGroup>
                </Form>
                </>}
                </div> :<></> }
            {this.state.step === 0 ?   <div className='add-containe' dir='rtl'>
              <Form className='justify-content-space-between'>
                <FormGroup>
                <div className='rtl'>
                <UncontrolledDropdown className='ticker-list-deposit ml-auto '>
                  <DropdownToggle  color='light' block className=''>
                {this.state.coinname === '' ?
                  <div className=''> <p className='py-2 text-medium text-muted text-center'>یک ارز انتخاب کنید</p>
                  </div>
                :
                  <div className='d-flex justify-content-space-between'>
                  <span className={this.state.c1.slug+'-icon cr-icon'}></span>

                  <span className='slug-crypto my-auto mr-auto text-dark text-medium   font-weight-bold'>{this.state.c1.slug}</span>


                </div>
              }
                  </DropdownToggle>
                  <DropdownMenu className=''>
                  <div className='my-1 rtl'>
                  {this.state.list.map(item=>
                    <>
                      <DropdownItem active={item.usname === 'usdt' ? true : false} data-name={item.irname} id={item.id} data-name={item.usname} onClick={()=>this.setcointaker(item)}>
                      <div className='d-flex justify-content-space-between'>
                        <span className={item.slug+'-icon cr-icon'}></span>
                        <span className='mr-2 text-dark  text-medium mt-2 selected-text'>{item.slug}</span>
                        <span className='text-dark text-medium mr-auto mt-2 selected-text'>{item.usname}</span>
                      </div>
                      </DropdownItem>
                    </>
                  )}
                  </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                </div>
                <div className='mt-3'>
                <UncontrolledDropdown className='ticker-list-deposit ml-auto'>
                  <DropdownToggle  color='light' block className=''>
                {this.state.selected !== true  ?
                  <div className=''> <p className='py-2 text-medium text-muted text-center'>شبکه برداشت را انتخاب کنید</p>
                   </div>
                  :
                  <>
                  <li className='d-flex'>
                  <span className={this.state.currntchain.currency +'-icon'}>
                  </span>

                  <span className='mr-auto my-auto text-dark text-medium  font-weight-bold'>{this.state.currntchain.chainName}
                  </span>
                  </li>
                  </>
              }
                  </DropdownToggle>
                  <DropdownMenu className='w-100 modifier'>
                  {this.state.chains.map(item=>
                    <>
                      <DropdownItem  onClick={()=>this.handlechain(item)}>
                        <li className='d-flex'>
                        <span className={item.currency +'-icon'}>
                        </span>
                        <span className='mr-2 mt-2 text-muted text-medium rtl'>در شبکه
                        </span>
                        <span className='mr-2 mt-2 text-muted text-medium rtl'>{item.chain}
                        </span>
                        <span className='mr-auto mt-2 text-muted text-medium font-weight-bold'>{item.chainName}
                        </span>
                        </li>
                      </DropdownItem>
                    </>
                  )}
                  </DropdownMenu>
                </UncontrolledDropdown>
                </div>

                </FormGroup>
                <FormGroup className='mt-3' >
                <label className='float-label'>
                {t('destination-address')}
                </label>
                   <Input
                     onChange={this.setdes}
                     className='form-control ltr text-center pl-5'
                     id="des"
                     name="des"
                     type="text"
                     value={this.state.address}
                   />

                </FormGroup>
                <FormGroup  className='mt-3'>
                <label className='float-label'>
                {t('destination-tag')}
                </label>
                  <Input
                    onChange={this.onchange}
                    id="tag"
                    name="tag"
                    type="text"
                    className='ltr form-control text-center pl-4'
                  />
                  <FormText>{t('need-memo-note')}</FormText>
                </FormGroup>
                <FormGroup className='mt-3' >
                <label className='float-label'>
                {t('tx-amount')}
                </label>
                   <NumericFormat
                   className='ltr form-control text-center pl-4'
                    value={this.state.amount}
                    placeholder='مقدار را وارد کنید'
                    thousandSeparator=","
                    onValueChange={(values, sourceInfo) => {
                    this.setamount(values, sourceInfo)
                     }}
                    name="amount"
                    displayType="input"
                   />

                 {this.state.coinname !== '' ?
                 <>
                 <Table className='mt-5 text-small lh-lg text-muted' size='small' responsive borderless>
                  <thead>
                  </thead>
                  <tbody>
                    <tr className='rtl'>
                    <td>موجودی شما :
                    </td>
                    <td className='ltr'>{this.state.c1.availablebalance}
                    </td>
                    </tr>
                    <tr className='rtl'>
                    <td>قابل برداشت :
                    </td>
                    <td className='ltr' onClick={this.setamountall}>{this.state.c}
                    </td>
                    </tr>
                    <tr className='rtl'>
                    <td>حداقل کارمزد برداشت :
                    </td>
                    <td className='ltr'>{this.state.b}
                    </td>
                    </tr>
                    <tr className='rtl'>
                    <td>حداقل مقدار برداشت :
                    </td>
                    <td className='ltr'>{this.state.a}
                    </td>
                    </tr>
                  </tbody>
                 </Table>
                 </>
                 :<></>}
                </FormGroup>

                <FormGroup   className='mt-3'>
                  <Button className='form-button' disabled={this.state.btn} type='submit'  color='primary' onClick={this.setwithdrawal}>ثبت</Button>
                </FormGroup>

                </Form>
              </div> : <></> }
              </Col>
              <Col xl='5' className='bg-light text-dark info-col'>
                <p className='p-3 text-dark'>
                نکات مهم قبل از انجام برداشت :
                </p>
                <p className='p-3 text-medium text-dark'>
                در تعیین شبکه برداشت دقت لازم را داشته باشید و از پشتیبانی کیف پول مقصد از شبکه انتخابی اطمینان حاصل کنید.
                 </p>
                <p className='p-3 text-medium text-dark'>
                در صورتی که آدرس مقصد متعلق به کاربر رپیتکس باشد، انتقال به صورت مستقیم و سریع از رپیتکس انجام شده و کارمزد برداشت شما صفر خواهد بود.
              </p>
                <p className='p-3 text-medium text-dark'>
در صورتی که درخواست برداشت به یکی از آدرس‌های ثبت شده در دفتر آدرس داشته باشیم دیگر نیازی به کد دوعاملی و رمز یکبار مصرف هنگام درخواست برداشت نیست.             </p>
                <p className='p-3 text-medium text-dark'>
لطفا قبل از معامله در رپیتکس، فرآیند احراز هویت خود را کامل کرده و پس از آن کیف پول خود را شارژ کنید.             </p>
                <p className='p-3 text-medium text-dark'>
از ثبت برداشت به آدرس کانترکت خودداری کنید. در غیر این صورت هزینه مازاد به عهده کاربر خواهد بود.             </p>
                <p className='p-3 text-medium text-dark'>
از برداشت مستقیم از آدرس خود به مقصد اکسچنج‌های جهانی که در شروط استفاده از خدمات خود، کاربران ایرانی را محدود ساخته‌اند به ویژه اکسچنج‌های آمریکایی نظیر کوین‌بیس و کراکن جداً خودداری فرمایید. در صورت نیاز به چنین انتقالی حتماً از کیف پول شخصی و آدرس‌های یک بارمصرف و انتقال چند لایه بین آدرس های‌خود استفاده کنید.             </p>
              </Col>
            </Row>
}
            </CardBody>
            </Card>

    </div> }
    <Modal isOpen={this.state.scanner} toggle={this.openscanner} className='rtl py-4'>
        <ModalBody>

          <div className='h-400'>
            <p className='p-3 text-center'>{t('qr-code-scanner')}</p>
          {this.state.record === true ?
            <QrReader
            facingMode = {this.state.camera === true ? 'environment' : 'user'}
            delay={this.state.delay}
            onError={this.handleError}
            onScan={(data)=>this.handleScan(data)}
            style={{ width: '100%' , height : '100%'}}
            />
:<></>}
          </div>
        </ModalBody>

          <div className='d-flex p-3 justify-content-space-between'>
            <span onClick={this.switchcamera} className=''><PiUserSwitchThin size='2rem' /></span>
            {this.state.record === true ? <span onClick={this.switchrecord} className=''><PiStop size='2rem' /></span> :<span onClick={this.switchrecord} className=''><PiPlayLight size='2rem' /></span>
             }
            <span onClick={() => this.setState({scanner : false})} className=''><PiCameraSlashThin size='2rem' /></span>
          </div>
      </Modal>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
