import React from 'react'
import axios from 'axios'
import { List, ListInlineItem ,Button} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';
import {Link} from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      usdt : {
                    'date' : '******',
                    'high' : '******',
                    'low'  : '******',
                    'change' :'******',
                    'price'  :'******',
                  }
    }
  }
  componentWillMount(){
    // this.int = setInterval(()=> this.getusdt() ,30000 )
  }
  // getusdt =()=>{
  //   axios.get('/api/market/').then(res=>{
  //     this.setState({usdt : res.data.usdt})
  //   })
  // }

  deposit=(e)=>{
    var id = e.currentTarget.getAttribute('id')
    var url = '/Panel/deposit/'+id
    this.props.history.push(url)
  }
componentWillUnmount(){
  clearInterval(this.int)
}
  render(){
    const { t } = this.props;
    const c = this.props.usdtirprice === '******' ?  <p className=''></p>:
      <NumericFormat value={this.props.usdtirprice}  thousandSeparator="," displayType="text"  className='text-medium my-auto font-weight-bold mt-2 mx-1' />
    const l = this.props.usdtirprice === '******' ?  <p className=''>
</p>:
      <NumericFormat value={this.props.usdtmin}  thousandSeparator="," displayType="text"  className='text-medium my-auto font-weight-bold mt-2 mx-1'/>
    const h = this.props.usdtprice === '******' ?  <p className=''>
</p>:
      <NumericFormat value={this.props.usdtmax}  thousandSeparator="," displayType="text"  className='text-medium my-auto font-weight-bold mt-2 mx-1'/>
    return(
    <>
    <List style={{'height' : '50px'}} type="inline" className='rtl border-light-xl d-flex justify-content-space-between'>
          <ListInlineItem>
          <div className='usdt-icon my-auto'></div>
          </ListInlineItem>
          <ListInlineItem>
          <b className='mr-2 bold'>{this.props.usdtday}</b>
          </ListInlineItem>
          <ListInlineItem>
          <p className='text-medium my-auto mr-3'> قیمت لحظه ای : {c}</p>
          </ListInlineItem>
          <ListInlineItem>
          <p className='text-medium my-auto mr-auto '>
          بالا ترین :{h}</p>

          </ListInlineItem>
          <ListInlineItem>
          <p className='text-medium my-auto mr-auto '>
          پائین ترین :   {l}
          </p>

          </ListInlineItem>
          <ListInlineItem>
          <p className='text-medium my-auto mr-auto'>
          تغییرات : % {this.props.changeRate > 0 ?
          <span className='text-medium my-auto text-success font-weight-bold mx-2'>{this.props.changeRate} </span> :
          <span className='text-medium my-auto text-danger font-weight-bold mx-2'>{this.props.changeRate} </span>
          }</p>

          </ListInlineItem>
          <ListInlineItem style={{'height' : '50px'}} className='r-0 btn btn-primary text-medium my-auto mr-auto px-5 r0'>
          <Link  to='/panel/exchange/buy/USDT'>
           <p className='text-white-fixed mt-2 f14'>{t('buy-usdt-now')}</p>
          </Link>
          </ListInlineItem>

    </List>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
