import React from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import cookie from 'react-cookies'
import SimpleReactValidator from 'simple-react-validator'
import {useTranslation,withTranslation} from 'react-i18next'
import {Row,Col, Container,Button,Form,FormFeedback,FormGroup,Label,Input,FormText} from 'reactstrap'
import {Link} from  'react-router-dom'
import Spinner from '../../spinner.js'

import logo from '../../../style/images/logo.png'
import SimpleLineIcon from 'react-simple-line-icons'
import NumberFormat from 'react-number-format';
import { GoogleLogin,GoogleOAuthProvider } from '@react-oauth/google'
const MySwal = withReactContent(Swal)


class Homelayer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        logedin : false,
        email : '',
        password : '',
        step : 1,
        name : '',
        family : '',
        nationalid : {},
        mobile : '',
        otp : '',
        ne : false,
        fe : false,
        button1 : false,
        button2 : false,
        button3 : false,
        button4 : true,
        refrence_id : '',
        nationalid : ''

    }
    this.validator = new SimpleReactValidator();
  }


  componentDidMount(){

  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  onChangep = (e) => {
    var p = /^[\u0600-\u06FF\s]+$/;

   if (!p.test(e.target.value)) {
       this.setState({ne : true , nem : 'کیبورد خود را فارسی نمایید' , name : ''})
       return
   }else{
    this.setState({
      [e.target.name]: e.target.value , ne : false , nem : ''
    })
  }
  }
  onChangef = (e) => {
    var p = /^[\u0600-\u06FF\s]+$/;

   if (!p.test(e.target.value)) {
       this.setState({fe : true , fem : 'کیبورد خود را فارسی نمایید' , family : ''})
       return
   }else{
    this.setState({
      [e.target.name]: e.target.value , fe:false , fem : ''
    })
  }
  }
  blur = ()=>{
    const {t} = this.props
    this.setState({blur : !this.state.blur,submit : !this.state.submit,message : `${t('sending-data')}`})
    // console.log(`${t('sending-data')}`)
  }
  submit= (e) => {
    e.preventDefault();
    const {t} = this.props
    // if (!this.validator.fieldValid('name')) {
    //   console.log(`${t('name-error-signup')}`)
    //   return
    // }else{
    // }
    // if (!this.validator.fieldValid('email')) {
    //   console.log(`${t('email-error-signup')}`)
    //   return
    // }else{
    // }
    // if (!this.validator.fieldValid('password')) {
    //   console.log(`${t('password-error-signup')}`)
    //   return
    // }else{
    // }
    var d = this.state.password;
    const data = new FormData()
    data.append('name',this.state.name)
    data.append('password',d)
    data.append('email',this.state.email)
    this.blur()
    axios.post('/api/signup' , data)
    .then(response=> {
      setTimeout(
        () => this.setState({ blur : !this.state.blur}),
        3000
      );
      if(response.data.status || response.data.type === 'error'){
        if(response.data.content === 'duplicate.email'){
          console.log(`${t('duplicate.error')}`)
          return
        }else{
          console.log(`${t('error.server')}`)
        }
      }else if(response.data.status || response.data.type === 'success'){
        this.setState({verified : 'ok',status : 'ثبت',mode : 'note',message : `${t('success.register')}`,tc: 'h6 loader-message text-success text-center'})
        console.log('ایمیل فعالسازی حساب کاربری ارسال شد')
      }
    });
  }
  componentWillMount(){
    let uuid = cookie.load('uuid')
      if(uuid) {
        this.props.history.push('/')
      }
      var email =this.props.match.params.email
      this.setState({email : email})
  }
  stepone = (e)=>{
    e.preventDefault();
    // this.setState({step : 2})
    // return
    const {t} = this.props
    if (!this.validator.fieldValid('name')) {
      console.log('نام خود را وارد کنید')
      return
    }else{
    }
    if (!this.validator.fieldValid('family')) {
      console.log('نام خانوادگی خود را وارد کنید')
      return
    }else{
    }

    if (!this.validator.fieldValid('nationalid')) {
      console.log('کد ملی را وارد کنید')
      return
    }else{
    }

    if (!this.validator.fieldValid('mobile')) {
      console.log('شماره تلفن همرا را وارد کنید')
      return
    }else{
    }

    this.setState({button2 : true})
    const formdata = new FormData()
    formdata.append('name',this.state.name)
    formdata.append('family',this.state.family)
    formdata.append('nationalid',this.state.nationalid)
    formdata.append('mobile','0'+this.state.mobile)
    axios.post('/api/signup/step_one_registration',formdata).then(response=>{
  this.setState({button2 : false})
  if(response.data.status === 'error'){
    MySwal.fire({
      position: 'center',
     icon: 'error',
     title: <p className='rtl text-center'>{response.data.message}</p>,
     showConfirmButton: false,
     timer: 15000,
   didOpen: () => {
     // `MySwal` is a subclass of `Swal` with all the same instance & static methods
     MySwal.showSuccess('مرحله بعد')
   },
 }).then(() => {
 })
  }
 if(response.data.status === 'success'){
   MySwal.fire({
     position: 'center',
    icon: 'success',
    title: <p className='rtl text-center'>مرحله اول ثبت نام با موفقیت انجام شد</p>,
    showConfirmButton: false,
    timer: 1500,
  didOpen: () => {
    // `MySwal` is a subclass of `Swal` with all the same instance & static methods
    MySwal.showSuccess('مرحله بعد')
  },
}).then(() => {
  this.setState({step : 2})
})
   //
 }
})
  }
  step3 = (e)=>{
    e.preventDefault();
    // this.setState({step : 2})
    // return
    const {t} = this.props


    if(this.state.refrence_id === ''){

    }
    this.setState({button3 : true})
const formdata = new FormData()
formdata.append('email',this.state.email)
formdata.append('refrence_id',this.state.refrence_id)

axios.post('/api/signup/step_three_registration',formdata).then(response=>{
  if(response.data.status === 'error'){


        MySwal.fire({
          position: 'center',
         icon: 'error',
         title: <p className='rtl text-center'>{response.data.message}</p>,
         showConfirmButton: false,
         timer: 10000,
       didOpen: () => {
         // `MySwal` is a subclass of `Swal` with all the same instance & static methods
         MySwal.showSuccess('مرحله بعد')
       },
     }).then(() => {
       this.setState({button3 : false})
     })


  }
  if(response.data.status === 'success'){
    MySwal.fire({
      position: 'center',
     icon: 'success',
     title: <p className='rtl text-center'>کد اعتبار سنجی ایمیل ارسال گردید</p>,
     showConfirmButton: false,
     timer: 3000,
   didOpen: () => {
     // `MySwal` is a subclass of `Swal` with all the same instance & static methods
     MySwal.showSuccess('مرحله بعد')
   },
 }).then(() => {
   this.setState({step : 4})
 })
    //
  }
})
  }
  handleChange = (values , sourceInfo) => {

    let otp = values.value
    this.setState({ otp : values.value })
    if(otp.length === 4){
      this.setState({button2 : true})
      const formdata = new FormData()
      formdata.append('otp' , otp)
      axios.post('/api/signup/verify_otp',formdata).then(response=>{
              // console.log(response.data.message)
        if(response.data.status === 'success'){
          MySwal.fire({
            position: 'center',
           icon: 'success',
           title: <p className='rtl text-center'>شماره همراه شما با موفقیت احراز شد</p>,
           showConfirmButton: true,
           timer: 1500,
         didOpen: () => {
           // `MySwal` is a subclass of `Swal` with all the same instance & static methods
           MySwal.showSuccess('مرحله بعد')
         },
       }).then(() => {
         this.setState({step : 3})
       })
          //
        }
        if(response.data.satus === 'error'){
          console.log(response.data.message)
        }
      })

    }}
  handleChangeemail = (values) => {
    let otp = values.value
    this.setState({ otpemail : otp });
    if(otp.length === 4){
      this.setState({button2 : true})
      const formdata = new FormData()
      formdata.append('otp' , otp)
      axios.post('/api/signup/verify_otp_email',formdata).then(response=>{
        if(response.data.status === 'success'){
            MySwal.fire({
              position: 'center',
             icon: 'success',
             title: <p className='rtl text-center'>ثبت نام شما با موفقیت انجام گردید</p>,
             showConfirmButton: false,
             timer: 3000,
           didOpen: () => {
             // `MySwal` is a subclass of `Swal` with all the same instance & static methods
             MySwal.showSuccess('مرحله بعد')
           },
         }).then(() => {
           this.setState({step : 3})
         })
            //

          setTimeout(
            ()=>this.props.history.push('/Panel/Dashboard') , 3100
          )
        }
        if(response.data.satus === 'error'){
          this.setState({ otpemail : '' });
          console.log(response.data.message)
        }
      })
    }}
setdate = (e)=>{
  this.setState({bdate : e.target.value})
}
change =(values,sourceInfo)=>{
  let value = values.value
  var p = /^[\u0600-\u06FF\s]+$/;

 if (p.test(value)) {
     console.log('کیبورد را به حالت انگلیسی قرار دهید')
     return
 }
  this.setState({[sourceInfo] : value})
}
success = data => {
  const {t} = this.props
  axios.post('/api/Google',data).then(response=>{
    if(response.data.status || response.data.type === 'error'){
      if(response.data.content === 'not.found'){
        // this.setState({mode : 'signin'})
        console.log('کاربری یافت نشد')
        return
    }
      this.setState({active2 : true})
      if(response.data.content === 'wrong.password.email'){
        this.setState({message : `${t('error.pass.not.match')}`})
        console.log('رمز عبور یا ایمیل اشتباه است')
      }
      if(response.data.content === 'not.active'){
        this.setState({message : `${t('error.login.not.active')}`})
      }
      if(response.data.content === 'not.found'){
        this.setState({message : `${t('error.login.not.found')}`})
      }
      // this.setState({active2 : true})

    }
    if(response.data.type === 'success' || response.data.status === 'success'){
      if(response.data.auth === 'loggedin'){
        this.props.history.push('/panel/dashboard')
      }else{
      this.setState({mode : 'code' , auth : response.data.auth})
    }
   }
  })
}
successsignup = response => {
  axios.post('/api/Google/signup',response).then(response=>{
    if(response.data.type === 'success'){
      if(response.data.mode === 'getinfo'){
            this.setState({mode : 'getphone',cname : response.data.name, cmail : response.data.email})
            return
      }
      this.props.history.push('/panel/Dashboard')
      }else{
      this.props.history.push('/')
    }
  })

}
error = response => {
  axios.post('/api/Google',response).then(response=>{
    console.log(response)
  })
  console.error(response) // eslint-disable-line
}
  render(){
    const {t} = this.props
    return(
      <div>
      <GoogleOAuthProvider clientId={t('clientId')}>

            <h4 className='text-center hero-icon-4 mb-5'>تکمیل مرحله ثبت نام</h4>
            {this.state.step === 5 ?
              <>

              <div className='h100'>
                <h5 className='my-auto c-i mt-2'>تکمیل ثبت نام</h5>
              </div>


              <div className='h100 d-flex '>
              <SimpleLineIcon  name='user-following mx-1' color='#0069d9' size='large'/>
              <p className='pt-2 mr-2 mt-1'>ثبت نام شما با موفقیت انجام شد</p>

              </div>

              <div className='h100 '>
                <FormGroup className='text-center' color='success'>
                  <Button  onClick={this.forwardlogin}  block type='submit' color='primary'  className='input-addon-home pointer mt-5 py-2'>ورود کاربر
                  </Button>
                </FormGroup>
              </div>
              </>
              :<></> }
            {this.state.step === 3 ?
              <>

              <div className='' id='auth-login'>
              <FormGroup>
              <Label for="email">
                ایمیل
              </Label>
              <Input
                placeholder=''
                className='py-2'
                type='email'
                name='email'
                
                disabled
                value={this.state.email}
               />
              {this.validator.message('email', this.state.email, 'required|email')}
              <FormFeedback>
              </FormFeedback>
              <FormText>آدرس ایمیل خود را وارد کنید
              </FormText>
              </FormGroup>

              </div>
              <div className='' id='auth-login'>
              <FormGroup>
              <Label for="password">
                رمز عبور
              </Label>
              <Input
              placeholder=''
               className='py-2'
               type='password'
               name='password'
               
               onChange={this.onChange}
               />
              {this.validator.message('password', this.state.password, 'required|min:6')}
              <FormFeedback>
              </FormFeedback>
              <FormText>حداقل 6 کاراکتر - فرمت انگلیسی
              </FormText>
             </FormGroup>
              </div>
              <div className='' id='auth-login'>
              <FormGroup>
               <Label for="refrence_id">
                کد معرف <span className='text-success' style={{'font-size' : '10px !important'}}>(اختیاری)</span>
               </Label>
               <Input
                className='ltr py-2'
                name='refrence_id'
                
                onChange={this.onChange} />
               {this.validator.message('refrence_id', this.state.refrence_id, 'integer')}
               <FormFeedback valid={false}>
               </FormFeedback>
               <FormText>در صورت داشتن کد معرف وارد کنید
               </FormText>
              </FormGroup>
              </div>


              <div className=''>
                <FormGroup className='text-center'>
                  <Button disabled ={this.state.button3} onClick={this.step3}  block type='submit' color='primary'  className='input-addon-home pointer mt-5 py-2'>ادامه
                  </Button>
                </FormGroup>
              </div>
              </>
              :<></> }
            {this.state.step === 1 ?
              <>
              <div className='h100 d-flex-xs' id='auth-login-input'>
              <FormGroup>
              <Label for="name">
                نام
              </Label>
              <Input
                className='py-2'
                invalid={this.state.ne}
                type='text'
                name='name'
                
                onChange={this.onChangep}
                value ={this.state.name}
               />
              {this.validator.message('name', this.state.name, 'required')}
              <FormText color={this.state.ne === true ? 'warning' : 'muted' } >{this.state.ne === true ? this.state.nem : 'نام مطابق مدارک هویتی'}
              </FormText>
             </FormGroup>
              <FormGroup className='family-input'>
              <Label for="family">
                نام خانوادگی
              </Label>
              <Input
                invalid={this.state.fe}
                placeholder=''
                className='py-2'
                type='text'
                name='family'
                
                onChange={this.onChangef}
               />
              {this.validator.message('family', this.state.family, 'required')}
              <FormFeedback>
              </FormFeedback>
              <FormText className='text-small' color={this.state.fe === true ? 'warning' : 'muted' } >{this.state.fe === true ? this.state.fem : 'نام خانوادگی مطابق مدارک هویتی'}
              </FormText>
             </FormGroup>
              </div>
              <div className='h100' id='auth-logininput'>
              <FormGroup>
              <Label for="nationalid">
                کد ملی
              </Label>
              <NumberFormat
                  
                  name='nationalid'
                  inputMode="decimal"
                  value={this.state.nationalid}
                  onValueChange = {(values, sourceInfo)=>this.change(values,'nationalid')}
                  displayType="input"
                  className='font-weight-bold text-center form-control'
                />
              {this.validator.message('nationalid', this.state.nationalid, 'required|numeric|size:10')}
              <FormFeedback>
              </FormFeedback>
              <FormText>کد ملی شامل عدد 10 رقمی
              </FormText>
             </FormGroup>
              </div>
              <div className='h100' id='auth-logininput'>
              <FormGroup>
               <Label for="mobile">
                شماره تلفن همراه
               </Label>
               <NumberFormat
                   
                   name='mobile'
                   prefix='0'
                   inputMode="numeric"
                   value={this.state.mobile}
                   onValueChange={(values, sourceInfo) =>this.change(values ,'mobile')}
                   displayType="input"
                   className='font-weight-bold text-center form-control'
                 />
               {this.validator.message('mobile', this.state.mobile, 'required|numeric|size:10')}
               <FormFeedback valid={false}>
               </FormFeedback>
               <FormText>شماره موبایل باید مطابق با کد ملی باشد
               </FormText>
              </FormGroup>
              </div>

              <div className='h100'>
                <FormGroup className='text-center'>
                  <Button disabled={this.state.button2} onClick={this.stepone}  block type='submit' color='primary'  className='input-addon-home pointer mt-5 py-2'>ادامه
                  </Button>
                </FormGroup>
                <FormGroup className='mt-5'>
                <GoogleLogin
                  clientId='932613481557-j781dm8g1nd6rggjvk8s9dbijg14o40n.apps.googleusercontent.com'
                   onSuccess={response=>this.success(response)}
                   onError={response=>this.error(response)}
                   useOneTap
                   shape = ''
                   ux_mode = 'popup'
                   text = 'signin_with'
                   theme = 'filled_blue'
                 />
                 </FormGroup>
                <Row xl='2' lg='2' md='2' sm='2' xs='2' className='rtl pb-10'>
                  <Col>
                    <div className=' w-100 ml-auto  py-2'><p className='pointer text-dark' onClick={()=>this.props.history.push('login')}> حساب کاربری دارید ؟ وارد شوید</p>
                    </div>
                  </Col>
                  <Col>
                    <div className=' w-100 ltr py-2'><p className='pointer mr-auto text-dark' onClick={()=>this.props.history.push('forgotpassword')}>بازیابی رمز عبور</p>
                    </div>
                  </Col>
                </Row>

              </div>
              </>
              :<></> }
            {this.state.step === 2 ?
              <>

              <div className='h100 ' id='auth-login'>


              </div>
              <div className='h100' id='auth-login'>
              <FormGroup className='otp-input '>
              <Label for="nationalid" className='mb-5'>
                کد اعتبارسنجی تلفن همراه
              </Label>
              <div className='d-flex justify-content-space-between w-100 mt-2'>
              <NumberFormat
                  
                  name='otp'
                  format="#    #    #    #"
                  inputMode="decimal"
                  value={this.state.otp}
                  onValueChange={(values,sourceInfo) =>this.handleChange(values , sourceInfo)}
                  displayType="input"
                  className='font-weight-bold ltr text-center form-control w-100'
                />
              {this.validator.message('otp', this.state.otp, 'required|integer|size:4')}
              </div>
              <FormFeedback>
              </FormFeedback>
              <FormText className='mt-3'>کد  4 رقمی ارسال شده را وارد کنید
              </FormText>
             </FormGroup>
              </div>
              <div className='h100' id='auth-login'>

              </div>


              </>
              :<></> }
            {this.state.step === 4 ?
              <>

              <div className='' id='auth-login'>


              </div>
              <div className='' id='auth-login'>
              <FormGroup className='otp-input '>
              <Label for="otpemail" className=' mb-5'>
                کد اعتبارسنجی ایمیل
                <small className='mx-2'>(کد  4 رقمی ارسال شده را وارد کنید)</small>
              </Label>
              <div className='d-flex justify-content-space-between w-100 mt-2'>
              <NumberFormat
                  
                  name='otpemail'
                  placeholder=''
                  format="#    #    #    #"
                  inputMode="decimal"
                  value={this.state.otpemail}
                  onValueChange={(values) =>this.handleChangeemail(values)}
                   displayType="input"
                   className='font-weight-bold ltr text-center form-control w-100'
                />
              {this.validator.message('otpemail', this.state.otpemail, 'required')}
              </div>
              <FormFeedback>
              </FormFeedback>
              <FormText>توجه : در صورت دریافت نکردن ایمیل پوشه اسپم را چک کنید
              </FormText>

             </FormGroup>
              </div>

              </>
              :<></> }

            </GoogleOAuthProvider>
     </div>
    )
  }
}
export default withTranslation()(Homelayer)
