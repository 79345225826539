import React from 'react';
import {withTranslation} from 'react-i18next'
import {Row,Col,Form,FormText,FormGroup,Button,Input,Table,Modal,
ModalHeader,
ModalBody,
ModalFooter} from 'reactstrap'
import cookie from 'react-cookies'
import axios from 'axios'
import SimpleLineIcon from 'react-simple-line-icons'
import {Socket} from './sections/socket'
import Chart from './chart'
import NumberFormat from 'react-number-format'
import NumericFormat from 'react-number-format'
import {Link} from 'react-router-dom'
import xsicon from '../../style/images/xs-icon.png'
import { AiTwotoneDownCircle } from "react-icons/ai";
import ReactLoading from 'react-loading';
import {Helmet} from 'react-helmet'
import { VscDiffAdded } from "react-icons/vsc";
import { BsClockHistory,BsStar,BsWallet,BsListCheck,BsQrCode} from "react-icons/bs";
import { IoIosArrowRoundDown } from "react-icons/io";
import Switch from "react-switch";
import {AiFillStar,AiOutlineStar} from "react-icons/ai";
import { TfiClose } from "react-icons/tfi";
import { IoCloseOutline } from "react-icons/io5";
import Toast from 'awesome-toast-component'
import { Tooltip } from 'reactstrap';
import Loader from '../landing/loader'
import Miniloader from '../landing/miniloader'
import { PiMedalThin } from "react-icons/pi";
import { TiDownloadOutline } from "react-icons/ti";
import F from 'float'
import Content from '../panel/sidebar'
import {
  atan2, chain, derivative, e, evaluate, log, pi, pow, round, sqrt
} from 'mathjs'
  class Trade extends React.Component{
  constructor(props){
    super(props);
    const {t} = this.props
    this.state={
      layer : 1,
      history :[],
      currentmarket :[],
      hasdata : false,
      loggedin : false,
      marketcollapse : false,
      accounts : [],
      favorites : [],
      openorders : [],
      closeorders : [],
      limitorders : [],
      markets : [],
      loader : true,
      hasdata : false,
      cr : 'orders',
      email : 'U',
      balancetext : `${t('balance :')}` ,
      slnote : `${t('sl-note')}` ,
      tpnote : `${t('tp-note')}` ,
      pricelabel : `${t('price')}` ,
      pricelabelsl : `${t('price-sl')}` ,
      pricelabeltp : `${t('price-tp')}` ,
      amountlabel : `${t('order-amount')}` ,
      textsell1 : `${t('sell')}` ,
      btnsell1 : false,
      textsell2 : `${t('sell')}` ,
      btnsell2 : false,
      textbuy1 : `${t('buy')}` ,
      btnbuy1 : false,
      textbuy2 : `${t('buy')}` ,
      btnbuy2 : false,
      in : '',
      searchTerm : '',
      orderform : false,
      formtype : false,
      msection : 'market',
      toggletp1 : false,
      toggletp2 : false,
      toggletp3 : false,
      toggletp4 : false,
      section : 'market',
      msection : 'market',
      dsvi : false,
      dias : false,
      qs : '',
      modalmenuitem : 'all',
      fee : 0
    }
  }
  toggletp1 = ()=>{
    this.setState({toggletp1 : !this.state.toggletp1})
  }


  toggletp2 = ()=>{
    this.setState({toggletp2 : !this.state.toggletp2})
  }


  toggletp3 = ()=>{
    this.setState({toggletp3 : !this.state.toggletp3})
  }


  toggletp4 = ()=>{
    this.setState({toggletp4 : !this.state.toggletp4})
  }
  showmarkets = ()=>{
    this.setState({marketcollapse : !this.state.marketcollapse})
  }
  changesearch = (e)=>{
    let value = e.target.value.toUpperCase()
    this.setState({searchTerm : value})
  }

  Getstatus = (x) =>{
    console.log(x);
    return this.props.getstatus(x)
  }
componentWillMount(){
  this.market = this.props.match.params.symbol.toUpperCase()
  var fields = this.market.split('-');
  var base   = fields[0];
  var quote  = fields[1];
  this.base  = base
  this.quote = quote
  var width = window.innerWidth
  if(width < 767){
    this.setState({section : 'list'})
  }else{
    this.setState({section : 'market'})
  }
}
loader = ()=>{
  return (<div className='loader-home'>
    <h3 className=''><Loader /></h3>
  </div>)
}
miniloader = ()=>{
  return (<div className='loader-home-mini'>
    <h3 className=''><Miniloader /></h3>
  </div>)
}
togglemenu = ()=>{
  this.setState({xlm : !this.state.xlm})
}
componentWillUnmount(){
  this.props.togglemenufalse()
  clearInterval(this.um)
  clearInterval(this.dataint)
}
componentDidMount(){
  this.start()
  this.settheme()
  this.fetchhistorydata()
  this.um=setInterval(()=>this.update_orders(),10000)
}
openorderform =(x)=>{
  if(x == 1){
    this.setState({orderform : true , formtype : true })
  }else{
    this.setState({orderform : true , formtype : false })
  }
}
closeorder =(i)=>{
    const {t} = this.props
  axios.get('/api/trade/closeorder/'+i.orderid).then(response=>{
    if(response.data.status === 'success'){
      new Toast(`${t(response.data.message)}`, {
        style: {
            container: [
                ['background-color', 'red']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
          position : 'top'
    })
    return
    }else{
      new Toast(`${t(response.data.message)}`, {
        style: {
            container: [
                ['background-color', 'red']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
          position : 'top'
    })
    }
  })
this.update_orders()
}
update_orders = ()=>{
  if(!cookie.load('uid')){
    return
  }
  while(this.state.hasdata !== true){
    return
  }
  axios.get('/api/trade/update_orders').then(res=>{
    this.setState({
      orders : res.data.orders,
      openorders : res.data.orders.openorders,
      closeorders : res.data.orders.closeorders,
      limitorders : res.data.orders.limitorders,
    })
  })
}
fetchhistorydata = ()=>{
  let m = this.props.match.params.symbol.toUpperCase()
  this.dataint=setInterval(()=>
  axios.get('/api/trade/updatemarket/'+m).then(res=>{
    if(res.status == 503){
      clearInterval(this.ws)
      clearInterval(this.dataint)
      this.connection.close()
      this.props.history.push('/panel/dashboard')
    }
    this.setState({
      accounts : res.data.accounts,
      baseCurrencybalance : res.data.balance.baseCurrencybalance,
      bb : res.data.balance.baseCurrencybalance,
      quoteCurrencybalance : res.data.balance.quoteCurrencybalance,
      qb : res.data.balance.quoteCurrencybalance,
      history : res.data.history,
      loggedin : res.data.loggedin,
    })

  }), 10000)
}
totime = (timestamp)=>{
  var date    = new Date(timestamp/1000000),
  hours   = date.getHours(),
  minutes = date.getMinutes(),
  seconds = date.getSeconds();
  var output  = ("0" + hours).slice(-2) + ':' + ("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2);
  return(
    <small>{output}</small>
  )
}
settheme = () =>{
  const theme = document.body.getAttribute('class')
  if(theme === 'dark'){
    this.setState({darkMode : true  , theme : 'dark'})
  }else{
    this.setState({darkMode : false  , theme : 'light'})
  }
}

setfavorites = () =>{
  let fav = localStorage.getItem('favorite');
  if(fav){
    this.setState({favorites : JSON.parse(fav)})
  }else{
    localStorage.setItem('favorite',[])
  }
}
setloggedin = () =>{
  if(cookie.load('uid')){
    this.setState({loggedin : true})
    }
}
start = ()=>{
  if(this.quote   !== 'IRR'){
    this.market2 = this.market

  }else{
    if(this.market === 'USDT-IRR'){
      this.market2 = 'USDC-USDT'
    }else{
      this.market2 = this.base+'-'+'USDT'
    }
}
axios.get('/api/trade/getmarket/'+this.market).then(res=>{
  if(res.data.status === 'error'){
    if(res.data.message === 'خطا در ارتباط با سرور ترید - 40005'){
      clearInterval(this.ws)
      clearInterval(this.dataint)
      this.props.history.push('/panel/dashboard')
    }
  }
  this.id  = res.data.id
  this.token = res.data.token
  this.setState({
    markets : res.data.markets,
    accounts : res.data.accounts,
    baseCurrencybalance : res.data.balance.baseCurrencybalance,
    quoteCurrencybalance : res.data.balance.quoteCurrencybalance,
    openorders : res.data.orders.openorders,
    closeorders : res.data.orders.closeorders,
    limitorders : res.data.orders.limitorders,
    history : res.data.history,
    level : parseInt(res.data.userlevel),
    usdt : res.data.usdt,
    loggedin : res.data.loggedin,
    name : res.data.name,
    ip : res.data.ip,
    email : res.data.email,
  })
  const found = res.data.markets.find(element => element.symbolName === this.market);
  console.log(found);
  if(!found || found == 'undefined'){
    clearInterval(this.ws)
    clearInterval(this.dataint)
    // this.props.history.push('/panel/dashboard')
    // return
  }
  this.calcfee(res.data.userlevel , found.fee , found.zarib)
  if(this.quote === 'IRR'){
    this.pi = found.pi
    const loader = this.miniloader()
    this.setState({hasdata : true,loader : false ,bi : parseInt(found.bi),qi :parseInt(found.bi),pi : parseInt(found.pi),currentmarket : found})
    this.url = "wss://ws-api-spot.kucoin.com/?token="+this.token+"&[connectId="+this.id+"]"
    this.socketdata = <Socket decimal={this.pi} loading={loader} setp={(e)=>this.setp(e)} set={(e)=>this.set(e)} usdt = {res.data.usdt} url={this.url} token={this.token} id={this.id} market={this.market2} />

  }else{
    this.pi = found.pi
    const loader = this.miniloader()
      this.setState({hasdata : true,loader : false ,bi : parseInt(found.bi),qi :parseInt(found.qi) ,pi :parseInt(found.pi),currentmarket : found})
    this.url = "wss://ws-api-spot.kucoin.com/?token="+this.token+"&[connectId="+this.id+"]"
    this.socketdata = <Socket decimal={this.pi} decimalbi={this.bi} loading={loader} setp={this.setp} set = {this.set}  usdt = {res.data.usdt} url={this.url} token={this.token} id={this.id} market={this.market2} />

  }

})
}
calcfee = (a,b,c) =>{
  const {t} = this.props
  let d = parseFloat(b) * parseInt(c)
  let fee
  let level
  console.log(a);
  switch (parseInt(a)) {
    case 0:
      fee =  d
      level = `${t('level-0')}`
      break;
    case 1:
      fee =  F.floor(d - ((15/100) * d),5)
      level = `${t('level-1')}`
      break;
    case 2:
      fee =  F.floor(d - ((30/100) * d),5)
      level = `${t('level-2')}`
      break;
    case 3:
      fee =  F.floor(d - ((40/100) * d),5)
      level = `${t('level-3')}`
      break;
    case 4:
      fee =  0
      level = `${t('level-vip')}`
      break;

    default:
    fee = d
    level = `${t('level0')}`
  }
  console.log('Your Current fee is  : ' , fee);
  this.setState({fee : fee , userlevel : level})

}
set = (e)=>{
  let amount = e.target.getAttribute("data-amount")
amount = parseFloat(amount)
amount = Number(amount)
this.setState({amountsell : amount , amountbuy: amount  ,lamountbuy: amount , lamountsell: amount})
if(this.state.pricesell !== ''){
  this.setState({volumesell : F.floor((this.state.pricesell * amount) , this.state.qi) })
  this.setState({volumebuy : F.floor((this.state.pricebuy * amount) , this.state.qi) })
  this.setState({lvolumebuy : F.floor((this.state.lpricebuy * amount) , this.state.qi) })
  this.setState({lvolumesell : F.floor((this.state.lpricesell * amount) , this.state.qi) })
}
}


setp = (e)=>{
    let price = e.target.getAttribute("data-price")
    let priceusd = e.target.getAttribute("data-priceusd")
    price = parseFloat(price)
    priceusd = parseFloat(priceusd)
    console.log(price);
    this.setState({pricesell : price , pricebuy : price,lpricebuy : price , lpricesell : price})

}
leftmenuor = ()=>{
  this.setState({cr : 'orders'})
}
leftmenuhi = ()=>{
  this.setState({cr : 'leftmenuhi'})
}
leftmenuwa = ()=>{
  this.setState({cr : 'leftmenuwa'})
}
leftmenufa = ()=>{
  this.setState({cr : 'leftmenufa'})
}
/// submitsellmarket        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
submitsellmarket = (e)=>{
  e.preventDefault()
  if(!cookie.load('uid')){
    return
  }

  const {t}  = this.props
  let pricesell = this.state.pricesell
  let usdtmainprice = this.state.usdt === 1 ?
    pricesell
  : F.floor((pricesell / this.state.usdt) , this.state.pi)
  let amountsell = this.state.amountsell
  let volumesell = this.state.volumesell
  let side = 'sell'
  let fee = this.state.fee
  let min = this.state.currentmarket.baseMinSize
  let max = this.state.currentmarket.baseMaxSize
  let g = this.state.volumesell * fee
  let f = this.state.volumesell - g
  f = F.floor(f , this.state.qi)
  g = F.floor(g,this.state.qi)
  let balance = this.state.baseCurrencybalance
  let type = 'market'
  if(pricesell === ''){
    return
  }
  if(amountsell === ''){
    return
  }
  if(pricesell === undefined){
    return
  }
  if(amountsell === undefined){
    return
  }
  if(this.state.baseCurrencybalance < amountsell){
    new Toast(`${t('insuficent.balance')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
    return
  }
  if(amountsell < min){
    this.setState({msammin : false})
    new Toast(`${t('min.base.amount.error')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
    return
  }
  if(amountsell > max){
    this.setState({msamax : false})
    new Toast(`${t('max.base.amount.error')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
    return
  }
  const form = new FormData();
    form.append("price" , pricesell)
    form.append("orderprice" , usdtmainprice)
    form.append("amount" , amountsell)
    form.append("quoteamount" , f)
    form.append("order_volume" , volumesell)
    form.append("fee_amount" , g)
    form.append("fee" , fee )
    form.append("side" , side)
    form.append("usdt" , this.state.usdt)
    form.append("base" , this.base)
    form.append("quote" , this.quote)
    form.append("market" , this.market)
    form.append("mainmarket" , this.market2)
    form.append("min" , min)
    form.append("max" , max)
    form.append("type" , type)
    form.append("userlevel" , this.state.userlevel)
    this.setState({btnsell1 : true , textsell1 : `${t('in progress')}`})
      axios.post('/api/trade/sell/'+this.market , form).then(res=>{
      this.setState({btnsell : false , textsell1 : `${t('sell')}` , mainprice : '' , mainamount : '' , sellvalume : ''})
      if(res.status !== 200){
        new Toast(`${t('connection.error')}`, {
          style: {
              container: [
                  ['background-color', 'yellow']
              ],
              message: [
                  ['color', '#000'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
            })
      }else{
        if(res.data.status === 'error-fatal'){
          return
        }
        if(res.data.status === 'error'){
          new Toast(`${t('fail.sell.market')}`, {
            style: {
                container: [
                    ['background-color', 'red']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
            position : 'top'
              })
          return
        }
        if(res.data.status === 'success'){
          new Toast(`${t('success.sell.market')}`, {
            style: {
                container: [
                    ['background-color', 'green']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
            position : 'top'
              })
          return
        }
      }
    })
    this.update_orders()
}
/// submitbuymarket        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
submitbuymarket = (e)=>{
  e.preventDefault()
  if(!cookie.load('uid')){
    return
  }

  const {t} = this.props
  let price = this.state.pricebuy
  let mainprice = this.state.usdt === 1 ?
    this.state.pricebuy
  : F.floor((this.state.pricebuy / this.state.usdt) , this.state.pi)
  let amount = this.state.amountbuy
  let volume = this.state.volumebuy
  let level = this.state.userlevel
  let side = 'buy'
  let usdt = this.state.usdt
  let fee = this.state.fee
  let min = this.state.currentmarket.baseMinSize
  let max = this.state.currentmarket.baseMaxSize
  let type = 'market'
  let a = price * amount
  let b = a * fee
  let quoteamount = a + b
      quoteamount = F.floor(quoteamount , this.state.qi)
      b = F.floor(b,this.state.qi)
  if(price === ''){
    return
  }
  if(amount === ''){
    return
  }
  if(this.state.quoteCurrencybalance < quoteamount){
    new Toast(`${t('insuficent.balance.error')}`, {
      style: {
          container: [
              ['background-color', 'yellow']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
        return
  }
  if(amount < min){
    new Toast(`${t('min.base.amount.error')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
    return
  }
  if(amount > max){
    new Toast(`${t('max.base.amount.error')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
    return
  }

  const form = new FormData();
    form.append("price" , price)
    form.append("orderprice" , mainprice)
    form.append("amount" , amount)
    form.append("quoteamount" , volume)
    form.append("feeamount" , b)
    form.append("volume" , volume)
    form.append("userlevel" , level)
    form.append("fee" , fee)
    form.append("usdt" , usdt)
    form.append("side" , side)
    form.append("fee" , fee)
    form.append("base" , this.base)
    form.append("quote" , this.quote)
    form.append("market" , this.market)
    form.append("mainmarket" , this.market2)
    form.append("min" , min)
    form.append("max" , max)
    form.append("type" , type)
    this.setState({btnbuy1 : true , textbuy1 : `${t('in progress')}`})
    axios.post('/api/trade/buy/'+this.market , form).then(res=>{


      this.setState({btnbuy1 : false , textbuy1 : `${t('buy')}` , mainprice : '' , mainamount : '' , buyvalume : ''})
      if(res.status !== 200){
        new Toast(`${t('connection.error')}`, {
          style: {
              container: [
                  ['background-color', 'yellow']
              ],
              message: [
                  ['color', '#000'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
            })
            return
      }else{
        if(res.data.status === 'error-fatal'){
          return
        }
        if(res.data.status === 'error'){
          new Toast(`${t('fail.buy.market')}`, {
            style: {
                container: [
                    ['background-color', 'red']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
            position : 'top'
              })
          return
        }
        if(res.data.status === 'success'){
          new Toast(`${t('success.buy.market')}`, {
            style: {
                container: [
                    ['background-color', 'green']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
            position : 'top'
              })
          return
        }

      }
    })
    this.update_orders()
}
/// submitselllimit        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
submitselllimit = (e)=>{
  e.preventDefault()
  if(!cookie.load('uid')){
    return
  }

  const {t}  = this.props
  let pricesell = this.state.lpricesell
  let usdtmainprice = this.state.usdt === 1 ?
    pricesell
  : F.floor((pricesell / this.state.usdt) , this.state.pi)
  let amountsell = this.state.lamountsell
  let volumesell = this.state.lvolumesell
  let side = 'sell'
  let stop = this.state.lpricelimitsell
  let fee = this.state.fee
  let min = this.state.currentmarket.baseMinSize
  let max = this.state.currentmarket.baseMaxSize
  let g = this.state.lvolumesell * fee
  let f = this.state.lvolumesell - g
  f = F.floor(f , this.state.qi)
  g = F.floor(g,this.state.qi)
  let balance = this.state.baseCurrencybalance
  let type = 'market'
  if(pricesell === ''){
    return
  }
  if(amountsell === ''){
    return
  }
  if(pricesell === undefined){
    return
  }
  if(amountsell === undefined){
    return
  }
  if(this.state.baseCurrencybalance < amountsell){
    new Toast(`${t('insuficent.balance')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
    return
  }
  if(amountsell < min){
    this.setState({msammin : false})
    new Toast(`${t('min.base.amount.error')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
    return
  }
  if(amountsell > max){
    this.setState({msamax : false})
    new Toast(`${t('max.base.amount.error')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
    return
  }
  const form = new FormData();
    form.append("price" , pricesell)
    form.append("orderprice" , usdtmainprice)
    form.append("amount" , amountsell)
    form.append("quoteamount" , f)
    form.append("order_volume" , volumesell)
    form.append("fee_amount" , g)
    form.append("fee" , fee )
    form.append("stop" , stop )
    form.append("side" , side)
    form.append("usdt" , this.state.usdt)
    form.append("base" , this.base)
    form.append("quote" , this.quote)
    form.append("market" , this.market)
    form.append("mainmarket" , this.market2)
    form.append("min" , min)
    form.append("max" , max)
    form.append("type" , type)
    form.append("userlevel" , this.state.userlevel)
    this.setState({btnsell1 : true , textsell1 : `${t('in progress')}`})
      axios.post('/api/trade/limit_sell/'+this.market , form).then(res=>{
      this.setState({btnsell : false , textsell1 : `${t('sell')}` , mainprice : '' , mainamount : '' , sellvalume : ''})
      if(res.status !== 200){
        new Toast(`${t('connection.error')}`, {
          style: {
              container: [
                  ['background-color', 'yellow']
              ],
              message: [
                  ['color', '#000'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
            })
      }else{
        if(res.data.status === 'error-fatal'){
          return
        }
        if(res.data.status === 'error'){
          new Toast(`${t('fail.sell.market')}`, {
            style: {
                container: [
                    ['background-color', 'red']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
            position : 'top'
              })
          return
        }
        if(res.data.status === 'success'){
          new Toast(`${t('success.sell.market')}`, {
            style: {
                container: [
                    ['background-color', 'green']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
            position : 'top'
              })
          return
        }
      }
    })
    this.update_orders()
}
/// submitbuylimit        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
submitbuylimit = (e)=>{
  e.preventDefault()
  if(!cookie.load('uid')){
    return
  }

  const {t} = this.props
  let price = this.state.lpricebuy
  let mainprice = this.state.usdt === 1 ?
    price
  : F.floor((price / this.state.usdt) , this.state.pi)
  let amount = this.state.lamountbuy
  let volume = this.state.lvolumebuy
  let level = this.state.userlevel
  let stop = this.state.lpricelimitbuy
  let side = 'buy'
  let usdt = this.state.usdt
  let fee = this.state.fee
  let min = this.state.currentmarket.baseMinSize
  let max = this.state.currentmarket.baseMaxSize
  let type = 'limit'
  let a = price * amount
  let b = a * fee
  let quoteamount = a + b
      quoteamount = F.floor(quoteamount , this.state.qi)
      b = F.floor(b,this.state.qi)
  if(price === ''){
    return
  }
  if(amount === ''){
    return
  }
  if(this.state.quoteCurrencybalance < quoteamount){
    new Toast(`${t('insuficent.balance.error')}`, {
      style: {
          container: [
              ['background-color', 'yellow']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
        return
  }
  if(amount < min){
    new Toast(`${t('min.base.amount.error')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
    return
  }
  if(amount > max){
    new Toast(`${t('max.base.amount.error')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#000'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
        })
    return
  }

  const form = new FormData();
    form.append("price" , price)
    form.append("orderprice" , mainprice)
    form.append("amount" , amount)
    form.append("quoteamount" , volume)
    form.append("qa" , quoteamount)
    form.append("feeamount" , b)
    form.append("stop" , stop)
    form.append("volume" , volume)
    form.append("userlevel" , level)
    form.append("fee" , fee)
    form.append("usdt" , usdt)
    form.append("side" , side)
    form.append("fee" , fee)
    form.append("base" , this.base)
    form.append("quote" , this.quote)
    form.append("market" , this.market)
    form.append("mainmarket" , this.market2)
    form.append("min" , min)
    form.append("max" , max)
    form.append("type" , type)
    this.setState({btnbuy1 : true , textbuy1 : `${t('in progress')}`})
    axios.post('/api/trade/limit_buy/'+this.market , form).then(res=>{


      this.setState({btnbuy1 : false , textbuy1 : `${t('buy')}` , mainprice : '' , mainamount : '' , buyvalume : ''})
      if(res.status !== 200){
        new Toast(`${t('connection.error')}`, {
          style: {
              container: [
                  ['background-color', 'yellow']
              ],
              message: [
                  ['color', '#000'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
            })
            return
      }else{
        if(res.data.status === 'error-fatal'){
          return
        }
        if(res.data.status === 'error'){
          new Toast(`${t('fail.buy.market')}`, {
            style: {
                container: [
                    ['background-color', 'red']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
            position : 'top'
              })
          return
        }
        if(res.data.status === 'success'){
          new Toast(`${t('success.buy.market')}`, {
            style: {
                container: [
                    ['background-color', 'green']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
            position : 'top'
              })
          return
        }

      }
    })
    this.update_orders()
}
//  SELL MARKET LIMIT  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
Setlpricesell =(v)=>{
  console.log(v);
  let price = v.value
  this.setState({lpricesell : price , lamountsell : '' , lvolumesell : '' , usdtpricesell : price / this.state.usdt})
  return
}
Setlpricelimit =(v)=>{
  let price = v.value
  this.setState({lpricelimitsell : price})
}
Setlvolumesell =(v)=>{
  if(!this.state.hasdata){
    return
  }
  let volumesell = v.target.value
  let price = this.state.lpricesell
  let amount
  if(price !== undefined){
     amount = volumesell / price
     amount = round(amount ,this.state.bi)
     console.log(amount);
  }
  this.setState({ lvolumesell : volumesell , lamountsell : amount})
  return
}
Setlamountsell =(v)=>{
  let lamount = v.target.value
  let lprice = this.state.lpricesell
  let lvolumesell
  if(lamount >= this.state.currentmarket.baseMinSize){
    if(lprice !== undefined){
      lvolumesell = lamount * lprice
      lvolumesell = round(lvolumesell ,this.state.qi)
      lamount = round(lamount ,this.state.bi)
    }
    console.log(lprice,lamount,lvolumesell);
      this.setState({lamountsell : lamount , lvolumesell : lvolumesell, errormsal : false})
  }else{
    this.setState({lamountsell : lamount , lvolumesell : '' , errormsal : true })
  }
}
//  SELL MARKET BUY  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
Setlpricebuy =(v)=>{
  let price = v.value
  this.setState({lpricebuy : price , lamountbuy : '' , lvolumebuy : '' , usdtpricebuy : price / this.state.usdt})
}
Setlpricelimitbuy =(v)=>{
  let price = v.value
  this.setState({lpricelimitbuy : price})
}
Setlvolumebuy =(v)=>{
  if(!this.state.hasdata){
    return
  }
  let volumebuy = v.target.value
  let price = this.state.lpricebuy
  let amount
  if(price !== undefined){
     amount = volumebuy / price
     amount = round(amount ,this.state.bi)
     console.log(amount);
  }
  this.setState({ lvolumebuy : volumebuy , lamountbuy : amount})
  return
}
Setlamountbuy =(v)=>{
  let amount = v.target.value
  let price = this.state.lpricebuy
  let volumebuy
  if(amount >= this.state.currentmarket.baseMinSize){
    if(price !== undefined){
      volumebuy = amount * price
      volumebuy = round(volumebuy ,this.state.qi)
      amount = round(amount ,this.state.bi)
    }
    console.log(amount);
      this.setState({lamountbuy : amount , lvolumebuy : volumebuy, errormsab : false})
  }else{
    this.setState({lamountbuy : amount , lvolumebuy : '' , errormsab : true })
  }
}
fixpi = (price)=>{
  let n = parseInt(this.state.bi)
  price = Math.floor(price * 100) / 100
  switch (n) {
    case 1:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,1})?/))
      break;
    case 2:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,2})?/))
      break;
    case 3:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,3})?/))
      break;
    case 4:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,4})?/))
      break;
    case 5:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,5})?/))
      break;
    case 6:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,6})?/))
      break;
    case 7:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,7})?/))
      break;
    case 8:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,8})?/))
      break;
    case 9:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,9})?/))
      break;
    default:
        price = Number(price.toString().match(/^\d+(?:\.\d{0,0})?/))
  }


  return price
}
fixbi = (price)=>{
  let n = parseInt(this.state.bi)
  console.log(n,price);
  price = Math.floor(price * 100) / 100
  switch (n) {
    case 1:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,1})?/))
      return price
      break;
    case 2:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,2})?/))
      return price
      break;
    case 3:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,3})?/))
      return price
      break;
    case 4:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,4})?/))
      return price
      break;
    case 5:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,5})?/))
      return price
      break;
    case 6:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,6})?/))
      return price
      break;
    case 7:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,7})?/))
      return price
      break;
    case 8:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,8})?/))
      return price
      break;
    case 9:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,9})?/))
      return price
      break;
    default:
      return price
  }



}
fixqi = (price)=>{
  let n = parseInt(this.state.qi)
  price = Math.floor(price * 100) / 100
  switch (n) {
    case 1:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,1})?/))
      break;
    case 2:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,2})?/))
      break;
    case 3:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,3})?/))
      break;
    case 4:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,4})?/))
      break;
    case 5:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,5})?/))
      break;
    case 6:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,6})?/))
      break;
    case 7:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,7})?/))
      break;
    case 8:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,8})?/))
      break;
    case 9:
      price = Number(price.toString().match(/^\d+(?:\.\d{0,9})?/))
      break;
    default:
        price = Number(price.toString().match(/^\d+(?:\.\d{0,0})?/))
  }


  return price
}

//  SELL MARKET   //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
kvs =(e)=>{
  this.setState({dsvi : true})
  setTimeout(()=>this.setState({dsvi : false}) , 1000)
}
kias =(e)=>{
  console.log(e);
  this.setState({dias : true})
  setTimeout(()=>this.setState({dias : false}) , 1000)
}
setked =()=>{
this.setState({dsvi : false})
}
setpricesell =(v)=>{
  let price = v.floatValue
  let usdtp = price/this.state.usdt
  this.setState({pricesell : price , amountsell : '' , volumesell : '' , usdtpricesell : usdtp})
}
setusdtpricesell =(v)=>{
  let price = v.value
  this.setState({usdtpricesell : v.value})
}
setamountsell =(v)=>{
  while(this.state.dsvi === true){
    return
  }
  let amount = v.target.value
  if(amount !== false){
    let p = this.state.pricesell
    let volume = p * amount
    volume = volume.toFixed(this.state.qi)
    volume = Number(volume)
    console.log(amount , this.state.bi);
    amount = F.floor(amount ,this.state.bi)
    amount = parseFloat(amount)
    this.setState({amountsell : amount , volumesell : volume})

  }
}
setvolumesell =(v)=>{
  while(this.state.dias === true){
    return
  }
  let volumesell = v.target.value
  let price = this.state.pricesell
  if(!price){
    return
  }
  if(price === undefined){
    return
  }
  let amount
  amount = volumesell / price
  amount  = F.floor(amount ,this.state.bi)
  amount = Number(amount)
  this.setState({amountsell : amount,volumesell : volumesell})
  return
}


//  Buy MARKET   //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

setpricebuy =(v)=>{
  let price = v.floatValue
  this.setState({pricebuy : price , amountbuy : '' , volumebuy : '' ,usdtpricebuy : price / this.state.usdt})
}
setusdtpricebuy =(v)=>{
  let price = v.value
  console.log(price);
  this.setState({usdtpricebuy : v.value})
}
setamountbuy =(v)=>{
  let amount = v.target.value
  let price = this.state.pricebuy
  let volumebuy
  if(amount >= this.state.currentmarket.baseMinSize){
    if(price !== undefined){
      volumebuy = amount * price
      volumebuy = round(volumebuy ,this.state.qi)
      amount = F.floor(amount ,this.state.bi)
    }
      this.setState({amountbuy : amount , volumebuy : volumebuy, errormba : false})
  }else{
    this.setState({amountbuy : amount , volumebuy : '' , errormba : true })
  }
}
setvolumebuy =(v)=>{
  if(!this.state.hasdata){
    return
  }
  let volumebuy = v.target.value
  let price = this.state.pricebuy
  let amount
  console.log(parseInt(this.state.bi));
  if(price !== undefined){
     amount = volumebuy / price
     amount = round(amount ,this.state.bi)
     console.log(amount);
  }
  this.setState({ volumebuy : volumebuy , amountbuy : amount})
  return
}

/// PERCENT SET //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
setamountsellpercent = (e)=>{
  if(this.state.loggedin === false){
    return
  }
  if(!this.state.pricesell){
    return
  }
  if(this.state.pricesell == undefined){
    return
  }
  if(this.state.pricesell == 0){
    return
  }
  var basebalance = this.state.baseCurrencybalance
  if(basebalance == 0){
    return
  }
  let price = this.state.pricesell
  var y = basebalance * e/100
  y = round(y ,this.state.bi)
  let b = y * price
  b = round(b ,this.state.qi)
  this.setState({amountsell : y , volumesell :b})

}
setamountbuypercent = (e)=>{
  if(this.state.loggedin === false){
    return
  }
  if(!this.state.pricebuy){
    return
  }
  if(this.state.pricebuy == undefined){
    return
  }
  // if(this.quote !== 'IRR'){
  //   if(this.state.quoteCurrencybalance  < this.state.currentmarket.quoteMinSize ){
  //     return
  //   }
  // }else{
  //   if(this.state.quoteCurrencybalance  < this.state.currentmarket.quoteMinSize * this.state.usdt){
  //     return
  //   }
  // }
  var quotebalance = parseFloat(this.state.quoteCurrencybalance) * e/100
  let price = this.state.pricebuy
  let amount = quotebalance/price
  amount = round(amount ,this.state.bi)
  quotebalance = round(quotebalance ,this.state.qi)

  this.setState({volumebuy : quotebalance, amountbuy : amount  })

}
limitsetamountsellpercent = (e)=>{
  if(this.state.loggedin === false){
    return
  }
  if(!this.state.lpricesell){
    return
  }
  if(this.state.lpricesell == undefined){
    return
  }
  if(this.state.lpricesell == 0){
    return
  }
  var basebalance = this.state.baseCurrencybalance
  if(basebalance == 0){
    return
  }
  let price = this.state.lpricesell
  var y = basebalance * e/100
  y = round(y ,this.state.bi)
  let b = y * price
  b = round(b ,this.state.qi)
  this.setState({lamountsell : y , lvolumesell :b})
}
limitsetamountbuypercent = (e)=>{
  if(this.state.loggedin === false){
    return
  }
  if(!this.state.lpricebuy){
    return
  }
  if(this.state.lpricebuy == undefined){
    return
  }

  var quotebalance = parseFloat(this.state.quoteCurrencybalance) * e/100
  let price = this.state.lpricebuy
  let amount = quotebalance/price
  amount = round(amount ,this.state.bi)
  quotebalance = round(quotebalance ,this.state.qi)

  this.setState({lvolumebuy : quotebalance, lamountbuy : amount  })
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  render(){
    console.log(this.market);
    const {t} = this.props
    const marketsellform =
    <Form className='m-1'>
      <div>
         <NumberFormat customInput={Input}  inputmode="decimal" thousandSeparator=',' decimalScale={this.state.pi} placeholder={this.state.pricelabel}   onValueChange={(values)=>this.setpricesell(values)} displayType='input'  className='w-100 market-input' value={this.state.pricesell} />
         <NumberFormat type='hidden' decimalScale={this.state.bi}  thousandSeparator=',' onValueChange={(values)=>this.setusdtpricesell(values)} value={this.state.usdtpricesell} />
        <span className='market-input-symbol'>{this.quote}</span>
      </div>
        <div>
        <input min={parseFloat(this.state.currentmarket.baseMinSize)} type='number' decimalScale={this.state.bi} inputmode="decimal" onKeyUp={this.kias}  invalid={this.state.errormsa}   placeholder={this.state.amountlabel}  allowNegative={false} onChange={this.setamountsell}  className='w-100 market-input'  value={this.state.amountsell} />

        <span className='market-input-symbol'>{this.base}</span>
      </div>
      <div className='d-flex justify-content-space-between'>
      <FormText onClick={()=>this.setamountsellpercent(25)} data-percent='25' className='b-small'>25</FormText>
      <FormText onClick={()=>this.setamountsellpercent(50)} data-percent='50' className='b-small  mx-2'>50</FormText>
      <FormText onClick={()=>this.setamountsellpercent(75)} data-percent='75' className='b-small  mx-2'>75</FormText>
      <FormText onClick={()=>this.setamountsellpercent(100)} data-percent='100' className='b-small  float-left'>100</FormText>
      </div>
      <div className='mt-1'>
      <input  type='number' inputmode="decimal" onKeyUp={this.kvs}  allowNegative={false} placeholder={this.state.amountlabel}  onChange={this.setvolumesell}   className='w-100 market-input' thousandSeparator=',' value={this.state.volumesell} />
      <span className='market-input-symbol'>{this.quote}</span>
      </div>
      <div className='d-flex justify-content-space-around'>
        <VscDiffAdded onClick={this.forwardbuybase} className='ml-2 my-auto pointer ' size='' />
        <FormText className='my-auto' >موجودی : </FormText>
        <FormText className='mr-auto'>{this.base}</FormText>

        <FormText className='mr-2'><NumberFormat customInput={Input} className='small'  allowNegative={false} decimalScale={this.state.qi}  displayType='text' thousandSeparator=',' value={this.state.baseCurrencybalance} /></FormText>
        </div>
        <div className='d-flex'>
        <FormText className='ml-2'>{this.state.currentmarket.baseMinSize}</FormText>
        <FormText className='ltr ml-auto'>Min : </FormText>
        <FormText className='mx-2'>{this.state.currentmarket.baseMaxSize}</FormText>
        <FormText className='ltr mr-2'>Max : </FormText>
        </div>
        <div className='d-none justify-content-space-between'>
        <FormText className='mr-2'>{this.state.currentmarket.fee * this.state.currentmarket.zarib}</FormText>
        <FormText className='ltr mr-2'>Fee :</FormText>
        </div>
    {this.quote === 'IRR' ?
      <div className=' mt-1 d-flex justify-content-space-between'>
        <FormText className='my-auto' >سفارش شما  با قیمت جهانی اجرا میشود .</FormText>
        <FormText className='my-auto' ><NumberFormat  decimalScale={this.state.pi} displayType='text' thousandSeparator=',' value={this.state.pricesell / this.state.usdt} /></FormText>
      </div> : <></> }
    <FormGroup className='rtl mt-3'>
    {this.state.loggedin === false ?
      <Link to='/auth/login' className='text-center btn  btn-light btn-block' >
        <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
      </Link> :
       <Button className='sell-btn'  disabled={this.state.btnsell}  onClick={this.submitsellmarket} block color="danger" >{this.state.textsell1}   {this.base}</Button> }

    </FormGroup>
    </Form>
    const marketbuyform =
    <Form className='m-1'>
      <div className=''>
      <NumberFormat customInput={Input} allowNegative={false} placeholder={this.state.pricelabel} onValueChange={(values)=>this.setpricebuy(values)} decimalScale={this.state.pi}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.pricebuy} />
      <NumberFormat type='hidden' decimalScale={this.state.bi}  thousandSeparator=',' onValueChange={(values)=>this.setusdtpricebuy(values)} value={this.state.usdtpricebuy} />

      <span className='market-input-symbol'>{this.quote}</span>
      </div>
      <div className=''>
      <Input type='number' inputmode="decimal" id='buy-amount'  allowNegative={false} placeholder={this.state.amountlabel} onChange={this.setamountbuy}  className='w-100 market-input' displayType='input'  value={this.state.amountbuy} />
      <span className='market-input-symbol'>{this.base}</span>
      </div>
      <div className='d-flex justify-content-space-between'>
      <FormText onClick={()=>this.setamountbuypercent(25)} data-percent='25' className='b-small'>25</FormText>
      <FormText onClick={()=>this.setamountbuypercent(50)} data-percent='50' className='b-small  mx-2'>50</FormText>
      <FormText onClick={()=>this.setamountbuypercent(75)} data-percent='75' className='b-small  mx-2'>75</FormText>
      <FormText onClick={()=>this.setamountbuypercent(100)} data-percent='100' className='b-small  float-left'>100</FormText>
      </div>
      <div className='mt-1'>
      <Input type='number' inputmode="decimal" allowNegative={false} placeholder={this.state.amountlabel} onChange={this.setvolumebuy}  className='w-100 market-input' displayType='input'  value={this.state.volumebuy} />
      <span className='market-input-symbol'>{this.quote}</span>
      </div>
      <div className='d-flex justify-content-space-around'>
        <VscDiffAdded onClick={this.forwardbuyquote} className='ml-2 my-auto pointer ' size='' />
        <FormText className='my-auto' >موجودی : </FormText>
        <FormText className='mr-auto'>{this.quote}</FormText>

        <FormText className='mr-2'><NumberFormat className='small'  allowNegative={false} decimalScale={this.state.qi}  displayType='text' thousandSeparator=',' value={this.state.quoteCurrencybalance} /></FormText>
        </div>
        <div className='d-flex'>
        <FormText className='ml-2'>{this.state.currentmarket.quoteMinSize}</FormText>
        <FormText className='ltr ml-auto'>Min : </FormText>
        <FormText className='mx-2'>{this.state.currentmarket.quoteMaxSize}</FormText>
        <FormText className='ltr mr-2'>Max : </FormText>
        </div>
        <div className='d-none justify-content-space-between'>
        <FormText className='mr-2'>{this.state.currentmarket.fee * this.state.currentmarket.zarib}</FormText>
        <FormText className='ltr mr-2'>Fee :</FormText>
        </div>
    {this.quote === 'IRR' ?
      <div className=' mt-1 d-flex justify-content-space-between'>
        <FormText className='my-auto' >سفارش شما  با قیمت جهانی اجرا میشود .</FormText>
        <FormText className='my-auto' ><NumberFormat decimalScale={this.state.pi} displayType='text' thousandSeparator=','  value={this.state.pricebuy / this.state.usdt} /></FormText>


      </div> : <></> }
      <FormGroup className='rtl mt-3'>
      {this.state.loggedin === false ?
        <Link to='/auth/signup' className='text-center btn  btn-light btn-block' >
      <span onClick={this.activeloginmodal} className='text-muted my-auto'>{t('signup')}</span>
      </Link> :
      <Button className='buy-btn' disabled={this.state.btnbuy1}  onClick={this.submitbuymarket} block color="success">{this.state.textbuy1}  {this.base}</Button> }


      </FormGroup>
    </Form>

    // document.getElementById("asks").addEventListener("click", this.someFunction());
    const userui =
    <div className='my-auto nav-link login-button'>
      <div className=''  inNavbar>
        <h5 className='market-avatar' >{this.state.email[0].toUpperCase()}</h5>
      </div>
      </div>

    const depositbtn =
    <>
    <Link to='/panel/depositirt' className='deposit-btn mx-2 my-auto'>
    <span className='mr-2 d-block '>{t('deposit-btn')}</span>
    <IoIosArrowRoundDown size='1.4rem' className='d-block arrow-down' />
    </Link>
    </>
    const close = <IoCloseOutline className='pointer ml-3' size='1.4rem' onClick={this.showmarkets} />
    const loader = this.loader()
    const market = this.state.markets.filter(item => item.symbolName.includes(this.state.searchTerm));
    const markets = market.filter(item => item.quoteCurrency.includes(this.state.qs));
  return(
    <>
    <Helmet>
       <title>{this.market} | Rapitex</title>
    </Helmet>
    {this.state.loader === true ? <>{loader} </>:
      <div className='market-layer'>
      <div className='mc-nav'>
        <div onClick={this.showmarkets}   className='ltr pointer' >
          <div className={this.base + '-icon icon-medium ltr'}></div>
          <div className={this.quote + '-icon quote-icon'}></div>
          <h6 className='slug-header'>{this.base}/{this.quote}
          <AiTwotoneDownCircle  className='my-auto pointer' size='0.8rem' />
          </h6>
        </div>


        <div  className='mc-nav-item rtl'>
        <div className='mt-auto small d-grid'>
          <div className='text-center'>
              <small className='text-center my-auto '> {t('current-price')}</small>
          </div>
          <div className='text-center'>
            {this.state.hasdata !== true ?
              <small className='my-auto'> <ReactLoading className='my-auto ml-4' type='balls' color='orange' height={20} width={20} /></small>:
              document.getElementById('current-price') ?
              <small className='w-10 my-auto mr-1'><NumberFormat  allowNegative={false}  decimalScale={this.state.currentmarket.pi}  className='' displayType='text' thousandSeparator=',' value={document.getElementById('current-price').innerHTML   } /></small> :
              <ReactLoading className='my-auto mx-auto' type='balls' color='orange' height={20} width={20} />
            }
          </div>
        </div>

        </div>
        <div  className='mc-nav-item '>
        <div className='mt-auto d-grid'>
          <div className='my-auto text-center'>
            <small>{t('24h')}</small>
          </div>
          <div className='text-center'>
          {this.state.hasdata !== true ?  <p className='my-auto'> <ReactLoading className='my-auto mx-auto' type='balls' color='orange' height={20} width={20} /></p>:
            <NumberFormat  allowNegative={false}   className={this.state.currentmarket.changeRate > 0 ? 'text-success my-auto' : 'text-danger my-auto'} displayType='text' thousandSeparator=',' value={F.floor((this.state.currentmarket.changeRate * 100),2)} /> }
          </div>
        </div>
        </div>
        <div  className='mc-nav-item '>
        <div className='text-center'>
          <div className='text-center'>
            <small>{t('vol.')}</small>
          </div>
          <div className='d-flex'>
          {this.state.hasdata !== true ?  <p className='ltr my-auto'> <ReactLoading className='my-auto mx-auto' type='balls' color='orange' height={20} width={20} /></p>:
              <small>{this.state.currentmarket.vol}</small> }
          </div>
        </div>
        </div>
        <div  className='mc-nav-item '>
        <div className='mt-auto d-grid'>
          <div className='text-center'>
            <small>{t('vol.value')}</small>
          </div>
          <div className='text-center'>
          {this.state.hasdata !== true ?  <p className='ltr my-auto'> <ReactLoading className='my-auto mx-auto' type='balls' color='orange' height={20} width={20} /></p>:
              <small><NumberFormat  allowNegative={false}  decimalScale={this.state.currentmarket.pi}  className='' displayType='text' thousandSeparator=',' value ={this.state.currentmarket.volValue * this.state.usdt} /></small> }
          </div>
        </div>
        </div>
        <div  className='mc-nav-item '>
        <div className='d-grid'>
          <div className='text-center'>
            <small>{t('marketfee')}</small>
          </div>
          <div className='text-center'>
          {this.state.hasdata !== true ?  <p className='ltr my-auto'> <ReactLoading className='my-auto mx-auto' type='balls' color='orange' height={20} width={20} /></p>:
              <small>{this.state.fee}</small> }
          </div>
        </div>
        </div>
        <div  className='mc-nav-item '>
        <div className='d-grid'>
          <div className='text-center'>
            <small>{t('marketrank')}</small>
          </div>
          <div className='text-center'>
          <div className='d-flex'>
          {this.state.hasdata !== true ?  <p className='ltr my-auto'> <ReactLoading className='my-auto mx-auto' type='balls' color='orange' height={20} width={20} /></p>:
              <small className='d-flex'>{this.state.currentmarket.rank}<PiMedalThin className='mx-2 mt-1' size='0.9rem' /></small> }
          </div>
          </div>
        </div>
        </div>
        <div  className='mc-nav-item-last'>
        <div className='d-grid'>
          <div className='text-center'>
            <small>{t('user-level')}</small>
          </div>
          <div className='text-center'>
          {this.state.hasdata !== true ?  <p className='ltr my-auto'> <ReactLoading className='my-auto mx-auto' type='balls' color='orange' height={20} width={20} /></p>:
              <small>{this.state.userlevel}</small> }
          </div>
        </div>
        </div>
        <TiDownloadOutline onClick={this.props.showdownloadapp} size='1.5rem' className='ml-auto mr-2' />
        {depositbtn}
        <div onClick={this.togglemenu} >{userui}</div>
        <Link className='ml-2 d-flex'  to='/'>
        <div>
        <img src={xsicon} width='36' height='36px'/>
        </div>
        </Link>

        </div>
        <div className='mc-nav-xs'>

          <div  className='ltr' >
          <div className='my-auto ' >
          <h6 onClick={this.showmarkets} className='lead  my-auto mx-2'>{this.base}/{this.quote}
          <AiTwotoneDownCircle   className='my-auto pointer' size='0.8rem' />
          </h6>
          </div>
          </div>
          <TiDownloadOutline onClick={this.props.showdownloadapp} size='1.5rem' className='ml-auto mr-3' />
          <div className='ltr' >
          <SimpleLineIcon name='d-one chart my-auto' onClick={()=>this.setState({chartvisible : !this.state.chartvisible})}/>
          </div>

          <div onClick={this.props.togglemenu} >{userui}</div>

        </div>
        {this.state.layer === 1 ?
        <div className='layer-market-main'>
          <Row  className='rtl no-gutters'  xl='2' lg='2' md='1' sm='1' xs='1'>
          <Col className='col-left d-none d-md-block' xl='3' lg='3' md='3' sm='' xs=''>
          <div className='mc-header'>
            <BsListCheck href="#" id="BsListCheck"  onClick={this.leftmenuor}  size='1rem'/>
            <Tooltip placement="bottom" isOpen={this.state.toggletp1} target="BsListCheck" toggle={this.toggletp1} >{t('orders')}</Tooltip>
            <BsClockHistory href="#" id="BsClockHistory"  onClick={this.leftmenuhi}  size='1rem'/>
            <Tooltip placement="bottom" isOpen={this.state.toggletp2} target="BsClockHistory" toggle={this.toggletp2} >{t('orderhistory')}</Tooltip>
            <BsWallet href="#" id="BsWallet"  onClick={this.leftmenuwa}   size='1rem'/>
            <Tooltip placement="bottom" isOpen={this.state.toggletp3} target="BsWallet" toggle={this.toggletp3} >{t('assets')}</Tooltip>
            <BsStar href="#" id="BsStar"  onClick={this.leftmenufa}  size='1rem'/>
            <Tooltip placement="bottom" isOpen={this.state.toggletp4} target="BsStar" toggle={this.toggletp4} >{t('favorites')}</Tooltip>
          </div>
            {this.state.cr === 'orders' ? <>
            {this.socketdata}
            </> : <></>}
            {this.state.cr === 'leftmenuhi' ? <div className='history-container' >
            {this.state.history.map(ha=>
              <div className='rtl d-flex justify-content-space-between px-1 pb-1'>
              {ha.side === 'buy' ? <>
               <small onClick={this.setpriceasks} data-price={ha.price} className='text-success font-weight-bold'>{ha.price}</small>
               <small onClick={this.setamountasks} data-amount={ha.size} className='ltr text-success'>{parseFloat(ha.size).toFixed(6)}</small>
               <small  className='ltr '>{this.totime(ha.time)}</small>

               </>
               :
               <>

               <small onClick={this.setpriceasks} data-price={ha.price} className='text-danger font-weight-bold'>{ha.price}</small>
               <small onClick={this.setamountasks} data-amount={ha.size} className='ltr text-danger'>{ha.size}</small>
               <small  className='ltr '>{this.totime(ha.time)}</small>
               </>}</div>)}
            </div> : <></>}
            {this.state.cr === 'leftmenuwa' ? <>

            {this.state.accounts.map(acc=>
              <div className='o-l-c px-2 d-flex ltr my-2 py-2' >
                <p className={acc.slug + '-icon icon-small'}></p>
                <a href={'/trade/'+acc.slug+'-USDT'} className='ml-3 mt-2'>{acc.slug}</a>
                <p className='text-medium my-auto ml-auto'>{acc.availablebalance}</p>
              </div>
            )}
            </> : <></>}
            {this.state.cr === 'leftmenufa' ? <>
            {this.showleftmenufa}
            </> : <></>}
          </Col>
            <Col xl='9' lg='9' md='9' sm='' xs=''>
              <div className='chart-xl'>
              <Chart  btn={this.btn} theme = {this.state.theme} className='' market={this.market} />
              </div>
              <div className='chart-xl-bottom'>
              <div className='ordering-row scrollHider'>
                <div className='menuBox d-flex rtl'>
                  <span onClick={()=>this.setState({section : 'list'})} className={this.state.section === 'list' ? 'd-block d-md-none text-medium activetab-m' : 'd-block d-md-none text-medium' }>{t('orderlist')}</span>
                  <span onClick={()=>this.setState({section : 'history'})} className={this.state.section === 'history' ? 'd-block d-md-none text-medium activetab-m' : 'd-block d-md-none text-medium' }>{t('historylist')}</span>
                  <span className={this.state.section === 'market' ? 'd-none d-md-block activetab-m' : 'd-none d-md-block text-gray' } onClick={()=>this.setState({section : 'market' , msection : 'market'})}>{t('market')}</span>
                  <span className={this.state.section === 'SL-TP' ? 'd-none d-md-block activetab-m' : 'd-none d-md-block text-gray' } onClick={()=>this.setState({section : 'SL-TP' , msection : 'SL-TP'})}>{t('SL-TP')}</span>
                  <span className={this.state.section === 'orders' ? 'activetab-m' : 'text-gray' } onClick={()=>this.setState({section : 'orders'})}>{t('Orders')}</span>
                  <span className={this.state.section === 'limitorders' ? 'activetab-m' : 'text-gray' } onClick={()=>this.setState({section : 'limitorders'})}>{t('limitorders')}</span>
                  <span className={this.state.section === 'orderhistory' ? 'activetab-m' : 'text-gray' } onClick={()=>this.setState({section : 'orderhistory'})}>{t('orderhistory')}</span>
                </div>
              </div>
              {this.state.section === 'list' ? <div className='d-block d-md-none'>
              {this.socketdata}
              </div>:<></>}
              {this.state.section === 'history' ?
              <div className='d-block d-md-none'>
              {this.state.history.map(ha=>
                <div className='rtl d-flex justify-content-space-between px-1 pb-1'>
                {ha.side === 'buy' ? <>
                 <small onClick={this.setpriceasks} data-price={ha.price} className='text-success font-weight-bold'>{ha.price}</small>
                 <small onClick={this.setamountasks} data-amount={ha.size} className='ltr text-success'>{parseFloat(ha.size).toFixed(6)}</small>
                 <small  className='ltr '>{this.totime(ha.time)}</small>

                 </>
                 :
                 <>

                 <small onClick={this.setpriceasks} data-price={ha.price} className='text-danger font-weight-bold'>{ha.price}</small>
                 <small onClick={this.setamountasks} data-amount={ha.size} className='ltr text-danger'>{ha.size}</small>
                 <small  className='ltr '>{this.totime(ha.time)}</small>
                 </>}
                </div>
              )}
              </div>


              :<></>}
              {this.state.section === 'market' ?<div className='d-none d-md-block'>
              <Row xl='2' lg='2' md='2' sm='1' xs='1' className='no-gutters'>
                <Col>
                  {marketsellform}
                </Col>
                <Col>
                  {marketbuyform}
                </Col>
              </Row></div>
              :<></>}
              {this.state.section === 'SL-TP' ?<div className='d-none d-md-block'>
              <Row xl='2' lg='2' md='2' sm='1' xs='1' className='no-gutters'>
                <Col>
                <Form className='m-1'>
                  <div>
                    <NumberFormat customInput={Input} placeholder={this.state.pricelabel}  allowNegative={false} onValueChange={(values)=>this.Setlpricesell(values)} decimalScale={this.state.pi}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lpricesell} />
                    <span className='market-input-symbol'>{this.quote}</span>
                  </div>
                  <div className='mt-1'>
                    <NumberFormat placeholder={this.state.tpnote}  allowNegative={false} onValueChange={(values)=>this.Setlpricelimit(values)} decimalScale={this.state.pi}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lpricelimit} />
                    <span className='market-input-symbol'>{this.quote}</span>
                  </div>
                  <div>
                    <Input type='number' inputmode="decimal"  placeholder={this.state.amountlabel}  allowNegative={false} onChange={this.Setlamountsell}   className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lamountsell} />
                    <span className='market-input-symbol'>{this.base}</span>
                  </div>
                <div className='rtl d-flex justify-content-space-between mt-1'>
                <FormText onClick={()=>this.limitsetamountsellpercent(25)} data-percent='25' className='b-small'>25</FormText>
                <FormText onClick={()=>this.limitsetamountsellpercent(50)} data-percent='50' className='b-small  mx-2'>50</FormText>
                <FormText onClick={()=>this.limitsetamountsellpercent(75)} data-percent='75' className='b-small  mx-2'>75</FormText>
                <FormText onClick={()=>this.limitsetamountsellpercent(100)} data-percent='100' className='b-small  float-left'>100</FormText>
                </div>
                <div className='mt-1'>
                  <Input type='number' inputmode="decimal"  allowNegative={false} placeholder={this.state.amountlabel} onChange={this.Setlvolumesell}   className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lvolumesell} />
                  <span className='market-input-symbol'>{this.quote}</span>
                </div>
                <div className='d-flex justify-content-space-around'>
                  <VscDiffAdded onClick={this.forwardbuybase} className='ml-2 my-auto pointer ' size='' />
                  <FormText className='my-auto' >موجودی : </FormText>
                  <FormText className='mr-auto'>{this.base}</FormText>

                  <FormText className='mr-2'><NumberFormat customInput={Input} className='small'  allowNegative={false} decimalScale={this.state.qi}  displayType='text' thousandSeparator=',' value={this.state.baseCurrencybalance} /></FormText>
                  </div>
                  <div className='d-flex'>
                  <FormText className='ml-2'>{this.state.currentmarket.baseMinSize}</FormText>
                  <FormText className='ltr ml-auto'>Min : </FormText>
                  <FormText className='mx-2'>{this.state.currentmarket.baseMaxSize}</FormText>
                  <FormText className='ltr mr-2'>Max : </FormText>
                  </div>
                  <div className='d-none justify-content-space-between'>
                  <FormText className='mr-2'>{this.state.currentmarket.fee * this.state.currentmarket.zarib}</FormText>
                  <FormText className='ltr mr-2'>Fee :</FormText>
                  </div>
              {this.quote === 'IRR' ?
                <div className=' mt-1 d-flex justify-content-space-between'>
                  <FormText className='my-auto' >سفارش شما  با قیمت جهانی اجرا میشود .</FormText>
                  <FormText className='my-auto' ><NumberFormat decimalScale={this.state.pi} displayType='text' thousandSeparator=',' onValueChange={(values)=>this.setusdtpricesell(values)} value={this.state.usdtpricesell} /></FormText>


                </div> : <></> }
                <FormGroup className='rtl mt-3'>
                {this.state.loggedin === false ?
                    <Link to='/auth/login' className='text-center btn  btn-light btn-block' >
                    <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
                  </Link> :
                   <Button className='sell-btn' disabled={this.state.btnsell2}  onClick={this.submitselllimit} block color="danger">{this.state.textsell2} {this.base}</Button> }
                </FormGroup>
                </Form>

                </Col>
                <Col>
                <Form className='m-1'>
                  <div>
                   <NumberFormat customInput={Input} placeholder={this.state.pricelabel}  allowNegative={false} onValueChange={(values)=>this.Setlpricebuy(values)} decimalScale={this.state.pi}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lpricebuy} />
                    <span className='market-input-symbol'>{this.quote}</span>
                  </div>
                  <div className='mt-1'>

                  <NumberFormat placeholder={this.state.slnote}  allowNegative={false} onValueChange={(values)=>this.Setlpricelimitbuy(values)} decimalScale={this.state.pi}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lpricelimitbuy} />
                  <span className='market-input-symbol'>{this.quote}</span>
                  </div>
                  <div>
                  <Input type='number' inputmode="decimal"  placeholder={this.state.amountlabel}  allowNegative={false} onChange={this.Setlamountbuy}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lamountbuy} />
                  <span className='market-input-symbol'>{this.base}</span>
                </div>
                <div className='rtl d-flex justify-content-space-between mt-1'>
                  <FormText onClick={()=>this.limitsetamountbuypercent(25)} data-percent='25' className='b-small'>25</FormText>
                  <FormText onClick={()=>this.limitsetamountbuypercent(50)} data-percent='50' className='b-small  mx-2'>50</FormText>
                  <FormText onClick={()=>this.limitsetamountbuypercent(75)} data-percent='75' className='b-small  mx-2'>75</FormText>
                  <FormText onClick={()=>this.limitsetamountbuypercent(100)} data-percent='100' className='b-small  float-left'>100</FormText>
                </div>
                <div className='mt-1'>
                  <Input type='number' inputmode="decimal"   allowNegative={false} placeholder={this.state.amountlabel} onChange={this.Setlvolumebuy}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lvolumebuy} />
                  <span className='market-input-symbol'>{this.quote}</span>
                </div>
                <div className='d-flex justify-content-space-around'>
                  <VscDiffAdded onClick={this.forwardbuyquote} className='ml-2 my-auto pointer ' size='' />
                  <FormText className='my-auto' >موجودی : </FormText>
                  <FormText className='mr-auto'>{this.quote}</FormText>

                  <FormText className='mr-2'><NumberFormat className='small'  allowNegative={false} decimalScale={this.state.qi}  displayType='text' thousandSeparator=',' value={this.state.quoteCurrencybalance} /></FormText>
                  </div>
                  <div className='d-flex'>
                  <FormText className='ml-2'>{this.state.currentmarket.quoteMinSize}</FormText>
                  <FormText className='ltr ml-auto'>Min : </FormText>
                  <FormText className='mx-2'>{this.state.currentmarket.quoteMaxSize}</FormText>
                  <FormText className='ltr mr-2'>Max : </FormText>
                  </div>
                  <div className='d-none justify-content-space-between'>
                  <FormText className='mr-2'>{this.state.currentmarket.fee * this.state.currentmarket.zarib}</FormText>
                  <FormText className='ltr mr-2'>Fee :</FormText>
                  </div>
              {this.quote === 'IRR' ?
                <div className=' mt-1 d-flex justify-content-space-between'>
                  <FormText className='my-auto' >سفارش شما  با قیمت جهانی اجرا میشود .</FormText>
                  <FormText className='my-auto' ><NumberFormat decimalScale={this.state.pi} displayType='text' thousandSeparator=','  value={this.state.usdtpricebuy} /></FormText>


                </div> : <></> }
                <FormGroup className='rtl mt-3'>
                {this.state.loggedin === false ?
                    <Link to='/auth/signup' className='text-center btn  btn-light btn-block' >
                    <span onClick={this.activeloginmodal} className='text-muted my-auto'>{t('signup')}</span>
                  </Link> :
                  <Button className='buy-btn' disabled={this.state.btnbuy2}  onClick={this.submitbuylimit} block color="success">{this.state.textbuy2} {this.base}</Button> }
                 </FormGroup>
                </Form>

                </Col>
              </Row></div>
              :<></>}
              {this.state.section === 'orders' ?
              <>
                {this.state.section === 'orders' || this.state.sectionmd === 'orders' ?
                <div className='custom-overflow historylist-container ordelist user-order-container  rtl  mt-1  px-3 mx-1'>
                <Table size="small" borderless responsive className='text-small'>
                <thead>
                <tr>
                  <th>#</th>
                  <th>شماره سفارش</th>
                  <th>مارکت</th>
                  <th>نوع</th>
                  <th>سمت</th>
                  <th>قیمت</th>
                  <th>مقدار</th>
                  <th>تاریخ</th>
                  <th>وضعیت</th>
                  <th>لغو</th>
                </tr>
                </thead>
                <tbody>
                {this.state.openorders.map((i,index)=>
                <tr>
                <th scope="row">{index+1}</th>
                <td><p>{i.orderid}</p></td>
                <td classes='market-td'><span>{i.market}</span></td>
                <td><p>{t(i.type)}</p></td>
                <td><p>{t(i.side)}</p></td>
                <td><p>{i.price}</p></td>
                <td><p>{i.amount}</p></td>
                <td><p>{i.date}</p></td>
                <td><p>{t(i.status)}</p></td>
                <td onClick={()=>this.closeorder(i)}><p><SimpleLineIcon name='close pointer' color='red' /></p></td>
                </tr>
                )}

                </tbody>
                </Table>

                </div> : <></>}
              </>
              :<></>}
              {this.state.section === 'limitorders' || this.state.sectionmd === 'limitorders' ?
              <div className='custom-overflow historylist-container ordelist user-order-container  rtl  mt-1  px-3 mx-1'>

                <Table size="small" responsive borderless className='text-small'>
                <thead>
                <tr>
                  <th>#</th>
                  <th>شماره سفارش</th>
                  <th>مارکت</th>
                  <th>سمت</th>
                  <th>قیمت</th>
                  <th>قیمت حد</th>
                  <th>مقدار</th>
                  <th>تاریخ</th>
                  <th>وضعیت</th>
                  <th>لغو</th>
                </tr>
                </thead>
                <tbody>
                {this.state.limitorders.map((i,index)=>
                <tr>
                <th scope="row">{index+1}</th>
                <td><p>{i.orderid}</p></td>
                <td><p>{i.market}</p></td>
                <td><p>{i.side === '300014' ? <small className='text-success'>{t(i.side)}</small> : <small className='text-danger'>{t(i.side)}</small>}</p></td>
                <td><p>{i.price}</p></td>
                <td><p>{i.sl || i.tp}</p></td>
                <td><p>{i.amount}</p></td>
                <td><p>{i.date}</p></td>
                <td><p>{t(i.status)}</p></td>
                <td onClick={()=>this.closeorder(i)}><SimpleLineIcon name='close pointer' color='red' /></td>
                </tr>
                )}

                </tbody>
                </Table>
              </div>
            :<></>}
            {this.state.section === 'orderhistory' || this.state.sectionmd === 'orderhistory' ?
            <div className='custom-overflow historylist-container ordelist user-order-container  rtl  mt-1  px-3 mx-1'>
            <Table size="small" borderless responsive className='text-small'>
            <thead>
            <tr>
              <th>#</th>
              <th>شماره سفارش</th>
              <th>مارکت</th>
              <th>نوع</th>
              <th>سمت</th>
              <th>قیمت</th>
              <th>مقدار</th>
              <th>تاریخ</th>
              <th>وضعیت</th>
            </tr>
            </thead>
            <tbody>
            {this.state.closeorders.map((i,index)=>
            <tr>
            <th scope="row">{index+1}</th>
            <td><p>{i.orderid}</p></td>
            <td><p>{i.market}</p></td>
            <td><p>{t(i.type)}</p></td>
            <td><p>{t(i.side)}</p></td>
            <td><p>{i.price}</p></td>
            <td><p>{i.amount}</p></td>
            <td><p>{i.date}</p></td>
            <td><p>{t(i.status)}</p></td>
            </tr>
            )}

            </tbody>
            </Table>

            </div> : <></>}
              </div>
            </Col>

          </Row>
        </div> :
        <div className='layer-market-pro'>
          <Row   xl='2' lg='2' md='1' sm='1' xs='1' >
            <Col xl='' lg='' md='' sm='' xs=''>

            </Col>
            <Col xl='' lg='' md='' sm='' xs=''>

            </Col>
          </Row>
        </div>
      }
      </div>
    }
      {this.state.loggedin === true ?
      <div className='market-bottom' >
        <Button onClick={()=>this.openorderform(1)} className='mr-1 w-50' color='success'>{t('buy')}</Button>
        <Button onClick={()=>this.openorderform(2)} className='ml-1 w-50' color='danger'>{t('sell')}</Button>
      </div> : <></>}
      {this.state.setting === true ?
      <div  data-aos='slide-left'  className='toleft-collapse'>

      <div className='toright-collapse-row d-flex py-2 pointer'>

        <span onClick={()=>this.setState({setting : !this.state.setting})} className='mr-auto'><TfiClose /></span>
      </div>
      <Row>
        <Col>
        <Switch onColor='#219101' height={24} onHandleColor='#fff'  onChange={this.changefastorder} checked={this.state.changefastorder} />

        </Col>
        <Col>
        <Switch onColor='#219101' height={24} onHandleColor='#fff'  onChange={this.changeshowleft} checked={this.state.changeshowleft} />

        </Col>
        <Col>
        <Switch onColor='#219101' height={24} onHandleColor='#fff'  onChange={this.changelayout} checked={this.state.changelayout} />

        </Col>
      </Row>
      </div>
      :<></> }
      {this.state.xlm === true ? <div className='sidebar d-none d-sm-block'><Content/></div> : <></>}
      <Modal fade={false} toggle={this.showmarkets} isOpen={this.state.marketcollapse} className='tx-modal'>
        <ModalHeader className='market-modal-header'  close={close}>
        <input type="search" id="site-search" className='market-search-input' onChange={this.changesearch} />
        </ModalHeader>
        <ModalBody>
        <div className='pb-3'>
              <div  className='scrollHider-gutter'>
                <span onClick={()=>this.setState({modalmenuitem : "all" , qs : '' })} className={this.state.modalmenuitem === 'all' ? 'active-category-badge' : 'category-badge'}>All</span>
                <span onClick={()=>this.setState({modalmenuitem : "IRR", qs : 'IRR'})} className={this.state.modalmenuitem === 'IRR' ? 'active-category-badge' : 'category-badge'}>IRR</span>
                <span onClick={()=>this.setState({modalmenuitem : "USDT", qs : 'USDT'})} className={this.state.modalmenuitem === 'USDT' ? 'active-category-badge' : 'category-badge'}>USDT</span>
                <span onClick={()=>this.setState({modalmenuitem : "BTC" , qs : 'BTC'})} className={this.state.modalmenuitem === 'BTC' ? 'active-category-badge' : 'category-badge'}>BTC</span>
                <span onClick={()=>this.setState({modalmenuitem : "ETH", qs : 'ETH'})} className={this.state.modalmenuitem === 'ETH' ? 'active-category-badge' : 'category-badge'}>ETH</span>
                <span onClick={()=>this.setState({modalmenuitem : "KCS", qs : 'KCS'})} className={this.state.modalmenuitem === 'KCS' ? 'active-category-badge' : 'category-badge'}>KCS</span>
              </div>
          </div>

        {this.state.hasdata !== true ? <>{this.miniloader} </> :
        <div className='market-list-modal'>
        {markets.map((m,index)=>
          <>
          <div className='toright-collapse-row d-flex py-2 pl-3 pointer'>


            <a className='symbol-row icon d-flex' >
              <span className={m.baseCurrency + '-icon icon-small'}/>
            </a>
            <a className='symbol-row d-flex' >
            <div className='mr-2 pointer' onClick={()=>this.setfav(m)}>
            {m.favorite === 'yes' ? <AiFillStar className='fav-icon-active' color='orange' size='1rem'/> : <AiOutlineStar size='1rem' className='fav-market-icon' />}
            </div>
              <a href={'/trade/'+m.symbolName}  className='symbol-row  slug'>{m.symbolName}</a>

            </a>
            <a className='symbol-row' href={'/trade/'+m.symbolName}>
            {m.changeRate > 0 ?
            <div className='ltr text-success mx-2'>{F.floor((m.changeRate * 100) , 3)} %</div> :
            <div className='ltr text-danger mx-2'>{F.floor((m.changeRate * 100) , 3)} %</div> }
            </a>
            <a className='symbol-row d-flex irprice' href={'/trade/'+m.symbolName}>
            <NumberFormat   className='text-dgray' displayType='text' value={m.irsell} thousandSeparator=',' />

            </a>
            <a className='symbol-row d-flex' href={'/trade/'+m.symbolName}>
            <NumberFormat   className='text-dgray' displayType='text' value={m.sell} thousandSeparator=','  />

            </a>
          </div>
          </>
        )}

        </div>}
        </ModalBody>

      </Modal>
      {this.state.marketcollapse === 'ss' ?<>

      <div  data-aos='slide-right'  className='toright-collapse'>
      <div className='sticky-top'>
        <span onClick={this.showmarkets} className='mt-4 mb-2 ml-auto mr-3 float-right'><TfiClose size='1.2rem'/></span>
        <input   className='mr-3 market-input' onChange={this.changesearch} />
        <div  className='scrollHider-gutter'>
          <span onClick={()=>this.setState({modalmenuitem : "all" , qs : '' })} className={this.state.modalmenuitem === 'all' ? 'active-category-badge' : 'category-badge'}>All</span>
          <span onClick={()=>this.setState({modalmenuitem : "IRR", qs : 'IRR'})} className={this.state.modalmenuitem === 'IRR' ? 'active-category-badge' : 'category-badge'}>IRR</span>
          <span onClick={()=>this.setState({modalmenuitem : "USDT", qs : 'USDT'})} className={this.state.modalmenuitem === 'USDT' ? 'active-category-badge' : 'category-badge'}>USDT</span>
          <span onClick={()=>this.setState({modalmenuitem : "BTC" , qs : 'BTC'})} className={this.state.modalmenuitem === 'BTC' ? 'active-category-badge' : 'category-badge'}>BTC</span>
          <span onClick={()=>this.setState({modalmenuitem : "ETH", qs : 'ETH'})} className={this.state.modalmenuitem === 'ETH' ? 'active-category-badge' : 'category-badge'}>ETH</span>
          <span onClick={()=>this.setState({modalmenuitem : "KCS", qs : 'KCS'})} className={this.state.modalmenuitem === 'KCS' ? 'active-category-badge' : 'category-badge'}>KCS</span>
        </div>
        </div>

      <div className='toright-collapse-row  header d-flex py-2 pl-3 pointer'>

        <a className='symbol-row text-medium symbol-row  slug' >
          {t('market/symbol')}
        </a>

        <a className='symbol-row  slug   text-medium'>{t('change')}</a>
        <a className='symbol-row  slug  text-medium '>{t('rial-price')}</a>
        <a className='symbol-row  slug  text-medium'>{t('real-price')}</a>

      </div>
      {this.state.hasdata !== true ? <>{this.miniloader} </> :
      <div className='p-2'>
      {markets.map((m,index)=>
        <>
        <div className='toright-collapse-row d-flex py-2 pl-3 pointer'>


          <a className='symbol-row icon d-flex' >
            <span className={m.baseCurrency + '-icon icon-small'}/>
          </a>
          <a className='symbol-row d-flex' >
          <div className='mr-2 pointer' onClick={()=>this.setfav(m)}>
          {m.favorite === 'yes' ? <AiFillStar className='fav-icon-active' color='orange' size='1rem'/> : <AiOutlineStar size='1rem' className='fav-market-icon' />}
          </div>
            <a href={'/trade/'+m.symbolName}  className='symbol-row  slug'>{m.symbolName}</a>

          </a>
          <a className='symbol-row' href={'/trade/'+m.symbolName}>
          {m.changeRate > 0 ?
          <div className='ltr text-success mx-2'>{m.changeRate}</div> :
          <div className='ltr text-danger mx-2'>{m.changeRate}</div> }
              </a>
              <a className='symbol-row' href={'/trade/'+m.symbolName}>
          <span className='text-medium mx-2'><NumberFormat  allowNegative={false} className='text-dgray' displayType='text' value={m.irsell} thousandSeparator=',' /></span></a>
          <a className='symbol-row' href={'/trade/'+m.symbolName}>
          <span className='text-medium  w-25 mx-2'><NumberFormat  allowNegative={false} className='text-dgray' displayType='text' value={m.sell} thousandSeparator=',' /></span></a>
        </div>
        </>
      )}

      </div>}
      </div>
      </>:<></> }

      {this.state.orderform === true ?
      <div  data-aos='slide-up'  className='rtl orderform-collapse'>
      <div className='order-form-row'>
          <p className={this.state.msection === 'market' ? 'font-weight-bold activetab-m px-1 mx-1 d-flex' : 'font-weight-boldpx-1 mx-1 d-flex'} onClick={()=>this.setState({msection : 'market',section : 'market'})}>{t('market')}</p>
          <p className={this.state.msection === 'SL-TP' ? 'font-weight-bold activetab-m px-1 mx-1 d-flex' :'font-weight-bold px-1 mx-1 d-flex'} onClick={()=>this.setState({msection : 'SL-TP',section : 'SL-TP'})}>{t('SL-TP')}</p>
          <div onClick={()=>this.setState({orderform : false})} className='mr-auto'><TfiClose size='1.2rem'/></div>
      </div>
        <div>
          <div onClick={()=>this.setState({formtype : !this.state.formtype})} className={this.state.formtype === true ? 'switch-side-btn buy' : 'switch-side-btn sell' }>
            {this.state.formtype ? <p className='btntextbuy'>{t('buy')}</p> : <p className='btntextsell'>{t('sell')}</p> }
          </div>
            {this.state.msection === 'market'  && this.state.formtype === true ?
          <>
          {marketbuyform}</> : <></>}
            {this.state.msection === 'SL-TP'  && this.state.formtype === true ?
           <>
           <Form className='m-1'>
             <div className='my-2'>
              <NumberFormat  placeholder={this.state.pricelabel}  allowNegative={false} onValueChange={(values)=>this.Setlpricebuy(values)} decimalScale={this.state.pi}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lpricebuy} />
              <NumberFormat type='hidden' decimalScale={this.state.bi}  thousandSeparator=',' onValueChange={(values)=>this.setusdtpricebuy(values)} value={this.state.usdtpricebuy} />
               <span className='market-input-symbol'>{this.quote}</span>
             </div>
             <div className='my-2'>

             <NumberFormat placeholder={this.state.slnote}  allowNegative={false} onValueChange={(values)=>this.Setlpricelimitbuy(values)} decimalScale={this.state.pi}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lpricelimitbuy} />
             <span className='market-input-symbol'>{this.quote}</span>
             </div>
             <div className='my-2'>
             <Input type='number' inputmode="decimal" placeholder={this.state.amountlabel}  allowNegative={false} onChange={this.Setlamountbuy}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lamountbuy} />
             <span className='market-input-symbol'>{this.base}</span>
           </div>
           <div className='rtl d-flex justify-content-space-between mt-1'>
             <FormText onClick={()=>this.limitsetamountbuypercent(25)} data-percent='25' className='b-small'>25</FormText>
             <FormText onClick={()=>this.limitsetamountbuypercent(50)} data-percent='50' className='b-small  mx-2'>50</FormText>
             <FormText onClick={()=>this.limitsetamountbuypercent(75)} data-percent='75' className='b-small  mx-2'>75</FormText>
             <FormText onClick={()=>this.limitsetamountbuypercent(100)} data-percent='100' className='b-small  float-left'>100</FormText>
           </div>
           <div className='mt-1'>
             <Input type='number' inputmode="decimal"  allowNegative={false} placeholder={this.state.amountlabel} onChange={this.Setlvolumebuy}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lvolumebuy} />
             <span className='market-input-symbol'>{this.quote}</span>
           </div>
           <div className='d-flex justify-content-space-around'>
             <VscDiffAdded onClick={this.forwardbuyquote} className='ml-2 my-auto pointer ' size='' />
             <FormText className='my-auto' >موجودی : </FormText>
             <FormText className='mr-auto'>{this.quote}</FormText>

             <FormText className='mr-2'><NumberFormat className='small'  allowNegative={false} decimalScale={this.state.qi}  displayType='text' thousandSeparator=',' value={this.state.quoteCurrencybalance} /></FormText>
             </div>
             <div className='d-flex'>
             <FormText className='ml-2'>{this.state.currentmarket.quoteMinSize}</FormText>
             <FormText className='ltr ml-auto'>Min : </FormText>
             <FormText className='mx-2'>{this.state.currentmarket.quoteMaxSize}</FormText>
             <FormText className='ltr mr-2'>Max : </FormText>
             </div>
             <div className='d-none justify-content-space-between'>
             <FormText className='mr-2'>{this.state.currentmarket.fee * this.state.currentmarket.zarib}</FormText>
             <FormText className='ltr mr-2'>Fee :</FormText>
             </div>
         {this.quote === 'IRR' ?
           <div className=' mt-1 d-flex justify-content-space-between'>
             <FormText className='my-auto' >سفارش شما  با قیمت جهانی اجرا میشود .</FormText>
             <FormText className='my-auto' ><NumberFormat decimalScale={this.state.pi} displayType='text' thousandSeparator=','  value={this.state.usdtpricebuy} /></FormText>


           </div> : <></> }
           <FormGroup className='rtl mt-3'>
           {this.state.loggedin === false ?
               <Link to='/auth/login' className='text-center btn  btn-light btn-block' >
               <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
             </Link> :
             <Button className='buy-btn' disabled={this.state.btnbuy2}  onClick={this.submitbuylimit} block color="success">{this.state.textbuy2} {this.base}</Button> }
            </FormGroup>
           </Form></>:<></> }

            {this.state.msection === 'SL-TP'  && this.state.formtype === false ?<>
          <Form className='m-1'>
            <div className='my-2'>
              <NumberFormat customInput={Input} placeholder={this.state.pricelabel}  allowNegative={false} onValueChange={(values)=>this.Setlpricesell(values)} decimalScale={this.state.pi}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lpricesell} />
              <span className='market-input-symbol'>{this.quote}</span>
            </div>
            <div className='my-2'>
              <NumberFormat placeholder={this.state.tpnote}  allowNegative={false} onValueChange={(values)=>this.Setlpricelimit(values)} decimalScale={this.state.pi}  className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lpricelimit} />
              <span className='market-input-symbol'>{this.quote}</span>
            </div>
            <div className='my-2'>
              <Input type='number' inputmode="decimal" placeholder={this.state.amountlabel}  allowNegative={false} onChange={this.Setlamountsell}   className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lamountsell} />
              <span className='market-input-symbol'>{this.base}</span>
            </div>
          <div className='rtl d-flex justify-content-space-between mt-1'>
          <FormText onClick={()=>this.limitsetamountsellpercent(25)} data-percent='25' className='b-small'>25</FormText>
          <FormText onClick={()=>this.limitsetamountsellpercent(50)} data-percent='50' className='b-small  mx-2'>50</FormText>
          <FormText onClick={()=>this.limitsetamountsellpercent(75)} data-percent='75' className='b-small  mx-2'>75</FormText>
          <FormText onClick={()=>this.limitsetamountsellpercent(100)} data-percent='100' className='b-small  float-left'>100</FormText>
          </div>
          <div className='mt-1'>
            <Input type='number' inputmode="decimal"  allowNegative={false} placeholder={this.state.amountlabel} onChange={this.Setlvolumesell}   className='w-100 market-input' displayType='input' thousandSeparator=',' value={this.state.lvolumesell} />
            <span className='market-input-symbol'>{this.quote}</span>
          </div>
          <div className='d-flex justify-content-space-around'>
            <VscDiffAdded onClick={this.forwardbuybase} className='ml-2 my-auto pointer ' size='' />
            <FormText className='my-auto' >موجودی : </FormText>
            <FormText className='mr-auto'>{this.base}</FormText>

            <FormText className='mr-2'><NumberFormat customInput={Input} className='small'  allowNegative={false} decimalScale={this.state.qi}  displayType='text' thousandSeparator=',' value={this.state.baseCurrencybalance} /></FormText>
            </div>
            <div className='d-flex'>
            <FormText className='ml-2'>{this.state.currentmarket.baseMinSize}</FormText>
            <FormText className='ltr ml-auto'>Min : </FormText>
            <FormText className='mx-2'>{this.state.currentmarket.baseMaxSize}</FormText>
            <FormText className='ltr mr-2'>Max : </FormText>
            </div>
            <div className='d-none justify-content-space-between'>
            <FormText className='mr-2'>{this.state.currentmarket.fee * this.state.currentmarket.zarib}</FormText>
            <FormText className='ltr mr-2'>Fee :</FormText>
            </div>
        {this.quote === 'IRR' ?
          <div className=' mt-1 d-flex justify-content-space-between'>
            <FormText className='my-auto' >سفارش شما  با قیمت جهانی اجرا میشود .</FormText>
            <FormText className='my-auto' ><NumberFormat decimalScale={this.state.pi} displayType='text' thousandSeparator=',' onValueChange={(values)=>this.setusdtpricesell(values)} value={this.state.usdtpricesell} /></FormText>


          </div> : <></> }
          <FormGroup className='rtl mt-3'>
          {this.state.loggedin === false ?
            <Link to='/auth/login' className='text-center btn  btn-light btn-block' >
            <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
          </Link> :
             <Button className='sell-btn'  disabled={this.state.btnsell2}  onClick={this.submitselllimit} block color="danger">{this.state.textsell2} {this.base}</Button> }
          </FormGroup>
          </Form>
  </> : <></>}
            {this.state.msection === 'market'  && this.state.formtype === false ? <>
            {marketsellform} </>:<></> }
        </div>


      </div>
      :<></> }

    </>
    )
  }
  }
export default withTranslation()(Trade)
