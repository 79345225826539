import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import Logotext from '../../style/images/logo.png'
import {Link} from 'react-router-dom'
import SimpleLineIcon from 'react-simple-line-icons'
import {useTranslation,withTranslation} from 'react-i18next'
import moment from 'jalali-moment'
import axios from 'axios'

class Example extends React.Component {
  constructor(props) {
       super(props);
       this.state = {
        name : '',
        email : '',
        user : [],
        x : 'X'
       }
   }
   componentWillMount(){
     axios.get('/api/dashboard/getuser').then(response=>{
       var x = response.data.userdata.name.charAt(0).toUpperCase()
       this.setState({user : response.data.userdata , x : x})
     })
   }

  render(){

  return (
      <div className='d-none d-md-block'>
        <Navbar  light color="white" expand="xs" className='market-navbar'>
        <div className='nav-link login-button' onClick={this.props.onClick}><SimpleLineIcon name='bulb power-icon pointer'  /></div>
        <Link className='nav-link login-button' to='#'>

            </Link>
        <Link className='nav-link login-button' to='#'><SimpleLineIcon name='size-fullscreen' color='black' /></Link>
        <Link className='nav-link text-dark login-button d-flex small' to='#'><SimpleLineIcon name='calendar mx-2' color='purple' /><span className='clock'></span></Link>

          <Link to='/panel/transactions/' className='ml-auto'>
            تراکنش ها
          </Link>
          <Link to='/panel/dashboard' className='ml-auto'>
            داشبورد
          </Link>
          <Link to='/' className='ml-auto'>
            <img src={Logotext} width='32px' />
          </Link>
          <SimpleLineIcon name='menu navbar-menu-lg' />
        </Navbar>
      </div>
  );
}
}

export default withTranslation()(Example);
