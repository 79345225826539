import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import withReactContent from 'sweetalert2-react-content'
import {Form,Label,FormText,
  Card, CardHeader,FormGroup,Table,Alert, CardBody, InputGroup, InputGroupText, Input,Col,Row ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Button} from 'reactstrap'
import {Helmet} from 'react-helmet'
import { withTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
import {Link} from 'react-router-dom'
import NumericFormat from 'react-number-format';
import NumberFormat from 'react-number-format';
import Loader from '../../landing/loader'
import { CiCirclePlus } from "react-icons/ci";
import Mainalert from './alertdeposit'
import { PiCopyThin } from "react-icons/pi";
import Toast from 'awesome-toast-component'

class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data : [],
      mode : 2 ,
      list : [],
      banks : [],
      bank : [],
      txs : [],
      loader : true,
      type : 1
    }
this.validator = new SimpleReactValidator();
  }
  componentWillMount(){
    let uuid = cookie.load('uid')
    if(!uuid) {
      this.props.history.push('/auth/login')
    }
    this.getwallet()
  }
  getwallet =()=>{
    const {t} = this.props
    axios.get('/api/wallet/irt').then(response=>{
      this.setState({loader : false})
      if(response.data.status === 'error-fatal'){
        this.props.history.push('/auth/login')
        return
      }
      const data = response.data
    // alert(data.user.dv)
      if(data.status === 'success'){
        this.setState({user : response.data.user , banks : response.data.banks , balance : response.data.balance})
        if(data.user.completed !== '2'){
          ///////notverified
          this.setState({verification : '0'})

          return
        }
        if(data.state === '2'){
          ////////not bank account
          this.setState({account : false})
          this.setState({error : true , message : `${t('add-account-warning')}`})
          // this.props.history.push('/panel/submit_bank_account')
          return
        }

      }else{
        this.setState({error : true , errormessage : response.data.message})
      }

    })

  }
  chargewallet =()=>{
    if(this.state.verification === "0"){
      console.log('امکان واریز قبل از احراز هویت مقدور نیست')
      new Toast( 'امکان واریز قبل از احراز هویت مقدور نیست', {
        style: {
            container: [
                ['background-color', 'red']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
          position : 'top'
    })
      return
    }
    if (this.state.bank.length < 1) {
      console.log('حساب مبدا را انتخاب کنید')
      return
    }else{

    }
    let amount = this.state.amount
    let name = this.state.user.name

    let fee = this.state.fee
    let email = this.state.user.email
    let phone = this.state.user.mobile
    const formdata = new FormData()
    formdata.append('CardNo' , this.state.bank.accountNo)
    formdata.append('amount' , this.state.amount)
    formdata.append('fee' , fee)
    axios.post('/api/deposit/new' , formdata).then(response=>{
      // console.log(response.data);

      if(response.data.status === 'error-fatal'){
        this.props.history.push('/auth/login')
        return
      }
      if(response.data.status === 'error'){
        new Toast(response.data.message, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
            position : 'top'
      })
        return
      }
      if(response.data.status === 'success'){
        var url = response.data.url
        // window.open(url, "_blank")
        window.open(url);
      // this.setState({modal : !this.state.modal})
      }else{
        return
      }
    })

  }
  onchange =(e)=>{
    let fee = e.target.value * 2/10000;
    this.setState({[e.target.name] : e.target.value.value , fee :fee })
  }
  setlogo =(i)=>{
    var number = i.substring(6,-16)
    if(number === '603799'){return "https://rapitex.com/images/logo/banks/meli.png"}
    if(number === '589210'){return "https://rapitex.com/images/logo/banks/sepah.png"}
    if(number === '627961'){return "https://rapitex.com/images/logo/banks/sanatmadan.png"}
    if(number === '603770'){return "https://rapitex.com/images/logo/banks/keshavarsi.png"}
    if(number === '628023'){return "https://rapitex.com/images/logo/banks/maskan.png"}
    if(number === '627760'){return "https://rapitex.com/images/logo/banks/postbank.png"}
    if(number === '502908'){return "https://rapitex.com/images/logo/banks/tosehe.png"}
    if(number === '627412'){return "https://rapitex.com/images/logo/banks/eghtesad.png"}
    if(number === '622106'){return "https://rapitex.com/images/logo/banks/parsian.png"}
    if(number === '502229'){return "https://rapitex.com/images/logo/banks/pasargad.png"}
    if(number === '627488'){return "https://rapitex.com/images/logo/banks/karafarin.png"}
    if(number === '621986'){return "https://rapitex.com/images/logo/banks/saman.png"}
    if(number === '639346'){return "https://rapitex.com/images/logo/banks/sina.png"}
    if(number === '639607'){return "https://rapitex.com/images/logo/banks/sarmaye.png"}
    if(number === '502806'){return "https://rapitex.com/images/logo/banks/shahr.png"}
    if(number === '502938'){return "https://rapitex.com/images/logo/banks/day.png"}
    if(number === '603769'){return "https://rapitex.com/images/logo/banks/saderat.png"}
    if(number === '610433'){return "https://rapitex.com/images/logo/banks/mellat.png"}
    if(number === '627353'){return "https://rapitex.com/images/logo/banks/tejarat.png"}
    if(number === '589463'){return "https://rapitex.com/images/logo/banks/refah.png"}
    if(number === '627381'){return "https://rapitex.com/images/logo/banks/sepah.png"}
    if(number === '639370'){return "https://rapitex.com/images/logo/banks/mehreqtesad.png"}
    if(number === '639599'){return "https://rapitex.com/images/logo/banks/sepah.png"}
    if(number === '504172'){return "https://rapitex.com/images/logo/banks/resalat.png"}
  }
  setbankacount =(item)=>{
    this.setState({bankd : true ,bank : item})
  }
  convertor = (function() {
   var convertor = {
     convert: function(num) {
       if (isNaN(num)) return "";
       num = Math.round(num);
       var yekan = ["یک", "دو", "سه", "چهار", "پنج", "شش", "هفت", "هشت", "نه"];
       var dahgan1 = [
         "ده",
         "یازده",
         "دوازده",
         "سیزده",
         "چهارده",
         "پانزده",
         "شانزده",
         "هفده",
         "هجده",
         "نوزده"
       ];
       var dahgan2 = [
         "بیست",
         "سی",
         "چهل",
         "پنجاه",
         "شصت",
         "هفتاد",
         "هشتاد",
         "نود"
       ];
       var sadgan = [
         "صد",
         "دویست",
         "سیصد",
         "چهارصد",
         "پانصد",
         "ششصد",
         "هفتصد",
         "هشتصد",
         "نهصد"
       ];

       var _num = num.toString();
       var _count = 0;
       var _yekan = "";
       var _dahgan = "";
       var _sadgan = "";
       var _hezargan = "";
       var _hezargan2 = "";
       var _milion = "";
       var _milion2 = "";
       var _miliard = "";
       var _miliard2 = "";
       var _terilion = "";
       var _triliard = "";

       for (var i = _num.length - 1; i >= 0; i--) {
         _count++;

         //-1 => برای اینکه با توجه به موقعیت پیدا میکنم و جون از یک شروع میکنم پس منهای یک میکنم
         // که موقعیت درست رو به من بده
         var _s = parseInt(_num[i]) - 1;
         switch (_count) {
           case 1:
             if (_s >= 0) _yekan = yekan[_s];
             break;
           case 2:
             if (_s >= 0) {
               if (_s === 0) {
                 // زمانی که عدد کوچکتر از 20 باشد
                 var _d = parseInt(_num[i + 1]);
                 _dahgan = dahgan1[_d];
                 _yekan = "";
               } else {
                 _dahgan = dahgan2[_s - 1];

                 if (_yekan.trim() !== "") _dahgan += " و ";
               }
             }
             break;
           case 3:
             //صدگان
             if (_s >= 0) {
               _sadgan = sadgan[_s];
               if (_yekan.trim() !== "" || _dahgan.trim() !== "")
                 _sadgan += " و ";
             }
             break;
           case 4:
             //هزارگان
             if (_s >= 0) {
               _hezargan = yekan[_s];
               if (
                 _yekan.trim() !== "" ||
                 _dahgan.trim() !== "" ||
                 _sadgan.trim() !== ""
               )
                 _hezargan += " هزار و ";
               else _hezargan += " هزار ";
             }
             break;
           case 5:
             if (_s >= 0) {
               var _d = parseInt(_num[i + 1]);
               if (_s === 0) {
                 // زمانی که عدد کوچکتر از 20 باشد
                 _hezargan = dahgan1[_d] + " هزار ";
                 // _yekan = '';
               } else {
                 if (yekan[_d - 1] == undefined)
                   _hezargan = dahgan2[_s - 1] + " هزار ";
                 else
                   _hezargan =
                     dahgan2[_s - 1] + " و " + yekan[_d - 1] + " هزار ";
               }

               if (
                 _yekan.trim() !== "" ||
                 _dahgan.trim() !== "" ||
                 _sadgan.trim() !== ""
               )
                 _hezargan += " و ";
             }
             break;
           case 6:
             if (_s >= 0) {
               _hezargan2 = sadgan[_s];
               if (_hezargan.trim() !== "") _hezargan2 += " و ";
               else {
                 _hezargan2 += " هزار ";
                 if (
                   _yekan.trim() !== "" ||
                   _dahgan.trim() !== "" ||
                   _sadgan.trim() !== ""
                 )
                   _hezargan2 += " و ";
               }
             }
             break;
           case 7:
             //میلیون
             if (_s >= 0) {
               _milion = yekan[_s] + " میلیون ";
               if (
                 _yekan.trim() !== "" ||
                 _dahgan.trim() !== "" ||
                 _sadgan.trim() !== "" ||
                 _hezargan.trim() !== "" ||
                 _hezargan2.trim() !== ""
               )
                 _milion += " و ";
             }
             break;
           case 8:
             if (_s >= 0) {
               var _d = parseInt(_num[i + 1]);
               if (_s === 0) {
                 // زمانی که عدد کوچکتر از 20 باشد
                 _milion = dahgan1[_d] + " میلیون ";
                 // _yekan = '';
               } else {
                 if (yekan[_d - 1] === undefined)
                   _milion = dahgan2[_s - 1] + " میلیون ";
                 else
                   _milion =
                     dahgan2[_s - 1] + " و " + yekan[_d - 1] + " میلیون ";
               }

               if (
                 _yekan.trim() !== "" ||
                 _dahgan.trim() !== "" ||
                 _sadgan.trim() !== "" ||
                 _hezargan.trim() !== "" ||
                 _hezargan2.trim() !== ""
               )
                 _milion += " و ";
             }
             break;
           case 9:
             //میلیون
             if (_s >= 0) {
               _milion2 = sadgan[_s];
               if (_milion.trim() !== "") _milion2 += " و ";
               else {
                 _milion2 += " میلیون ";
                 if (
                   _yekan.trim() !== "" ||
                   _dahgan.trim() !== "" ||
                   _sadgan.trim() !== ""
                 )
                   _milion2 += "و ";
               }
             }
             break;
           case 10:
             //میلیارد
             if (_s >= 0) {
               _miliard = yekan[_s] + " میلیارد ";
               if (
                 _yekan.trim() !== "" ||
                 _dahgan.trim() !== "" ||
                 _sadgan.trim() !== "" ||
                 _hezargan.trim() !== "" ||
                 _hezargan2.trim() !== "" ||
                 _milion.trim() !== "" ||
                 _milion2.trim() !== ""
               )
                 _miliard += "و ";
             }
             break;
           case 11:
             if (_s >= 0) {
               var _d = parseInt(_num[i + 1]);
               if (_s === 0) {
                 // زمانی که عدد کوچکتر از 20 باشد
                 _miliard = dahgan1[_d] + " میلیارد ";
               } else {
                 if (yekan[_d - 1] == undefined)
                   _miliard = dahgan2[_s - 1] + " میلیارد ";
                 else
                   _miliard =
                     dahgan2[_s - 1] + " و " + yekan[_d - 1] + " میلیارد ";
               }

               if (
                 _yekan.trim() !== "" ||
                 _dahgan.trim() !== "" ||
                 _sadgan.trim() !== "" ||
                 _hezargan.trim() !== "" ||
                 _hezargan2.trim() !== "" ||
                 _milion.trim() !== "" ||
                 _milion2.trim() !== ""
               )
                 _miliard += "و ";
             }
             break;
           case 12:
             //میلیون
             if (_s >= 0) {
               _miliard2 = sadgan[_s];
               if (_miliard.trim() !== "") _miliard2 += " و ";
               else {
                 _miliard2 += " میلیارد ";
                 if (
                   _yekan.trim() !== "" ||
                   _dahgan.trim() !== "" ||
                   _sadgan.trim() !== ""
                 )
                   _miliard2 += "و ";
               }
             }
             break;
           default:
             break;
         }
       }

       return (
         _miliard2 +
         _miliard +
         _milion2 +
         _milion +
         _hezargan2 +
         _hezargan +
         _sadgan +
         _dahgan +
         _yekan +
         ' '+
         'تومان'
       );
     }
   };

   return convertor;
 })();
   fee =(x)=>{
     if(x < 6000001){
       return 1200
     }
     if(x > 6000001 && x < 200000000){
       return x * 0.0002
     }
     if(x>200000000){
       return 40000
     }
   }
   setamount =(x)=>{
   let fee = this.fee(x)
   this.setState({amount : x , fee : fee})
 }
  render(){
    var x = this.convertor.convert(this.state.balance /10)
    var y = this.convertor.convert(this.state.amount /10)
    const { t } = this.props
    const balance  = <NumberFormat value={parseInt(this.state.balance)}  thousandSeparator="," displayType="text"  className='font-weight-bold' />
    var id =this.props.match.params.id
    return(
    <>
    <Helmet>
       <title>{t('deposit-irr-title')}</title>
    </Helmet>

    {this.state.loader === true ?
      <>
        <div className='loader-home  py-5'>
          <h3 className='text-center  m-auto mt-auto'><Loader /></h3>
        </div>
      </> :
    <div className='rtl mt-3'>

      <>
      <Mainalert/>
        <Card className=''>
        <CardHeader>
          <div className='d-flex rtl'>
           <p onClick={()=>this.setState({type : 1})} className={this.state.type === 1 ? 'pointer' : 'pointer' }>واریز با درگاه پرداخت</p>
           <p onClick={()=>this.setState({type : 2})} className={this.state.type === 2 ? 'd-none mx-3 pointer' : 'd-none mx-3 pointer' } >واریز با شبا</p>
          </div>
        </CardHeader>
        <CardBody>
        {this.state.error === true ? <Alert className='my-4 mr-3 ml-4' color="info rtl">
           {this.state.message}
         </Alert>  : <></>}

       {this.state.type === 1 ?

        <Row className='rtl no-gutters'>
          <Col xl='7' className='m-auto p-3'>
          <Form>
          <FormGroup>
          <Row className='info-main pb-3'>
          <Col xl='12' lg='12' md='12' sm='12' xs='12' className='my-4 rtl text-medium c-i font-weight-bold'>واریز ریالی</Col>
          <Col xl='12' lg='12' md='12' sm='12' xs='12' className='rtl mt-3 text-medium c-i inline-space d-flex justify-content-space-around mr-auto'>موجودی : <small className='font-weight-bold mr-auto  text-medium ml-3'> {balance}</small> <small className='d-none d-md-block'>{x}</small><SimpleLineIcon name='refresh mx-2 pointer' color='indigo'  onClick={this.getwallet} /></Col>
          </Row>
          </FormGroup>
          <FormGroup className='input-inherit'>
            <Label className='text-medium' for="amount">مبلغ واریز *</Label>
            <NumericFormat
             inputMode="decimal"
             value={parseInt(this.state.amount)}
             thousandSeparator=","
             onValueChange={(values, sourceInfo) => {
             this.setamount(values.floatValue)
              }}
             name="amount"
             displayType="input"
             className='font-weight-bold form-control text-center' />
            {this.validator.message('amount', this.state.amount, 'string|required')}
            <FormText className='d-flex justify-content-space-between'> {this.state.amount > 0 ?
              <>
              <li>
                {y}
              </li>
              <li className='text-muted pt-2 rtl mr-auto' >
              <NumericFormat value={this.state.fee} thousandSeparator="," displayType="text" suffix={t('irr')} prefix='کارمزد : '  className='font-weight-bold' />
              </li>
              </>: <></>}
              </FormText>
           </FormGroup>
          <FormGroup className='my-5'>
            <Label className='text-medium' for="name">انتخاب حساب *</Label>
            <UncontrolledDropdown className='bg-light ticker-list-2 m-auto'>
              <DropdownToggle color='light' className='py-2' block>
            {this.state.bankd !== true ? <span className='my-auto text-medium'>یک حساب بانکی انتخاب کنید</span> :
                <div className='d-flex  justify-content-space-between'>
                  <span><img width='30px' src={this.setlogo(this.state.bank.accountNo)} /></span>
                  <span className='my-auto text-muted ml-auto mr-3'>{this.state.bank.bankname}</span>
                  <span className='my-auto text-medium ltr float-right'><NumberFormat className='rtl text-dark'  format="####    ####    ####    ####"  value={this.state.bank.accountNo} displayType='text' /></span>
                </div>
           }
              </DropdownToggle>
              <DropdownMenu className='w-100 modifier text-medium'>
              {this.state.banks.map((item,index)=>
                <>
                  <DropdownItem onClick={()=>this.setbankacount(item)}>
                  <div className='d-flex justify-content-space-between'>
                      <span><img width='30px' src={this.setlogo(item.accountNo)} /></span>
                    <span className='mr-3 text-muted ml-auto  text-medium'>{item.bankname}</span>
                    <span className='text-medium ltr float-right'><NumberFormat className='rtl text-dark'  format="####    ####    ####    ####"  value={item.accountNo} displayType='text' /></span>
                  </div>
                  </DropdownItem>
                </>
              )}
              <DropdownItem>
              <Link to='/panel/submit_bank_account' className='d-flex justify-content-space-between'>
                <span className='text-muted text-medium'>{t('add-bank-account')}</span>
                <span className='mr-3 text-muted'><CiCirclePlus size='1.2rem' color='success'/></span>
              </Link>
              </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <FormText className='mt-2'>در صورت واریز از کارت های بانکی ثبت نشده حساب ریالی شما شارژ نشده  و برگشت وجه تا 72 ساعت امکان پذیر نخواهد بود.</FormText>
           </FormGroup>


          <FormGroup className='button-container my-5'>
          <Button className='text-medium' color='primary' block onClick={this.chargewallet}>انتقال به درگاه بانکی</Button>
          </FormGroup>
          </Form>
          </Col>
          <Col xl='5' className='bg-light text-dark info-col'>
            <p className='p-3 text-dark'>
            نکات مهم قبل از انجام واریز:
            </p>
            <p className='p-3 text-medium text-dark'>
            حتماً به دقت به آدرس صفحه درگاه بانکی توجه کنید و تنها پس از اطمینان از حضور در سایت‌های سامانه‌ی شاپرک، مشخصات کارت بانکی خود را وارد نمایید.
            </p>
            <p className='p-3 text-medium text-dark'>
            در صفحه درگاه، به دقت مبلغ نمایش داده شده را بررسی کنید.
            </p>

          </Col>

        </Row> :
        <Row className='rtl no-gutters'>
          <Col xl='7' className='m-auto p-3'>
          <Form>
          <FormGroup>
          <Row className='info-main my-2 d-flex justify-content-space-around px-2 py-3'>
          <Col xs='12' sm='12' md='12' lg='12' xl='12' className='mt-3 rtl text-medium c-i d-flex mr-auto'>
          <p>{t('balance')}</p>
          <Link className='font-weight-bold text-medium mx-3'> {balance}</Link>
          <small>{x}</small>
          <small className='pointer mb-1 d-flex'><SimpleLineIcon name='refresh mx-2 pointer'  onClick={this.getwallet} /></small>
          </Col>
          </Row>
          </FormGroup>
          <div className='p-3 add-container mt-5' dir='ltr'>
          <Label>بانک مقصد</Label>
            <InputGroup>
              <Input disabled value={this.state.address} placeholder="" className='input-custome-button text-selected' />
              <Button color='success' outline className='custom-input-button text-dark'>
                <PiCopyThin className='text-dark' />
              </Button>
            </InputGroup>
          </div>
          <div className='p-3 add-container mt-5' dir='ltr'>
          <Label>شبای مقصد</Label>
            <InputGroup>
              <Input disabled value={this.state.address} placeholder="" className='input-custome-button text-selected' />
              <Button color='success' outline className='custom-input-button text-dark' onClick={this.copyaddress}>
                <PiCopyThin className='text-dark' />
              </Button>
            </InputGroup>
          </div>

          <div className='p-3 add-container mt-5' dir='ltr'>
          <Label>شناسه واریز</Label>
            <InputGroup>
              <Input disabled value={this.state.address} placeholder="" className='input-custome-button text-selected' />
              <Button color='success' outline className='custom-input-button text-dark' onClick={this.copyaddress}>
                <PiCopyThin className='text-dark' />

              </Button>
            </InputGroup>
          </div>


          <FormText className='mt-2'>در صورت واریز از کارت های بانکی ثبت نشده حساب ریالی شما شارژ نشده  و برگشت وجه تا 72 ساعت امکان پذیر نخواهد بود.</FormText>

          <FormGroup className='button-container my-5'>
          <Button className='text-medium' color='primary' block onClick={this.chargewallet}>انتقال به درگاه بانکی</Button>
          </FormGroup>
          </Form>
          </Col>
          <Col xl='5' className='bg-light text-dark info-col'>
            <p className='p-3 text-dark'>
            نکات مهم قبل از انجام واریز:
            </p>
            <p className='p-3 text-medium text-dark'>
            حتماً به دقت به آدرس صفحه درگاه بانکی توجه کنید و تنها پس از اطمینان از حضور در سایت‌های سامانه‌ی شاپرک، مشخصات کارت بانکی خود را وارد نمایید.
            </p>
            <p className='p-3 text-medium text-dark'>
            در صفحه درگاه، به دقت مبلغ نمایش داده شده را بررسی کنید.
            </p>

          </Col>

        </Row> }
        </CardBody>
        </Card>

        </>

    </div> }

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
