import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import reportWebVitals from './reportWebVitals';
import './i18n.js'
import './index.css'
import './style/1200.css'
import './style/dark.css'
import './app.css'
import './bootstrap.min.css'
import '@raha.group/persian-fonts/style.css'
import 'bootstrap/dist/css/bootstrap-grid.css'
import 'shabnam-font/dist/font-face.css'
import 'samim-font/dist/font-face.css'
import * as serviceWorker from "./serviceWorker";
import ReactPWAInstallProvider from "react-pwa-install";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<>
    <App />
    </>
);
reportWebVitals();
serviceWorker.unregister();
