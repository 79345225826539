import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import {
  Card, Button, CardHeader, CardFooter, CardBody,
CardTitle, CardText,Nav,NavLink, Form, FormGroup, Label, Input,Container,Col,Row,Table,List} from 'reactstrap'
import {Helmet} from 'react-helmet'
import {RiFileListLine,RiFileForbidLine} from "react-icons/ri";
import {FaHandHoldingUsd,FaHandHoldingMedical} from "react-icons/fa";
import {BiDotsVertical} from "react-icons/bi";
import SimpleLineIcon from 'react-simple-line-icons'
import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator'
class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      wallet : false,
      walletmessage : true,
      acoounts : 0,
      trx : 0,
      walletname : 'nader.erfan@gmail.com',
      withdraw : [],
      income : [],
      txs : [],
      balance : '',
      coin : '',
      setwithdrawal : false,
      account : []

    }
    this.validator = new SimpleReactValidator();
  }
  componentWillMount(){
    let uuid = cookie.load('uid')
    if(!uuid) {
      this.props.history.push('/')
    }
    this.getdetails()
  }
  getdetails =()=>{
    let id =this.props.match.params.id;

    this.setState({id : id})
    const formdata = new FormData()
    formdata.append('id',id)
    axios.post('/api/Accounts/Get_Account_Info',formdata).then(response=>{
      if(response.data.status === "error-fatal"){
        document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        cookie.remove('uid')
        axios.get('/Users/logout')
        setTimeout(
        () => this.props.history.push('/'),
        1000
      ); }
      this.setState({account : response.data.account,txs : response.data.txs,gate : response.data.gate , coin : response.data.coin.slug})
    })
  }
  withdrawal = ()=>{
    this.setState({setwithdrawal : !this.state.setwithdrawal})
  }
  render(){
    console.log(this.state);
    const { t } = this.props;
    return(
    <>
    <Helmet>
       <title>{t('profile.title')}</title>
    </Helmet>
    <div >
      <div className='panel-container'>
        <div className='panel-container-left fixed'>
          <section className='mt-5 mx-2 text-dark lh-xl text-center'>
          <Card body className="radius shadow">

                <div className='d-flex justify-content-space-between'>
                  <FaHandHoldingUsd size='2em' className='mt-2'/>
                  <h4>{this.state.account.balance}</h4>
                  <BiDotsVertical size='1.2em' className='mt-2'/>
                </div>
                <div className='d-flex justify-content-space-between  mt-2'>
                  <small>{this.state.coin}</small><span className={this.state.coin+'-icon-lg'} />
                </div>
           </Card>
          </section>
          <section className='mt-5 mx-2 text-dark lh-xl'>
            <div className='float-left d-flex justify-content-space-between'>
              <SimpleLineIcon name='chart py-2' color='black' size='large' />
              <h4 className='text-center'>{this.state.income.length}</h4>
              <BiDotsVertical size='1.2em' className='mt-2'/>
            </div>
              <p className='text-center mt-1 mb-0'>{t('total.withdraw.accounts')}</p>
          </section>
          <section className='mt-5 mx-2 text-dark lh-xl'>
            <div className='float-left d-flex justify-content-space-between'>
              <FaHandHoldingMedical size='2rem' color='black'/>
              <h4 className='text-center'>{this.state.withdraw.length}</h4>
              <BiDotsVertical size='1.2em' className='mt-2'/>
            </div>
              <p className='text-center mt-1 mb-0'>{t('income.trx')}</p>
          </section>

        </div>
        <div className='panel-container-right'>
          <div className='content'>
          <Row xs="1" sm="1" md="2" lg='2' xl='4'>
             <Col xl='12' className='m-auto text-center'>



             <Table borderless responsive size='sm' className='ltr' numbered>
             <thead >
             <tr className='font-weight-bold text-medium'>
               <td>
                #
               </td>
               <td>
                ID
               </td>

               <td>
                 Balance
               </td>
               <td>
                 Date
               </td>

               <td onClick={this.gettxdetail} >
                 Address
               </td>
               <td>
                 withdraw
               </td>
               <td>
                 details
               </td>
             </tr>
             </thead>
             <tbody>
             {this.state.txs.map(tx=>
               <tr className='text-small'>
               <td><p className='count'></p></td>
               <td>
                 {tx.invoice}
               </td>
               <td>
                 {tx.balance}
               </td>
               <td>
                 {tx.date}
               </td>
               <td>
                 {tx.dp}
               </td>

               <td>
                 <Button disabled={!tx.balance} data-dp={tx.dp} data-balance={tx.balance} onClick={this.withdrawal} color='info' size='sm'>withdraw</Button>
               </td>
               <td>
                 <Button data-dp={tx.dp} data-balance={tx.balance} onClick={this.withdrawal} color='secondary' size='sm'>details</Button>
               </td>

               </tr>
             )}
             </tbody>
             </Table>

             </Col>
           </Row>
          </div>
        </div>
      </div>
      <div className={this.state.setwithdrawal === true ? 'withdrawal show' : 'withdrawal'}>
      <Form>

      </Form>
      </div>
    </div>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
