import React from 'react';
import Switch from "react-switch";
import Toggle from './toggle';

class DarkModeToggle extends React.Component{
  constructor(props){
    super(props);
    this.state={
      darkMode : false,
    }
}
componentWillMount(){
  const theme = document.body.getAttribute('class')
  if(theme === 'dark'){
    this.setState({darkMode : true})
  }
}
darkMode = ()=>{
//   if ( document.getElementById("root").classList.contains('dark') )
//
// document.getElementById("root").classList.toggle('light');
//
if (this.state.darkMode === false){
    document.body.classList.remove('light');

    document.body.classList.add('dark');
}
if (this.state.darkMode === true){
    document.body.classList.remove('dark');
    document.body.classList.add('light');
}


    // let bodyColor = document.body.className;
  this.setState({
    darkMode : !this.state.darkMode
  })
}


render(){


  return (
    <div className="">
    <Switch
    width={70}
    checked={this.state.darkMode}
    onChange={this.darkMode}
    offColor="#ffc107"
    onColor="#58e2c7"
    uncheckedIcon={
      <span className='rtl text-dark mt-1 text-light ml-2 absolute' >
        ☀
      </span>
    }
    checkedIcon={
      <span className='rtl text-dark mt-1 text-light ml-3 absolute'>
        ☾
      </span>
    }
     />


    </div>
  );
}
};

export default DarkModeToggle;
