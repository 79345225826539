import React, { PureComponent } from 'react';
import {Row,Col,Table,Card,CardBody,Button} from 'reactstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'
import cookie from 'react-cookies'
import Loader from '../../landing/loader'
import {withTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet'
import SimpleLineIcon from 'react-simple-line-icons'
import NumberFormat from 'react-number-format'
import Toast from 'awesome-toast-component'
class Dashboard extends PureComponent{
  constructor(props){
    super(props);
    this.state={
      loggedin : false,
      activetab : 0,
      data : [],
      hubs : [],
      loader : true
//        hubs : [
//             { "name":"signup",
//               "start": 23,
//               "end":23,
//               "reward" :'10~50' ,
//               "currencyname" :'نات کوین ' ,
//               "currency" : "NOT",
//               "details":[
//                 { "stepname" : "signup",
//                   "steptype"  : "signup",
//                   "achived" : true,
//                   "reward" : 10 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "kyc",
//                   "steptype"  : "kyc",
//                   "achived" : false,
//                   "reward" : 10 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "deposit",
//                   "steptype"  : "deposit",
//                   "amount" : "10000000",
//                   "reward" : 10 ,
//                   "type" : "rial",
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "hold",
//                   "steptype"  : "hold",
//                   "amount" : "5",
//                   "type" : "day",
//                   "reward" : 20 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 }
//               ]},
//             { "name":"signup",
//               "start":33 ,
//               "end":23,
//               "reward" :'10~50' ,
//               "currencyname" :'نات کوین ' ,
//               "currency" : "NOT",
//               "details":[
//                 { "stepname" : "signup",
//                   "steptype"  : "signup",
//                   "achived" : true,
//                   "reward" : 10 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "kyc",
//                   "steptype"  : "kyc",
//                   "achived" : false,
//                   "reward" : 10 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "deposit",
//                   "steptype"  : "deposit",
//                   "amount" : "10000000",
//                   "reward" : 10 ,
//                   "type" : "rial",
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "hold",
//                   "steptype"  : "hold",
//                   "amount" : "5",
//                   "type" : "day",
//                   "reward" : 20 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 }
//               ]},
//             { "name":"signup",
//               "start": 23,
//               "end":23,
//               "reward" :'10~50' ,
//               "currencyname" :'نات کوین ' ,
//               "currency" : "NOT",
//               "details":[
//                 { "stepname" : "signup",
//                   "steptype"  : "signup",
//                   "achived" : true,
//                   "reward" : 10 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "kyc",
//                   "steptype"  : "kyc",
//                   "achived" : false,
//                   "reward" : 10 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "deposit",
//                   "steptype"  : "deposit",
//                   "amount" : "10000000",
//                   "reward" : 10 ,
//                   "type" : "rial",
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "hold",
//                   "steptype"  : "hold",
//                   "amount" : "5",
//                   "type" : "day",
//                   "reward" : 20 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 }
//               ]},
//             { "name":"signup",
//               "start": 23,
//               "end":23,
//               "reward" :'10~50' ,
//               "currencyname" :'نات کوین ' ,
//               "currency" : "NOT",
//               "details":[
//                 { "stepname" : "signup",
//                   "steptype"  : "signup",
//                   "achived" : true,
//                   "reward" : 10 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "kyc",
//                   "steptype"  : "kyc",
//                   "achived" : false,
//                   "reward" : 10 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "deposit",
//                   "steptype"  : "deposit",
//                   "amount" : "10000000",
//                   "reward" : 10 ,
//                   "type" : "rial",
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 },
//                 { "stepname" : "hold",
//                   "steptype"  : "hold",
//                   "amount" : "5",
//                   "type" : "day",
//                   "reward" : 20 ,
//                   "currencyname" :'نات کوین ' ,
//                   "currency" : "NOT"
//                 }
//               ]},
//
// ],
    }
  }
  gethubdata = ()=>{
    axios.get('/api/dashboard/rewards_hub').then(res=>{
      this.setState({hubs : res.data.data , status : res.data.campaign_status , loader : false})
      // console.log(JSON.parse(res.data.data[0].details));
    })
  }
  generateData() {
      const data = [];
      const keys =this.state.list
if(keys === undefined || keys.length === 0){
  return
}
      keys.forEach( (key , index)=> {
        data.push({
          name : key.slug,
          color : key.color,
          balance: parseFloat(key.availablebalance) * parseFloat(key.price),
          availablebalance: key.availablebalance,
          value: key.availablebalance,
        });
      });
      return data;
    }
UNSAFE_componentWillMount(){
  if(!cookie.load('uid')) {
    this.setState({loggedin : false})
  }else{
    this.setState({loggedin : true})
  }
this.gethubdata()

}
setlevel =(i)=>{
  const {t} = this.props
  switch (i) {

    case '1':
        this.setState({userlevel : `${t('level-1')}`})
      break;
    case '2':
        this.setState({userlevel : `${t('level-2')}`})
      break;
    case '4':
        this.setState({userlevel : `${t('level-4')}`})
      break;
    default:
    this.setState({userlevel : `${t('level-0')}`})
  }
}
forwradexchangeirr = ()=>{
  this.props.history.push('/panel/exchange/buy/IRR')
}
forwradexchange = ()=>{
  this.props.history.push('/panel/exchange/buy/USDT')
}
setShowassets =()=>{
  this.setState({showassets : !this.state.showassets})
}
toggledropdown =()=>{
  this.setState({dropdownOpen : !this.state.dropdownOpen})
}
toggleasset =(i)=>{
  this.setState({assetcurrency : i})
  this.toggledropdown()
}
forwardmarket =(i)=>{
  if(i.baseCurrency === 'IRR' || i.baseCurrency === 'PERFECT' || i.baseCurrency === 'FTN' ){
    return '#'
  }else{
    return '/trade/'+i.baseCurrency+'-USDT'
  }

}
forwardbuysell =(i)=>{

   if(cookie.load('uid')){
  return '/panel/exchange/'+i.baseCurrency
}else{
   return '/auth/login'
}
}
serach1 =(e)=>{
  this.setState({sd1 : e.target.value})
}
serach2 =(e)=>{
  this.setState({sd2 : e.target.value})
}
handleChange = (checked) =>{
    this.setState({ checked : !this.state.checked });
  }
copyusercode=()=>{
  const {t} = this.props
  navigator.clipboard.writeText(this.state.usercode)
  console.log(
    <div dir='rtl'>
      <span className='rtl'>{t('copied !')}</span>
    </div>,
  );
}
levelmodal =()=>{
  this.setState({lm : !this.state.lm})
}
setcharttype =(type)=>{
  this.setState({chartmode : type})
}
check_status = ()=>{
  if(!cookie.load('uid')){
    return
  }
  let i = this.state.activetab
  const {t} = this.props
  switch (i) {
    case 0:
      if(this.state.status.c1 === true && this.state.status.c2 === true && this.state.status.c3 === true && this.state.status.c4 === true){
        axios.get('/api/dashboard/set_campaign_reawrd/' + this.state.activetab).then(res=>{
          if(res.data.status === 'error'){
            new Toast(`${t(res.data.message)}`, {
              style: {
                  container: [
                      ['background-color', 'blue']
                  ],
                  message: [
                      ['color', '#eee'],
                  ],
                  bold: [
                      ['font-weight', '900'],
                  ]
              },
              timeout : 3000,
                position : 'top'
          })
        }else{
          new Toast(`${t(res.data.message)}`, {
            style: {
                container: [
                    ['background-color', 'green']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
              position : 'top'
        })
        }
        })
      }else{
        new Toast('شما شرایط مورد نظر را  ندارید', {
          style: {
              container: [
                  ['background-color', 'green']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
            position : 'top'
      })
        return
      }
      break;
    case 1:
    console.log(this.state);
      if(this.state.status.c1 === true && this.state.status.c2 === true && this.state.status.c7 === true && this.state.status.c4 === true && this.state.status.c8 === true){
        axios.get('/api/dashboard/set_campaign_reawrd/' + this.state.activetab).then(res=>{
          if(res.data.status === 'error'){
            new Toast(`${t(res.data.message)}`, {
              style: {
                  container: [
                      ['background-color', 'blue']
                  ],
                  message: [
                      ['color', '#eee'],
                  ],
                  bold: [
                      ['font-weight', '900'],
                  ]
              },
              timeout : 3000,
                position : 'top'
          })
        }else{
          new Toast(`${t(res.data.message)}`, {
            style: {
                container: [
                    ['background-color', 'green']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
              position : 'top'
        })
        }
        })
      }else{
        new Toast('شما شرایط مورد نظر را  ندارید', {
          style: {
              container: [
                  ['background-color', 'green']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
            position : 'top'
      })
        return
      }
    case 2:
      if(this.state.status.c5 === true){
        axios.get('/api/dashboard/set_campaign_reawrd/' + this.state.activetab).then(res=>{
          if(res.data.status === 'error'){
            new Toast(`${t(res.data.message)}`, {
              style: {
                  container: [
                      ['background-color', 'blue']
                  ],
                  message: [
                      ['color', '#eee'],
                  ],
                  bold: [
                      ['font-weight', '900'],
                  ]
              },
              timeout : 3000,
                position : 'top'
          })
        }else{
          new Toast(`${t(res.data.message)}`, {
            style: {
                container: [
                    ['background-color', 'green']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
              position : 'top'
        })
        }
        })
      }else{
        new Toast('شما شرایط مورد نظر را  ندارید', {
          style: {
              container: [
                  ['background-color', 'green']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
            position : 'top'
      })
        return
      }
      break;
    case 3:
      if(this.state.status.c6 === true){
        axios.get('/api/dashboard/set_campaign_reawrd/' + this.state.activetab).then(res=>{
          if(res.data.status === 'error'){
            new Toast(`${t(res.data.message)}`, {
              style: {
                  container: [
                      ['background-color', 'blue']
                  ],
                  message: [
                      ['color', '#eee'],
                  ],
                  bold: [
                      ['font-weight', '900'],
                  ]
              },
              timeout : 3000,
                position : 'top'
          })
        }else{
          new Toast(`${t(res.data.message)}`, {
            style: {
                container: [
                    ['background-color', 'green']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000,
              position : 'top'
        })
        }
        })
      }else{
        new Toast('شما شرایط مورد نظر را  ندارید', {
          style: {
              container: [
                  ['background-color', 'green']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
            position : 'top'
      })
        return
      }
      break;
    default:

  }
}
componentDidMount(){

}
changeassetview = ()=>{
  this.setState({assetview : !this.state.assetview})
}
render(){
  const {t} = this.props
  const hubs = this.state.hubs

  return(
    <>

    <Helmet>
       <title>{t('dashboard-title')}</title>
    </Helmet>
    {this.state.loader === true ?
      <div>
        <div className='loader-home'>
          <h3 className=''><Loader /></h3>
        </div>
      </div>
       :
      <div className='mt-3 rtl pb-5'>
        <div className='d-none d-sm-block'>
        <div className='d-flex rtl'>
      {hubs.map((i,index)=>
        <p onClick={()=>this.setState({activetab : index})} className='rewards-badge ml-3'>{i.name}</p>
      )}
      </div>
        </div>
        <div className='d-block d-sm-none'>
        <div className='rtl'>
      {hubs.map((i,index)=>
        <p onClick={()=>this.setState({activetab : index})} className='w-100 px-2 my-2 py-2  rewards-badge'>{i.name}</p>
      )}
      </div>
        </div>
      <Row xl='1' className={cookie.load('uid') ? 'mt-5 rtl pb-5' : 'container-fluid mt-5 py-5 rtl'} id='dashboard'>

      {hubs.map((i,index)=>
        <>
        {this.state.activetab === index ?
           <Col xl='12' lg='12' md='12' sm='12' xs='12' className='rw-container rtl'>
        <p className='lead'>{t(i.name)}</p>
        {i.rewards.map((r,index)=>
          <p className='mt-3 d-flex'><li>{t('this.step.reward.is :')}</li><p className='lead mx-1'><p>{r.reward} </p></p> <p className={r.currency + '-icon d-block mx-1'} /><p className='mx-1'>{r.currencyname}</p></p>

        )}
        <Row className='mt-4' xl='1' xs='1' sm='1' md='1' lg='1'>
        {i.details.map((p,index)=>
          <Col className='rw-step-container no-gutters'>
          <div className='d-flex'>
            <p>{t('step')}{t(index +1)} </p><p className='mx-1'>:</p> <p className='mx-2'>{t(p.stepname)}</p>
          </div>
          <span className='step-counter'>{t(index+1)}</span>
            <div className={p.status === true ? 'step-reward-status fill' : 'step-reward-status' }>
            </div>
            <div className='mt-4'>
            {p.steptype === 'hold' ? <div className='d-flex'><li></li><p>{t('holddate :')}</p><span className='mx-1'>{p.amount}</span><p className='mx-1'>{t('day')}</p></div> : null }
            {p.steptype === 'deposit' ? <div className='d-flex'><li></li><p>{t('depositamount : ')}</p><span className='mx-1'><NumberFormat value={p.amount} displayType='text' thousandSeparator=',' /></span><p className='mx-1'>{t('rial')}</p></div> : null }
            {p.steptype === 'trade' ? <div className='d-flex'><li></li><p>{t('tradeamount : ')}</p><span className='mx-1'><NumberFormat value={p.amount} displayType='text' thousandSeparator=',' /></span><p className='mx-1'>{t('عدد')}</p></div> : null }
            {p.steptype === 'referal' ? <div className='d-flex'>
            <li></li><p>{t('referalcount : ')}</p><span className='mx-1'><NumberFormat value={p.amount} displayType='text' thousandSeparator=',' /></span><p className='mx-1'>{t('active-trader')}</p>
            </div>
            : null }
            </div>
            <p className='mt-2 mb-2 rtl text-muted d-flex'>{t('amount ')}<NumberFormat value={p.reward} displayType='text' thousandSeparator=',' /> <div className={p.currency + '-icon d-block mx-1'} />{p.currencyname}{t('you-will-achive :')}</p>

            <Link to={p.link} className='step-button active'>{t(p.stepname)}</Link>
          </Col>
        )}

        </Row>
        </Col> : <></> }
        </>
      )}



      <Col xl='12' lg='12' md='12' sm='12' xs='12' className='mt-5'>
      {this.state.activetab === 0 ? <div><Button onClick={this.check_status} color='primary' className='px-4'>{t('check_status_campain')}</Button></div> : null}
      {this.state.activetab === 1 ? <div><Button onClick={this.check_status} color='primary' className='px-4'>{t('check_status_campain')}</Button></div> : null}
      {this.state.activetab === 2 ? <div><Button onClick={this.check_status} color='primary' className='px-4'>{t('check_status_campain')}</Button></div> : null}
      {this.state.activetab === 3 ? <div><Button onClick={this.check_status} color='primary' className='px-4'>{t('check_status_campain')}</Button></div> : null}
      </Col>
      </Row >
    </div>
  }
  </>
  )
}
}
export default withTranslation()(Dashboard)
