import React from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import cookie from 'react-cookies'
import SimpleReactValidator from 'simple-react-validator'
import {useTranslation,withTranslation} from 'react-i18next'
import {Row,Col, Button,Form,FormFeedback,FormGroup,Label,Input,FormText} from 'reactstrap'
import { GoogleLogin } from '@react-oauth/google'
import {withRouter} from 'react-router-dom'
const MySwal = withReactContent(Swal)

class Login extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        logedin : false,
        email : '',
        password : '',
    }
    this.validator = new SimpleReactValidator();
  }



  onChange =(e)=>{
    this.setState({[e.target.name] : e.target.value})
  }
  resetpass =(e)=>{
    e.preventDefault();
    const {t} = this.props
    if (!this.validator.fieldValid('password')) {
      console.log(`${t('enter.valid.password')}`)
      return
    }else{

    }
    if (this.state.password !== this.state.passconf) {
      console.log('رمز عبور و تکرار  برابر نیستند')
      return
    }else{

    }

    var data = new FormData()
    var token = this.props.match.params.token;
    var email = this.props.match.params.email
    data.append('password',this.state.password)
    data.append('passconf',this.state.passconf)
    data.append('email',email)
    data.append('token',token)
    axios.post('/api/auth/Reset_password',data).then(res=>{
      if(res.data.status === 'success'){
          console.log(res.data.message)
          this.props.history.push('/Auth/login')
        }else{
          console.log(res.data.message)
        }

    })
  }
  componentWillMount(){
    let uuid = cookie.load('uid')
    if(uuid) {
      this.props.history.push('/')
    }
  }
  toggle2 =()=>{this.props.history.push('/auth/login')}
  render(){
    const {t} = this.props
    return(
      <div>
      <h4 className='text-center hero-icon-4'>بازیابی رمز عبور</h4>
      <Form onSubmit={this.resetpass} className='form-auth pb-5'>
      <FormGroup>
        <Label for="password">
          رمز عبور
        </Label>
        <Input
        id="password"
        component="input"
        name="password"
        className="rtl"
        type="password"

        onChange={this.onChange}
        value={this.state.password}
        />
        {this.validator.message('password', this.state.password, 'required|min:6')}

      </FormGroup>
      <FormGroup>
        <Label for="passconf">
          تکرار رمز عبور
        </Label>
        <Input
        id="passconf"
        component="input"
        name="passconf"
        className="rtl"
        type="password"

        onChange={this.onChange}
        value={this.state.passconf}
        />
        {this.validator.message('passconf', this.state.passconf, 'required|min:6')}

      </FormGroup>
      <FormGroup className="mt-10">
      <Button color="primary" sm={2} size="md" block>ارسال</Button>
      <FormText className='text-primary ul-li li ul text-small'>در صورت عدم دریافت ایمیل پوشه اسپم را چک کنید</FormText>
      </FormGroup>
      </Form>

      <FormGroup className="mt-10">
      <Button onClick={this.toggle2} color="primary" sm={2} size="md" block>صفحه ورود</Button>
      </FormGroup>
     </div>
    )
  }
}
export default withRouter(withTranslation()(Login))
