import React from 'react'
import axios from 'axios'
import cookie from 'react-cookies'
import {
  FormGroup,Table,CardHeader,Card,CardBody,Row,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
Button, Form, Label,Alert,FormFeedback, FormText,Input,Container,Col,Modal,ModalBody,ModalHeader,ModalFooter,InputGroup, InputGroupText} from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Helmet} from 'react-helmet'
import SimpleLineIcon from 'react-simple-line-icons'
import {Link,withRouter} from 'react-router-dom'
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator'
import NumberFormat  from 'react-number-format';
import { CiCirclePlus } from "react-icons/ci";
import Loader from '../../landing/loader'
import Toast from 'awesome-toast-component'
const MySwal = withReactContent(Swal)
class Wallet extends React.Component {

     constructor(props) {
          super(props);
          const { t } = this.props
          this.state = {
            verified : "",
            code : "",
            button : "ok",
            accounts :[],
            banks :[],
            cardNo : '',
            btn : true,
            loader : true,
            inputotp : true,
            modal : false,
            modalstate : 0,
            fee : '',
            btn2 : false,
            otpbtn : false,
            amount : '',
            wid : '',
            link : "",
            amounterror : false,
            btntxt :  `${t('submit-withdraw')}`,
            btntxt2 :  `${t('last-submit')}`,
          }
          this.validator = new SimpleReactValidator();

      }
      componentWillMount(){
        let uuid = cookie.load('uid')
        if(!uuid) {
          this.props.history.push('/auth/login')
        }
        this.getwallet()
      }
      chekcookie =()=>{
        let uuid = cookie.load('uid')
        if(!uuid) {
          this.props.history.push('/auth/login')
        }
      }
      getwallet =()=>{
        this.chekcookie()
        axios.get('/api/wallet/withdrawcrypto/all').then(response=>{
          if(response.data.status === "error-fatal"){
            document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            cookie.remove('uid')
            axios.get('/Users/logout')
            setTimeout(
            () => this.props.history.push('/auth/login'),
            1000
          );}
          if(response.data.status === 'error'){
            const { t } = this.props
            new Toast(`${t(response.data.message)}`, {
              style: {
                  container: [
                      ['background-color', 'red']
                  ],
                  message: [
                      ['color', '#eee'],
                  ],
                  bold: [
                      ['font-weight', '900'],
                  ]
              },
              timeout : 3000,
              position : 'top'
          })
            this.setState({loader : false,error : true , errormessage : response.data.message})
            return
          }
          this.setState({available : response.data.available,loader : false,list :  response.data.data,auth : response.data.security})
        })
      }
      convertor = (function() {
       var convertor = {
         convert: function(num) {
           if (isNaN(num)) return "";
           num = Math.round(num);
           var yekan = ["یک", "دو", "سه", "چهار", "پنج", "شش", "هفت", "هشت", "نه"];
           var dahgan1 = [
             "ده",
             "یازده",
             "دوازده",
             "سیزده",
             "چهارده",
             "پانزده",
             "شانزده",
             "هفده",
             "هجده",
             "نوزده"
           ];
           var dahgan2 = [
             "بیست",
             "سی",
             "چهل",
             "پنجاه",
             "شصت",
             "هفتاد",
             "هشتاد",
             "نود"
           ];
           var sadgan = [
             "صد",
             "دویست",
             "سیصد",
             "چهارصد",
             "پانصد",
             "ششصد",
             "هفتصد",
             "هشتصد",
             "نهصد"
           ];

           var _num = num.toString();
           var _count = 0;
           var _yekan = "";
           var _dahgan = "";
           var _sadgan = "";
           var _hezargan = "";
           var _hezargan2 = "";
           var _milion = "";
           var _milion2 = "";
           var _miliard = "";
           var _miliard2 = "";
           var _terilion = "";
           var _triliard = "";

           for (var i = _num.length - 1; i >= 0; i--) {
             _count++;

             //-1 => برای اینکه با توجه به موقعیت پیدا میکنم و جون از یک شروع میکنم پس منهای یک میکنم
             // که موقعیت درست رو به من بده
             var _s = parseInt(_num[i]) - 1;
             switch (_count) {
               case 1:
                 if (_s >= 0) _yekan = yekan[_s];
                 break;
               case 2:
                 if (_s >= 0) {
                   if (_s === 0) {
                     // زمانی که عدد کوچکتر از 20 باشد
                     var _d = parseInt(_num[i + 1]);
                     _dahgan = dahgan1[_d];
                     _yekan = "";
                   } else {
                     _dahgan = dahgan2[_s - 1];

                     if (_yekan.trim() !== "") _dahgan += " و ";
                   }
                 }
                 break;
               case 3:
                 //صدگان
                 if (_s >= 0) {
                   _sadgan = sadgan[_s];
                   if (_yekan.trim() !== "" || _dahgan.trim() !== "")
                     _sadgan += " و ";
                 }
                 break;
               case 4:
                 //هزارگان
                 if (_s >= 0) {
                   _hezargan = yekan[_s];
                   if (
                     _yekan.trim() !== "" ||
                     _dahgan.trim() !== "" ||
                     _sadgan.trim() !== ""
                   )
                     _hezargan += " هزار و ";
                   else _hezargan += " هزار ";
                 }
                 break;
               case 5:
                 if (_s >= 0) {
                   var _d = parseInt(_num[i + 1]);
                   if (_s === 0) {
                     // زمانی که عدد کوچکتر از 20 باشد
                     _hezargan = dahgan1[_d] + " هزار ";
                     // _yekan = '';
                   } else {
                     if (yekan[_d - 1] == undefined)
                       _hezargan = dahgan2[_s - 1] + " هزار ";
                     else
                       _hezargan =
                         dahgan2[_s - 1] + " و " + yekan[_d - 1] + " هزار ";
                   }

                   if (
                     _yekan.trim() !== "" ||
                     _dahgan.trim() !== "" ||
                     _sadgan.trim() !== ""
                   )
                     _hezargan += " و ";
                 }
                 break;
               case 6:
                 if (_s >= 0) {
                   _hezargan2 = sadgan[_s];
                   if (_hezargan.trim() !== "") _hezargan2 += " و ";
                   else {
                     _hezargan2 += " هزار ";
                     if (
                       _yekan.trim() !== "" ||
                       _dahgan.trim() !== "" ||
                       _sadgan.trim() !== ""
                     )
                       _hezargan2 += " و ";
                   }
                 }
                 break;
               case 7:
                 //میلیون
                 if (_s >= 0) {
                   _milion = yekan[_s] + " میلیون ";
                   if (
                     _yekan.trim() !== "" ||
                     _dahgan.trim() !== "" ||
                     _sadgan.trim() !== "" ||
                     _hezargan.trim() !== "" ||
                     _hezargan2.trim() !== ""
                   )
                     _milion += " و ";
                 }
                 break;
               case 8:
                 if (_s >= 0) {
                   var _d = parseInt(_num[i + 1]);
                   if (_s === 0) {
                     // زمانی که عدد کوچکتر از 20 باشد
                     _milion = dahgan1[_d] + " میلیون ";
                     // _yekan = '';
                   } else {
                     if (yekan[_d - 1] === undefined)
                       _milion = dahgan2[_s - 1] + " میلیون ";
                     else
                       _milion =
                         dahgan2[_s - 1] + " و " + yekan[_d - 1] + " میلیون ";
                   }

                   if (
                     _yekan.trim() !== "" ||
                     _dahgan.trim() !== "" ||
                     _sadgan.trim() !== "" ||
                     _hezargan.trim() !== "" ||
                     _hezargan2.trim() !== ""
                   )
                     _milion += " و ";
                 }
                 break;
               case 9:
                 //میلیون
                 if (_s >= 0) {
                   _milion2 = sadgan[_s];
                   if (_milion.trim() !== "") _milion2 += " و ";
                   else {
                     _milion2 += " میلیون ";
                     if (
                       _yekan.trim() !== "" ||
                       _dahgan.trim() !== "" ||
                       _sadgan.trim() !== ""
                     )
                       _milion2 += "و ";
                   }
                 }
                 break;
               case 10:
                 //میلیارد
                 if (_s >= 0) {
                   _miliard = yekan[_s] + " میلیارد ";
                   if (
                     _yekan.trim() !== "" ||
                     _dahgan.trim() !== "" ||
                     _sadgan.trim() !== "" ||
                     _hezargan.trim() !== "" ||
                     _hezargan2.trim() !== "" ||
                     _milion.trim() !== "" ||
                     _milion2.trim() !== ""
                   )
                     _miliard += "و ";
                 }
                 break;
               case 11:
                 if (_s >= 0) {
                   var _d = parseInt(_num[i + 1]);
                   if (_s === 0) {
                     // زمانی که عدد کوچکتر از 20 باشد
                     _miliard = dahgan1[_d] + " میلیارد ";
                   } else {
                     if (yekan[_d - 1] == undefined)
                       _miliard = dahgan2[_s - 1] + " میلیارد ";
                     else
                       _miliard =
                         dahgan2[_s - 1] + " و " + yekan[_d - 1] + " میلیارد ";
                   }

                   if (
                     _yekan.trim() !== "" ||
                     _dahgan.trim() !== "" ||
                     _sadgan.trim() !== "" ||
                     _hezargan.trim() !== "" ||
                     _hezargan2.trim() !== "" ||
                     _milion.trim() !== "" ||
                     _milion2.trim() !== ""
                   )
                     _miliard += "و ";
                 }
                 break;
               case 12:
                 //میلیون
                 if (_s >= 0) {
                   _miliard2 = sadgan[_s];
                   if (_miliard.trim() !== "") _miliard2 += " و ";
                   else {
                     _miliard2 += " میلیارد ";
                     if (
                       _yekan.trim() !== "" ||
                       _dahgan.trim() !== "" ||
                       _sadgan.trim() !== ""
                     )
                       _miliard2 += "و ";
                   }
                 }
                 break;
               default:
                 break;
             }
           }

           return (
             _miliard2 +
             _miliard +
             _milion2 +
             _milion +
             _hezargan2 +
             _hezargan +
             _sadgan +
             _dahgan +
             _yekan +
             ' '+
             'تومان'
           );
         }
       };

       return convertor;
     })();
withdraw =(e)=>{
  e.preventDefault();
  const { t } = this.props
  const formData = new FormData();
  formData.append('amount', this.state.amount)
  formData.append('shaba', this.state.bank.accountNo)
  formData.append('cardNo', this.state.bank.shaba)
  formData.append('fee', this.state.fee)
  formData.append('id', this.state.wid)
  formData.append('otp', this.state.otp)
  formData.append('volume', this.state.volume)
  formData.append('p', this.state.p)
  this.setState({btn2 : false , btntxt2  : `${t('in progress')}`})

  axios.post('/api/Withdrawal/withdraw_irr' , formData).then(response=>{

    this.setState({btn2 : true  , wid : ' ' ,btntxt2 : `${t('last-submit')}` ,showalert : false  , message : "" , otp : '', otpbtn : true , inputotp : ''})
    if(response.data.type === 'fatal-error'){
      return
    }
    if(response.data.status === 'error'){
      new Toast(`${t(response.data.message)}`, {
        style: {
            container: [
                ['background-color', 'red']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
      this.setState({showalert : true ,status : response.data.status , errormessage : response.data.message,otp : '', otpbtn : true, inputotp : ''})
    }
    if(response.data.status === 'success'){
      new Toast(`${t(response.data.message)}`, {
        style: {
            container: [
                ['background-color', 'green']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
      // this.setState({amount :  ,})
      this.update()
      this.setState({link : response.data.url, modalstate  : 2 , wid : response.data.id ,showalert : true ,status : response.data.status , message : response.data.message , otp : '', otpbtn : true , inputotp : ''})
    }
  })

}
onChange = (e) => {
      this.setState({
           [e.target.name]: e.value
      })
 }
send_otp = (e) => {
  // console.log(this.state);
  // return
       this.check()
       const {t} = this.props
      e.preventDefault();
      // if(this.state.amount){
      //   console.log('مبلغ برداشت را وارد کنید')
      //   return
      // }
      if (!this.validator.fieldValid('amount')) {
        new Toast(`${t('enter.valid.amount')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
        this.setState({errormessage : `${t('enter.valid.amount')}`})
        return
      }else{

      }
      if(parseInt(this.state.volume) > parseInt(this.state.balance)){
        new Toast(`${t('balance.is.low')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
        this.setState({errormessage : `${t('balance.is.low')}`})
        return
      }
      if(this.state.amount < 500000){
        new Toast(`حداقل موجودی برای برداشت 50,000 تومان است`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
        this.setState({errormessage : 'حداقل موجودی برای برداشت 100000 تومان میباشد'})
        return
      }
      if(this.state.volume > this.state.available){
        new Toast(`${t('maximum.available.withdraw')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
        this.setState({errormessage : `${t('maximum.available.withdraw')}`})
        return
      }

      if (!this.validator.fieldValid('amount')) {
        new Toast(`${t('no.valid.amount')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
        this.setState({errormessage : `${t('no.valid.amount')}`})
        return
      }else{

      }
      if (this.state.bankd !== true) {
        new Toast(`${t('no.valid.bank')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
        console.log(`${t('no.valid.bank')}`)
        return
      }else{

      }
      const formData = new FormData();
      formData.append('amount', this.state.amount)
      formData.append('cardNo', this.state.bank.accountNo)
      formData.append('shaba', this.state.bank.shaba)
      formData.append('fee', this.state.fee)
      formData.append('p', this.state.p)
      this.setState({btn : false})
      axios.post('/api/Withdrawal/get_otp', formData).then(response => {
        this.setState({btn : true})

        if(response.data.type === 'fatal-error'){
          return
        }
        if(response.data.status === 'error'){
          this.setState({showalert : true ,status : response.data.status ,alert : 'error', alertmessage : response.data.message})
        }
        if(response.data.status === 'success'){

          // this.setState({amount :  ,})
          // this.update()
          this.setState({inputotp : false ,otpbtn : true , wid : response.data.withdrawalid ,showalert : true ,alert : 'success' ,status : response.data.status , alertmessage : response.data.message})
        }
      })
    }

  check = ()=>{
    if(!cookie.load('uid')){
      this.props.history.push('/')
    }
  }
  componentDidMount(){

    this.update()
     }
  update =()=>{
       this.check()
       const formData = new FormData();
       formData.append('uid' , cookie.load('uid'))
       axios.post('/api/Finance/get_bank_accounts',formData).then(response=>{
      var data = response.data
      if(data.status === 'success'){
        this.setState({banks : data.data,balance : response.data.balance ,message : ''})
      }else{
        this.setState({message : response.data.message,bm : data.message})
      }
           }
         )
       }
setbankacount =(item)=>{
  // console.log(this.state.bank);
  this.setState({bankd : true ,bank : item , cardNo : item.accountNo})
}
activmodal =()=>{
    const { t } = this.props
  if (!this.validator.fieldValid('amount')) {
    new Toast(`${t('irr.input.amount.error')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
    this.setState({amounterror : true , amounterrormessage : `${t('input.amount.error')}`})
    return
  }else{

  }
  if (this.state.amount > this.state.balance) {
    new Toast(`${t('irr.input.amount.error.2')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
    this.setState({amounterror : true , amounterrormessage : `${t('irr.input.amount.error.3')}`})
    return
  }else{

  }
  if(this.state.amount < 500000){
    new Toast(`${t('irr.input.amount.error.4')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000 , position : 'top'
  })
    this.setState({amounterror : true , amounterrormessage : `${t('irr.input.amount.error.4')}`})
    return
  }
  if(this.state.amount > this.state.available){
    new Toast(`${t('maximum.available.withdraw')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
  this.setState({amounterror : true , amounterrormessage  : `${t('maximum.available.withdraw')}`})
  return
}
  if(this.state.amount > 1000000000){
    new Toast(`${t('input.amount.maximum.limit')}`, {
      style: {
          container: [
              ['background-color', 'red']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000,
      position : 'top'
  })
    this.setState({amounterror : true , amounterrormessage : `${t('input.amount.maximum.limit')}`})
    return
  }

  this.setState({modal : !this.state.modal , modalstate : 1 , otpbtn : false})
}
// getotp =()=>{
//   axios.get('/api/Withdrawal/get_otp')
// }
setotp =(e)=>{
  let value = e.value

  this.setState({otp : e.value})
  if(value.length  === 4){
    this.setState({btn2 : true})
    return
  }
}
setamount =(e)=>{
  const { t } = this.props
  let value = parseInt(e.value)
  let fee = this.fee(value)
  this.setState({errormessage : '' , amount : value , fee : fee , volume : value + fee})

}
fee =(x)=>{
  let fee
  let p
  if(x < 10000001){
    fee =  30000
    p = 8000
  }
  if(x > 10000001 && x < 1000000000){
    fee =  x * 0.0030
    p = x * 0.0008
  }
  if(fee  > 200000){
    fee =  200000
    p = 50000
  }
  this.setState({p : p})
  return fee
}
setlogo =(i)=>{
  var number = i.substring(6,-16)
  console.log(number);
  if(number === '603799'){return "https://rapitex.com/images/logo/banks/meli.png"}
  if(number === '589210'){return "https://rapitex.com/images/logo/banks/sepah.png"}
  if(number === '627961'){return "https://rapitex.com/images/logo/banks/sanatmadan.png"}
  if(number === '603770'){return "https://rapitex.com/images/logo/banks/keshavarsi.png"}
  if(number === '628023'){return "https://rapitex.com/images/logo/banks/maskan.png"}
  if(number === '627760'){return "https://rapitex.com/images/logo/banks/postbank.png"}
  if(number === '502908'){return "https://rapitex.com/images/logo/banks/tosehe.png"}
  if(number === '627412'){return "https://rapitex.com/images/logo/banks/eghtesad.png"}
  if(number === '622106'){return "https://rapitex.com/images/logo/banks/parsian.png"}
  if(number === '502229'){return "https://rapitex.com/images/logo/banks/pasargad.png"}
  if(number === '627488'){return "https://rapitex.com/images/logo/banks/karafarin.png"}
  if(number === '621986'){return "https://rapitex.com/images/logo/banks/saman.png"}
  if(number === '639346'){return "https://rapitex.com/images/logo/banks/sina.png"}
  if(number === '639607'){return "https://rapitex.com/images/logo/banks/sarmaye.png"}
  if(number === '502806'){return "https://rapitex.com/images/logo/banks/shahr.png"}
  if(number === '502938'){return "https://rapitex.com/images/logo/banks/day.png"}
  if(number === '603769'){return "https://rapitex.com/images/logo/banks/saderat.png"}
  if(number === '610433'){return "https://rapitex.com/images/logo/banks/mellat.png"}
  if(number === '627353'){return "https://rapitex.com/images/logo/banks/tejarat.png"}
  if(number === '589463'){return "https://rapitex.com/images/logo/banks/refah.png"}
  if(number === '627381'){return "https://rapitex.com/images/logo/banks/sepah.png"}
  if(number === '639370'){return "https://rapitex.com/images/logo/banks/mehreqtesad.png"}
  if(number === '639599'){return "https://rapitex.com/images/logo/banks/sepah.png"}
  if(number === '504172'){return "https://rapitex.com/images/logo/banks/resalat.png"}
}
  render(){

    var x = this.convertor.convert(this.state.balance /10)
    var y = this.convertor.convert(this.state.amount /10)
    const { t } = this.props
    const balance  = <NumberFormat value={parseInt(this.state.balance)}  thousandSeparator="," displayType="text"  className='font-weight-bold' />
    return(
    <>
    <Helmet>
       <title>برداشت ریالی | رپیتکس</title>
    </Helmet>
    {this.state.loader === true ?
      <>
        <div className='loader-home bg-white py-5'>
          <h3 className='text-center bg-white m-auto mt-auto'><Loader /></h3>
        </div>
      </> :
<div className='pb-5 mt-3 rtl'>


  <Card className=''>
  <CardHeader>
    <div className='d-flex rtl'>
     <p onClick={()=>this.setState({type : 1})} className={this.state.type === 1 ? 'pointer' : 'pointer' }>برداشت  ریالی</p>
    </div>
  </CardHeader>
    <CardBody className='h-400'>
      {this.state.error === true ?
  <Alert color="info" className='rtl mt-3 mx-3'>
    <p><span>{this.state.errormessage}.</span><Link className='mx-1' to='/panel/profile' >{t('click-to-verify')}</Link></p>
  </Alert> : <>
<Row>
  <Col xl={{size:7}} className='m-auto py-2'>
  {this.state.modalstate === 2 ?

    <div className='p-3'>
      <p className='text-medium rtl alert alert-success'>{t('payment.was-successful')}</p>
      <div className='d-flex rtl my-5'>
      <p className='text-medium rtl ml-auto '>{t('your.paymentlink')}</p>
      <a href={this.state.link} target="_blank" className='text-medium mr-auto ltr'>{this.state.link}</a>
    </div>
    </div>
   : <></> }
  {this.state.modalstate === 1 ?
    <Row  className='rtl text-medium p-3'>
      <Col>
      <p className='lead rtl mt-2 mb-3'>{t('withdrawal-irt-details :')}</p>
      {this.state.showalert ===true ?
      <Alert color={this.state.alert} >
      {this.state.alertmessage}
      </Alert>

    :<></>}
      <Table size='small' borderless>
      <thead>

      </thead>
      <tbody className='lh-xl'>
          <tr className='text-medium'>
            <td className=' py-3'>مبلغ برداشت
            </td>

            <td>
            </td>
            <td className='ltr'>
          {t('rial  ')}  <NumberFormat value={this.state.amount} displayType={'text'} thousandSeparator=","  />
            </td>
          </tr>

        <tr className='text-medium'>
            <td>{t('to-card')}
            </td>
            <td>
            </td>
            <td className='ltr font-weight-bold'><NumberFormat value={this.state.cardNo} displayType={'text'} format="####    ####    ####    ####"  />

            </td>
          </tr>

        </tbody>
        </Table>
        <Form>
          <InputGroup size='sm' className='rtl'>
            <Button
              color='info'
              onClick={this.send_otp}
              disabled ={this.state.otpbtn}
              className='custom-input-button text-white-fixed'>
              {t('send-otp')}
            </Button>
             <NumberFormat
             disabled={this.state.inputotp}
             inputMode="decimal"
             className='form-control ltr pl-3'
             format="# # # #"
             onValueChange = {(values)=>this.setotp(values)}
             displayType="input"
              value={this.state.otp}/>
             {this.validator.message('otp', this.state.otp, 'required|size:4|numeric')}
           </InputGroup>
        </Form>
         <Button onClick={this.withdraw} disabled = {!this.state.btn2}   color='primary' className ="mt-4 px-5">{this.state.btntxt2}</Button>
      </Col>
    </Row> : <></>}

    {this.state.modalstate === 0 ?
      <div className="rtl px-3">
        <Form>
        <FormGroup>
          <Row className='info-main pb-3'>
          <Col xl='12' lg='12' md='12' sm='12' xs='12' className='my-4 rtl text-medium c-i font-weight-bold'>برداشت  ریالی</Col>
          <Col xl='12' lg='12' md='12' sm='12' xs='12' className='rtl mt-3 text-medium c-i inline-space d-flex justify-content-space-around mr-auto'>موجودی : <small className='font-weight-bold text-medium mr-auto '> {balance}</small> <SimpleLineIcon name='refresh mx-2 pointer' color='indigo'  onClick={this.getwallet} /></Col>
          </Row>
        </FormGroup>
          <FormGroup>
            <Label className='required text-medium' for="name">مبلغ برداشت</Label>
            <NumberFormat invalid={this.state.amounterror} inputMode="decimal" className={this.state.amounterror === true ? "form-control ltr":'form-control ltr' } value={this.state.amount} thousandSeparator=',' min='0'  onValueChange={(values, sourceInfo) => {
              this.setamount(values)
              }} name="amount" />

            {this.validator.message('amount', this.state.amount, 'required')}
            <div className='px-1 d-flex text-muted text-medium'>

              <FormText color={this.state.ne === true ? 'warning' : 'muted' } >{this.state.amounterror === true ? this.state.amounterrormessage : y}

              </FormText>
              {this.state.amount > 0 ?
              <FormText className='text-muted pt-2 rtl mr-auto' >
              <li className='text-muted pt-2 rtl mr-auto' >
              کارمزد :  <NumberFormat value={this.state.fee} thousandSeparator="," displayType="text" suffix={t('irr')}  className='font-weight-bold' />
              </li>
              </FormText> : <></>}
              </div>
            </FormGroup>
          <FormGroup>
    <Label className='text-medium' for="name">انتخاب حساب *</Label>
    <UncontrolledDropdown className='bg-light ticker-list-2 m-auto'>
      <DropdownToggle color='light' className='py-3' block>
  {this.state.bankd !== true ? <span className='my-auto text-medium'>یک حساب بانکی انتخاب کنید</span> :
    <div className='d-flex text-medium justify-content-space-between px-3'>
    <span><img width='30px' src={this.setlogo(this.state.bank.accountNo)} /></span>
      <span className='my-auto mr-3'>{this.state.bank.bankname}</span>
      <NumberFormat className='my-auto text-medium mr-auto ltr' value={this.state.cardNo} displayType={'text'} format="####    ####    ####    ####"  />
    </div>
  }
      </DropdownToggle>
      <DropdownMenu className='bg-white w-100 modifier'>
      {this.state.banks.map(item=>
        <>
          <DropdownItem onClick={()=>this.setbankacount(item)}>
          <div className='d-flex text-dark'>
            <span><img width='30px' src={this.setlogo(item.accountNo)} /></span>
            <span className='my-auto mr-5 rtl text-muted text-medium'>{item.bankname}</span>
            <NumberFormat className='my-auto mr-auto text-medium text-muted mr-5 ltr' value={item.accountNo} displayType={'text'} format="####    ####    ####    ####"  />
          </div>
          </DropdownItem>
        </>
      )}
      <DropdownItem>
      <Link to='/panel/submit_bank_account' className='py-3 d-flex justify-content-space-between'>
        <span className='text-muted text-medium'>{t('add-bank-account')}</span>
        <span className='mr-3 text-muted'><CiCirclePlus size='1.2rem' color='success'/></span>
      </Link>
      </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    <FormText></FormText>
    </FormGroup>
    <FormGroup>
    <Button  onClick={this.activmodal}  color='primary' className ="px-5 py-2 mx-auto mt-5">{this.state.btntxt}</Button>
    </FormGroup>
  </Form>
      </div> : <></>}
  </Col>
  <Col xl='5' className='info-col'>
    <p className='p-3 mt-3'>
    نکات مهم قبل از انجام برداشت :
    </p>
    <li className='p-3 text-medium'>
      {t('wirrnote1')}
    </li>
    <li className='p-3 text-medium'>
      {t('wirrnote2')}
    </li>
    <li className='p-3 text-medium'>
      {t('wirrnote3')}
    </li>
  </Col>
</Row>
            </>}
    </CardBody>
    </Card>
  </div> }
  <Modal  className="borderless mt-10">
    <ModalBody>
    {this.state.modalstate === 2 ?

      <div className='p-3'>
        <p className='text-medium rtl alert alert-success'>{t('payment.was-successful')}</p>
        <div className='d-flex rtl my-5'>
        <p className='text-medium rtl ml-auto '>{t('your.paymentlink')}</p>
        <a href={this.state.link} target="_blank" className='text-medium mr-auto ltr'>{this.state.link}</a>
      </div>
      </div>
     : <></> }
    {this.state.modalstate === 1 ?
      <Row  className='rtl text-medium p-3'>
        <Col className='lh-xl'>
        <p className='lead rtl mt-2 mb-3'>{t('withdrawal-irt-details :')}</p>
        {this.state.showalert ===true ?
        <Alert color={this.state.alert} >
        {this.state.alertmessage}
        </Alert>

      :<></>}
        <Table size='sm' borderless>
        <thead>

        </thead>
        <tbody>
            <tr className='text-medium'>
              <td className=' py-3'>مبلغ برداشت
              </td>

              <td>
              </td>
              <td className='ltr font-weight-bold'>
            {t('rial  ')}  <NumberFormat value={this.state.amount} displayType={'text'} thousandSeparator=","  />
              </td>
            </tr>

          <tr className='text-medium'>
              <td>{t('to-card')}
              </td>
              <td>
              </td>
              <td className='ltr font-weight-bold'><NumberFormat value={this.state.cardNo} displayType={'text'} format="####    ####    ####    ####"  />

              </td>
            </tr>

          </tbody>
          </Table>
          <Form>
            <InputGroup size='sm' className='rtl'>
              <Button
                color='info'
                onClick={this.send_otp}
                disabled ={this.state.otpbtn}
                className='custom-input-button text-white-fixed'>
                {t('send-otp')}
              </Button>
               <NumberFormat
               disabled={this.state.inputotp}
               inputMode="decimal"
               className='form-control ltr pl-3'
               format="# # # #"
               onValueChange = {(values)=>this.setotp(values)}
               displayType="input"
                value={this.state.otp}/>
               {this.validator.message('otp', this.state.otp, 'required|size:4|numeric')}
             </InputGroup>
          </Form>
           <Button onClick={this.withdraw} disabled = {!this.state.btn2} block outline  color='primary' className ="mt-4">{this.state.btntxt2}</Button>
        </Col>
      </Row> : <></>}

    </ModalBody>
    <ModalFooter className='text-center'>
    <Button outline onClick={()=>this.setState({modal : false})} size='sm' color="dark"className='rtl px-3' >بستن</Button>{' '}


    </ModalFooter>
  </Modal>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
