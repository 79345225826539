import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import {
  Card, Button, CardHeader, CardFooter, CardBody,
CardTitle, CardText,Nav,NavLink, Form, FormGroup, Label, Input,Container,Col,Row,Table,List} from 'reactstrap'
import {Helmet} from 'react-helmet'
import {RiFileListLine,RiFileForbidLine} from "react-icons/ri";
import {FaHandHoldingUsd,FaHandHoldingMedical} from "react-icons/fa";
import {BiDotsVertical} from "react-icons/bi";
import SimpleLineIcon from 'react-simple-line-icons'
import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator'
class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      wallet : false,
      walletmessage : true,
      acoounts : 0,
      trx : 0,
      walletname : 'nader.erfan@gmail.com',
      data : [],
      trx : [],

    }
    this.validator = new SimpleReactValidator();
  }
  componentWillMount(){
    this.getwallet()
  }
  getwallet =()=>{
    axios.get('/api/wallet').then(response=>{

      var data = response.data.data
      if(data.wallet === 'not.found'){
        this.setState({wallet : false})
      }else{
        this.setState({data : data,wallet : true,trx : response.data.trx})
      }

    })
  }
  addwallet = () =>{
    this.setState({walletmessage : false})
    axios.get('/api/wallet/addwallet').then(response=>{
      var data = response.data.data

    })
  }
  deposit=(e)=>{
    var id = e.currentTarget.getAttribute('id')
    var url = '/Panel/deposit/'+id
    this.props.history.push(url)
  }
  render(){
    const { t } = this.props;
    // console.log(this.state.data);
    return(
    <>
    <Helmet>
       <title>{t('profile.title')}</title>
    </Helmet>
    <div className='pt-5' >

          {this.state.wallet === false ? <></> :
          <Row xs="1" sm="1" md="2" lg='2' xl='1'>
             <Col xl='8' className='m-auto text-center'>
             <p className='rtl'>لیست دارایی ها </p>
              <Table className='borderless' responsive borderless>

                  <tbody>
                  {this.state.data.map(list=>
                    <tr id={list.acc_id}>
                    <td className={list.currency +'-icon text-medium'}  >
                    </td>
                    <td>
                      {list.usname}
                    </td>
                    <td>
                      {list.balance}
                    </td>
                    <td>{list.price}</td>
                    <td id={list.acc_id} onClick={this.deposit} className='pointer'><Button size='sm' color='success' >واریز</Button></td>
                    <td id={list.acc_id} onClick={this.withdraw} className=''><Button size='sm' color='danger' >برداشت</Button></td>
                    <td id={list.acc_id} onClick={this.report} className=''><Button size='sm' color='info' >تراکنش ها</Button></td>
                    </tr>)}
                  </tbody>
              </Table>
             </Col>



           </Row>
         }
          </div>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
