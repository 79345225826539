import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import {DropdownItem,
  Card, Button, CardHeader, CardFooter, CardBody,ModalBody,Modal,
ModalHeader,
  ModalFooter,
CardTitle, CardText,Nav,NavLink, Form, FormGroup, Label, Input,Container,Col,Row,Table,List} from 'reactstrap'
import {Helmet} from 'react-helmet'
import {RiFileListLine,RiFileForbidLine} from "react-icons/ri";
import {FaHandHoldingUsd,FaHandHoldingMedical} from "react-icons/fa";
import {BiDotsVertical} from "react-icons/bi";
import {BsApple,BsGooglePlay,BsEyeFill ,BsEyeSlashFill} from "react-icons/bs";
import SimpleLineIcon from 'react-simple-line-icons'
import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator'
import { render } from 'react-dom'
import authdoc from '../../../style/images/authdoc.svg'
import dv from '../../../style/images/dv.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Link} from 'react-router-dom'
import NumericFormat from 'react-number-format';
const MySwal = withReactContent(Swal)

class Profile extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data:[],

    }
    this.validator = new SimpleReactValidator();
  }
backtopanel =()=>{
  this.props.history.push('/panel/dashboard')
  // window.open('https://rapi', "_blank", "noreferrer");
}
  componentWillMount(){

    let id =this.props.match.params.id;
    this.id = id
    this.setState({id : id})
    this.getdata()
  }
  getdata =()=>{
    axios.get('/api/payment/fail/'+this.id).then(response=>{
      if(response.data.status === "error-fatal"){
        // document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        // cookie.remove('uid')
        // axios.get('/Users/logout')
        // setTimeout(
        // () => this.props.history.push('/'),
        // 1000
      // )
      }
      this.setState({data : response.data})
    })

  }
numeric =(i)=>{
  <NumericFormat value={i}  thousandSeparator="," displayType="text"  className='font-weight-bold text-light mx-1' />


}

  render(){
const { t } = this.props;
// console.log(this.state);
    return(
    <>
      <Helmet>
         <title>واریز موفق | رپیتکس</title>
      </Helmet>
      <div className='mt-10' >
      <Row >
        <Col xl={{size:4,offset:4}} className='my-auto'>
          <Card className='rtl mt-3'>
            <CardHeader className='bg-darkblue'>تراکنش ناموفق
            </CardHeader>
              <CardBody>
                <div className='content text-center bg-white py-3'>

                    <Table size='sm' striped borderless>
                      <tbody>
                        <tr>
                          <td>مبلغ تراکنش
                          </td>
                          <td><NumericFormat value={this.state.data.amount}  thousandSeparator="," displayType="text"  className='font-weight-bold text-danger mx-1' />
 ریال
                          </td>
                        </tr>
                        <tr>
                          <td>تاریخ تراکنش :
                          </td>
                          <td>{this.state.data.date}
                          </td>
                        </tr>

                        <tr>
                          <td>وضعیت تراکنش :
                          </td>
                          <td>{this.state.data.status}
                          </td>
                        </tr>
                        <tr>
                          <td>علت لغو
                          </td>
                          <td>{this.state.data.detail}
                          </td>
                        </tr>
                        <tr>
                          <td>کد رهگیری :
                          </td>
                          <td>{this.state.data.transId}
                          </td>
                        </tr>
                        <tr>
                          <td>کارت پرداخت کننده :
                          </td>
                          <td>{this.state.data.CardNo}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                </div>
              </CardBody>
              <CardFooter className='bg-white'>
              <Link to='/panel/dashboard'>
                <Button  block outline color='dark'>
                  بازگشت به پنل کاربری
                </Button>
              </Link>
              </CardFooter>
            </Card>
            </Col>
          </Row>
          </div>

    </>
    )
  }
  }
  export default withTranslation()(Profile)
