import React,{lazy} from 'react'
import {
  BrowserRouter as Router,Switch, Route,Link
} from "react-router-dom";
import Landing from './pages/landing/layer'
import Homepage from './pages/landing/home'
import Fees from './pages/landing/fees'
import Terms from './pages/landing/terms'
import Help from './pages/landing/help'
import About from './pages/landing/about-us'
import Security from './pages/landing/security'
import { TfiClose } from "react-icons/tfi";
import { UserAgent } from "react-useragent";
import Currencies from './pages/landing/currencies'
import Merchant from './pages/landing/merchant'
import Trade from './pages/trade'
import Wallet from './pages/landing/wallet'
import Auth from './pages/auth'
import Pax from './pages/landing/pax'
import Rewards from './pages/landing/rewards'
import Panel from './pages/panel'
import Market from './pages/market'
import Form from './pages/panel/dashboard/form'
import Successpay from './pages/panel/successpayment'
import Failpayment from './pages/panel/successpayment/fail'
import AOS from "aos"
import { CgDarkMode } from "react-icons/cg";
import { GoSun } from "react-icons/go";
import { BsMoonStars } from "react-icons/bs";
import SimpleLineIcon from 'react-simple-line-icons'
import NumberFormat from 'react-number-format'
import {BsGraphUp,BsGraphDown} from "react-icons/bs";
import {AiFillStar,AiOutlineStar} from "react-icons/ai";
import xsicon from './style/images/xs-icon.png'
import {BsArrowLeft} from 'react-icons/bs'
import Usdt from './pages/panel/tetherdark'
import { RiUserSettingsLine } from "react-icons/ri"
import { LuUser2 } from "react-icons/lu";
import ReactDOM from 'react-dom/client';
import logo from './style/images/xs-icon.png'
import { RiTokenSwapLine } from "react-icons/ri";
import {BsApple} from "react-icons/bs";
import {FcGoogle} from "react-icons/fc";
import QRCode from "react-qr-code";
import { PiCopyThin } from "react-icons/pi";
import { BsDownload } from "react-icons/bs";
import { RxShare2 } from "react-icons/rx";
import { VscDiffAdded } from "react-icons/vsc";
import Aos from 'aos'
import Content from './pages/panel/sidebar'
import {isMobile} from 'react-device-detect';
import { slide as Menu } from 'react-burger-menu'
import { BurgerClose as Icon } from "react-icons-animated";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,Table,
  NavbarText,Modal,ModalBody,ModalFooter,ModalHeader,
  Container,Col,Row,DropdownItem,Dropdown,DropdownToggle,DropdownMenu,ButtonDropdown
} from 'reactstrap';
import axios from 'axios'
import cookie from 'react-cookies'
import {AiOutlineStock,AiOutlineClose} from 'react-icons/ai'
import {useTranslation,withTranslation} from 'react-i18next'

import {PiWalletThin,PiSwapThin,PiStackThin,PiListDashesThin} from 'react-icons/pi'

class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isClosed : false,
      notification : false,
      support : false,
      isOpen : false,
      drop1 : false,
      drop2 : false,
      circle : false,
      mobile : false,
      languagedropdown : false,
      showapp : false,
      darkMode : true,
      theme : 'light',
      app : true,

      main : [],
      accounts : [],
      list : [],
      markets : [],
      chartdata1 : [],
      merged : [],
      c : [],
      fav : [{"symbolName":"ANKR-BTC","id":"7","base":"ANKR","quote":"BTC","price":"0.309878","volValue":"1968.5479953079","lastusdt":"0.0000005596","sell":"0.0000005597","changeRate":"-0.0014","favorite":"yes"},{"symbolName":"IRR-USDT","id":"1","base":"IRR","quote":"USDT","price":"1","volValue":null,"lastusdt":"1","sell":"1","changeRate":"0","favorite":"yes"},{"symbolName":"USDT-USDT","id":"2","base":"USDT","quote":"USDT","price":"1","volValue":null,"lastusdt":"","sell":"596770","changeRate":null,"favorite":"yes"},{"symbolName":"FTN-USDT","id":"3","base":"FTN","quote":"USDT","price":"1","volValue":"653280.66155703","lastusdt":"0.000001218","sell":"596770","changeRate":"-0.0751","favorite":"yes"},{"symbolName":"AVA-USDT","id":"4","base":"AVA","quote":"USDT","price":"301929","volValue":"5254536462.9277","lastusdt":"0.545245","sell":"0.5456","changeRate":"-0.0125","favorite":"yes"},{"symbolName":"FET-BTC","id":"5","base":"FET","quote":"BTC","price":"6.86927","volValue":"578253.88590532","lastusdt":"0.000012405","sell":"0.000012409","changeRate":"-0.0192","favorite":"yes"}],
      se : [],
      usdt : [],
      socketedata : [],
      qr : 'https://rapitex.com/download/app.apk' ,
      markets : [],
      messages : [],
      notifs : [],
      nm : false,
      dpo : false,
      ses : false,
      dp1 : false,
      dp2 : false,
      searchTerm : '',
      loggedin : false,
      navbarcl : 'top-navbar',
      navbarclmdlg : 'd-none d-md-block d-xl-none',
    }
    // this.markets =
    // {  'usdt' : {
    //               'date' : '******',
    //               'high' : '******',
    //               'low'  : '******',
    //               'change' :'******',
    //               'price'  :'******',
    //             }}

    this.wsd = []
    this.accounts = []
    this.toggle = this.toggle.bind(this)
  }

setmessages =(data)=>{
  this.setState({messages : data})
}
setIsClosed = ()=>{
  this.setState({isClosed : !this.state.isClosed})
  setTimeout(()=>this.setState({isClosed : !this.state.isClosed}) , 1000)
}

  tgdp1 =()=>{
    this.setState({dp1 : !this.state.dp1})
  }
  hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };
  getstatus =(x)=>{
    const {t} = this.props
    let status
    switch (x) {
      case '300001':
        status =  t('deposit')
        break;
      case '300002':
        status =  t('withdraw')
        break;
      case '300003':
        status =  t('depositirr')
        break;
      case '300004':
        status =  t('withdrawirr')
        break;
      case '300005':
        status =  t('swap')
        break;
      case '300006':
        status =  t('marketorder')
        break;
      case '300007':
        status =  t('advancedorder')
        break;
      case '300008':
        status =  t('open')
        break;
      case '300009':
        status =  t('close')
        break;
      case '300010':
        status =  t('cancel')
        break;
      case '300011':
        status =  t('waiting')
        break;
      case '300012':
          return t('successful')
        break;
      case '300013':
          return t('error')
        break;
      case '300014':
          return t('buy')
        break;
      case '300015':
          return t('sell')
        break;
      case '300016':
          return t('level-0')
        break;
      case '300017':
          return t('level-01')
        break;
      case '300018':
          return t('level-02')
        break;
      case '300019':
          return t('level-03')
        break;
      case '300020':
          return t('vip')
        break;
      case '300021':
          return t('inprogressdeposit')
        break;
      case '300022':
          return t('inprogressdepositcrypto')
        break;
      default:

    }
    return status

  }
  tgdp2 =()=>{
    this.setState({dp2 : !this.state.dp2})
  }
  tgse =()=>{
    this.setState({ses : !this.state.se})
  }
  darkMode =()=>
  {
    if (this.state.darkMode === false){
        document.body.classList.remove('light');

        document.body.classList.add('dark');
        this.setState({theme : 'dark'})
    }
    if (this.state.darkMode === true){
        document.body.classList.remove('dark');
        document.body.classList.add('light');
        this.setState({theme : 'light'})
    }


        // let bodyColor = document.body.className;
      this.setState({
        darkMode : !this.state.darkMode

      })
  }
  searchbar =(e)=>{
    e.preventDefault()
    let value = e.target.value.toUpperCase();
    if(value.length > 2){
      axios.get('/api/search/markets/'+value).then(res=>{
          this.setState({se : res.data})
          if(res.data.length > 0){
            this.setState({ses : true})
          }
      })
    }
  }
  componentDidMount(){

    setInterval(
      () =>cookie.load('uid') ?
        this.setState({loggedin : true }): this.setState({loggedin : false }) ,3000
    )
    window.scroll(0,0)
  }

  toggle = ()=>{
    this.setState({isOpen : !this.state.isOpen})
  }
  togglefasle = ()=>{
    this.setState({isOpen : false})
  }
  showdownloadapp = ()=>{
    this.setState({showapp : !this.state.showapp})
  }
  dpo = ()=>{
    this.setState({dpo : !this.state.dpo})
  }
  mainmodal = ()=>{
    this.setState({mainmodalisopen : !this.state.mainmodalisopen})
  }

  componentWillMount(){
    if(isMobile){
      this.setState({mobile : true})
    }
    var referrer = document.referrer;
    let data = new FormData()
    data.append('referrer',referrer)
    data.append('url',window.location.href)
    data.append('ismobile',isMobile)
    axios.post('/api/useragent',data)
    let fav = localStorage.getItem('favorite');
    if(fav){
      this.setState({fav : JSON.parse(fav)})
    }else{
      localStorage.setItem('favorite',JSON.stringify(this.state.fav))
    }
    const theme = document.body.getAttribute('class')
    if(theme === 'dark'){
      this.setState({darkMode : true , theme : true})
    }
  if(isMobile){
    this.setState({mobile : true})
  }
  }
  componentWillUnmount(){
    clearInterval(this.marketinterval)
  }
  setmarketplace=(i)=>{
    let symbol = i.symbolName
    this.props.history.push('/trade/'+symbol)
  }
  setmarket=(i)=>{
    let symbol = i.symbolName
    this.props.history.push('/trade/'+symbol)
  }
  setfav =(i)=>{
    let symbolName = i.symbolName
    let fav = this.state.fav;
    const found = fav.find(element => element.symbolName === symbolName);
    if(found){
      found.favorite = ''
      var array = [...this.state.fav]; // make a separate copy of the array
      var index = array.indexOf(found)
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({fav: array});
      }
      localStorage.setItem('favorite',JSON.stringify(array))
    }else{
      i.favorite = 'yes'
      const d = []
      d.symbolName = symbolName;
      d.id = i.id
      fav.push(i)
      this.setState({fav : fav})
      localStorage.setItem('favorite',JSON.stringify(fav))
    }
  }
  setusdt = (data)=>{
    this.setState({usdtprice : data})
  }
  setsupport =()=>{
    this.setState({support : !this.state.support})
    var i="YhJ33p"
    let a=window
    let d=document
    var g=d.createElement("script")
    if(this.state.support === false){

      var i="YhJ33p"
      let a=window
      let d=document
      var g=d.createElement("script")
      let  s="https://www.goftino.com/widget/"+i
      let l=localStorage.getItem("goftino_"+i)
      g.src=l?s+"?o="+l:s
      g.async=!0
      d.getElementsByTagName("head")[0].appendChild(g)

      const root = ReactDOM.createRoot(document.getElementById('support'));
      root.render(
          <g/>
      );
    }else{
      const root = ReactDOM.createRoot(document.getElementById('support'));
      d.getElementById('goftino_w').remove()
      root.render(
        <></>
      );
    }

  }
  nmtg =()=>{
    axios.get('/api/messages/get_messages').then(res=>{
      this.setState({messages : res.data.public.concat(res.data.private)})
    })
    this.setState({notification : !this.state.notification})
  }
changelanguage = () =>{

}
openlangdropdown = () =>{
  this.setState({languagedropdown : !this.state.languagedropdown})
}
  render(){

    const closeBtn = <button className="close" onClick={this.nmtg}>&times;</button>;
    const closeBtn2 = <button className="close" onClick={this.showdownloadapp}>&times;</button>;
    const {t} = this.props
    const filteredmarket = this.se
    const filteredmarket2 = filteredmarket
    const messages = this.state.messages
    return(
      <main className={this.state.theme}>

      <div className='top-row'>
        <div className='ltr d-flex top-bar'>
        <div className='icon-round'>
          <img src={xsicon} width='25px' className='user text-dark' />
        </div>
        <div className='topbar-input'>
          <input type='search' autoComplete='off' onChange={this.searchbar} className='w-100' />
        </div>

        <div className='icon-round'>
          <SimpleLineIcon onClick={this.setsupport}  name='earphones'/>
        </div>


        <div onClick={this.nmtg}  className='icon-round' >
          <SimpleLineIcon name='bell' />
        </div>

        <div className='icon-round' onClick={this.darkMode}>
        {this.state.darkMode === true ?
        <GoSun  name='moon' size='1.4em' /> :<BsMoonStars name='sun' color='#000' /> }
        </div>

        </div>

      </div>

      <Router>

      {this.state.isOpen === true ?
        <div className='mobile-menu d-block d-lg-none'>
      <Menu onClose={this.toggle}  isOpen={this.state.isOpen}  className='' width={ 250 } right>
         <Content onClick={this.toggle}  loggedin={this.state.loggedin} />
       </Menu> </div>: <></> }

      <Navbar className='top-navbar-md' color='light'  fixed='top' >
          <Link   className='d-flex ltr' to='/'>
          <h1 className='mr-3 nav-logo-text'>Rapitex</h1>
          <img width='40px' className='mr-3' src={logo} />

          </Link>
          <Link   className='landing-nav mr-5' to='/trade/BTC-USDT'>
            {t('market')}
          </Link>
          <Link   to='/panel/dashboard' className='landing-nav' >{t('Dashboard')}</Link>
          <Link   to='/panel/Exchange/buy/btc' className='landing-nav' >{t('Swap')}</Link>
          <Link   to='/panel/depositirt' className='landing-nav' >{t('Deposit')}</Link>
          <Link   to='/panel/transactions' className='landing-nav' >{t('transactions')}</Link>
          <Link   to='/panel/wallet'  className='landing-nav' >{t('assets')}</Link>
          <Link   to={cookie.load('uid') ? '/panel/rewards' : '/rewards' }  className='landing-nav rewards-badge' >{t('rewards')}</Link>
          {this.state.loggedin == true ?
          <></> : <> <Link  className='landing-nav login-icon px-4 rewards-badge-outline' to='/auth/login'>
            {t('Login')}
            </Link>
            <Link  to='/Auth/signup' className='landing-nav rewards-badge ml-3  px-4'>
        {t('Signup')}
          </Link></> }
          <div className=  {this.state.loggedin == true ? 'e-i icon-navbar ' :'icon-navbar radius' }onClick={this.setsupport}>
            <SimpleLineIcon name='earphones'  />
          </div>
          <div className='radius icon-navbar ' onClick={this.darkMode}>
          {this.state.darkMode === true ?
            <CgDarkMode  size='1.2rem' />:<CgDarkMode size='1.2rem' /> }
          </div>
          <div onClick={this.nmtg}  className='icon-navbar icon-round ml-3' >
            <SimpleLineIcon name='bell' />
          </div>
          <div className='d-none icon-navbar  ml-3' >
          <ButtonDropdown isOpen={this.state.languagedropdown} toggle={this.openlangdropdown}>
            <DropdownToggle tag='span'>
            <SimpleLineIcon name='globe' />
            </DropdownToggle>
            <DropdownMenu className='min-dropdownmenu'>
              <DropdownItem >{t('tr')}</DropdownItem>
              <DropdownItem >{t('ar')}</DropdownItem>
              <DropdownItem>{t('en')}</DropdownItem>
              <DropdownItem>{t('fa')}</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          </div>
          {this.state.loggedin === true ?
          <div className='radius' >
            <Link to='/panel/logout'>
            <SimpleLineIcon name='power' />
          </Link>
          </div>:
            <></>}

      </Navbar>
      <Modal  className='modal-fullscreen' isOpen={this.state.ses}>
        <ModalBody className=''>
        <div className=''>
        <div className='pointer d-flex mt-3'>
          <AiOutlineClose  onClick={this.tgse}/>

        </div>

        <Table  borderless responsive  size='small' className='mt-3 market-table hv-100' >
        <thead>
        </thead>
          <tbody className='rtl text-small'>
          {this.state.se.map(i=>
            <tr onClick={this.tgse} className='pointer rtl'  key={i.id}>

            <td className='ltr icon-market text-dark'>
              <Link to={'/trade/' + i.symbolName} >
                <span className={i.baseCurrency+'-icon d-block float-left small-icon mr-auto '}></span>
                <span className={i.quoteCurrency+'-icon small-icon float-left mr-auto d-block quote-icon-td'}></span>
              </Link>
            </td>

            <td>
              <Link to={'/trade/' + i.symbolName} >
              <p className='text-dark ltr mr-auto'>{i.symbolName}</p>
              </Link>
              </td>

            <td className='ltr'>
              <Link to={'/trade/' + i.symbolName} >
            <small className={parseFloat(i.changeRate) > 0 ? 'text-success d-flex mx-1' : 'text-danger d-flex mx-1' }>
            <span className='text-small mx-1'>{parseFloat(i.changeRate) > 0 ? <BsGraphUp name='arrow-up' size='1rem' color='green' /> : <BsGraphDown name='arrow-down' size='1rem' color='red'/> }</span>
            <span>{i.changeRate}</span>
            </small>
            </Link>
            </td>
            <Link to={'/trade/' + i.symbolName} >
            <td className='text-small rtl d-none d-md-block'>Rapitex.com</td>
            </Link>
            </tr>
          )}
          </tbody>

        </Table>
        </div>
        </ModalBody>
      </Modal>


        <Modal isOpen={this.state.notification}   className='tx-modal'>
        <div onClick={this.nmtg} className='pointer mt-4 mb-4 ml-auto mr-3 float-right'><TfiClose size='1.2rem'/></div>

        <Table size='small' responsive >
        <thead>
        <tr>
            <td><p className='d-inline pl-2' >{t('subject')}</p></td>
            <td>{t('content')}</td>
            <td>{t('date')}</td>
        </tr>
        </thead>
        <tbody>
        {messages.map(i=>
          <tr key={i.id} style={{'line-height' : '50px','border-bottom':'1px solid #88888840'}}>
              <td><p className='d-inline' >{t(i.subject)}</p></td>
              <td>{t(i.content)}</td>
              <td><small>{i.date}</small><small className='ml-2'>{i.time}</small></td>
          </tr>
          )}
          </tbody>
          </Table>
        </Modal>

      <Modal fade={false} toggle={this.nmtg} className='modal-messages' >
        <ModalHeader toggle={this.nmtg} close={closeBtn}>
          {t('notifications')}
        </ModalHeader>
        <ModalBody className='pb-5 rtl'>
        <Table size='small' responsive borderless >
        <thead>
        <tr>
            <td>{t('subject')}</td>
            <td>{t('content')}</td>
            <td>{t('date')}</td>
        </tr>
        </thead>
        <tbody>
        {messages.map(i=>
          <tr key={i.id}>
              <td>{t(i.subject)}</td>
              <td>{t(i.content)}</td>
              <td>{t(i.date)}{t(i.time)}</td>
          </tr>
          )}
          </tbody>
          </Table>
        </ModalBody>
      </Modal>
      <Modal fade={false} toggle={this.showdownloadapp} className='mt-5' isOpen={this.state.showapp}>
        <ModalHeader toggle={this.nmtg} close={closeBtn2}>
        {this.state.app === true ?
           <p>{t('andriod-app')}</p>
            :
            <p>{t('ios-app')}</p> }
        </ModalHeader>

        <ModalBody className='h-400 py-5'>
        {this.state.app === true ?
          <>
          <div className='text-center'>
        <QRCode value={this.state.qr} className='mx-auto mt-4' />
        </div>

        </>

        : <div>
        <p className='lead rtl'>{t('ios-app-note')}</p>
        <div className='mt-3 rtl lh-xl'>
        <div className='rtl d-flex'><RxShare2 className='my-auto' size='1.3rem'/><p className='mx-2'>{t('press-share-button')}</p></div>
        <div className='rtl d-flex'><VscDiffAdded className='my-auto' size='1.3rem'/><p className='mx-2'>{t('add-to-homescreen')}</p></div>
        </div>
        <div className='text-center mt-5'>
        <Button onClick={this.showdownloadapp} color='danger mx-auto py-2 px-5'>{t('ok')}</Button>
        </div>
        </div> }
        <div className='mt-5'>
        <a href={this.state.qr} download >
          <Button onClick={()=>this.setState({app : true})} className='my-3 py-2 ' block color='primary'><FcGoogle/><small className='mx-2'>{t('download-app-text')}</small></Button>
          </a>
          <Button onClick={()=>this.setState({app : false})} className='my-3 py-2 ' block color='primary'><BsApple/><small className='mx-2'>{t('addtohomescreen-app-text')}</small></Button>
        </div>
        </ModalBody>
      </Modal>
       <Switch>


              <Route
                path="/Auth"

                render={props => <Auth  {...props} />}
              />
              <Route
                path="/Panel"
                render={props => <Panel getstatus = {this.getstatus} isOpen={this.state.isOpen} theme={this.state.theme} darkMode={this.state.darkMode} markets={this.markets}  {...props} />}
              />

                <Route
                  path='/success_payment/:id'
                  render={(props) => <Successpay getstatus = {this.getstatus} {...props} />}
                />
                <Route
                  path='/failpayment/:id'
                  render={(props) => <Failpayment {...props} />}
                />
                <Route path='/trade/:symbol' render={props => <Trade getstatus = {this.getstatus} ismobile={this.state.mobile} showdownloadapp={this.showdownloadapp} togglemenu = {this.toggle} togglemenufalse = {this.togglefasle} theme={this.state.theme} darkMode={this.state.darkMode}
                  {...props} />} />
              <Landing>
                <Route path='/'  exact render={props => <Homepage setmessages={this.setmessages} markets={this.markets} {...props} />} />
                <Route path='/rewards' render={props => <Rewards markets={this.state.markets}  {...props} />} />
                <Route path='/fees' render={props => <Fees markets={this.state.markets}  {...props} />} />
                <Route path='/Terms-and-Condition' render={props => <Terms {...props} />} />
                <Route path='/FAQ' render={props => <Help {...props} />} />
                <Route path='/about-us' render={props => <About {...props} />} />
                <Route path='/currencies' render={props => <Currencies markets={this.state.markets}  {...props} />} />
                <Route path='/PAXG_مارکت_طلا_در_صرافی_رپیتکس _PAXG_GOLD' render={props => <Pax {...props} />} />
                <Route path='/Security' render={props => <Security {...props} />} />
              </Landing>
      </Switch>
      <Navbar  className='xs-navbar d-block d-lg-none  text-center rtl'>
        <Link to='#' onClick={this.toggle} className=''>
        <SimpleLineIcon name='menu' color='#fff' />

          <span className='d-block text-tiny text-white'>منو</span>
        </Link>
        <Link onClick={()=>this.setState({isOpen : false})} to='/'><SimpleLineIcon name='home' color='#ffffff' size='1.4rem'  />
          <span className='d-block text-tiny text-white'>{t('home')}</span>
        </Link>
        <Link onClick={()=>this.setState({isOpen : false})} to='/trade/BTC-USDT'><PiSwapThin color='#ffffff' size='1.4rem' className='' />
          <span className='d-block text-tiny text-white'>{t('market')}</span>
        </Link>
        <Link onClick={()=>this.setState({isOpen : false})} to='/Panel/Wallet'><PiWalletThin color='#ffffff' size='1.4rem' className='' />
          <span className='d-block text-tiny text-white'>{t('assets')}</span>
        </Link>
        <Link onClick={()=>this.setState({isOpen : false})} to='/panel/transactions'>
        <SimpleLineIcon name='list' color='#fff' size='1.4' />
          <span className='d-block text-tiny text-white'>{t('transactions')}</span>
        </Link>
       </Navbar>
    </Router>
    </main>
    )
  }
}
export default withTranslation()(App)
