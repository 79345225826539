import React from 'react';
import { Oval } from 'react-loader-spinner'
import logo from '../../style/images/mainlogo.png'
const Example = ({ spin, color }) => (
<>
  <h3><img src={logo} width='36px' /></h3>
    <Oval  height="80"
      width="70"
      radius="9"
      wrapperClass='loader-ring'
      color='#ffbf49' />
    </>
);

export default Example;
