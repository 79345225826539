import React from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import { useTranslation, withTranslation } from 'react-i18next';
import {Row,Col} from 'reactstrap'
import fee from '../../../style/images/pampgold.jpeg'
class About extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }
componentDidMount(){
  window.scroll(0,0)
}

  render(){
      const { t } = this.props;
    return(
      <>
      <Helmet>
        <title>مارکت طلا PAXG | رپیتکس</title>
      </Helmet>
      <div className='h-400 pt-10'>
      <Row xl='1' lg='1' md='1' sm='1' xs='1'>
        <Col className='header-bg-dark'>
        </Col>
        <Col>
          <div className='content-section-1 '>
              <p className='rtl  title-m '>ارز پکس گلد - (PAXG)</p>
              <hr/>
              <Row lg='1' md='1' xl='2' sm='1' xs='1'>
              <Col>
                <div className='text-center'>
                  <img src={fee}  className='mt-5 img-section-sized'/>
                </div>
              </Col>
              <Col className='rtl px-3'>
                <div className='px-3 mt-2 lh-xl rtl'>
                  <li>{t('about.px1')}
                  </li>
                </div>
              </Col>


              </Row>
              <Row>
                <Col>
                  <div className='rtl px-2 mt-5 lh-xl'>
                    <li>{t('about.px2')}
                    </li>
                    <li>{t('about.px3')}
                    </li>
                    <li>{t('about.px5')}
                    </li>
                    <li>{t('about.px6')}
                    </li>
                  </div>
                </Col>
              </Row>
          </div>
        </Col>
      </Row>
</div>
      </>
    )
  }
}
export default withTranslation()(About)
