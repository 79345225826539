import React from 'react'
import axios from 'axios'
import cookie from 'react-cookies'
import SimpleReactValidator from 'simple-react-validator'
import {useTranslation,withTranslation} from 'react-i18next'
import {Row,Col, Button,Form,FormFeedback,FormGroup,Label,Input,FormText} from 'reactstrap'
import { GoogleLogin } from '@react-oauth/google'
import {Link} from 'react-router-dom'
import Toast from 'awesome-toast-component'
class Login extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        logedin : false,
        email : '',
        password : '',
    }
    this.validator = new SimpleReactValidator();

  }



  onChange =(e)=>{
    this.setState({[e.target.name] : e.target.value})
  }
  sendforgotemail =(e)=>{
    const {t} = this.props

    e.preventDefault();
    if (!this.validator.fieldValid('email')) {
      new Toast(`${t('enter.valid.email')}`, {
        style: {
            container: [
                ['background-color', 'red']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
         console.log('ایمیل خود را وارد کنید')
         return
       }else{

       }

    const formdata = new FormData()
    formdata.append('email',this.state.email)
    axios.post('/api/signin/Forgot_password',formdata).then(response=>{
      const {t} = this.props
     let res = response.data
     if(res.status === 'success'){
       new Toast(`${t(res.message)}`, {
         style: {
             container: [
                 ['background-color', 'green']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })
           console.log(res.message)
         this.props.history.push('/Auth/login')
       }else{
         new Toast(`${t(res.message)}`, {
           style: {
               container: [
                   ['background-color', 'red']
               ],
               message: [
                   ['color', '#eee'],
               ],
               bold: [
                   ['font-weight', '900'],
               ]
           },
           timeout : 3000,
           position : 'top'
       })
         console.log(res.message)
       }

    })
  }
  componentWillMount(){
    let uuid = cookie.load('uuid')
    if(uuid) {
      this.props.history.push('/')
    }
  }
  componentDidMount(){
    // axios.get('/login')
  }
  toggle2 =()=>{this.props.history.push('login')}
  render(){
    const {t} = this.props
    return(
      <div>

      <h4 className='text-center hero-icon-4'>بازیابی رمز عبور</h4>
      <Form onSubmit={this.sendforgotemail} className='form-auth pb-5'>
      <FormGroup className='my-5'>
      <Label className='required' for="email">
        ایمیل
      </Label>
      <Input
      id="email"
      name="email"
      className="rtl"
      type="email"
      onChange={this.onChange}
      value={this.state.email}
      />
      {this.validator.message('email', this.state.email, 'email|required')}

      </FormGroup>
      <FormGroup className="">
      <Button color="primary" sm={2} size="md" block>درخواست لینک</Button>
      <FormText className='text-primary ul-li li ul text-small'>در صورت عدم دریافت ایمیل پوشه اسپم را چک کنید</FormText>
      </FormGroup>
      <FormGroup className="mt-5">
      <Button color='dark' outline block>
      <Link className='text-dark' to='/auth/login'>صفحه ورود</Link>
      </Button>
      </FormGroup>
      </Form>



     </div>
    )
  }
}
export default withTranslation()(Login)
