import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem,CardBody } from 'reactstrap';
import SimpleLineIcon from 'react-simple-line-icons'
const Example = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown aria-haspopup={false}  a11y isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle direction='down' block color='white' className='alert alert-success font-weight-bold p-3 my-2 d-flex'>
        <span id={props.id} className='rtl ml-auto'>{props.subject}
        </span>
        <span><SimpleLineIcon name={dropdownOpen === false ? 'arrow-down mr-auto' : 'arrow-up mr-auto' } size='small' />
        </span>
      </DropdownToggle>
      <DropdownMenu positionFixed aria-expand={true}  className='w-100 px-3'>
        <CardBody className='w-100'>
          <p className='text-dark rtl'>{props.content}</p>
        </CardBody>

      </DropdownMenu>
    </Dropdown>
  );
}

export default Example;
