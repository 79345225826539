import {Row,Col,Button} from 'reactstrap'
import {Link} from 'react-router-dom'
import { useTranslation, withTranslation,Trans } from 'react-i18next';

const Xslide1 =()=>{
const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide1-bg' />
    <div className='slider-slide1'>

    <Row>


      <Col>
      <h5 className='slide-title'>
        {t('s1h1')}
      </h5>
      <p className="text-white-fix slider-p rtl" >{t('s1p1')}</p>


      </Col>
    </Row>

    </div>
    </>
  )
}
export {Xslide1};
const Xslide2 =()=>{
  const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide2-bg ' />
    <div className='slider-slide2'>
    <h5 className='slide-title'>
      {t('s2h1')}
    </h5>
    <p className="text-white-fix slider-p rtl" >{t('s2p1')}</p>
    <p className="text-white-fix slider-p rtl" >{t('s2p2')}</p>


    </div>
    </>
  )
}
export {Xslide2};
const Xslide3 =()=>{
  const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide3-bg ' />
    <div className='slider-slide3'>
    <h5 className='slide-title'>
      {t('s3h1')}
    </h5>
    <p className="text-white-fix slider-p rtl" >{t('s3p1')}</p>


    </div>

    </>
  )
}
export {Xslide3};
const Xslide4 =()=>{
  const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide4-bg ' />
    <div className='slider-slide4'>
    <h5 className='slide-title'>
      {t('s4h1')}
    </h5>
    <p className="text-white-fix slider-p rtl" >{t('s4p1')}</p>
    <p className="text-white-fix slider-p rtl" >{t('s4p2')}</p>
    <div className='mt-5'>
    <Link className='text-white btn-light-outline py-2 mt-3 px-5' to='PAXG_مارکت_طلا_در_صرافی_رپیتکس _PAXG_GOLD'>
      {t('read-more')}
    </Link>
    </div>
    </div>
    </>
  )
}
export {Xslide4};
const Xslide5 =()=>{
  const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide5-bg' />
    <div className='slider-slide4'>
    <h5 className='slide-title'>
      {t('s5h1')}
    </h5>
    <p className="text-white-fix slider-p rtl" >{t('s5p1')}</p>
    <p className="text-white-fix slider-p rtl" >{t('s5p2')}</p>

    </div>
    </>
  )
}
export {Xslide5};
const Xslide6 =()=>{
  const { t, i18n } = useTranslation();
  return(
    <>
    <div className='slider-slide2-bg' />
    <div className='slider-slide2'>
    <h5 className='slide-title'>
      {t('s6h1')}
    </h5>
    <p className="text-white-fix slider-p rtl" >{t('s6p1')}</p>
    </div>
    </>
  )
}
export {Xslide6};
