import React from 'react';
import {Link} from 'react-router-dom'
import { CiCoinInsert,CiBitcoin,CiInboxOut } from "react-icons/ci";
import { useTranslation, withTranslation } from 'react-i18next';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { RiUserSettingsLine } from "react-icons/ri"
import { LuUser2 } from "react-icons/lu";
import { RiTokenSwapLine } from "react-icons/ri";
import SimpleLineIcon from 'react-simple-line-icons'
import { GoSun } from "react-icons/go";
import { BsMoonStars } from "react-icons/bs";
import { MdOutlineCampaign } from "react-icons/md";
import cookie from 'react-cookies'
class Side extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      coin : 'BTC',
      coinname : 'بیت کوین',
      coinPrice : 16800,
      buysell : 'buy',
      fee : '',
      list : [],
      active : '',
      sub1 : false,
      themes : 'light' | 'dark',
      darkMode : true,
      isrtl : true,
      collapsed : true
    }
  }
  darkMode =()=>
  {
    if (this.state.darkMode === false){
        document.body.classList.remove('light');

        document.body.classList.add('dark');
        this.setState({theme : 'dark'})
    }
    if (this.state.darkMode === true){
        document.body.classList.remove('dark');
        document.body.classList.add('light');
        this.setState({theme : 'light'})
    }


        // let bodyColor = document.body.className;
      this.setState({
        darkMode : !this.state.darkMode
      })
  }
  hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };
  render(){
    const {t} = this.props

    return(
        <Sidebar
        rtl={this.state.isrtl}
         collapsed ={false}>
          <Menu>

          {this.props.loggedin === true || cookie.load('uid') ? <>
            <MenuItem onClick={this.props.onClick} component={<Link to="/" />} className='mt-3 rtl' icon={<SimpleLineIcon name="home" />}>
              {t('homepage')}
            </MenuItem>
            <MenuItem onClick={this.props.onClick} component={<Link to="/panel/profile" />}  className='rtl' icon={<LuUser2 size='1.4rem' />}>
              <small className='d-flex'><span className='ml-auto'>{localStorage.getItem('email')}</span></small>


            </MenuItem>
            <MenuItem onClick={this.props.onClick} component={<Link   to={cookie.load('uid') ? '/panel/rewards' : '/rewards' } >
            {t('rewards&campaigns')}</Link>}
className='rtl' icon={<MdOutlineCampaign size='1.4rem' />}>
              {t('rewards&campaigns')}
            </MenuItem>
            <MenuItem onClick={this.props.onClick} component={<Link to="/panel/dashboard" />} className='rtl' icon={<SimpleLineIcon name="grid" />}>
              {t('dashboard')}
            </MenuItem>
            <SubMenu className='rtl' label={t('withdraw-deposit')} icon={<SimpleLineIcon name='list' />}>
                     <MenuItem onClick={this.props.onClick} component={<Link to="/panel/depositirt" />}>{t('depositirr')}</MenuItem>
                     <MenuItem onClick={this.props.onClick} component={<Link to="/panel/withdrawirt" />}>{t('withdrawirr')}</MenuItem>
                     <MenuItem onClick={this.props.onClick} component={<Link to="/panel/depositcrypto" />}>{t('deposit-crypto')}</MenuItem>
                     <MenuItem onClick={this.props.onClick} component={<Link to="/panel/withdrawall" />}>{t('withdraw-crypto')}</MenuItem>
                   </SubMenu>
            <MenuItem onClick={this.props.onClick} component={<Link to="/trade/BTC-USDT" />} className='rtl' icon={<SimpleLineIcon name="chart" />}>
              {t('market')}
            </MenuItem>

            <MenuItem onClick={this.props.onClick} component={<Link to="/panel/exchange/buy/BTC" />} className='rtl' icon={<RiTokenSwapLine size='1.4rem'/>}>
              {t('simple-market')}
            </MenuItem>
            <MenuItem onClick={this.props.onClick} component={<Link to="/panel/profile" />} className='rtl' icon={<SimpleLineIcon name="user" />}>
              {t('profile')}
            </MenuItem>
            <MenuItem onClick={this.props.onClick} component={<Link to="/panel/wallet" />} className='rtl' icon={<SimpleLineIcon name="wallet" />}>
              {t('assets')}
            </MenuItem>
            <MenuItem onClick={this.props.onClick} component={<Link to="/panel/transactions" />} className='rtl' icon={<SimpleLineIcon name="list" />}>
              {t('transactions')}
            </MenuItem>


            <MenuItem onClick={this.props.onClick} component={<Link to="/panel/logout" />} className='rtl' icon={<SimpleLineIcon name="logout" />}>
            خروج
            </MenuItem>

            </> : <>
              <MenuItem onClick={this.props.onClick} component={<Link to="/" />} className='rtl mt-3' icon={<SimpleLineIcon name="home" />}>
                {t('homepage')}
              </MenuItem>
              <MenuItem onClick={this.props.onClick} component={<Link to="/auth/login" />} className='rtl' icon={<SimpleLineIcon name="user" />}>
                {t('login')}
              </MenuItem>

              <MenuItem  onClick={this.props.onClick}component={<Link to="/auth/signup" />} className='rtl' icon={<SimpleLineIcon name="user-follow" />}>
                {t('signup')}
              </MenuItem>

              <MenuItem onClick={this.props.onClick} component={<Link to="/fees" />} className='rtl' icon={<SimpleLineIcon name='grid' size=''/>}>
                {t('fees')}
              </MenuItem>
              <MenuItem onClick={this.props.onClick} component={<Link to="/terms_and_conditions" />} className='rtl' icon={<SimpleLineIcon name="grid" />}>
                {t('rules')}
              </MenuItem>
              <MenuItem onClick={this.props.onClick} component={<Link to="/about-us" />} className='rtl' icon={<SimpleLineIcon name="grid" />}>
                {t('about-us')}
              </MenuItem>
              <MenuItem onClick={this.props.onClick} component={<Link to="/currencies" />} className='rtl' icon={<SimpleLineIcon name="list" />}>
                {t('currencies')}
              </MenuItem>

            </> }
            <MenuItem  onClick={this.darkMode} className='rtl' icon={this.state.darkMode === true ?
            <GoSun  name='moon' size='1rem'  /> :<BsMoonStars size='1rem' name='sun'  /> }>
            <div className='icon-round'>
            {this.state.darkMode === true ?
              <>{t('light-mode')}</>:  <>{t('dark-mode')} </>}
            </div>
            </MenuItem>

            <div className='mt-5 mb-0 text-center'>
              <span  className='text-tiny text-gray'>
              v   1.2.0   @rapitex
              </span>
            </div>
          </Menu>
        </Sidebar>
    )
  }
}
export default withTranslation()(Side)
