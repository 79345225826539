import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Modal,ModalHeader,ModalBody ,ModalFooter,Card,CardHeader,ButtonDropdown,InputGroup,CardBody,Row,Form,FormGroup,Label,Input,Col,Button,Table,Container,UncontrolledDropdown, FormText ,Dropdown,DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import {Link} from 'react-router-dom'
import SearchInput, {createFilter} from 'react-search-input'
import {Helmet} from 'react-helmet'
import { withTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
import Select from 'react-select';
import NumericFormat from 'react-number-format';
const MySwal = withReactContent(Swal);


class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.getvc = this.getvc.bind(this);
    this.state = {
      list :[],
      coinname : '',
      coinname2 : '',
      fee : 0.002,
      all : 0,
      response : {"orderid" : "15821122255","status":"success","market":"YFI-IRR","quoteamount":1000000,"baseamount":0.00027489,"type":"\u062a\u0628\u062f\u06cc\u0644","date":"1402\/02\/24","message":"\u062a\u0631\u0627\u06a9\u0646\u0634 \u0628\u0627 \u0645\u0648\u0641\u0642\u06cc\u062a \u0627\u0646\u062c\u0627\u0645 \u0634\u062f","quote":"IRR","base":"YFI"},
      merged : [],
      accounts : [],
      c1 : [],
      c2 : [],
      rest : false,
      bds : true,
      input1 : true,
      btn : false,
      selectedOption: [],
      selectedOption2: [],
      a1 : {},
      a2 : {},
      modal : false,
      quote : '',
      res : '',
      responsebase : '',
      responsequote : '',
      id : 'USDT',
      type : 'buy'
        }
    this.validator = new SimpleReactValidator();
    // this.getvc
      }
      handleChange = (selectedOption) => {
        if(selectedOption === this.state.selectedOption2){
          console.log('گزینه های انتخابی اشتباه است')
          return
        }
       this.setState({ selectedOption });

         this.setState({amount : '', res : '' , fee : '' , rest : false})

     }
      handleChange2 = (selectedOption2) => {
        if(this.state.selectedOption === selectedOption2){
          console.log('گزینه های انتخابی اشتباه است')
          return
        }
       this.setState({ selectedOption2 });
       this.setState({c : true})

     }
  setcointaker = (item) =>{
    this.setState({coinname : item.name ,slug : item.slug, coinPrice : item.irprice , fee : item.fee,c1 : item})
  }
  setcointicker = (item) =>{
    if(this.state.coinname === '') {

      return
    }
    this.setState({coinname2 : item.name , input1 : false ,slug2 : item.slug , coinPrice2 : item.irprice , fee2 : item.fee,c2 : item})
  }
  onChange = (e)=>{
    var value = e.target.value
    this.setState({amount : parseFloat(value)})
    if(this.state.coinname === ''){
      console.log('ارزی انتخاب نکرده اید')
      return
    }
    if(this.state.coinname2 === ''){
      console.log('ارزی انتخاب نکرده اید')
      return
    }
   var c1 =  this.state.selectedOption
   var c2  =  this.state.selectedOption2

  var type = this.state.type
   var a1 =   parseFloat(c1.buy)
   var a2  =  parseFloat(c2.sell)
   var f1 =  parseFloat(c1.fee)
   var f2 =  parseFloat(c2.fee)
   var all = e.target.value * a1
   var res = all/a2
   var fee = all * f2
   // if(parseFloat(all) > 1000000-1){
     this.setState({res : res ,all : all})
     // this.setState({res : res ,all : all, btn : false})
   // }else{
   //   this.setState({btn : true})
   // }

  }
componentWillMount(){
  var id = this.state.id
  var type =this.state.type
  this.setState({base : id , side: type})
  // if(!cookie.load('uid')){
  //   return
  // }
this.getstate()
// this.getvc()
this.getdata()

}
getdata = ()=>{
  axios.get('/api/swap/list').then(response=>{
    var data = response.data
    // if(response.data.status === 'error-fatal'){
    //   // console.log('توکن شما منقضی شده است.لطفا مجددا وارد شوید')
    //   // this.props.history.push('/auth/login')
    //   return
    // }
    if(response.data.status === 'success'){

    // console.log('irrr:',response.data[0]);
    this.setState({accounts : response.data.accounts,list : response.data.list})


    let x = response.data.list.map(itm => ({
        ...response.data.accounts.find((item) => (item.symbol === itm.symbol) && item),
        ...itm
    }));

    this.setState({merged : x})

    var id = this.state.id
    var type =this.state.type
    const found = x.find(element => element.taker === id);
    // console.log('found=>',found);
    // const i = found.in
    this.setState({i : found.id , type : type});
    var i = found.id - 1
    // console.log(i);
    if(type === 'buy'){
      this.setState({c2 : x[i] ,c1 : x[0] ,i2 : x[i].taker.toLowerCase() ,i1 : x[0].taker.toLowerCase(),input1 : false  })

    }else{
      this.setState({c1: x[i] ,c2 : x[0] ,i1 : x[i].taker.toLowerCase() ,i2 : x[0].taker.toLowerCase(),input1 : false  })

    }
   }
  })
}
checkmarket = (e)=>{
  e.preventDefault()
  if(this.state.selectedOption.slug === this.state.selectedOption2.value){
    console.log('گزینه های انتخابی اشتباه است')
    return
  }
  let market = this.state.selectedOption2.value +'-'+this.state.selectedOption.slug
  const formdata = new FormData();
  formdata.append('market',market)
  formdata.append('base',this.state.selectedOption2.taker)
  formdata.append('quote',this.state.selectedOption.slug)
  axios.post('/api/exchange/checkswap',formdata).then(response=>{
    // if(response.data.status === 'error-fatal'){
    //   this.props.history.push('/auth/login')
    //   return
    // }
     this.setState({fee : response.data.fee ,type : response.data.status ,rest : true , basebalance : response.data.basebalance  , quotebalance : response.data.quotebalance})
  }

  )
}
swap = (e)=>{
  e.preventDefault()
  this.setState({btn : true})
  // console.log(this.state);
  // return
if(this.state.amount === ''){
  this.setState({inputerror : true })

  console.log('مقدار ارز را وارد کنید')
  return
}
  var all = this.state.all
  var amount = this.state.amount
  var a2 = this.state.res
  var type = this.state.type
  if(parseFloat(amount) > parseFloat(this.state.quotebalance)){
    console.log('موجودی شما کافی نمیباشد')
    this.setState({btn : false})
    // return
  }
  const formdata = new FormData();
  formdata.append('amount',amount)
  formdata.append('market',this.state.market)
  formdata.append('allpay',all)
  formdata.append('takeramount',a2)
  formdata.append('type',type)
  formdata.append('c1',this.state.selectedOption.taker)
  formdata.append('c2',this.state.selectedOption2.taker)
  this.setState({bds : false})
  axios.post('/api/exchange/swap',formdata).then(response=>{
    // console.log();
    this.setState({bds : true})
    if(response.data.status === 'success'){
      console.log(response.data.message)
      // this.getstate()
    }
    // if(response.data.status === 'error-fatal'){
    //   this.props.history.push('/auth/login')
    // }
    if(response.data.status === 'error'){
      console.log(response.data.message)
    }
    this.setState({btn : false})



  })
}



getvc = ()=>{
  var base = this.state.base
  var quote = this.state.quote
  var type =this.state.type
  this.setState({coin : base})
  var url = '/api/exchange/check_order/'+base+'/'+quote
  axios.get(url).then(response=>{
    // if(response.data.status === 'error-fatal'){
    //   console.log('توکن شما منقضی شده است.لطفا مجددا وارد شوید')
    //   this.setState({loginmodal : true})
    //   this.props.history.push('/auth/login')
    //       return
    // }
    if(response.data.status === 'success'){
      this.setState({
        baseprice : response.data.baseprice,
        quoteprice : response.data.quoteprice,
        fee : response.data.fee
      })
    }
  })
}
check = (base , quote)=>{
  this.setState({a1 : '' , x : '',res : ''})
  // var base = this.state.base
  // var quote = this.state.quote
  var type =this.state.type
  // this.setState({coin : base})
  var url = '/api/exchange/check_order/'+base+'/'+quote
  axios.get(url).then(response=>{
    // if(response.data.status === 'error-fatal'){
    //   this.props.history.push('/auth/login')
    //   return
    // }
    if(response.data.status === 'success'){
      this.setState({
        baseprice : response.data.baseprice,
        quoteprice : response.data.quoteprice,
        fee : response.data.fee
      })
    }
  })
}
getstate = ()=>{
  var id = this.state.id
  var quote = this.state.quote
  var type =this.state.type
  this.setState({coin : id})
  var url = '/api/exchange/check_order/'+id+'/'+quote
  axios.get(url).then(response=>{
    // if(response.data.status === 'error-fatal'){
    //   this.props.history.push('/auth/login')
    //   return
    // }
    if(response.data.status === 'success'){
      if(type === 'buy'){
        this.setState({
          baseprice : response.data.baseprice,
          quoteprice : response.data.quoteprice,
          fee : response.data.fee
        })
      }else{
        this.setState({
          quoteprice : response.data.baseprice,
          baseprice : response.data.quoteprice,
          fee : response.data.fee
        })
      }

    }
  })
}
seti1 = (i)=>{
  let i1 = i.symbol
  this.setState({c1 : i , i1 : i1.toLowerCase() , quote : i.symbol})
  this.check(this.state.base , i.symbol)
  // console.log(i);
}
changea = (i)=>{
  this.setState({amount : i ,a1: i})
  let xx = this.convertor.convert(i/10)
  this.setState({x : xx})
  var a1 =   parseFloat(this.state.quoteprice)
  var a2  =  parseFloat(this.state.baseprice)
  var fee = this.state.fee
  var a = i * a1
  var b = a / a2
  var x = fee * b
  var y = b - x
  if(y > 1){
    this.setState({res : y.toFixed(2),all : a })
  }else{
    this.setState({res : y.toFixed(8),all : a  })
  }

    let g = this.convertor.convert(y/10)
    this.setState({g : g})

  // console.log(i);
}
seti2 = (i)=>{
  let i1 = i.taker
  this.setState({c2 : i , i2 : i1.toLowerCase(), base : i.taker})
  this.check(i.taker , this.state.quote)
  // console.log(i);
}
changea1 =(e)=>{
  this.setState({amount : e.target.value ,[e.target.name] : e.target.value , error : ''})

  if(this.state.i1 === ''){
    this.setState({input1error : 'ارزی انتخاب نکرده اید'})
    return
  }
  if(this.state.i2 === ''){
      this.setState({input2error : 'ارزی انتخاب نکرده اید'})
    return
  }
 let amount = e.target.value
 var type =this.state.type
 var c1 =  this.state.c1
 var c2 =  this.state.c2
 var a1 =   this.findquoteprice()
 var a2 =  this.findbaseprice()

 var fee = this.state.fee
 var a = amount * a1
 var b = a / a2
 var x = fee * b
 var y = b - x
 // var y = y.toFixed(9)
 // this.setState({res : y})
var x = this.convertor.convert(e.target.value/10)
 // let  addCommas = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 // let  removeNonNumeric =  num.toString().replace(/[^0-9]/g, "");
 // let amount = addCommas(removeNonNumeric)
 // let amount = this.thousand(e.target.value)
 if(y > 1){
   this.setState({res : y.toFixed(2),all : a , x : x })
 }else{
   this.setState({res : y.toFixed(8),all : a , x : x })
 }
 let g = this.convertor.convert(y/10)
 this.setState({g : g})
}
findquoteprice = ()=>{
  var quote = this.state.i1.toUpperCase()
  const found = this.state.list.find(element => element.taker === quote);
  console.log(found);
  // return
  this.setState({quoteprice : parseFloat(found.buy)})
  return parseFloat(found.buy)
}
findbaseprice = ()=>{
  var quote = this.state.i2.toUpperCase()
  const found = this.state.list.find(element => element.taker === quote);
  console.log(found);
  this.setState({quoteprice : parseFloat(found.sell)})
  return parseFloat(found.sell)
}
componentDidMount(){
  // this.getvc()
// console.log(this.state.c1);
}
lowercase =(i)=>{
  let x = i.toLowerCase()
  return(
    <span>{x}</span>
  )
}
convert = ()=>{
  this.a = this.state.c1
  this.a1 = this.state.i1
  this.b = this.state.c2
  this.b2 = this.state.i2
  this.setState({c1 : this.b})
  this.setState({i1 : this.b2})
  this.setState({c2 : this.a})
  this.setState({i2 : this.a1})
  var type =this.state.type
  this.setState({a1 : '' , res : ''})
  if(type === 'buy'){
  this.props.history.push('/panel/exchange/sell/'+this.state.id)}else{
  this.props.history.push('/panel/exchange/buy/'+this.state.id)
  }
}
 convertor = (function() {
  var convertor = {
    convert: function(num) {
      if (isNaN(num)) return "عدد وارد شده معتبر نمی باشد.";
      num = Math.round(num);
      var yekan = ["یک", "دو", "سه", "چهار", "پنج", "شش", "هفت", "هشت", "نه"];
      var dahgan1 = [
        "ده",
        "یازده",
        "دوازده",
        "سیزده",
        "چهارده",
        "پانزده",
        "شانزده",
        "هفده",
        "هجده",
        "نوزده"
      ];
      var dahgan2 = [
        "بیست",
        "سی",
        "چهل",
        "پنجاه",
        "شصت",
        "هفتاد",
        "هشتاد",
        "نود"
      ];
      var sadgan = [
        "صد",
        "دویست",
        "سیصد",
        "چهارصد",
        "پانصد",
        "ششصد",
        "هفتصد",
        "هشتصد",
        "نهصد"
      ];

      var _num = num.toString();
      var _count = 0;
      var _yekan = "";
      var _dahgan = "";
      var _sadgan = "";
      var _hezargan = "";
      var _hezargan2 = "";
      var _milion = "";
      var _milion2 = "";
      var _miliard = "";
      var _miliard2 = "";
      var _terilion = "";
      var _triliard = "";

      for (var i = _num.length - 1; i >= 0; i--) {
        _count++;

        //-1 => برای اینکه با توجه به موقعیت پیدا میکنم و جون از یک شروع میکنم پس منهای یک میکنم
        // که موقعیت درست رو به من بده
        var _s = parseInt(_num[i]) - 1;
        switch (_count) {
          case 1:
            if (_s >= 0) _yekan = yekan[_s];
            break;
          case 2:
            if (_s >= 0) {
              if (_s === 0) {
                // زمانی که عدد کوچکتر از 20 باشد
                var _d = parseInt(_num[i + 1]);
                _dahgan = dahgan1[_d];
                _yekan = "";
              } else {
                _dahgan = dahgan2[_s - 1];

                if (_yekan.trim() !== "") _dahgan += " و ";
              }
            }
            break;
          case 3:
            //صدگان
            if (_s >= 0) {
              _sadgan = sadgan[_s];
              if (_yekan.trim() !== "" || _dahgan.trim() !== "")
                _sadgan += " و ";
            }
            break;
          case 4:
            //هزارگان
            if (_s >= 0) {
              _hezargan = yekan[_s];
              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== ""
              )
                _hezargan += " هزار و ";
              else _hezargan += " هزار ";
            }
            break;
          case 5:
            if (_s >= 0) {
              var _d = parseInt(_num[i + 1]);
              if (_s === 0) {
                // زمانی که عدد کوچکتر از 20 باشد
                _hezargan = dahgan1[_d] + " هزار ";
                // _yekan = '';
              } else {
                if (yekan[_d - 1] == undefined)
                  _hezargan = dahgan2[_s - 1] + " هزار ";
                else
                  _hezargan =
                    dahgan2[_s - 1] + " و " + yekan[_d - 1] + " هزار ";
              }

              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== ""
              )
                _hezargan += " و ";
            }
            break;
          case 6:
            if (_s >= 0) {
              _hezargan2 = sadgan[_s];
              if (_hezargan.trim() !== "") _hezargan2 += " و ";
              else {
                _hezargan2 += " هزار ";
                if (
                  _yekan.trim() !== "" ||
                  _dahgan.trim() !== "" ||
                  _sadgan.trim() !== ""
                )
                  _hezargan2 += " و ";
              }
            }
            break;
          case 7:
            //میلیون
            if (_s >= 0) {
              _milion = yekan[_s] + " میلیون ";
              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== "" ||
                _hezargan.trim() !== "" ||
                _hezargan2.trim() !== ""
              )
                _milion += " و ";
            }
            break;
          case 8:
            if (_s >= 0) {
              var _d = parseInt(_num[i + 1]);
              if (_s === 0) {
                // زمانی که عدد کوچکتر از 20 باشد
                _milion = dahgan1[_d] + " میلیون ";
                // _yekan = '';
              } else {
                if (yekan[_d - 1] === undefined)
                  _milion = dahgan2[_s - 1] + " میلیون ";
                else
                  _milion =
                    dahgan2[_s - 1] + " و " + yekan[_d - 1] + " میلیون ";
              }

              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== "" ||
                _hezargan.trim() !== "" ||
                _hezargan2.trim() !== ""
              )
                _milion += " و ";
            }
            break;
          case 9:
            //میلیون
            if (_s >= 0) {
              _milion2 = sadgan[_s];
              if (_milion.trim() !== "") _milion2 += " و ";
              else {
                _milion2 += " میلیون ";
                if (
                  _yekan.trim() !== "" ||
                  _dahgan.trim() !== "" ||
                  _sadgan.trim() !== ""
                )
                  _milion2 += "و ";
              }
            }
            break;
          case 10:
            //میلیارد
            if (_s >= 0) {
              _miliard = yekan[_s] + " میلیارد ";
              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== "" ||
                _hezargan.trim() !== "" ||
                _hezargan2.trim() !== "" ||
                _milion.trim() !== "" ||
                _milion2.trim() !== ""
              )
                _miliard += "و ";
            }
            break;
          case 11:
            if (_s >= 0) {
              var _d = parseInt(_num[i + 1]);
              if (_s === 0) {
                // زمانی که عدد کوچکتر از 20 باشد
                _miliard = dahgan1[_d] + " میلیارد ";
              } else {
                if (yekan[_d - 1] == undefined)
                  _miliard = dahgan2[_s - 1] + " میلیارد ";
                else
                  _miliard =
                    dahgan2[_s - 1] + " و " + yekan[_d - 1] + " میلیارد ";
              }

              if (
                _yekan.trim() !== "" ||
                _dahgan.trim() !== "" ||
                _sadgan.trim() !== "" ||
                _hezargan.trim() !== "" ||
                _hezargan2.trim() !== "" ||
                _milion.trim() !== "" ||
                _milion2.trim() !== ""
              )
                _miliard += "و ";
            }
            break;
          case 12:
            //میلیون
            if (_s >= 0) {
              _miliard2 = sadgan[_s];
              if (_miliard.trim() !== "") _miliard2 += " و ";
              else {
                _miliard2 += " میلیارد ";
                if (
                  _yekan.trim() !== "" ||
                  _dahgan.trim() !== "" ||
                  _sadgan.trim() !== ""
                )
                  _miliard2 += "و ";
              }
            }
            break;
          default:
            break;
        }
      }

      return (
        _miliard2 +
        _miliard +
        _milion2 +
        _milion +
        _hezargan2 +
        _hezargan +
        _sadgan +
        _dahgan +
        _yekan +
        ' '+
        'تومان'
      );
    }
  };

  return convertor;
})();
submit = (e)=>{
  e.preventDefault()
  let c1 = this.state.c1
  let c2 = this.state.c2
  if(c1.availablebalance === '0'){
    return
  }
  if(this.state.a1 < 0){
    return
  }
  if(this.state.a1 === 0){
    return
  }
  if(parseFloat(this.state.all) < 500000){
  this.setState({error : 'ارزش معامله باید بیشتر از 1,000,000 ریال (یکصد هزار تومان) باشد'})
    return
  }
  if(!c1.availablebalance){
    this.setState({error : 'موجودی کافی نیست'})
    return
  }
  if(parseFloat(c1.availablebalance) < parseFloat(this.state.a1)){
  this.setState({error : 'موجودی کافی نیست'})
  return
  }
  const formdata = new FormData()
  formdata.append('base',this.state.i2)
  formdata.append('quote',this.state.i1)
  formdata.append('fee',this.state.fee)
  formdata.append('value',this.state.all)
  formdata.append('baseamount',this.state.res)
  formdata.append('quoteamount',this.state.a1)
  formdata.append('type',this.state.type)
  axios.post('/api/exchange/swap'  , formdata).then(response=>{
    if(response.data.status === 'success'){
      // console.log(response.data.message)
      this.getdata()
      this.setState({modal : !this.state.modal , a1 : '' , response : response.data, res : '' ,
      responsebase : response.data.base.toLowerCase(),
      responsequote : response.data.quote.toLowerCase(),
    })

    }
    // if(response.data.status === 'error-fatal'){
    //   this.props.history.push('/auth/login')
    // }
    if(response.data.status === 'error'){
      console.log(response.data.message)
    }
    this.setState({btn : false})

  })
}
closemodal =()=>{
  this.props.history.push('/panel/wallet')
}
  render(){
    let filteredEmails = this.state.merged
    const i = this.state.i
    const c = <NumericFormat value={parseInt(this.state.a1)}  thousandSeparator="," displayType="text"  className='font-weight-bold' />
    console.log(this.state);
    const { t } = this.props;
    return(
    <>
    <Modal isOpen={this.state.modal}  className={this.state.className}>
      <ModalHeader className='rtl bg-darkblue text-white '><div className='d-flex justify-content-space-between'><span className='ml-auto'>نتیجه تراکنش : </span>
      <span className='text-success mr-2'>#{this.state.response.orderid}</span>
      </div>
      </ModalHeader>
      <ModalBody className='radius ltr h-400'>

      <Row xs='2' className='ltr h100'>

      <Col>
      <div className='py-2 px-3'>
        <span className={this.state.responsebase +'-icon border abslute large-icon quote-icon mr-auto'}></span>
        <span className={this.state.responsequote +'-icon border abslute large-icon base-icon'}></span>

      </div>

      </Col>

      </Row>

      <Row xs='1' className='rtl mt-4  bg-light'>
      <Col>
      <div className='mt-3 rtl pt-3 text-center'>
        <h5 className='text-success'>موفق</h5>
      </div>
      <div className='d-flex mt-3'>
        <span className={this.state.responsequote +'-icon my-auto'}></span><p className='rtl text-dark mr-2 my-auto'>مقدار پرداختی : </p><p className='mr-auto my-auto font-weight-bold'><NumericFormat value={this.state.response.quoteamount} thousandSeparator="," displayType="text" suffix={'( '+this.state.responsequote.toUpperCase()+ ' )'} /></p>
      </div>
      <div className='d-flex mt-3'>
        <span className={this.state.responsebase +'-icon my-auto'}></span><p className='rtl text-dark mr-2 my-auto'>مقدار دریافتی : </p><p className='mr-auto my-auto font-weight-bold'><NumericFormat value={this.state.response.baseamount} thousandSeparator="," displayType="text" suffix={'( '+this.state.responsebase.toUpperCase()+ ' )'} /></p>
      </div>
      <div className='d-flex mt-3'>
        <p className='rtl text-dark mr-2'>تاریخ :  </p><p className='mr-auto font-weight-bold'>{this.state.response.date}</p>
      </div>

      </Col>
      <Col>

      </Col>


      </Row>
      <div className='mt-3 bg-light rtl py-3 text-center'>
        <Link to={'/panel/order-detail/' + this.state.response.orderid}>برای مشاهده جزئیات بیشتر کلیک کنید</Link>
      </div>
      </ModalBody >
      <ModalFooter>
      <Button color='primary' onClick={this.closemodal}>بستن</Button>
      </ModalFooter>
    </Modal>
    <div className='mt-3 rtl'>
    <Card className='bg-darkblue text-white'>

    <CardBody className='text-center'>
      <Row>
        <Col xl={{size :8, offset : 2}} className='m-auto'>
          <div className='py-2'>
          <FormGroup>
          <InputGroup size='lg'>
              <UncontrolledDropdown>
            <DropdownToggle color='white' className='px-2  input-custome-button'>

            <div className='d-flex' >
            <span className='my-auto text-white'>پرداخت میکنم</span>
            <span className='mx-2'>
              <span className={this.state.i1+ '-icon my-auto'} ></span>
            </span>
            <span className='mr-1 my-auto text-white'>
              {this.state.c1.symbol}
            </span>

            </div>

            </DropdownToggle>
            <DropdownMenu right className='px-2'>

              <div className='px-3 bg-white' >
                <SearchInput onChange={this.serach} placeholder=' ' className='market-input' />
              </div>
              <DropdownItem divider />
              <div className='historylist-container custom-overflow'>
              {this.state.accounts.map(item=>
                <DropdownItem  className='d-flex py-2' onClick={()=>this.seti1(item)} >
                <span className='ml-5'>
                  <span className={item.symbol.toLowerCase()+ '-icon'} ></span>
                </span>
                <span className='mr-auto text-white'>
                  {item.symbol}
                </span>

                </DropdownItem>
              )}
              </div>
            </DropdownMenu>
              </UncontrolledDropdown>
            <Input
             className='swap-input bg-darkblue text-white'
             name='a1'
             
             type='number'
             value = {this.state.a1}
             min='0'
             onChange={this.changea1}
             />


           </InputGroup>
           <div className='d-flex'>
           {this.state.c1.symbol === 'IRR' ? <small className='text-primary rtl' >{this.state.x}</small>: <></> }
           <FormText color='success' className='ltr pointer mr-auto' onClick={()=>this.changea(this.state.c1.availablebalance)}>
           <NumericFormat value={this.state.c1 !== '' ? this.state.c1.availablebalance : 0 }   thousandSeparator="," displayType="text"   suffix={' '+ this.state.c1.symbol + ' '} />
           </FormText >
           </div>
            </FormGroup>
          </div>
          <div className='py-2'>
          <span className='exchange-icon mx-auto pointer' onClick={this.convert}></span>
          </div>
          <div className='py-2'>

          <FormGroup>
          <InputGroup size='lg' className='bg-darkblue text-white' >
              <UncontrolledDropdown>
            <DropdownToggle color='white' className='px-2 bg-darkblue text-white input-custome-button'>
            <div className='d-flex' >
            <span className='my-auto'>دریافت میکنم</span>
            <span className='mx-2'>
              <span className={this.state.i2 + '-icon my-auto text-white'} ></span>
            </span>
            <span className='mr-1 my-auto'>
              {this.state.c2.taker}
            </span>

            </div>

            </DropdownToggle>
            <DropdownMenu right className='px-2'>

              <div className='px-3 bg-white' >
                <SearchInput onChange={this.serach} placeholder=' ' className='market-input' />
              </div>
              <DropdownItem divider />
              <div className='historylist-container custom-overflow'>
              {this.state.merged.map(item=>
                <DropdownItem className='d-flex py-2' onClick={()=>this.seti2(item)} >
                <span className='ml-1'>
                  <div className={item.taker.toLowerCase()+ '-icon text-white'} ></div>
                </span>
                <span className='mr-auto'>
                  {item.taker}
                </span>

                </DropdownItem>
              )}
              </div>
            </DropdownMenu>
              </UncontrolledDropdown>
            <Input value={this.state.res} className='swap-input bg-darkblue text-white'/>
           </InputGroup>
           <div className='d-flex' >
           { this.state.i2 === 'irr' ? <small className='text-primary rtl' >{this.state.g}</small> : <></> }
           </div>
           </FormGroup>
           <FormGroup className='p py-2 px-3'>

           <small className='text-danger rtl' >{this.state.error}</small>
           </FormGroup>
           {cookie.load('uid') ?
           <FormGroup className=''>
            <Button disabled={!this.state.bds} onClick={this.submit} color='success' block size='lg'>ثبت</Button>
           </FormGroup>
           : <></>}
          </div>
        </Col>
      </Row>
    </CardBody>
    </Card>
    </div>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
