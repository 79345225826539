import React, { useState,useEffect } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  NavbarText,
  Container,Col,Row,DropdownItem
} from 'reactstrap';
import DarkModeToggle from '../darkmode'
import DarkModeTogglexs from '../darkmode-xs'
import {AiOutlineStock ,AiOutlineWindows} from 'react-icons/ai'
import {CiHome} from 'react-icons/ci'
import {FcAbout} from 'react-icons/fc'
import {CgWebsite} from 'react-icons/cg'
import {FaUncharted} from 'react-icons/fa'
import {RiMenuFoldLine} from 'react-icons/ri'
import {PiWalletThin,PiSwapThin,PiStackThin,PiListDashesThin,PiDiamondsFourLight} from 'react-icons/pi'
import SimpleLineIcon from 'react-simple-line-icons'
import {Link} from 'react-router-dom'
import cookie from 'react-cookies'
import {useTranslation,withTranslation} from 'react-i18next'
function Example(args) {
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation()
  const toggle = () => setIsOpen(!isOpen);
  //navbar scroll when active state
    const [navbar, setNavbar] = useState(false)

    //logo scroll when active

    //navbar scroll changeBackground function
    const changeBackground = () => {
      // console.log(window.scrollY)
      if (window.scrollY >= 0) {
        setNavbar(true)
      } else {
        setNavbar(false)
      }
    }

    useEffect(() => {
      changeBackground()
      // adding the event when scroll change background
      window.addEventListener("scroll", changeBackground)
    })
  return (
    <div className='rtl landing-navbar'>
    
      {isOpen === true ? <>
        <div className='xs-menu rtl pl-5' data-aos="slide-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="300">

          <Row xs='1'>
          {!cookie.load('uid') ?
            <Col onClick={toggle}>
            <Link onClick={toggle} to='/' className='mx-auto'>
            <div className='logo-container-xs' />
           </Link>
            <Link onClick={toggle} to='/Auth/login' className='d-flex mb-3 mt-4'>
            <span>
              <SimpleLineIcon name='user' color='white'/>
            </span>
            <span className='mr-4'>
            ورود

            </span>
            </Link>
              <DropdownItem divider className='text-muted' />
            </Col>:
            <Col>
            <Link onClick={toggle} to='/panel/dashboard' className='d-flex my-3'>
            <span>
              <SimpleLineIcon name='user' color='white'/>
            </span>
            <span className='mr-4'>
            پنل کاربری

            </span>
            </Link>

            </Col> }

              {!cookie.load('uid') ?
              <Col>
              <Link onClick={toggle} to='/Auth/signup' className='d-flex my-3'>
              <span>
                <SimpleLineIcon name='user-follow' color='white'/>
              </span>
              <span className='mr-4'>
              ثبت نام
            </span>
            </Link>
            </Col> : <></> }

            <DropdownItem divider className='text-muted' />
            <Link onClick={toggle} className=' py-2 rtl text-dark market-nav-icon' to='/trade/BTC-USDT'>

            <AiOutlineStock size='2rem' className='text-light'/>
            <span className='mr-4 text-light'>
             مارکت
             </span>


            </Link>

            <DropdownItem divider className='text-muted' />
            <Col>
            <Link onClick={toggle} to='/rules' className='d-flex my-3'>
            <span>
              <SimpleLineIcon name='map' color='white'/>
            </span>
            <span className='mr-4'>
            قوانین سایت
          </span>
          </Link>
            </Col>
            <DropdownItem divider className='text-muted' />
            <Col>
            <Link onClick={toggle} to='/about-us' className='d-flex my-3'>
            <span>
              <SimpleLineIcon name='map' color='white'/>
            </span>
            <span className='mr-4'>
            درباره ما
          </span>
          </Link>
            </Col>
            <DropdownItem divider className='text-muted' />
            <Col>
            <Link onClick={toggle} to='/contact-us' className='d-flex my-3'>
            <span>
              <SimpleLineIcon name='map' color='white'/>
            </span>
            <span className='mr-4'>
            تماس با ما
          </span>
          </Link>
            </Col>
            <DropdownItem divider className='text-muted' />
            <Col>
            <Link onClick={toggle}  to='/fees' className='d-flex my-3'>
            <span>
              <SimpleLineIcon name='map' color='white'/>
            </span>
            <span className='mr-4'>
            کارمزد ها
          </span>
          </Link>
            </Col>
            <DropdownItem divider className='text-muted' />
            <Col>
            <Link onClick={toggle}  to='/faq' className='d-flex my-3 small' >
            <span>
              <SimpleLineIcon name='support' color='white'/>
            </span>
            <span className='mr-4'>
            سوالات متداول
            </span>
            </Link>

            </Col>
          </Row>
        </div>
      </> :
    <></>}
      <div className='d-none d-lg-block horizental-menu'>
       <div className={navbar ? 'bg-ocean d-flex just-content-space-between text-light shadow fixed-top' : 'd-flex just-content-space-between text-light  py-2  fixed-top pb-2'}>


        <Link onClick={toggle} to='/' className='ml-auto'>
        <div className='logo-container-home mx-2' />
       </Link>
        <div className='mt-2 d-flex'>
        <Link onClick={toggle} className='landing-nav rtl mx-2 px-1 text-light market-nav-icon' to='/trade/BTC-USDT'>
         مارکت
        </Link>

        <Link onClick={toggle} to='/about-us' className='landing-nav rtl mx-2 text-light ' >درباره ما</Link>
        <Link onClick={toggle} to='/Terms-and-Condition' className='landing-nav rtl mx-2 text-light ' >قوانین و مقررات</Link>
        <Link onClick={toggle} to='/currencies' className='landing-nav rtl mx-2 text-light ' >لیست ارزها</Link>
        <Link onClick={toggle} to='/fees' className='landing-nav rtl mx-2 text-light ' >کارمزد</Link>
        <Link onClick={toggle} to='/faq'  className='landing-nav rtl mx-2 text-light ' >سوالات متداول</Link>
        </div>
        <div className='mr-auto rtl d-flex my-auto'>

        {!cookie.load('uid') ? <>

         <Link onClick={toggle} className='landing-nav  mx-4 text-light login-icon' to='auth/login'>ورود
         </Link>

         <Link onClick={toggle} to='/Auth/signup' className='mr-auto my-auto  signup-icon'>
         <NavbarText className='mr-auto px-2'><Button style={{'font-size' : '16px'}} color='warning' className='px-5'>ثبت نام </Button></NavbarText>
        </Link>
         </>
      :
        <Link onClick={toggle} to='/Panel/Dashboard' className=' '>
        <NavbarText className='ml-auto px-2'><Button style={{'font-size' : '16px'}} color='warning' className='px-5'>پنل کاربری</Button></NavbarText>
        </Link>
      }
      <div className='mx-4 my-auto text-light' to='#'><DarkModeToggle/>
      </div>

        </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Example);
