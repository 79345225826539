import React from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import { useTranslation, withTranslation } from 'react-i18next';
import {Row,Col,Button} from 'reactstrap'
import terms from '../../../style/images/terms.svg'
class About extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }
componentDidMount(){
  window.scrollTo(0, 0)
}

  render(){
      const { t } = this.props;
    return(
      <>
      <Helmet>
        <title>{t('terms-title')}</title>
      </Helmet>
      <div className='h-400 pt-10 terms'>
      <div className='rtl'>
          <h1 className="mb-5 rtl">{t("terms-Conditions")}</h1>
          <p className="lead">
          موافقتنامه زیر مجموعه ای از قوانین و شرایط استفاده (Terms & Conditions)از رپیتکس است که بستری (پلتفرم) برای مبادله ی رمز ارزهای دیجیتال (Cryptocurrency) بر مبنای قیمت پیشنهادی کاربران است.
          </p>
          <p>
          این موافقتنامه فی مابین رپیتکس و کاربر منعقد می شود و رپیتکس و کاربر متعهد به انجام تمامی مفاد آن خواهند بود. این مفاد که از سوی رپیتکس ارائه می شود، پس از قبول کاربر و ثبت نام در رپیتکس، قابل اجرا و الزام آور خواهد بود.
  تمامی قوانین و شرایط مندرج در این موافقتنامه، در صورت لزوم قابل تغییر خواهند بود و بدیهی است قبل از اجرای مفاد جدید، کاربر از تغییرات آن مطلع خواهد شد.
          </p>

      </div>
      <hr className="my-5" />
        <div className='rtl'>
        <Row xl='2' lg='2' md='1' xs='1' sm='1' dir='rtl'>

        <Col>
        <p>
          1- رپیتکس تابع قوانین جمهوری اسلامی ایران است.
          </p>
          <p>
          2- در رپیتکس هیچ گونه تبادل ارز کاغذی اعم از دلار، یورو و ارز رایج کشورهای دیگر وجود ندارد.
          </p>
          <p>
          3- رپیتکس هیچ نقشی در تعیین قیمت رمز ارزهای موجود در سایت ندارد و تنها بستری است که امکان تبادل رمز ارزها را مهیا می کند.
          </p>
        </Col>

        </Row>


        <p>
        4- رپیتکس هیچ گونه مسئولیتی در قبال نحوه معاملات و  سود و زیان های ناشی از آن ندارد.
کاربر متعهد می شود که:
        </p>
        <p className='mr-2'>1- استفاده از سایت رپیتکس به هر صورت و نحوی اعم از ثبت نام، خرید، فروش به معنای این است که کاربر تمامی قوانین را خوانده و فهمیده و از مفاد آن آگاه است و موافق تمامی قوانین و شرایط مندرج در این موافقتنامه می باشد، در غیر اینصورت نباید از رپیتکس استفاده کند.</p>
        <p className='mr-2'>2- هرگونه خرید و فروش در بازار رمز ارزها، خطرات و ریسک های خود را دارد، کاربر با آگاهی کامل از این ضرر و زیان ها، مسئولیت تمامی آن ها را می پذیرد.</p>
        <p className='mr-2'>3- رپیتکس می تواند هر زمان بر اساس لزوم، مفاد این موافقتنامه را تغییر دهد و  کاربر را از تغییرات آن مطلع سازد و در صورتی که کاربر بعد از 10 روز به مفاد تغییرات واکنشی نشان ندهد رپیتکس فرض را بر قبول و تعهد کاربر در خصوص قوانین جدید خواهد گذاشت و تمامی مفاد موافقت نامه جدید، از سوی طرفین لازم الاجرا خواهد بود.</p>
        <p className='mr-2'>4- محافظت از تمامی اطلاعات امنیتی کاربر اعم از نام کاربری، رمز عبور، کد دو مرحله ای و سایر موارد امنیتی بر عهده کاربر خواهد بود و تمامی مسئولیت های ناشی از افشا اطلاعات امنیتی، متوجه کاربر است.</p>

        <p>
        5- کاربر می پذیرد که آشنایی کاملی در خصوص سفارش های مارکت، و استاپ لاس دارد و قوانین و جزییات این گونه سفارش ها را از راهنمای رپیتکس خوانده و وقوف کاملی به نکات آن دارد.
        </p>
        <p>
        6- در صورت تخطی کاربر و فعالیت های غیرقانونی او، رپیتکس حق مطالبه ضرر و زیان های ناشی از این فعالیت ها را برای خود محفوظ می داند.
        </p>
        <p>
        7- در صورت وقوع قطعی سرویس و یا هر عاملی که خدمات رپیتکس را دچار اخلال کند رپیتکس در اسرع وقت، تلاش خود را برای احیای سرویس انجام خواهد داد اما هرگونه زیان و سود ناشی از این وقفه، بر عهده کاربر خواهد بود.
        </p>
        <p>
        8- کاربر می پذیرد که کلیه فعالیت های او در رپیتکس، تابع قوانین جمهوری اسلامی ایران خواهد بود.
        </p>
        <p>
        9- کاربر می پذیرد که منبع و مقصد تمامی دارایی ها در تراکنش های رپیتکس، مبتنی بر قوانین بین المللی و قوانین جمهوری اسلامی ایران است.
        </p>
        <p>
        10- کاربر می پذیرد که مالک قانونی تمام دارایی ها و حساب هایی است که در رپیتکس به آن ها واریز و یا از آنها برداشت می کند.
        </p>
        <p>
        11- کاربر متعهد می شود جز در موارد متعهد شده توسط رپیتکس در این موافقتنامه، حق هیچ گونه داعیه، طلب و شکایت از مدیران، کارکنان و تمامی افراد مرتبط با این سایت را نداشته باشد.
        </p>
        <p>
        12- کاربر می پذیرد که در زمان ثبت نام، به سن قانونی رسیده (۱۸ سال کامل شمسی) و یا از اهلیت قانونی کافی برخوردار است.(
        </p>
        <p>رپیتکس می پذیرد که:
        </p>
        <p>
        1- موظف به حفظ تمامی اطلاعات شخصی اعم از عکس، کارت شناسایی و تمامی اطلاعات مربوط به احراز هویت می باشد.
        </p>
        <p>
        2- تمامی اطلاعاتی شخصی، که برای رپیتکس ارسال شده است و همچنین اطلاعات حساب ها و میزان موجودی و تراکنش ها، محفوظ و محرمانه است و به هیچ شخص حقیقی و حقوقی ثالثی ارائه نخواهد شد مگر به درخواست مراجع قضایی و پلیس فتای جمهوری اسلامی ایران.
        </p>
        <p>
        3- پیشاپیش اطلاع رسانی جامع و به موقعی در مورد تغییرات در مفاد موافقتنامه، کارمزدها و تعمیرات سایت که منجر به عدم دسترسی به رپیتکس می شود، انجام دهد.
        </p>
        <p>
        4- در صورت بروز هرگونه مشکل در کارکرد سایت، در اسرع وقت و به صورت 24 ساعت، نسبت به رفع مشکل اقدام نماید.
        </p>
        <p>5- پاسخگوی سوالات و ابهامات و مشکلات کاربران، در کمترین زمان ممکن باشد.
        </p>
        <p>
        6- درکمترین زمان ممکن، فرایند احراز هویت را انجام دهد.

        </p>
        <p>
        حساب کاربری در رپیتکس:
        </p>
<p>1- کاربر برای استفاده از رپیتکس، می باید فرایند احراز هویت را به طور کامل انجام دهد.
</p>
<p>2- کاربر می پذیرد که تمامی اطلاعات ارائه شده در مراحل ثبت نام و احراز هویت و استفاده از رپیتکس، صحیح، غیر دستکاری شده و متعلق به شخص خود اوست و در صورت مغایرت، تمامی مسئولیت ها و عواقب قانونی آن بر عهده کاربر خواهد بود.
</p>
<p>3- حساب کاربری، می باید توسط شخصی مدیریت و اداره شود که احراز هویت را بر مبنای مدارک شخصی خود، انجام داده است. انتقال یا مدیریت حساب کاربری توسط هر شخصی غیر از خود کاربر تخلف محسوب شده و رپیتکس تمامی اقدامات قانونی را برای خود مفروض و محفوظ می داند.
</p>
<p>4- کاربر، در صورت بروز هرگونه تغییرات در مدارک احراز هویت، می باید مراتب را در اسرع وقت به رپیتکس اطلاع دهد و احراز هویت را بر مبنای مدارک جدید انجام دهد.
</p>
<p>5- در صورت برداشت دارایی از حساب کاربری، مسئولیت صحت آدرس کیف پول مربوط به رمز ارز، امنیت کیف پول مقصد، ملاحظات قانونی و امکان بلوکه شدن دارایی در مقصد، بر عهده کاربر است و رپیتکس هیچ گونه مسئولیتی در قبال صحت کیف پول مقصد ندارد.
</p>
<p>6- برداشت های ریالی تابع قوانین و محدودیت های بانک های داخلی است و زمان واریز آن، مبتنی بر آیین نامه های مربوط به سانتا و شبا خواهد بود.
</p>
<p>7- اگر رپیتکس، تحت هر عنوان اشتباهاً یا من غیر حق یا سهوا، وجوه یا مبلغی را به حساب کاربری لحاظ و یا تغییراتی اعمال کند، هر زمان مجاز است، مختارانه، راسا و بدون انجام هرگونه تشریفات اداری و بدون اجازه کتبی از صاحب حساب نسبت به رفع مشکل و ایراد اقدام نماید و تشخیص رپیتکس در این خصوص، معتبر خواهد بود و کاربر حق هرگونه اعتراض و ادعا در مورد نحوه عملکرد رپیتکس در این خصوص را از خود ساقط می نماید.
</p>
<p>8- در صورتی که کاربر، اطلاعات امنیتی خود را برای ورود به حساب کاربری از دست دهد رپیتکس برای اجازه دسترسی دوباره به حساب، حق درخواست هرگونه اطلاعات هویتی را برای خود محفوظ می داند و در صورت تشخیص عدم صلاحیت، این دسترسی ممکن نخواهد بود.
</p>
<p>9- رپیتکس، ثبت نام در سایت را، در گرو احراز هویت کامل کاربر تلقی می کند.
</p>
<p>10- تصمیم در مورد صحت اطلاعات ارائه شده توسط کاربر جهت احراز هویت، بر عهده رپیتکس خواهد بود و در صورت صلاحدید، از احراز هویت کاربر جلوگیری به عمل خواهد آمد.
</p>
<p>11- تراکنش های انجام شده در سفارش ها در رپیتکس غیر قابل بازگشت خواهند بود.
</p>
<p>12- صحت تمامی اطلاعات لازم در فرآیند سفارش گذاری، بر عهده کاربر خواهد بود. رپیتکس هیچ گونه مسئولیتی در قبال سفارش گذاری اشتباه و ضرر و زیان های ناشی از آن، نخواهد داشت.
</p>
<p>13- کاربر می پذیرد که مسئولیت مالیاتی تمامی تراکنش های او در رپیتکس بر عهده اوست.
</p>
<p>14- رپیتکس هیچ گونه مسئولیتی در قبال تاخیر، نقص فنی و هر مشکلی که مربوط به بانک یا سیستم رمز ارز در تراکنش های ناموفق باشد، نمی پذیرد.
</p>
<p>15- هرنوع کارمزد، کمیسیون و هر نوع هزینه ای که سیستم بانکی یا رمز ارز به کاربر اعمال می کند بر عهده کاربر خواهد بود.
</p>
<p>16- رپیتکس، تنها مسئول جابجایی مقدار مشخص شده در سفارش، مابین طرفین معامله است و هیچ گونه مسئولیت دیگری ندارد.
</p>

        <p>
        کارمزدها و هزینه ها:
تمامی هزینه ها و کارمزدهای رپیتکس و توضیحات مربوطه، در صفحه کارمزدها و هزینه ها قابل دسترس است.
تایید هویت:
        </p>
        <p>
        1- برای تایید هویت به منظور تبعیت از قوانین پولشویی، حداقل موارد زیر مورد نیاز خواهد بود:
        <li className='mr-2'>آدرس پست الکترونیک</li>
        <li className='mr-2'>خط تلفن همراه به نام خود</li>
        <li className='mr-2'>شماره تلفن ثابت</li>
        <li className='mr-2'>کارت ملی</li>
        <li className='mr-2'>حساب بانکی</li>
        <li className='mr-2'>عکس سلفی به همراه مدارکی که در بخش تایید هویت قید شده است</li>

        </p>
        <p>
        2- موارد احراز هویت می تواند به موارد فوق محدود نباشد و رپیتکس در هر مرحله ای از تایید هویت، می تواند مدارک و مستندات اضافی برای اطمینان از صحت احراز هویت، از کاربران درخواست نماید.
        </p>
        <p>
        سایر:
        </p>
            <p>
            1- کاربر، در صورت وجود مغایرت و تعلیق حساب کاربری، موظف به انجام تمامی همکاری های لازم خواهد بود.
            </p>
            <p>
            2- در صورت تعلیق حساب کاربری، تمامی دسترسی ها اعم از واریز، برداشت، امکان سفارش گذاری و غیره از کاربر سلب خواهد شد.
            </p>
            <p>
            3- رپیتکس، تعهدی در قبال سود ریالی یا غیر ریالی موجود در کیف پول کاربر در حساب کاربری نخواهد داشت.
            </p>
            <p>
            4-  رپیتکس می تواند مبادله‌ی ریال را در شرایط ذیل ممنوع کرده و دلایل آن را به کاربر اعلام کند:
            </p>
            <li className='mr-2'>در صورت وجود درخواست کتبی از محاکم و نهادهای دولتی.</li>
            <li className='mr-2'>در صورتی که مبلغ مبادله بسیار زیاد باشد.</li>
            <li className='mr-2'>            در صورتی که کاربر مرتکب جرم شده یا مظنون به تملک عواید از محل ارتکاب جرم باشد.</li>
            <li className='mr-2'>            در سایر موارد چنانچه محدود کردن استفاده از خدمات پرداخت بر اساس سیاست عملیاتی رپیتکس ضروری تشخیص داده شود.</li>

        <p>
        5- رپیتکس، در حدود قانون، هیچگونه تعهد یا ضمانتی برای اموری که در این توافق نامه قید نشده بر عهده نمی گیرد. علاوه بر این، در خصوص محصولات یا ارزش واحد پول مجازی که رپیتکس آن را صادر نکرده یا واگذار ننموده و همچنین پرداخت آن را ضمانت ننموده، هیچ گونه تعهدی نخواهد داشت.

        </p>
<li className='mr-2'> خسارات ناشی از دسترسی غیر قانونی شخص ثالث به سرور و استفاده غیر قانونی از آن</li>
<li className='mr-2'> ضررهای ناشی از انتقال و انتشار بد افزارها از سوی شخص ثالث</li>
<li className='mr-2'> ضررهای ناشی از استفاده شخص ثالث از خدمات، خساراتی ناشی از دور زدن، حذف یا نابودی اطلاعات ارسال شده .</li>
<li className='mr-2'> ضررهای ناشی از قصور در خدمات ارتباطاتی توسط بستر ارائه خدمات. به ویژه مواردی که ارائه دهنده خدمات ارتباطاتی در صورت وجود رابطه حقوقی فی ما بین ما و کاربران موجب ناتوانی ما در ارائه‌ی خدمات شود و غیر قابل کنترل باشد. ضررهای ناشی از موارد قهریه از قبیل جنگ، آتش سوزی، زلزله و سایر بلایای طبیعی و موارد اضطراری ملی ضررهای ناشی از ویژگی های خاص رمز ارز. به ویژه مواردی که موجب نقص یا محدودیت فنی در سیستم مدیریت. در ارائه‌ی خدمات از سوی رپیتکس می‌شود.</li>
<li className='mr-2'> در صورتی که سرورها را برای ارائه‌ی خدمات قطع شود یا از دسترس خارج شوند</li>
<li className='mr-2'>سایر خسارات ناشی از دلایلی که ناشی از اعمال یا عمل رپیتکس نباشد.</li>

        <p>
        ارائه‌ی خدمات را از سوی رپیتکس متوقف گردیده یا تمام یا بخشی از این توافقنامه فسخ می شود. چنانچه اطلاعات ارائه شده از سوی کاربر با واقعیت همخوانی نداشته باشد. در صورتی که خسارتی متوجه رپیتکس شود، رپیتکس می‌تواند خسارت مذکور را از حساب کاربر برداشت نماید.

        </p>
        <p>

6- در صورت ورود خسارت به رپیتکس که ناشی از فعالیت های غیر قانونی کاربر باشد، رپیتکس می تواند حق مطالبه‌ی خسارت علیه کاربر را به موجب قانون استفاده کند.
        </p>
        <p>
        7- مواردی که در این موافقتنامه درج نشده است، تابع قوانین و مقررات جمهوری اسلامی ایران و یا تابع قواعد اعلام شده از سوی رپیتکس خواهد بود.
        </p>

        </div>
      </div>
      </>
    )
  }
}
export default withTranslation()(About)
