import React, { PureComponent } from 'react';
import {Row,Col,Table,Card,CardBody,Dropdown,ButtonGroup, DropdownMenu, DropdownToggle,Button} from 'reactstrap'
import {Link} from 'react-router-dom'
import axios from 'axios'
import cookie from 'react-cookies'
import SimpleLineIcon from 'react-simple-line-icons'
import nodata  from '../../../style/images/no-data.png'
import usericon  from '../../../style/images/usericon.svg'
import NumericFormat from 'react-number-format';
import NumberFormat from 'react-number-format';
import Loader from '../../landing/loader'
import {Helmet} from 'react-helmet'
import {withTranslation} from 'react-i18next'
import { FcManager } from "react-icons/fc"
import { PiCopySimpleThin } from "react-icons/pi";
import { FaRegEyeSlash,FaRegEye } from "react-icons/fa";
import SearchInput, {createFilter} from 'react-search-input'
import {AiOutlineStock,AiOutlineClose} from 'react-icons/ai'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Switch from "react-switch";
import { FaChartPie,FaChartSimple,FaCodiepie } from "react-icons/fa6";

import { FaArrowDown,FaArrowUp } from "react-icons/fa6";
import { FaCreditCard} from "react-icons/fa";
import { CiBank } from "react-icons/ci";
import { MdOutlineManageHistory } from "react-icons/md";


import { Carousel } from 'react-responsive-carousel'
import { PieChart, Pie, Sector, Cell,ResponsiveContainer } from 'recharts';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { LineChart, Line } from 'recharts';
const MySwal = withReactContent(Swal)

class Dashboard extends PureComponent{
  constructor(props){
    super(props);
    this.state={
      irb : 0,
      usb : 0,
      btc : [],
      eth : [],
      bnb : [],
      logs : [],
      ch : [],
      merged : [],
      irrmarket : [],
      activeIndex: 0,
      list : [],
      rowlingth : 2,
      transactions : [],
      accounts : [],
      lm : false,
      loader : true,
      name : '',
      email : '',
      usercode : '',
      userlevel : '',
      usertype : '',
      showassets : true,
      assetvalue : '',
      assetequal : '',
      c:[],
      cl1 :[],
      cl2 :[],
      c1 : [],
      ca : false,
      assetview : true,
      showassets : true,
      chartmode : 'pie',
      assetcurrency : 'IRR',
      sl : 10,
      noaccounts : [
        {
        key  : 'IRR',
        value : 100,
        color : '#f0f0f0'},
        {key  : 'BTC',
        value : 100,
        color : '#1e313c'},
        {key  : 'ETH',
        value : 100,
        color : '#3f4c55'},
        {key  : 'BNB',
        value : 100,
        color : '#e3a51a'},
        {key  : 'KCS',
        value : 100,
        color : '#dce7c5'},
        {key  : 'USDT',
        value : 0,
        color : '#3f4c55'}

      ]
    }
  }

  getalllist = ()=>{
    let count = this.state.sl
    let sl = count +50
    this.setState({sl : sl})
  }
  generateData() {
      const data = [];
      const keys =this.state.list
if(keys === undefined || keys.length === 0){
  return
}
      keys.forEach( (key , index)=> {
        data.push({
          name : key.slug,
          color : key.color,
          balance: parseFloat(key.availablebalance) * parseFloat(key.price),
          availablebalance: key.availablebalance,
          value: key.availablebalance,
        });
      });
      return data;
    }
UNSAFE_componentWillMount(){
  if(!cookie.load('uid')) {
    this.props.history.push('/')
    return
  }

  axios.get('/api/dashboard/userinfo').then(response=>{
    let list = response.data.list.irrmarket
    let x = list.map(itm =>({
      ...response.data.accounts.find((item) => (itm.baseCurrency === item.slug) && (itm.availablebalance = item.availablebalance)),
      ...itm
    }));


var sortedArray = x.sort(function(a, b) { return b.availablebalance - a.availablebalance; });
  this.setState({merged : sortedArray})
    if(!cookie.load('uid')){
      this.props.history.push('/auth/login')
    }
    if(response.data.status === 'error-fatal'){
      this.props.history.push('/auth/login')
    }
    this.setlevel(response.data.level)
    const cd = [];
    const keys = response.data.accounts
    if(keys === undefined || keys.length === 0){
      return
    }
    keys.forEach( (key , index)=> {
      cd.push({
        name : key.slug,
        color : key.color,
        balance: key.availablebalance,
        availablebalance: key.availablebalance,
        balance: parseFloat(key.availablebalance) * parseFloat(key.irprice),
        value: parseFloat(key.availablebalance) * parseFloat(key.irprice),
      });
    });
    localStorage.setItem('email',response.data.email)
    localStorage.setItem('name',response.data.name)
    localStorage.setItem('ip',response.data.ip)
    localStorage.setItem('device',response.data.device)

    this.setState({
      chartdata : cd,
      c : cd[0],
      btc : response.data.btc,
      eth : response.data.eth,
      bnb : response.data.bnb,
      name : response.data.name,
      email : response.data.email,
      list : response.data.list.markets,
      irrmarket : response.data.list.irrmarket,
      usercode : response.data.ucode,
      ch : response.data.chartdata,
      loader : false ,irb : response.data.irr , usb : response.data.usd , logs : response.data.logs, transactions : response.data.transactions, accounts : response.data.accounts})
  })
}
setlevel =(i)=>{
  const {t} = this.props
  switch (i) {

    case '1':
        this.setState({userlevel : `${t('level-1')}`})
      break;
    case '2':
        this.setState({userlevel : `${t('level-2')}`})
      break;
    case '4':
        this.setState({userlevel : `${t('level-4')}`})
      break;
    default:
    this.setState({userlevel : `${t('level-0')}`})
  }
}
forwradexchangeirr = ()=>{
  this.props.history.push('/panel/exchange/buy/IRR')
}
forwradexchange = ()=>{
  this.props.history.push('/panel/exchange/buy/USDT')
}
setShowassets =()=>{
  this.setState({showassets : !this.state.showassets})
}
toggledropdown =()=>{
  this.setState({dropdownOpen : !this.state.dropdownOpen})
}
toggleasset =(i)=>{
  this.setState({assetcurrency : i})
  this.toggledropdown()
}
forwardmarket =(i)=>{
  if(i.baseCurrency === 'IRR' || i.baseCurrency === 'PERFECT' || i.baseCurrency === 'FTN' ){
    return '#'
  }else{
    return '/trade/'+i.baseCurrency+'-USDT'
  }

}
forwardbuysell =(i)=>{

   if(cookie.load('uid')){
  return '/panel/withdrawall/'+i.baseCurrency
}else{
   return '/auth/login'
}
}
serach1 =(e)=>{
  this.setState({sd1 : e.target.value})
}
serach2 =(e)=>{
  this.setState({sd2 : e.target.value})
}
handleChange = (checked) =>{
    this.setState({ checked : !this.state.checked });
  }
copyusercode=()=>{
  const {t} = this.props
  navigator.clipboard.writeText(this.state.usercode)
  console.log(
    <div dir='rtl'>
      <span className='rtl'>{t('copied !')}</span>
    </div>,
  );
}
levelmodal =()=>{
  this.setState({lm : !this.state.lm})
}
setcharttype =(type)=>{
  this.setState({chartmode : type})
}
onPieEnter = (_, index) => {
  const data = this.state.chartdata
    this.setState({
      activeIndex: index,
    });
    const found = data[index]
    this.setState({c : found})
  };
componentDidMount(){

}
changeassetview = ()=>{
  this.setState({assetview : !this.state.assetview})
}
render(){
  const {t} = this.props
  const data = this.state.chartdata
  const emtypchart = this.state.noaccounts
  const coinlist = this.state.merged

  return(
    <>

    <Helmet>
       <title>{t('dashboard-title')}</title>
    </Helmet>
    {this.state.loader === true ?
      <>
        <div className='loader-home'>
          <h3 className=''><Loader /></h3>
        </div>
      </> :
    <div className='rtl' id='dashboard'>
    <div className='xs-screen '>
      <div className='xs-overlay d-flex'>
        <div class="menuSection">
          <img src={usericon} width='50px' className='usericon'/>
        </div>
        <div class="menuSection">
          <p className='mb-3'>{this.state.name}
          </p>
          <p className=''>{this.state.email}
          </p>
        </div>
        <div class="menuSection">
          <p className='mb-3'>{t('userid')}
          </p>
          <div className='d-flex'>
          <p className=''>{this.state.usercode}</p>
          <p className='' onClick={this.copyusercode} ><PiCopySimpleThin size='1rem' className='pointer  mr-2' /></p>
          </div>
        </div>
        <div class="menuSection">
          <p className='mb-3'>
          {t('userlevel')}
          </p>

          <p>{this.state.userlevel}</p>
        </div>
        <div class="menuSection">
          <p className=' mb-3'>{t('last-login')}
          </p>
            <div className=''>
            {this.state.logs.slice(0,1).map(log=>
              <div key={log.id} className='d-flex'>
                <span className=' text-successa'>{log.date}</span>
                <span className='mx-3 '>{log.ip}</span>
                <span className=''>{log.devicetype !== '0' ? <SimpleLineIcon name='screen-smartphone'  /> : <SimpleLineIcon name='screen-desktop'  />}</span>
              </div>
            )}
            </div>
        </div>
        </div>

    </div>


      <Row className='d-row-1'>
        <Col xl='7' lg='6' md='12' sm='12' xs='12'>
          <div className=''>
          <Row className='no-gutters rtl' xl='1'>
            <Col xl='12' className='p-3'>

            </Col>
            <Col xl='12' className='d-none d-md-block my-2'>
              <Row  className='xs-text-center d-flex no-gutters-xs ltr'>
                <Col xl='12' lg='12' xs='12' md='12' sm='12' className='rtl'>
                <div className='d-flex mt-5 mb-3 py-3 '>
                <h6 className='mt-3'>{t('assets')}
                </h6>
                <div className='mr-3 mt-3 ml-4'>
                {this.state.showassets === true ?
                <FaRegEye onClick={this.setShowassets} size='1rem' /> :
                <FaRegEyeSlash onClick={this.setShowassets} size='1rem' /> }
                </div>
                  <h1 className='my-auto '>
                  {this.state.assetcurrency === 'IRR' ?
                  this.state.showassets === false ? '******' :<h1> <NumberFormat className='h3' displayType='text' thousandSeparator=',' value={this.state.irb.toFixed(0)} />  IRR</h1>: <></> }
                  </h1>
                </div>
                </Col>
                <Col  lg='3'  md='3' className='no-gutters-xs'><Link className='' to='/panel/depositirt'><Button className='py-2 btn-block' color='warning' size='sm' block>{t('deposit')}</Button></Link></Col>
                <Col  lg='3'  md='3' className='no-gutters-xs px-2'><Link className='' to='/panel/withdrawirt'><Button className='py-2 btn-block' color='primary' size='sm' block>{t('withdraw')}</Button></Link></Col>
                <Col  lg='3'  md='3' className='no-gutters-xs'><Link className='' to='/panel/Exchange/buy/BTC'><Button className='py-2 btn-block' color='success' size='sm' block>{t('exchange')}</Button></Link></Col>
                <Col  lg='3'  md='3' className='no-gutters-xs'><Link className='' to='/panel/Exchange/buy/BTC'><Button className='py-2 btn-block' color='danger' size='sm' block>{t('sell')}</Button></Link></Col>
              </Row>
            </Col>
          </Row>
          </div>
        </Col>
        <Col xl='' lg='6' md='12' sm='12' xs='12' className='text-center'>
        {this.state.irb > 0 ?
          <div id='dashboard-chart-section' className='mx-auto text-center'>
          <ResponsiveContainer height={370}>
           <PieChart width={400} height={400}>
             <Pie
             activeIndex={this.state.activeIndex}
             activeShape={renderActiveShape}
             data={data}
             cx="50%"
             cy="50%"
             innerRadius={70}
             outerRadius={99}
             fill="#8884d8"
             dataKey="value"
               onMouseEnter={this.onPieEnter}
             />
           </PieChart>
           </ResponsiveContainer>
         </div> : <></> }
         <div className='ltr py-2'  >
         <div className='d-flex ltr'>
         <p className={this.state.c.name+'-icon mr-3 icon absolute'} />
          <li className=' my-auto ml-5 font-weight-bold'>{this.state.c.name}</li>
          <span className=' my-auto  text-small mx-4'>{this.state.showassets === false ? '******' : <NumberFormat value={this.state.c.availablebalance } displayType='text' thousandSeparator=',' />}</span>
          <span className=' my-auto  text-small mx-4'>{this.state.showassets === false ? '******' : <NumberFormat decimalScale={0} value={this.state.c.value} displayType='text' prefix=' ' thousandSeparator=',' />}{t('irr')}</span>
         </div>
         </div>
        </Col>
        <Col xl='12' className='d-block d-md-none my-2'>
          <div className='dashboard-icon-row d-flex justify-content-space-between'>
          <Link to='/panel/withdrawall' ><div className='send-btn'>
          <FaArrowUp size='1rem' />
          </div></Link>
          <Link to='/panel/depositcrypto' ><div className='recieve-btn'>
          <FaArrowDown size='1rem' />
          </div></Link>
          <Link to='/panel/depositirt' ><div className='deposit-r-btn'>
          <FaCreditCard
           size='1rem' />
          </div></Link>
          <Link to='/panel/withdrawirt' ><div className='withdraw-btn'>
          <CiBank size='1rem' />
          </div></Link>
          <Link to='/panel/transactions' ><div className='history-btn'>
          <MdOutlineManageHistory size='1rem' />
          </div></Link>
          </div>
        </Col>
        <Col xl='12' className='d-block d-md-none my-2'>
          <div className='px-3 dashboard-icon-row d-flex justify-content-space-between'>
          <span className='icon-row-slug' >{t('send-slug')}</span>
          <span className='icon-row-slug' >{t('recieve-slug')}</span>
           <span className='icon-row-slug' >{t('deposit-slug')}</span>
          <span className='icon-row-slug' >{t('withdraw-slug')}</span>
          <span className='icon-row-slug' >{t('history-slug')}</span>
          </div>
        </Col>


      </Row>

      <div className='divider rtl  my-5'/>

      <Row xl='6' className='d-none list-header mt-5'>
        <Col xs='2' xl='2'  className='my-auto'>

        <span className='rtl mr-2 my-auto font-weight-bold'>نماد</span>

        </Col>
        <Col xs='2' xl='2'  className='my-auto'>

        <span className='rtl mr-2 my-auto font-weight-bold'>موجودی</span>

        </Col>
        <Col xs='3' md='2' sm='3' xl='2' className='my-auto '><span className='my-auto tyext-center font-weight-bold'>خرید</span></Col>
        <Col xs='2' md='2' sm='2' xl='2' className='my-auto '><span className='my-auto tyext-center font-weight-bold'>فروش</span></Col>
        <Col xs='2' md='2' sm='2' xl='2' className='d-none d-lg-block my-auto text-center'>
        <span className='rtl my-auto font-weight-bold'>ارزش بازار </span>
        </Col>
        <Col xs='4' md='2' sm='3' lg='4' xl='2' className='my-auto d-none d-md-block'>
        <div className='d-flex'>
        <span className='mr-auto ml-3 my-auto font-weight-bold'>تغییر واحد ارزی</span>
        <Switch
        width={70}
        onChange={this.handleChange}
        checked={this.state.checked}
        offColor="#08f"
        onColor="#08f"
        uncheckedIcon={
          <span className='rtl pt-2 mt-2  ml-2'>
            تتر
          </span>
        }
        checkedIcon={
          <span className='rtl pt-2 mt-2  ml-3'>
            ریال
          </span>
        }
         />
      </div>
        </Col>
      </Row>
      <div className='d-none crypto-rows h-400 custom-overflow pb-5 mt-1'>
      {this.state.merged.slice(0,this.state.accounts.length).map(i=>
        <Link to={this.forwardmarket(i)}>
        <Row key={i.id} className='coin-list-row rtl'>
          <Col xs='3' xl='2' lg='1'  className='my-auto no-gutters'>
          <span className={i.baseCurrency+'-icon my-auto mr-2 xs-md-icon'}></span>

          <p className='mt-2 mr-4 pr-4 rtl '>{i.baseCurrency}</p>
          </Col>
          <Col xs='2' xl='2'  className='rtl  my-auto'>

          <NumberFormat className='' value={this.state.showassets === false ? '******' : i.availablebalance} thousandSeparator=',' displayType='text' />

          </Col>
          <Col  xl='2'  lg='2'  md='2' sm='2' xs='3'  className='my-auto '>
          <p className='my-auto'><NumericFormat value={this.state.checked === true ? parseFloat(i.sell) : parseInt(i.irsell)}  thousandSeparator="," displayType="text"  className='font-weight-bold  my-auto' /></p>
          <p className='d-block d-md-none my-auto'><NumericFormat value={this.state.checked === true ? parseFloat(i.buy) : parseInt(i.irbuy)}  thousandSeparator="," displayType="text"  className='font-weight-bold  my-auto' /></p>

          </Col>
          <Col  xl='2'  lg='2'  md='2' sm='2' xs='2'  className='my-auto d-none d-md-block'>
          <p className='my-auto'><NumericFormat value={this.state.checked === true ? parseFloat(i.buy) : parseInt(i.irbuy)}  thousandSeparator="," displayType="text"  className='font-weight-bold  my-auto' /></p>
          </Col>
          <Col  xl='2'  lg='2'  md='2' sm='2' xs='3'  className='d-none d-lg-block my-auto text-center'>
          <small className='d-block text-center'><NumericFormat value ={i.volValue}  thousandSeparator="," displayType="text"  className='small  font-weight-bold' /> </small>
          {parseFloat(i.changeRate) > 0 ?
            <small className="text-small text-success text-center coin-name" >  {parseFloat(i.changeRate) + '+'}</small> :
            <small className="text-small text-danger text-center m-auto coin-name" >  {parseFloat(i.changeRate) * -1 + ' -'}</small>}
          </Col>
          <Col  xl='2'  lg='4' md='4' sm='4' xs='3' className='ltr my-auto d-none d-md-block'>
            <div className='px-2 mr-auto'>
              <ButtonGroup block>
                  <Button className='' color='primary'><Link to={this.forwardmarket(i)} color='primary' block className='text-white-fixed text-small px-1'>معامله</Link></Button>
                  <Button className='' color='primary'><Link to={this.forwardbuysell(i)} block className='text-white-fixed text-small px-1'>واریز</Link></Button>
                  <Button className='' color='primary'><Link to={this.forwardbuysell(i)} block className='text-white-fixed text-small px-1'>برداشت</Link></Button>
              </ButtonGroup>
            </div>
          </Col>
          <Col xs='3' className='my-auto d-block d-md-none ltr'>
            <div className='d-flex rtl'>
              {parseFloat(i.changeRate) > 0 ?
              <Button block  color='success' className="text-small text-center coin-name" >  {parseFloat(i.changeRate) + '+'}</Button> :
              <Button block  color='danger' className="text-small text-center m-auto coin-name" >  {parseFloat(i.changeRate) * -1 + ' -'}</Button>}
            </div>
          </Col>
        </Row>
        </Link>
      )}
      <div className='text-center mt-5'>
      </div>
      </div>
      <Row className='pb-5'>
        <Col className='mx-auto cryptolist-table'>
        <Table className='dashboard-table' responsive size='small' borderless>
          <thead className='ct-t text-small  small'>
            <tr>
              <td className='pt-2 pb-2 rtl mr-2 my-auto font-weight-bold'>نماد</td>
              <td className='pt-2 pb-2 rtl mr-2 my-auto font-weight-bold'>موجودی</td>
              <td className='pt-2 pb-2 my-auto tyext-center font-weight-bold'>خرید</td>
              <td className='pt-2 pb-2 my-auto tyext-center font-weight-bold'>فروش</td>
              <td className='pt-2 pb-2 small my-auto font-weight-bold'>
                <div className='d-flex'>
                <Switch
                width={70}
                className=''
                onChange={this.handleChange}
                checked={this.state.checked}
                offColor="#08f"
                onColor="#08f"
                uncheckedIcon={
                  <small style={{
                      "width": " 42px",
                      "position": " absolute",
                      "opacity": " 1",
                      "right": " -20px",
                      "top": " 5px",
                      "pointer-events": "none",
                      "transition": " opacity 0.25s ease 0s",
                  }}>
                    تتر
                  </small>
                }
                checkedIcon={
                  <small style={{
                      "width": " 42px",
                      "position": " absolute",
                      "opacity": " 1",
                      "left": " 5px",
                      "top": " 5px",
                      "pointer-events": "none",
                      "transition": " opacity 0.25s ease 0s",
                  }}>
                    ریال
                  </small>
                }
                 />
                </div>
            </td>
            </tr>
          </thead>
          <tbody>
          {this.state.merged.slice(0,this.state.accounts.length).map(i=>
            <tr className='crypto-tr' >
              <td className='crypto-td-1'>
                <p className={i.baseCurrency+'-icon icon'}></p>
                <p className='slug mr-5'>{i.baseCurrency}</p>
              </td>

              <td className='crypto-td-2'>
                <p>{this.state.showassets === false ? '******' : <NumberFormat decimalScale={i.bi} className='' value={this.state.showassets === false ? '******' : i.availablebalance} thousandSeparator=',' displayType='text' />}</p>
                <p>{this.state.showassets === false ? '******' : <NumberFormat decimalScale={0} className='' value={this.state.showassets === false ? '******' : i.availablebalance * i.irsell} thousandSeparator=',' displayType='text' />}</p>
              </td>
              <td className='crypto-td-7 ltr'>
                <p className={i.baseCurrency+'-icon mr-auto absolute d-block'}></p>
                <p className='slug'>{i.baseCurrency}</p>
                <p className='price-cr'>
                <span><NumberFormat decimalScale={i.bi} className='' value={i.irsell} thousandSeparator=',' displayType='text' /></span>
                <span className={parseFloat(i.changeRate) > 0 ? 'text-success ml-2' : 'text-danger ml-2' }>{i.changeRate}</span></p>
              </td>
              <td className='crypto-td-3' >
                <p className='py-3 '><NumericFormat value={this.state.checked === true ? parseFloat(i.sell) : parseInt(i.irsell)}  thousandSeparator="," displayType="text"  className='font-weight-bold  my-auto' /></p>
              </td>
              <td className='crypto-td-4' >
                <p className=''><NumericFormat value={this.state.checked === true ? parseFloat(i.buy) : parseInt(i.irbuy)}  thousandSeparator="," displayType="text"  className='font-weight-bold  my-auto' /></p>
              </td>
              <td className='crypto-td-5'>
                  <ButtonGroup className='bg-primary' style={{"height":"40px"}} block>
                      <Button className='' color='primary'><Link to={this.forwardmarket(i)} color='primary' block className='text-white-fixed text-small px-1'>معامله</Link></Button>
                      <Button className='' color='primary'><Link to={this.forwardbuysell(i)} block className='text-white-fixed text-small px-1'>واریز</Link></Button>
                      <Button className='' color='primary'><Link to={this.forwardbuysell(i)} block className='text-white-fixed text-small px-1'>برداشت</Link></Button>
                  </ButtonGroup>
              </td>
              <td className='crypto-td-6'>
                  {parseFloat(i.changeRate) > 0 ?
                  <Button block  color='success' className="w-100" >  {parseFloat(i.changeRate) + '+'}</Button> :
                  <Button block  color='danger' className="w-100" >  {parseFloat(i.changeRate) * -1 + ' -'}</Button>}
              </td>
            </tr>
          )}
          </tbody>
        </Table>

        </Col>


      </Row>


    </div >}
  </>
  )
}
}
export default withTranslation()(Dashboard)
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,color, fill, payload,name,balance, percent, value ,index} = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
   <g>
     <text x={cx} y={cy} dy={8} textAnchor="middle" fill={payload.color}>
       {payload.name}
     </text>
     <text x={cx} y={cy -20} dy={8} textAnchor="middle" fill="#999">
       {` ${(percent * 100).toFixed(2)}%`}
     </text>
     <Sector
       cx={cx}
       cy={cy}
       innerRadius={innerRadius}
       outerRadius={outerRadius}
       startAngle={startAngle}
       endAngle={endAngle}
       fill={payload.color}
     />
     <Sector
       cx={cx}
       cy={cy}
       startAngle={startAngle}
       endAngle={endAngle}
       innerRadius={outerRadius + 6}
       outerRadius={outerRadius + 10}
       fill={payload.color}
     />
     <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke='none' fill="none" />

</g>
);
};


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
