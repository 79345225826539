import React from "react";
import cookie from 'react-cookies'
import axios from 'axios'
import { widget } from './charting_library';
import {Navbar,Tooltip , NavbarText,Card, Button,Dropdown, CardHeader, CardFooter, CardBody,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
CardTitle, CardText,Nav,NavLink, Form, FormGroup, Label, Input,FormFeedback,Container,Col,Row,Table,List,FormText,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {Helmet} from 'react-helmet'
import {RiFileListLine,RiFileForbidLine} from "react-icons/ri";
import {FaHandHoldingUsd,FaHandHoldingMedical} from "react-icons/fa";
import {BsGraphUp,BsGraphDown} from "react-icons/bs";
import {BiDotsVertical} from "react-icons/bi";
import {AiOutlineStock,AiOutlineClose} from 'react-icons/ai'
import {Link,withRouter} from 'react-router-dom'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
import { withTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';
import NumberFormat from 'react-number-format';
import askbid from '../../../style/images/askbid.svg'
import ask from '../../../style/images/ask.svg'
import bid from '../../../style/images/bid.svg'
import {FcDeleteDatabase} from "react-icons/fc";
import {AiFillStar,AiOutlineStar} from "react-icons/ai";
import SearchInput, {createFilter} from 'react-search-input'
import Toast from 'awesome-toast-component'

class Wallet extends React.Component{
  constructor(props){
    super(props);
    this._ref = React.createRef();
    this.markets = [];
    this.baseCurrency = 'BTC';
    this.quoteCurrency = 'USDT';
    this.usdt = 1;
    this.state = {
      data : [],
      fav: [],
      symbol : 'KUCOIN:BTCUSDT',
      theme : 'light',
      darkMode : false,
      navbarcl : 'bg-ocean xl-navbar',
      toggletooltip : false,
      intervaltime : 30000,
      market : 'BTCUSDT',
      bids :[],
      book :[],
      orders :[],
      modalmenuitem : 'all',
      marketmodal : true,
      infotoltip : true,
      showorders : false,
      data :[],
      asks :[],
      history :[],
      wsres :[],
      modalfullscreen : false,
      isopen : false,
      chartvisible : true,
      order : 'market',
      ot : 'open',
      type : 'buy',
      orders : [],
      at : '1',
      ordershistory : [],
      openorders : [],
      closeorders : [],
      accounts : [],
      markets : [],
      seemore : 30 ,
      basebalance : 0 ,
      quotebalance : 0,
      marketplace : '',
      step : 0.1,
      list : 1,
      loggedin : false,
      lsmodal : false,
      fee : 0.005,
      sellbutton : false,
      searchTerm : '',
      term : '',
      buybutton : false,
      marketmodal : false,
      }

          let timer;
          this.validator = new SimpleReactValidator();
          this.setmarket = this.setmarket.bind(this)
    }
    modalfullscreen =()=>{
      this.setState({modalfullscreen : !this.state.modalfullscreen})
    }
    changetheme =()=>{
      if(this.state.theme === 'dark'){
        this.setState({theme : 'light'})
      }
      if(this.state.theme === 'light'){
        this.setState({theme : 'dark'})
      }
    }
    setmarket = (i) =>{

      clearInterval(this.interval);
      // let url = this.state.url
      // let  ws =  this.WebSocket(url);
      this.ws.close()

      this.setState({history : [] , asks : [] , bids : [] ,term : ''})
      let base = i.baseCurrency.toUpperCase()
      let quote = i.quoteCurrency.toUpperCase()
      let m = base+'-'+quote

      this.props.history.push('/trade/'+ m)
      if(quote !== 'IRR'){
        this.baseCurrency = base
        this.quoteCurrency2 = quote
        this.quoteCurrency = quote
        this.market = this.baseCurrency+'-'+this.quoteCurrency
        this.market2 = this.baseCurrency+'-'+this.quoteCurrency2
        // this.chart = this.tvchart(this.market)
    }else{

      if(base === 'USDT'){
        this.baseCurrency = 'USDT'
        this.baseCurrency2 = 'USDT'
        this.quoteCurrency2 = 'USDC'
        this.quoteCurrency = 'IRR'
        this.market = this.baseCurrency+'-'+this.quoteCurrency
        this.market2 = this.baseCurrency+'-'+this.quoteCurrency2
      }else{
        this.baseCurrency2 = base
        this.baseCurrency = base
        this.quoteCurrency2 = 'USDT'
        this.quoteCurrency = 'IRR'
        this.market = this.baseCurrency+'-'+this.quoteCurrency
        this.market2 = this.baseCurrency+'-'+this.quoteCurrency2
      }

    }
    this.setState({base : this.baseCurrency , base2 : this.baseCurrency2 , quote : this.quoteCurrency , quote2 : this.quoteCurrency2 , market : this.market , market2 : this.market2})
    this.set()
     this.ochart(this.market)
    this.fetchhistory()
    this.updatedata()

    this.setState({marketmodal : false})
    this.baseCurrency =i.baseCurrency.toUpperCase()
    this.quoteCurrency = i.quoteCurrency.toUpperCase()
    this.fetchdata()
  }

    componentDidMount() {
      this.ochart(this.market)


    }
    componentWillMount(){
      const theme = document.body.getAttribute('class')
      if(theme === 'dark'){
        this.setState({darkMode : true  , theme : 'dark'})
      }else{
        this.setState({darkMode : false  , theme : 'light'})
      }
      let fav = localStorage.getItem('favorite');
      if(fav){
        this.setState({fav : JSON.parse(fav)})
      }else{
        localStorage.setItem('favorite',[])
      }
      if(cookie.load('uid')){
        this.setState({loggedin : true})
        }
      let symbol =this.props.match.params.symbol.toUpperCase();
      var fields = symbol.split('-');
      var base = fields[0];
      var quote = fields[1];
      if(quote !== 'IRR'){
        this.usdt = 1
        this.baseCurrency = base
        this.quoteCurrency2 = quote
        this.quoteCurrency = quote
        this.market = this.baseCurrency+'-'+this.quoteCurrency
        this.market2 = this.baseCurrency+'-'+this.quoteCurrency2
    }else{

      if(base === 'USDT'){
        this.baseCurrency = 'USDT'
        this.baseCurrency2 = 'USDT'
        this.quoteCurrency2 = 'USDC'
        this.quoteCurrency = 'IRR'
        this.market = this.baseCurrency+'-'+this.quoteCurrency
        this.market2 = this.baseCurrency+'-'+this.quoteCurrency2
      }else{
        this.baseCurrency2 = base
        this.baseCurrency = base
        this.quoteCurrency2 = 'USDT'
        this.quoteCurrency = 'IRR'
        this.market = this.baseCurrency+'-'+this.quoteCurrency
        this.market2 = this.baseCurrency+'-'+this.quoteCurrency2
      }

    }
    this.setState({base : this.baseCurrency ,baseCurrency : this.baseCurrency , base2 : this.baseCurrency2 , quote : this.quoteCurrency, quoteCurrency : this.quoteCurrency , quote2 : this.quoteCurrency2 , market : this.market , market2 : this.market2})
    this.set()
    this.fetchhistory()
    this.updatedata()
    this.fetchdata()
    }
    // componentWillUpdate(){
    //   this.setmarket()
    // }
    componentWillUnmount(){
      clearInterval(this.interval);
      // if(this.ws){
        this.ws.close()
      // }
    }
    update = () =>{
      // let base = this.state.baseCurrency
      // let quote = this.state.quoteCurrency
      let symbol = this.market
      axios.get('/api/mainmarket/getmarket/'+ symbol.toUpperCase()).then(response=>{
        // MySwal.fire({
        //   title: <p>Hello World</p>,
        //   didOpen: () => {
        //     // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        //     MySwal.showLoading()
        //   },
        // }).then(() => {
        //   return MySwal.fire(<p>Shorthand works too</p>)
        // })
        if(response.status !== 200){
          return
        }
        if(response.data.status === 'error-fatal'){
          cookie.remove('uid')
          this.setState({loggedin : false})
        }
        // if(this.usdt === ''){
        //   this.usdt = response.data.usdt
        // }else{
        //
        // }
        this.usdt = response.data.usdt
        this.markets = response.data.markets



        this.setState({
            baseCurrencybalance : response.data.balance.baseCurrencybalance,
            quoteCurrencybalance : response.data.balance.quoteCurrencybalance,
            fee : response.data.limits.fee,
            limits : response.data.limits,
            level : response.data.userlevel,
            loggedin : response.data.loggedin,
            openorders : response.data.orders.openorders,
            closeorders : response.data.orders.closeorders,
            intervaltime : response.data.intervaltime,
            usdt : response.data.usdt,

          })
      })
    }
    set = () =>{
      // let base = this.state.baseCurrency
      // let quote = this.state.quoteCurrency
      let symbol = this.market
      axios.get('/api/mainmarket/getmarket/'+ symbol.toUpperCase()).then(response=>{
        // MySwal.fire({
        //   title: <p>Hello World</p>,
        //   didOpen: () => {
        //     // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        //     MySwal.showLoading()
        //   },
        // }).then(() => {
        //   return MySwal.fire(<p>Shorthand works too</p>)
        // })
        if(response.status !== 200){
          return
        }
        if(response.data.status === 'error-fatal'){
          cookie.remove('uid')
          this.setState({loggedin : false})
        }
        // if(this.usdt === ''){
        //   this.usdt = response.data.usdt
        // }else{
        //
        // }
        this.usdt = response.data.usdt
        this.markets = response.data.markets

        this.token = response.data.token
        this.id = response.data.id
        let token = this.token
        let id = this.id
        let url = "wss://ws-api-spot.kucoin.com/?token="+token+"&[connectId="+id+"]"
        this.ws = new WebSocket(url);
        let market = this.market2

        var c = {
            "id": id,
            "type": "subscribe",
            "topic": "/spotMarket/level2Depth50:" + market,
            "privateChannel": false,
            "response": true
          }
          this.ws.onopen = (event) => {
          // ws.send(JSON.stringify(d));
          this.ws.send(JSON.stringify(c));
        };

        this.ws.onmessage = (event) => {
          const json = JSON.parse(event.data);
              if(!json.data){
                return
              }
              let d = json.data

              if(event !== undefined ){
                this.asks=  d.asks}
                else{
                  this.asks = []
                }
                if(d.bids){
                  this.bids=  d.bids;
                }else{
                  this.bids = []
                }
                this.setState({asks : this.asks , bids : this.bids})

                this.ws.send(JSON.stringify({
                  "id": id,
                  "type": "ping"
                }))

        };
        this.ws.onclose = (event)=> {
          console.log(event);
          clearInterval(this.wsint)
          return
        };
        this.setState({
            baseCurrencybalance : response.data.balance.baseCurrencybalance,
            quoteCurrencybalance : response.data.balance.quoteCurrencybalance,
            fee : response.data.limits.fee,
            accounts : response.data.accounts,
            limits : response.data.limits,
            level : response.data.userlevel,
            loggedin : response.data.loggedin,
            openorders : response.data.orders.openorders,
            closeorders : response.data.orders.closeorders,
            intervaltime : response.data.intervaltime,
            usdt : response.data.usdt,
            token : response.data.token,
            id : response.data.id
          })
      })
    }
    wsinterval =()=>{
      this.wsint = setInterval(()=>
      this.ws.send(JSON.stringify({
        "id": this.state.id,
        "type": "ping"
      })), 9000)
    }
    updatedata = ()=>{
      this.theme = 'light'
      this.interval = setInterval(
        ()=>this.fetchhistory(), 10000
      )
    }
    fetchhistory = ()=>{
      let market = this.market
      axios.get('/api/mainmarket/fetchhisory/'+market).then(response=>{
        this.setState({history : response.data})
      })
    }
    fetchdata = ()=>{
      let token = this.token
      let id = this.id
      let url = "wss://ws-api-spot.kucoin.com/?token="+token+"&[connectId="+id+"]"
      this.ws = new WebSocket(url);
      let market = this.market2

      var c = {
          "id": id,
          "type": "subscribe",
          "topic": "/spotMarket/level2Depth50:" + market,
          "privateChannel": false,
          "response": true
        }
        this.ws.onopen = (event) => {
        // ws.send(JSON.stringify(d));
        this.ws.send(JSON.stringify(c));
      };

      this.ws.onmessage = (event) => {
        console.log(event);
        const json = JSON.parse(event.data);

            let d = json.data

            if(d.saks.length === 50){this.setState({asks : d.asks , bids : d.bids})}
            this.wsint = setInterval(()=>
            this.ws.send(JSON.stringify({
              "id": this.state.id,
              "type": "ping"
            })), 9000)

      };
    }
    updatebalance=(base , quote)=>{

      let symbol = base+'-'+quote
      axios.get('/api/mainmarket/getmarket/'+ symbol.toUpperCase()).then(response=>{
        if(response.status !== 200){
          return
        }
        if(response.data.status === 'error-fatal'){
          cookie.remove('uid')
          this.setState({loggedin : false})
        }
        this.setState({
          baseCurrencybalance: response.data.balance.baseCurrencybalance,
          quoteCurrencybalance: response.data.balance.quoteCurrencybalance,
          fee: response.data.limits.fee,
          limits: response.data.limits,
          level: response.data.userlevel,
          loggedin: response.data.loggedin,
          intervaltime: response.data.intervaltime,
          openorders :response.data.orders.openorders,
          closeorders :response.data.orders.closeorders,
          })
      })

    }
    updateall=()=>{

      let symbol = this.state.baseCurrency+'-'+this.state.quoteCurrency
      axios.get('/api/mainmarket/getmarket/'+ symbol.toUpperCase()).then(response=>{
        if(response.status !== 200){
          return
        }
        if(response.data.status === 'error-fatal'){
          cookie.remove('uid')
          this.setState({loggedin : false})
        }
        this.setState({
          baseCurrencybalance: response.data.balance.baseCurrencybalance,
          quoteCurrencybalance: response.data.balance.quoteCurrencybalance,
          fee: response.data.limits.fee,
          limits: response.data.limits,
          level: response.data.userlevel,
          loggedin: response.data.loggedin,
          intervaltime: response.data.intervaltime,
          openorders :response.data.orders.openorders,
          closeorders :response.data.orders.closeorders,
          })
      })

    }
    numeric = i =>{
      return(
        <NumericFormat value = {i}  thousandSeparator="," displayType="text"  className='font-weight-bold' />
      )
    }
    ret = (index,i)=>{
      return(parseFloat(this.state.bids[index].amount) + parseFloat(i.amount));
    }
    s1 = (e)=>{
      let v = e.target.value
      let fee = this.state.fee
      let a = v * fee
      let b = a + v
      this.setState({[e.target.name] : e.target.value , totalsell : b})

    }
    activesignupmodal = ()=>{
      this.props.history.push('/Auth/signup')
    }
    activeloginmodal = ()=>{
      this.props.history.push('/Auth/login')
    }
    toggle = ()=>{
      this.setState({lsmodal : !this.state.lsmodal})
    }
    submitLogin = (e) => {
      e.preventDefault()
      const { t } = this.props;
      if (!this.validator.fieldValid('email')) {
        console.log(`${t('enter.valid.email')}`)
        return
      }else{

      }
      if (!this.validator.fieldValid('password')) {
        console.log(`${t('enter.valid.password')}`)
        return
      }else{

      }


      this.setState({active2 : false})
      var d = this.state.password;
      const formdata = new FormData()
      formdata.append('email',this.state.email)
      formdata.append('password',d )

      axios.post('/api/signin' , formdata)
      .then(response=> {
        this.setState({active2 : true})
        setTimeout(
                    () => this.setState({ blur : !this.state.blur,active2 : true}),
                    3000
                  );
        if(response.data.status || response.data.type === 'error'){
          this.setState({active2 : true})
          if(response.data.content === 'wrong.password.email'){
            this.setState({message : `${t('error.pass.not.match')}`})
          }
          if(response.data.content === 'not.active'){
            this.setState({message : `${t('error.login.not.active')}`})
          }
          if(response.data.content === 'not.found'){
            this.setState({message : `${t('error.login.not.found')}`})
          }
          // this.setState({active2 : true})

        }
        if(response.data.type === 'success'){
          this.setState({message : `${t('success.login')}` , loggedin : true , lsmodal : false})
          var base = this.state.baseCurrency
          var quote = this.state.quoteCurrency
          this.updatebalance(this.baseCurrency , this.quoteCurrency)
        }
      })
    }
    onChange = (e) => {
      e.preventDefault()
      this.setState({
        [e.target.name]: e.target.value
      })
    }
    setamountsell = (e)=>{
      var x = parseInt(e.target.getAttribute("data-percent"))/100
      var basebalance = parseFloat(this.state.baseCurrencybalance)
      if(basebalance === 0){
        return
      }
      var y = basebalance * x
      let a = this.state.fee
      let b = y * a
      let c = a + b
      this.setState({sellbaseamount : y , lsa :y ,totalsell : c})

    }
    setamountbuy = (e)=>{
      var x = parseInt(e.target.getAttribute("data-amount"))/100
      var quotebalance = parseFloat(this.state.quoteCurrencybalance) * x
      if(quotebalance === 0){
        return
      }
      let price = this.state.buybaseprice
      let a = this.state.fee
      let b = parseFloat(quotebalance / price)
      let d = parseFloat(b * a)
      let f = b - d
      this.setState({buybaseamount : f ,lba: f, total : quotebalance  })

    }
    setpriceasks =(e)=>{

      var u = parseFloat(e.target.getAttribute("data-price"))
      let value = u
      let amount = parseFloat(this.state.buybaseamount)
      let price = parseFloat(value)
      let fee = parseFloat(this.state.fee)
      let x = fee * amount
      let s = x + amount
      let total = s *  price
      this.setState({buybaseprice : u ,lsq : u, sellbaseprice : u, total : total})
      let a = parseFloat(this.state.sellbaseamount)
      let b = a * this.state.fee
      let c = a + b
      this.setState({totalsell : c})
    }
    setamountasks =(e)=>{
      var yy = parseFloat(e.target.getAttribute("data-amount"))
      let value = yy
      let price = parseFloat(this.state.buybaseprice)
      let amount = parseFloat(value)
      let fee = parseFloat(this.state.fee)
      let x = fee * amount
      let s = x + amount
      let total = s *  price
      this.setState({ total : total})
      let a = yy
      let b = a * this.state.fee
      let c = a + b
      this.setState({totalsell : c})
      this.setState({buybaseamount : yy ,sellbaseamount : yy ,lsa :yy})
    }
    setpricebids =(e)=>{
      e.preventDefault()
      var am = parseFloat(e.target.getAttribute("data-price"))
      this.setState({sellbaseprice : am , lsa:am,buybaseprice : am})
    }
    setamountbids =(e)=>{
      e.preventDefault()
      var x = parseFloat(e.target.getAttribute("data-amount"))
      this.setState({sellbaseamount : x , buybaseamount : x})
    }
    disablebuttunsell = () =>{
      this.setState({sellbutton : !this.state.sellbutton})
    }
    calculatelr = ()=>{
      var level = this.state.level
      switch (level) {
        case '0':
          var disc =  0
          break;
        case '1':
          var disc =  80
          break;
        case '2':
          var disc =  60
          break;
        default:
          var disc = 0
      }
return disc
    }
    clean = ()=>{
      this.setState({
        sellbaseprice : '',
        sellbaseamount : '',
        buybaseprice : '',
        buybaseamount : '',
        total : '',
        totalsell : '',
      })
    }
    maxamount = () =>{
      // var marketfee = parseFloat(this.state.limits.makerFeeRate)
      // var conf = parseFloat(this.state.limits.makerCoefficient)
      // var mfee = marketfee * conf
      // var price = parseFloat(this.state.sellbaseprice)
      // var amount = parseFloat(this.state.sellbaseamount)
      // var type = 'market'
      // var side = 'sell'
      // var a = this.state.baseCurrencybalance
      // var b = marketfee * conf
      // var lr = this.calculatelr()
      // var c = b * lr/100
      // var fee = b + c
      // var maxamount = a - fee*a
      // this.setState({maxamount : maxamount})
      return(
        <span>10</span>
      )
    }

    calcbase =()=>{
      var fee = this.state.fee
      var price = parseFloat(this.state.sellbaseprice)
      var amount = parseFloat(this.state.sellbaseamount)
      var balance = parseFloat(this.state.baseCurrencybalance)
      var type = 'market'
      var side = 'sell'
      let f = fee * price
      let max = balance - f
      this.setState({maxbaseamount : max})
      return max
    }
    submitsellmarket =(e)=>{
      const {t} = this.props
      e.preventDefault()
      this.disablebuttunsell()
      if(!cookie.load('uid')){
        this.setState({lsmodal : true})
      }
      var fee = this.state.fee
      var price = parseFloat(this.state.sellbaseprice)
      var amount = parseFloat(this.state.sellbaseamount)
      var balance = parseFloat(this.state.baseCurrencybalance)
      var type = 'market'
      var side = 'sell'
      let f = fee * amount
      let max = amount
      if(balance === 0){
        new Toast(`${t('raise.your.balance')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000
      })
          console.log('موجودی خود را افزایش دهید')
          return
      }
      if(balance < 0){
        new Toast(`${t('raise.your.balance')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000
      })
          console.log('موجودی خود را افزایش دهید')
          return
      }
      if(balance < parseFloat(max)){
        new Toast(`${t('raise.your.balance')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
          console.log('موجودی کافی نیست')
          return
      }
      let total = this.state.totalsell
      //
      // if(amount > maxamount){
      //   console.log('موجودی کافی نیست')
      //   return
      // }
      // if(amount < this.state.limits.baseCurrencyMinSize){
      //   console.log('خطا در مقدار سفارش')
      //   return
      // }
      const formdata = new FormData()
      formdata.append('price',price)
      formdata.append('amount',amount.toString())
      formdata.append('fee',this.state.fee)
      formdata.append('base',this.state.baseCurrency)
      formdata.append('quote',this.state.quoteCurrency)
      formdata.append('type',type)
      formdata.append('side',side)
      formdata.append('excluded',max)
      axios.post('/api/mainmarket/sell',formdata).then(response=>{
        this.disablebuttunsell()
        if(response.data.status === 'fatal-error'){
          this.setState({loggedin : false})
        return
        }
        if(response.data.status === 'success'){
          new Toast(`${t('order.placed')}`, {
            style: {
                container: [
                    ['background-color', 'green']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000
        })
          console.log('سفارش شمابا موفقیت ثبت شد')
          this.update()
        }
        if(response.data.status === 'error'){
          new Toast(`${t(response.data.message)}`, {
            style: {
                container: [
                    ['background-color', 'red']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000
        })
          console.log(response.data.message)
          return
        }
      })
      // this.updatebalance(this.baseCurrency , this.quoteCurrency)

    }
    submitbuymarket =(e)=>{
      e.preventDefault()
      console.log(this.state);
      const {t} = this.props
      this.disablebuttunsell()

      let price = this.state.buybaseprice
      let amount = this.state.buybaseamount
      let fee = this.state.fee
      let balance = this.state.quoteCurrencybalance
      let minimum = this.state.limits.baseCurrencyMinSize
      let m = this.state.limits.baseCurrencyMaxsize
      let tx= amount * price

      let x = fee * amount
      let s = x + tx
      let maximum = x + amount
      this.setState({
        min : minimum,
        max : maximum,
        x : x ,
        t : tx,
        s : s,
      })
      if(balance === 0){
        new Toast(`${t('insufficient.balance')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000
      })
        console.log('موجودی کافی نیست')
        return
      }
      if(amount <= 0){
        new Toast(`${t('check.amount.input')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000
      })
        console.log('مقدار خرید را بررسی کنید')
        return
      }
      if(balance < 0){
        new Toast(`${t('insufficient.balance')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000
      })
        console.log('موجودی کافی نیست')
        return
      }
      if(s > balance){
        new Toast(`${t('insufficient.balance')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000
      })
        console.log('موجودی کافی نیست')
        return
      }
      if(amount < minimum){
        new Toast(`${t('input.amount.minimum.error')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000
      })
        console.log('مقدار سفارش کمتر از حد مجاز است')
        return
      }
      if(amount > maximum){
        new Toast(`${t('input.amount.maximum.error')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000
      })
        console.log('مقدار سفارش بیشتر از حد مجاز است')
        return
      }
      if(amount > m){
        new Toast(`${t('input.amount.maximum.error')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000
      })
        console.log('مقدار سفارش بیشتر از حد مجاز است')
        return
      }
const data = new FormData()
data.append('price' , price)
data.append('amount' , amount)
data.append('fee' , fee)
data.append('base' , this.state.baseCurrency)
data.append('quote' , this.state.quoteCurrency)
if(!cookie.load('uid')){
  return
}
axios.post('/api/mainmarket/buy' , data).then(response=>{
  if(response.data.status === 'success'){
    new Toast(`${t(response.data.message)}`, {
      style: {
          container: [
              ['background-color', 'green']
          ],
          message: [
              ['color', '#eee'],
          ],
          bold: [
              ['font-weight', '900'],
          ]
      },
      timeout : 3000
  })
      this.update()
  }
})
    }
    changebuyprice = (e)=>{
      let value = e.target.value
      let amount = parseFloat(this.state.buybaseamount)
      let price = parseFloat(value)
      let fee = parseFloat(this.state.fee)
      let x = fee * amount
      let s = x + amount
      let total = s *  price
      this.setState({ buybaseprice : value , total : total})

    }
    changebuyamount = (e)=>{
      let value = e.target.value


      let price = parseFloat(this.state.buybaseprice)
      let amount = parseFloat(value)
      let fee = parseFloat(this.state.fee)
      let x = fee * amount
      let s = x + amount
      let total = s *  price
        this.setState({ buybaseamount : value , total : total})
    }
    forwardbuybase = ()=>{
      var base = this.state.baseCurrency
      this.props.history.push('/panel/exchange/buy/'+base)
    }
    forwardbuyquote = ()=>{
      var quote = this.state.quoteCurrency
      this.props.history.push('/panel/exchange/buy/'+quote)
    }
    totime = (timestamp)=>{
      var date    = new Date(timestamp/1000000),
      hours   = date.getHours(),
      minutes = date.getMinutes(),
      seconds = date.getSeconds();

      var output  = ("0" + hours).slice(-2) + ':' + ("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2);
      // console.log(output);
      return(
        <small>{output}</small>
      )
    }
    closeorder =(i)=>{
        const {t} = this.props
      axios.get('/api/mainmarket/closeorder/'+i.orderid).then(response=>{
        if(response.data.status === 'success'){
          new Toast(`${t(response.data.message)}`, {
            style: {
                container: [
                    ['background-color', 'red']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000
        })
          console.log(response.data.message)
          this.update()
        }else{
          new Toast(`${t(response.data.message)}`, {
            style: {
                container: [
                    ['background-color', 'red']
                ],
                message: [
                    ['color', '#eee'],
                ],
                bold: [
                    ['font-weight', '900'],
                ]
            },
            timeout : 3000
        })
        this.update()
        }
      })

    }
    setlist =(e)=>{
let id = e.currentTarget.getAttribute('id')
this.setState({ot : id})
    }
    submitselllimit =(e)=>{
      e.preventDefault()
      if(!cookie.load('uid')){
        return
      }
      // if(this.state.lsq === ''){
      //   return
      // }
      // if(this.state.lsa === ''){
      //   return
      // }
      // if(this.state.tp === ''){
      //   return
      // }

      let price = this.state.lsq
      let amount = this.state.lsa
      let tp = this.state.tp
      let fee = this.state.fee
      let balance = this.state.baseCurrencybalance
      let qb = this.state.quoteCurrencybalance
      const formdata = new FormData()
      formdata.append('market', this.market )
      formdata.append('side', 'sell')
      formdata.append('type', 'limit')
      formdata.append('price',price )
      formdata.append('amount',amount )
      formdata.append('tp',tp )
      formdata.append('fee',fee)
      formdata.append('balance',balance )
      formdata.append('qb',qb )
      formdata.append('base',this.state.baseCurrency )
      formdata.append('quote',this.state.quoteCurrency )
      axios.post('/api/limit/sell' , formdata).then(response=>{
        this.update()
      })
    }
    submitbuylimit =(e)=>{
      e.preventDefault()
      if(!cookie.load('uid')){
        return
      }
      // if(this.state.lsq === ''){
      //   return
      // }
      // if(this.state.lsa === ''){
      //   return
      // }
      // if(this.state.tp === ''){
      //   return
      // }

      let price = this.state.lbq
      let amount = this.state.lba
      let sl = this.state.sl
      let fee = this.state.fee
      let balance = this.state.baseCurrencybalance
      let qb = this.state.quoteCurrencybalance
      const formdata = new FormData()
      formdata.append('market', this.market )
      formdata.append('side', 'buy')
      formdata.append('type', 'limit')
      formdata.append('price',price )
      formdata.append('amount',amount )
      formdata.append('sl',sl )
      formdata.append('fee',fee)
      formdata.append('balance',balance )
      formdata.append('qb',qb )
      formdata.append('base',this.state.baseCurrency )
      formdata.append('quote',this.state.quoteCurrency )
      axios.post('/api/limit/buy' , formdata).then(response=>{

      })
      this.update()
    }
    lsqset =(e)=>{
      let value = e.target.value
      this.setState({lsq : value})
    }
    tpset =(e)=>{
      let value = e.target.value
      this.setState({tp : value})
    }
     lsaset=(e)=>{
      let value = e.target.value
      this.setState({lsa : value})
    }
    lbqset =(e)=>{
      let value = e.target.value
      this.setState({lbq : value})
    }
    slset =(e)=>{
      let value = e.target.value
      this.setState({sl : value})
    }
     lbaset=(e)=>{
      let value = e.target.value
      this.setState({lba : value})
    }
    toggletooltip = ()=>{
      this.setState({tooltipOpen : !this.state.tooltipOpen})
    }
    getLanguageFromURL=()=> {
     const regex = new RegExp('[\\?&]lang=([^&#]*)');
     const results = regex.exec(window.location.search);
     return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    serach = (e)=> {
      let term = e.target.value
       this.setState({term: term})
     }
     seemore = ()=>{
       this.setState({seemore : this.state.seemore + 200})
     }
     toggletheme =()=>{
       this.setState({darkMode : !this.state.darkMode })

       if(this.state.darkMode === false){
         document.body.classList.remove('light');

         document.body.classList.add('dark');
         this.setState({theme : 'dark'})
         this.tvWidget.changeTheme('dark').then(() => {
                 this.tvWidget.chart().applyOverrides({ 'paneProperties.backgroundGradientStartColor': '#0b1a2f' ,'paneProperties.backgroundGradientStartColor': '#0b1a2f','paneProperties.backgroundType':'gradient'});
           });
       }
       else{
         document.body.classList.remove('dark');
         document.body.classList.add('light');
         this.setState({ theme : 'light'})
         this.tvWidget.changeTheme('light').then(() => {
                 this.tvWidget.chart().applyOverrides({ 'paneProperties.backgroundGradientStartColor': '#ffffff' ,'paneProperties.backgroundGradientStartColor': '#ffffff','paneProperties.backgroundType':'gradient'});
           });
       }
     // //  this.tvWidget.applyOverrides({
     // //    "paneProperties.background": "#ffffff00",
     // //    "paneProperties.backgroundType": "solid"
     // // });
     //   if (this.state.darkMode === false){
     //     this.tvWidget.changeTheme('Dark').then(() => {
     //         this.tvWidget.chart().applyOverrides({ 'paneProperties.backgroundGradientStartColor': '#000518' ,'paneProperties.backgroundGradientStartColor': '#000518','paneProperties.backgroundType':'gradient'});
     //   });
     //       this.setState({theme : 'dark' , darkMode : !this.state.darkMode})
     //       document.body.classList.remove('light');
     //
     //       document.body.classList.add('dark');
     //
     //   }
     //   if (this.state.darkMode === true){
     //
     //     this.setState({theme : 'light' , darkMode : !this.state.darkMode})
     //       document.body.classList.remove('dark');
     //       document.body.classList.add('light');
     //
     //   }
     //
     //
     //       // let bodyColor = document.body.className;

       }
       componentWillUpdate(){
         // this.settheme()
       }
 ochart =(market)=>{
       const defaultProps = {
         interval: '15',
         containerId: 'tv_chart_container',
         datafeedUrl: 'https://rapitex.com/api',
         libraryPath: 'https://rapitex.com/charting_library/',
         // chartsStorageUrl: 'https://localhost/api',
         // datafeedUrl: 'https://demo_feed.tradingview.com',
         // libraryPath: '/charting_library/',
         // chartsStorageUrl: 'https://saveload.tradingview.com',
         chartsStorageApiVersion: '1.1',
         clientId: 'rapitex.com',
         userId: cookie.load('uid') || '',
         fullscreen: false,
         autosize: true,
         studiesOverrides: {},
         theme : this.state.theme,


       };
       const widgetOptions = {
         debug: true,
         container: 'tv_chart_container',
         allow_symbol_change: false,
         symbol: this.market,
         datafeed: new window.Datafeeds.UDFCompatibleDatafeed('https://rapitex.com/api'),
         interval: defaultProps.interval,
         library_path: defaultProps.libraryPath,
         locale: this.getLanguageFromURL() || "en",
         disabled_features: ['header_compare','header_undo_redo', 'header_symbol_search'],
         height : 350,
         width : 775,
         show_symbol_logos :true,
         show_exchange_logo : true,
         enabled_features: ["study_templates" , "show_symbol_logos" ,"show_exchange_logo","show_symbol_logo_in_legend" ],
         charts_storage_url: defaultProps.chartsStorageUrl,
         charts_storage_api_version: defaultProps.chartsStorageApiVersion,
         client_id: defaultProps.clientId,
         user_id: defaultProps.userId,
         fullscreen: defaultProps.fullscreen,
         autosize: defaultProps.autosize,
         studies_overrides: defaultProps.studiesOverrides,
         hide_legends : false,
         // custom_css_url: '/market.css',
         theme : defaultProps.theme
       };
       let container = document.getElementById('tv_chart_container')
       this.tvWidget = new widget(widgetOptions);
       this.tvWidget.onChartReady(() => {

   			this.tvWidget.headerReady().then(() => {
   				const button = this.tvWidget.createButton();
   				button.setAttribute('title', 'market change');
   				button.addEventListener('click',()=> this.setState({marketmodal : !this.state.marketmodal}));
   				button.innerHTML = this.market;

   			});
   		});

   		return () => {
   			this.tvWidget.remove();
   		};
       // document.body.appendChild(container);
       return(
         <div id='tv_chart_container' />
       )
     }



     setfav =(i)=>{
       let symbolName = i.symbolName
       let fav = this.state.fav;

       // console.log(i);
       const found = fav.find(element => element.symbolName === symbolName);
       if(found){
         found.favorite = ''
         var array = [...this.state.fav]; // make a separate copy of the array
         var index = array.indexOf(found)
         if (index !== -1) {
           array.splice(index, 1);
           this.setState({fav: array});
         }
         localStorage.setItem('favorite',JSON.stringify(array))
       }else{
         i.favorite = 'yes'
         const d = []
         d.symbolName = symbolName;
         d.id = i.id
         fav.push(i)
         this.setState({fav : fav})
         localStorage.setItem('favorite',JSON.stringify(fav))
       }
     }

     serachmarket =(e)=>{
       const slug = e.target.value.toUpperCase()

         this.setState({term : slug})

     }
     darkMode =()=>
     {
       if (this.state.darkMode === false){
           document.body.classList.remove('light');

           document.body.classList.add('dark');
           this.setState({theme : 'dark'})
           this.tvWidget.changeTheme('dark')
       }
       if (this.state.darkMode === true){
           document.body.classList.remove('dark');
           document.body.classList.add('light');
           this.setState({theme : 'light'})
           this.tvWidget.changeTheme('light')
       }


           // let bodyColor = document.body.className;
         this.setState({
           darkMode : !this.state.darkMode
         })
           if(this.state.darkMode === true){

             this.tvWidget.changeTheme('light')
           }else{
             this.tvWidget.changeTheme('dark')
           }
     }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  render(){
    // const Chart = this.ochart()
    const {t} = this.props
    let  sortedArray = this.state.asks
    const filteredmarket = this.markets.filter(item => item.quoteCurrency.includes(this.state.searchTerm.toUpperCase()));
    const filteredmarket2 = filteredmarket.filter(item => item.symbol.includes(this.state.term.toUpperCase()));
    const history = this.state.history.slice().reverse().map(ha=>
      <div className='d-flex justify-content-space-between px-1 pb-1'>
      {ha.side === 'buy' ? <>
       <small onClick={this.setpriceasks} data-price={ha.price} className='text-success font-weight-bold'>{ha.price}</small>
       <small onClick={this.setamountasks} data-amount={ha.size} className='ltr text-success'>{parseFloat(ha.size).toFixed(6)}</small>
       <small  className='ltr text-dark'>{this.totime(ha.time)}</small>

       </>
       :
       <>

       <small onClick={this.setpriceasks} data-price={ha.price} className='text-danger font-weight-bold'>{ha.price}</small>
       <small onClick={this.setamountasks} data-amount={ha.size} className='ltr text-danger'>{ha.size}</small>
       <small  className='ltr text-dark'>{this.totime(ha.time)}</small>
       </>}
      </div>
    )

    const ordering =
      <>

        {this.state.order === 'market' ?
          <Row data-aos="fade-zoom-in" data-aos-offset="2" data-aos-easing="ease-in-sine" data-aos-duration="600" className='rtl no-gutters'>
            <Col  className='col px-1' xl='6'>
              <Form>
                <div>
                  <input step="0.1" min='0' onChange={this.s1}  value={this.state.sellbaseprice} className='w-100 market-input' type="number" name="sellbaseprice" id="sellbaseprice" placeholder="قیمت" size='sm' />

                  <span className='market-input-symbol'>{this.state.quoteCurrency}</span>
                </div>
                  <div>
                  <input step="0.1" min='0' max={this.state.maxamount} onChange={this.s1} value={this.state.sellbaseamount} className='w-100 market-input mt-2' type="number" name="sellbaseamount" id="sellbaseamount" placeholder="مقدار"  size='sm'/>

                  <span className='market-input-symbol'>{this.state.baseCurrency}</span>
                </div>
                <div className='d-flex justify-content-space-between mt-1'>
                <button onClick={this.setamountsell} data-percent='25' className='b-small'>25</button>
                <button onClick={this.setamountsell} data-percent='50' className='b-small text-dark mx-2'>50</button>
                <button onClick={this.setamountsell} data-percent='75' className='b-small text-dark mx-2'>75</button>
                <button onClick={this.setamountsell} data-percent='100' className='b-small text-dark float-left'>100</button>
                </div>

              <div className='d-flex mt-2'>
               <FormText>موجودی : </FormText>
               <FormText className='mr-5'>{this.state.baseCurrencybalance}</FormText>
                <FormText className='mr-auto'>{this.state.baseCurrency}</FormText>
                <SimpleLineIcon onClick={this.forwardbuybase} name='shuffle my-auto mr-2 pointer ' size='' />
              </div>

              <div className='d-flex justify-content-space-between mt-2'>
               <FormText>کارمزد</FormText>
                <FormText>{this.state.fee}</FormText>
              </div>

              <FormGroup className='rtl mt-3'>
              {this.state.loggedin === false ?
                <div className='text-center btn  btn-light btn-block' >
                  <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
                </div> :
                 <Button outline  onClick={this.submitsellmarket} block color="danger">فروش {this.state.baseCurrency}</Button> }

              </FormGroup>
              </Form>
            </Col>
            <Col className='col rtl px-1'  xl='6' xs='12'>
              <Form>
              <div>
                <input onChange={this.changebuyprice} value={this.state.buybaseprice} className='w-100 market-input' type="number" name="buybaseprice" id="buybaseprice" placeholder="قیمت" size='sm' />

                <span className='market-input-symbol'>{this.state.quoteCurrency}</span>
              </div>
                <div>
                <input onChange={this.changebuyamount} value={this.state.buybaseamount} className='w-100 market-input mt-2' type="number" min='0' name="buybaseamount" id="buybaseamount" placeholder="مقدار"  size='sm'/>

                <span className='market-input-symbol'>{this.state.baseCurrency}</span>
              </div>
              <div className='d-flex justify-content-space-between'>
              <FormText onClick={this.setamountbuy} data-amount='25'  className='b-small'>25</FormText>
              <FormText onClick={this.setamountbuy} data-amount='50' className='b-small text-dark mx-2'>50</FormText>
              <FormText  onClick={this.setamountbuy} data-amount='75' className='b-small text-dark mx-2'>75</FormText>
              <FormText onClick={this.setamountbuy}  data-amount='100' className='b-small text-dark float-left'>100</FormText>
              </div>

              <div className='d-flex mt-2'>
               <FormText >موجودی :</FormText>
               <small className='mr-auto mt-1 text-dark'>{this.state.quoteCurrencybalance}</small>
                <FormText className='mr-auto'>{this.state.quoteCurrency}</FormText>
                <SimpleLineIcon onClick={this.forwardbuyquote} name='shuffle my-auto mr-2 pointer '  size='' />
              </div>
              <div className='d-flex justify-content-space-between mt-2'>
               <FormText>کارمزد</FormText>
                <FormText>{this.state.fee}</FormText>
              </div>

              <FormGroup className='rtl mt-3'>
              {this.state.loggedin === false ?
                <div className='text-center btn  btn-light btn-block' >
                  <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
                </div> :
                 <Button outline onClick={this.submitbuymarket} block color="success">خرید {this.state.baseCurrency}</Button> }


              </FormGroup>
              </Form>
            </Col>
            <Col>
            </Col>
          </Row>
          : <></> }
        {this.state.order === 'limit' ?
          <Row data-aos="fade-zoom-in" data-aos-offset="2" data-aos-easing="ease-in-sine" data-aos-duration="600" className='rtl no-gutters' xs='1' xl='2' lg='2'>
            <Col xs='12'  className='col px-1' xl='6' md='12'>
              <Form>
                <div>
                  <input onChange={this.lsqset} value={this.state.lsq} className='w-100 market-input' type="number" name="sellquote" id="sellquote" placeholder="قیمت" size='sm' />

                  <span className='market-input-symbol'>{this.state.quoteCurrency}</span>
                </div>
                <div className='mt-2'>
                  <input onChange={this.tpset} value={this.state.tp} className='w-100 market-input' type="number" name="selllimit" id="selllimit" placeholder="حد ضرر" size='sm' />
                  <div  className='market-input-symbol d-flex'>
                  <span>{this.state.quoteCurrency}</span>
                  <span className='my-auto' >

                  <SimpleLineIcon  ID='lstooltip' name='info my-auto ml-1' size='small' color='purple' />
                  <Tooltip className='rtl bg-darkblue text-warning' placement="bottom" isOpen={this.state.tooltipOpen} autohide={true} target="lstooltip" toggle={this.toggletooltip}>
                     زمانی که قیمت مارکت به این حد رسید سفارش اجرا میشود
                   </Tooltip>
                  </span>
                  </div>
                </div>
                  <div className='mt-2'>
                  <input onChange={this.lsaset} value={this.state.lsa} className='w-100 market-input' type="number" name="limitbaseamount" id="limitbaseamount" placeholder="مقدار"  size='sm'/>

                  <span className='market-input-symbol'>{this.state.baseCurrency}</span>
                </div>
                <div className='d-flex justify-content-space-between'>
                <FormText className='b-small'>25</FormText>
                <FormText className='b-small text-dark mx-2'>50</FormText>
                <FormText className='b-small text-dark mx-2'>75</FormText>
                <FormText className='b-small text-dark float-left'>100</FormText>
                </div>

              <div className='d-flex mt-2'>
               <FormText>موجودی : </FormText>
               <FormText className='mr-5'>{this.state.baseCurrencybalance}</FormText>
                <FormText className='mr-auto'>{this.state.baseCurrency}</FormText>
                <SimpleLineIcon name='shuffle my-auto mr-2 pointer '  size='' />
              </div>


              <FormGroup className='rtl mt-3'>
              {this.state.loggedin === false ?
                <div className='text-center btn  btn-light btn-block' >
                  <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
                </div> :
                 <Button outline  onClick={this.submitselllimit} block color="danger">فروش {this.state.baseCurrency}</Button> }

              </FormGroup>
              </Form>
            </Col>
            <Col xs='12' className='col px-1'  xl='6'>
              <Form>
              <div>
                <input onChange={this.lbqset} value={this.state.lbq} className='w-100 market-input' type="number" name="lbq" id="lbq" placeholder="قیمت" size='sm' />

                <span className='market-input-symbol'>{this.state.quoteCurrency}</span>
              </div>
              <div className='mt-2'>
                <input  onChange={this.slset} value={this.state.sl}className='w-100 market-input' type="number" name="sl" id="sl" placeholder="حد سود" size='sm' />
                <div className='market-input-symbol d-flex' >
                <span className=''>{this.state.quoteCurrency}</span>
                <span className='my-auto' >

                <SimpleLineIcon  ID='lstooltip' name='info my-auto ml-1' size='small' color='purple' />
                <Tooltip className='rtl bg-darkblue text-warning' placement="bottom" isOpen={this.state.tooltipOpen} autohide={true} target="lstooltip" toggle={this.toggletooltip}>
                   زمانی که قیمت مارکت به این حد رسید سفارش اجرا میشود
                 </Tooltip>
                </span>
                </div>
              </div>
                <div className='mt-2'>
                <input onChange={this.lbaset} value={this.state.lba} className='w-100 market-input' type="number" name="lba" id="lba" placeholder="مقدار"  size='sm'/>

                <span className='market-input-symbol'>{this.state.baseCurrency}</span>
              </div>
              <div className='d-flex justify-content-space-between'>
              <FormText className='b-small'>25</FormText>
              <FormText className='b-small text-dark mx-2'>50</FormText>
              <FormText className='b-small text-dark mx-2'>75</FormText>
              <FormText className='b-small text-dark float-left'>100</FormText>
              </div>

              <div className='d-flex mt-2'>
               <FormText >موجودی :</FormText>
               <small className='mr-auto mt-1 text-dark'>{this.state.quoteCurrencybalance}</small>
                <FormText className='mr-auto'>{this.state.quoteCurrency}</FormText>
                <SimpleLineIcon name='shuffle my-auto mr-2 pointer '  size='' />
              </div>

              <FormGroup className='rtl mt-3'>
              {this.state.loggedin === false ?
                <div className='text-center btn  btn-light btn-block' >
                  <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
                </div> :
                 <Button outline onClick={this.submitbuylimit} block color="success">خرید {this.state.baseCurrency}</Button> }

              </FormGroup>
              </Form>
            </Col>
            <Col>
            </Col>
          </Row>
          : <></> }
      </>
    const marketform =
    <>
      <Row className='mx-1 rtl'>
        <Col  className='col' xl='6'>
          <Form>
            <div>
              <input step="0.1" min='0' onChange={this.s1}  value={this.state.sellbaseprice} className='w-100 market-input' type="number" name="sellbaseprice" id="sellbaseprice" placeholder="قیمت" size='sm' />

              <span className='market-input-symbol'>{this.state.quoteCurrency}</span>
            </div>
              <div>
              <input step="0.1" min='0' max={this.state.maxamount} onChange={this.s1} value={this.state.sellbaseamount} className='w-100 market-input mt-2' type="number" name="sellbaseamount" id="sellbaseamount" placeholder="مقدار"  size='sm'/>

              <span className='market-input-symbol'>{this.state.baseCurrency}</span>
            </div>
            <div className='d-flex justify-content-space-between mt-1'>
            <button onClick={this.setamountsell} data-percent='25' className='b-small'>25</button>
            <button onClick={this.setamountsell} data-percent='50' className='b-small text-dark mx-2'>50</button>
            <button onClick={this.setamountsell} data-percent='75' className='b-small text-dark mx-2'>75</button>
            <button onClick={this.setamountsell} data-percent='100' className='b-small text-dark float-left'>100</button>
            </div>

          <div className='d-flex mt-2'>
           <FormText>موجودی : </FormText>
           <FormText className='mr-5'>{this.state.baseCurrencybalance}</FormText>
            <FormText className='mr-auto'>{this.state.baseCurrency}</FormText>
            <SimpleLineIcon onClick={this.forwardbuybase} name='shuffle my-auto mr-2 pointer ' size='' />
          </div>

          <div className='d-flex justify-content-space-between mt-2'>
           <FormText>کارمزد</FormText>
            <FormText>{this.state.fee}</FormText>
          </div>
          <div className='d-flex justify-content-space-between mt-2'>
           <FormText>پرداختی </FormText>
            <FormText>{this.state.totalsell}</FormText>
          </div>
          <FormGroup className='rtl mt-3'>
          {this.state.loggedin === false ?
            <div className='text-center btn  btn-light btn-block' >
              <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
            </div> :
             <Button outline onClick={this.submitsellmarket} block color="danger">فروش {this.state.baseCurrency}</Button> }

          </FormGroup>
          </Form>
        </Col>
        <Col className='col rtl'  xl='6' xs='12'>
          <Form>
          <div>
            <input onChange={this.changebuyprice} value={this.state.buybaseprice} className='w-100 market-input' type="number" name="buybaseprice" id="buybaseprice" placeholder="قیمت" size='sm' />

            <span className='market-input-symbol'>{this.state.quoteCurrency}</span>
          </div>
            <div>
            <input onChange={this.changebuyamount} value={this.state.buybaseamount} className='w-100 market-input mt-2' type="number" min='0' name="buybaseamount" id="buybaseamount" placeholder="مقدار"  size='sm'/>

            <span className='market-input-symbol'>{this.state.baseCurrency}</span>
          </div>
          <div className='d-flex justify-content-space-between'>
          <FormText onClick={this.setamountbuy} data-amount='25'  className='b-small'>25</FormText>
          <FormText onClick={this.setamountbuy} data-amount='50' className='b-small text-dark mx-2'>50</FormText>
          <FormText  onClick={this.setamountbuy} data-amount='75' className='b-small text-dark mx-2'>75</FormText>
          <FormText onClick={this.setamountbuy}  data-amount='100' className='b-small text-dark float-left'>100</FormText>
          </div>

          <div className='d-flex mt-2'>
           <FormText >موجودی :</FormText>
           <small className='mr-auto mt-1 text-dark'>{this.state.quoteCurrencybalance}</small>
            <FormText className='mr-auto'>{this.state.quoteCurrency}</FormText>
            <SimpleLineIcon onClick={this.forwardbuyquote} name='shuffle my-auto mr-2 pointer ' size='' />
          </div>
          <div className='d-flex justify-content-space-between mt-2'>
           <FormText>کارمزد</FormText>
            <FormText>{this.state.fee}</FormText>
          </div>
          <div className='d-flex justify-content-space-between mt-2'>
           <FormText>پرداختی </FormText>
            <FormText>{this.state.total} </FormText>
          </div>
          <FormGroup className='rtl mt-3'>
          {this.state.loggedin === false ?
            <div className='text-center btn  btn-light btn-block' >
              <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
            </div> :
             <Button outline onClick={this.submitbuymarket} block color="success">خرید {this.state.baseCurrency}</Button> }


          </FormGroup>
          </Form>
        </Col>
        <Col>
        </Col>
      </Row>
    </>
    const limitform =
    <>
    <Row id='llll' className='mx-1 rtl' xs='1' xl='2' lg='2'>
      <Col  className='col' xl='6' md='12'>
        <Form>
          <div>
            <input onChange={this.lsqset} value={this.state.lsq} className='w-100 market-input' type="number" name="sellquote" id="sellquote" placeholder="قیمت" size='sm' />

            <span className='market-input-symbol'>{this.state.quoteCurrency}</span>
          </div>
          <div className='mt-1'>
            <input onChange={this.tpset} value={this.state.tp} className='w-100 market-input' type="number" name="selllimit" id="selllimit" placeholder="حد فروش" size='sm' />
            <div  className='market-input-symbol d-flex'>
            <span>{this.state.quoteCurrency}</span>
            <span className='my-auto' >

            <SimpleLineIcon  ID='lstooltip' name='info my-auto ml-1' size='small' color='purple' />
            <Tooltip className='rtl bg-darkblue text-warning' placement="bottom" isOpen={this.state.tooltipOpen} autohide={true} target="lstooltip" toggle={this.toggletooltip}>
               زمانی که قیمت مارکت به این حد رسید سفارش اجرا میشود
             </Tooltip>
            </span>
            </div>
          </div>
            <div className='mt-1'>
            <input onChange={this.lsaset} value={this.state.lsa} className='w-100 market-input' type="number" name="limitbaseamount" id="limitbaseamount" placeholder="مقدار"  size='sm'/>

            <span className='market-input-symbol'>{this.state.baseCurrency}</span>
          </div>
          <div className='d-flex justify-content-space-between'>
          <FormText className='b-small'>25</FormText>
          <FormText className='b-small text-dark mx-2'>50</FormText>
          <FormText className='b-small text-dark mx-2'>75</FormText>
          <FormText className='b-small text-dark float-left'>100</FormText>
          </div>

        <div className='d-flex mt-2'>
         <FormText>موجودی : </FormText>
         <FormText className='mr-5'>{this.state.baseCurrencybalance}</FormText>
          <FormText className='mr-auto'>{this.state.baseCurrency}</FormText>
          <SimpleLineIcon name='shuffle my-auto mr-2 pointer '  size='' />
        </div>


        <FormGroup className='rtl mt-3'>
        {this.state.loggedin === false ?
          <div className='text-center btn  btn-light btn-block' >
            <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
          </div> :
           <Button outline  onClick={this.submitselllimit} block color="danger">فروش {this.state.baseCurrency}</Button> }

        </FormGroup>
        </Form>
      </Col>
      <Col className='col'  xl='6'>
        <Form>
        <div>
          <input onChange={this.lbqset} value={this.state.lbq} className='w-100 market-input' type="number" name="lbq" id="lbq" placeholder="قیمت" size='sm' />

          <span className='market-input-symbol'>{this.state.quoteCurrency}</span>
        </div>
        <div className='mt-1'>
          <input  onChange={this.slset} value={this.state.sl}className='w-100 market-input' type="number" name="sl" id="sl" placeholder="قیمت" size='sm' />
          <div className='market-input-symbol d-flex' >
          <span className=''>{this.state.quoteCurrency}</span>
          <span className='my-auto' >

          <SimpleLineIcon  ID='lstooltip' name='info my-auto ml-1' size='small' color='purple' />
          <Tooltip className='rtl bg-darkblue text-warning' placement="bottom" isOpen={this.state.tooltipOpen} autohide={true} target="lstooltip" toggle={this.toggletooltip}>
             زمانی که قیمت مارکت به این حد رسید سفارش اجرا میشود
           </Tooltip>
          </span>
          </div>
        </div>
          <div className='mt-1'>
          <input onChange={this.lbaset} value={this.state.lba} className='w-100 market-input' type="number" name="lba" id="lba" placeholder="مقدار"  size='sm'/>

          <span className='market-input-symbol'>{this.state.baseCurrency}</span>
        </div>
        <div className='d-flex justify-content-space-between'>
        <FormText className='b-small'>25</FormText>
        <FormText className='b-small text-dark mx-2'>50</FormText>
        <FormText className='b-small text-dark mx-2'>75</FormText>
        <FormText className='b-small text-dark float-left'>100</FormText>
        </div>

        <div className='d-flex mt-2'>
         <FormText >موجودی :</FormText>
         <small className='mr-auto mt-1 text-dark'>{this.state.quoteCurrencybalance}</small>
          <FormText className='mr-auto'>{this.state.quoteCurrency}</FormText>
          <SimpleLineIcon name='shuffle my-auto mr-2 pointer '  size='' />
        </div>

        <FormGroup className='rtl mt-3'>
        {this.state.loggedin === false ?
          <div className='text-center btn  btn-light btn-block' >
            <span onClick={this.activeloginmodal} className='text-muted my-auto'>ورود</span>
          </div> :
           <Button outline onClick={this.submitbuylimit} block color="success">خرید {this.state.baseCurrency}</Button> }

        </FormGroup>
        </Form>
      </Col>
      <Col>
      </Col>
    </Row>
    </>
    const openlist =
    <>
    {this.state.ot === 'open' ?
    <div className='custom-overflow historylist-container ordelist user-order-container  rtl  mt-1 border px-3 mx-1'>
    {this.state.openorders.length > 0 ?
    <Table size="sm" borderless className='text-small'>
  <thead>
    <tr>
      <th>#</th>
      <th>شماره سفارش</th>
      <th>مارکت</th>
      <th>نوع</th>
      <th>سمت</th>
      <th>قیمت</th>
      <th>مقدار</th>
      <th>تاریخ</th>
      <th>وضعیت</th>
      <th>لغو</th>
    </tr>
  </thead>
  <tbody>
 {this.state.openorders.map((i,index)=>
  <tr>
    <th scope="row">{index+1}</th>
    <td>{i.orderid}</td>
    <td>{i.market}</td>
    <td>{i.type}</td>
    <td>{i.status3}</td>
    <td>{i.price}</td>
    <td>{i.amount}</td>
    <td>{i.date}</td>
    <td>{i.status}</td>
    <td onClick={()=>this.closeorder(i)}><SimpleLineIcon name='close pointer' color='red' /></td>
  </tr>
 )}

  </tbody>
 </Table>
 : <div className='text-center my-auto'>
  <FcDeleteDatabase size='4rem' className='mt-5' />
  <p className='text-muted'>تراکنشی یافت نشد</p>
 </div> }
    </div> : <></>}
    </>
    const closelist =
    <>
    {this.state.ot === 'close' ?
    <div className='custom-overflow ordelist historylist-container user-order-container  rtl  mt-1 border px-3 mx-1'>
    {this.state.closeorders.length > 0 ?
    <Table size="sm" borderless className='text-small'>
  <thead>
    <tr>
      <th>#</th>
      <th>شماره سفارش</th>
      <th>مارکت</th>
      <th>نوع</th>
      <th>سمت</th>
      <th>قیمت</th>
      <th>مقدار</th>
      <th>تاریخ</th>
      <th>وضعیت</th>
    </tr>
  </thead>
  <tbody>
 {this.state.closeorders.map((i,index)=>
  <tr>
    <th scope="row">{index+1}</th>
    <td>{i.orderid}</td>
    <td>{i.market}</td>
    <td>{i.type}</td>
    <td>{i.status3}</td>
    <td>{i.price}</td>
    <td>{i.amount}</td>
    <td>{i.date}</td>
    <td>{i.status2}</td>
  </tr>
 )}

  </tbody>
 </Table>
 : <div className='text-center'>
  <FcDeleteDatabase size='4rem' className='mt-5'/>
  <p className='text-muted'>تراکنشی یافت نشد</p>
 </div> }
    </div> : <></>}
    </>
    const activeorders =
    <>
        <div className='d-flex rtl  market-header text-tiny'>
        <span className='pointer rtl p' id='open' onClick={(this.setlist)}>سفارشات باز</span>
        <span className='pointer p rtl mr-4' id='close' onClick={(this.setlist)}>تاریخچه سفارشات</span>
        <SimpleLineIcon onClick={this.set} name='reload mr-auto pointer' color='' size='medium' />
        </div>
        {this.state.ot === 'open' ?
        <div className='custom-overflow ordelist historylist-container user-order-container  rtl  mt-1 px-3 mx-1'>
        {this.state.openorders.length > 0 ?
        <Table size="sm" borderless className='text-small'>
      <thead>
        <tr>
          <th>#</th>
          <th>شماره سفارش</th>
          <th>مارکت</th>
          <th>نوع</th>
          <th>سمت</th>
          <th>قیمت</th>
          <th>مقدار</th>
          <th>تاریخ</th>
          <th>وضعیت</th>
          <th>لغو</th>
        </tr>
      </thead>
      <tbody>
     {this.state.openorders.map((i,index)=>
      <tr>
        <th scope="row">{index+1}</th>
        <td>{i.orderid}</td>
        <td>{i.market}</td>
        <td>{i.type}</td>
        <td>{i.status3}</td>
        <td>{i.price}</td>
        <td>{i.amount}</td>
        <td>{i.date}</td>
        <td>{i.status}</td>
        <td onClick={()=>this.closeorder(i)}><SimpleLineIcon name='close pointer' color='red' /></td>
      </tr>
     )}

      </tbody>
     </Table>
     : <div className='text-center'>
      <FcDeleteDatabase size='4rem' className='mt-5'/>
      <p className='text-muted'>تراکنشی یافت نشد</p>
     </div> }
        </div> : <></>}
        {this.state.ot === 'close' ?
        <div className='custom-overflow ordelist historylist-container user-order-container  rtl  mt-1  px-3 mx-1'>
        {this.state.closeorders.length > 0 ?
        <Table size="sm" borderless className='text-small'>
      <thead>
        <tr>
          <th>#</th>
          <th>شماره سفارش</th>
          <th>مارکت</th>
          <th>نوع</th>
          <th>سمت</th>
          <th>قیمت</th>
          <th>مقدار</th>
          <th>تاریخ</th>
          <th>وضعیت</th>
        </tr>
      </thead>
      <tbody>
     {this.state.closeorders.map((i,index)=>
      <tr>
        <th scope="row">{index+1}</th>
        <td>{i.orderid}</td>
        <td>{i.market}</td>
        <td>{i.type}</td>
        <td>{i.status3}</td>
        <td>{i.price}</td>
        <td>{i.amount}</td>
        <td>{i.date}</td>
        <td>{i.status2}</td>
      </tr>
     )}

      </tbody>
     </Table>
     : <div className='text-center'>
      <FcDeleteDatabase size='4rem' className='mt-5'/>
      <p className='text-muted'>تراکنشی یافت نشد</p>
     </div> }
        </div> : <></>}
    </>
      const fav = this.state.fav
    // const favorites = this.markets.map(itm => ({
    //     ...fav.find((item) => (item.id === itm.id) && item),
    //     ...itm
    // }));
    const accounts = this.state.accounts.sort(function(a, b) { return b.availablebalance - a.availablebalance; });
      return(
      <>



      <div className='d-block d-lg-none mt-3'>
      <Row className=' border-bottom rtl mt-3'>
        <Col xs='11' className='pl-2 pr-1 no-gutters'>
          <div className=' d-flex'>
          <UncontrolledDropdown  className='w-100'>
            <DropdownToggle onClick={()=>this.setState({marketmodal : !this.state.marketmodal})}  color='light' className='px-2 mt-2 h6 font-weight-bold border w-100'>
              {this.market}
            </DropdownToggle>
              <DropdownMenu className='d-none d-lg-block' >
                <div className='stick-top px-2'>
                <p className='text-center mt-2'>لیست مارکت ها</p>
                <div className='d-block w-100'>
                      <div className='my-auto'>
                      <SearchInput onChange={this.serach} placeholder=' ' className='market-input' />
                      </div>
                </div>
                </div>
                <div className='historylist-container ordelist custom-overflow  px-2' >
                {filteredmarket.map(i=>
                  <div className='border-bottom py-2 px-2'>
                  <span className='pointer' onClick={()=>this.setmarket(i)} id={i.id} header>{i.symbolName}</span>
                  </div>
                )}
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Col>
        <Col xs='1' className='ltr no-gutters'>
        <SimpleLineIcon name='chart  ml-2 mr-auto mt-4' onClick={()=>this.setState({chartvisible : !this.state.chartvisible})}/>

        </Col>
      </Row>
      </div>
      <div className='market'>
        <Row xs='1' sm='1' xl='2' lg='2' md='1' >
          <Col xl='2' lg='2' md='6' className='no-gutters  left-column d-none d-sm-block'>
          <div className=''>
            <div className='orderlist-header rtl bg-light  pr-3 pt-2 py-1'>
            <small className='rtl text-dark orderlist-header text-primary'>{t('favorites')}</small>
          </div>
          <div className='favoritelist-container ordelist'>
            {fav.slice(0,30).map(i=>
              <div key={i.id} className='d-flex'>
                <p className='ltr icon-market text-dark'>
                  <span className={i.baseCurrency+'-icon d-block float-left small-icon mr-auto '}></span>
                  <span className={i.quoteCurrency+'-icon small-icon float-left mr-auto d-block quote-icon-td'}></span>
                </p>
                <small className='my-auto mr-auto ml-0 text-dark pointer' onClick={()=>this.setmarket(i)}>{i.symbolName}</small>
                <div className='ml-auto my-auto pointer pr-2' onClick={()=>this.setfav(i)}>
                {i.favorite === 'yes' ? <AiFillStar className='fav-icon-active' /> : <AiOutlineStar className='fav-market-icon' />}
                </div>
              </div>
          )}
          </div>
          <div className=''>
            <div className='orderlist-header rtl bg-light  pr-3 pt-2 py-1'>
            <small className='rtl text-dark orderlist-header text-primary'>{t('assets')}</small>
          </div>
          </div>
          <div className='accountlist-container ordelist'>
          {accounts.map(a=>
            <div key={a.id} className='d-flex'>
              <p className='ltr icon-market text-dark'>
                <span className={a.slug+'-icon ml-1 small-icon float-left mr-auto d-block quote-icon-td'}></span>
              </p>
              <small className='my-auto mr-auto ml-0 text-dark pointer'>{a.slug}</small>
              <div className='ltr ml-auto my-auto pointer pr-2'>
                {a.availablebalance}
              </div>
            </div>
        )}
          </div>
          </div>
          </Col>
          {this.state.chartvisible === true ?
          <Col xl='8' md='12' lg='8' xs='12'  className='charting-container  no-gutters'>

            <div className='border-bottom'
              id='tv_chart_container'
            />
            <div className='d-none d-lg-block'>
              <div className='d-flex py-2 px-1 rtl'>
                <small onClick={()=>this.setState({at : '1' , order : 'market'})} className={this.state.order === 'market' ? 'pointer font-weight-bold text-primary' : 'pointer'}>{t('submit-order')}</small>
                <span className={this.state.order === 'limit' ? 'pointer text-primary font-weight-bold mx-3 my-auto' : 'my-auto pointer mx-3'} onClick={()=>this.setState({order : 'limit' , at : '1'}) } >لیمیت</span>

                <small onClick={()=>this.setState({at : '2'})} className={this.state.at === '2' ? 'pointer mx-3 font-weight-bold text-primary' : 'pointer mx-3'}>{t('openorders')}</small>
              </div>
              <div className='py-2 px-1 rtl'>
                {this.state.at !== '2' ? ordering :activeorders }
              </div>
            </div>
          </Col> :
          <Col xl='8' md='12' lg='8' xs='12'   className='h-0 charting-container'>

            <div className='border-bottom'
              id='tv_chart_container'
            />


          </Col>
        }
          <Col lg='2' md='6' xl='2' className=' no-gutters d-none d-lg-block right-column'>
            <div className='d-none d-lg-block orderlist-header rtl bg-light border border-bottom pr-3 pt-2 py-1'>
              <div className='d-flex'>
              <small onClick={()=>this.setState({showorders : !this.state.showorders})} className={this.state.showorders === false ? ' active font-weight-bold pointer text-primary ' : 'pointer'}>لیست معاملات</small>
              <small onClick={()=>this.setState({showorders : !this.state.showorders})} className={this.state.showorders === false ? ' pointer mr-auto ' : 'active font-weight-bold pointer mr-auto text-primary '}>تاریخچه معاملات</small>
            </div>
          </div>
            <Row xs='1' sm='1' xl='2' lg='2' md='1' >

              {this.state.showorders !== false ?
                <Col xl='12' xs='12' className='historylist-container ordelist no-gutters border-left'>
                {history}
                </Col>
                 :
              <Col xl='12' xs='12' className='no-gutters border-left'>
              <div className=' d-none d-lg-block'>
                <div className='d-block d-lg-none historylist-container ordelist custom-overflow  pr-3'>

                </div>
              </div>
              <div className='  d-none d-lg-block'>
              {this.state.list === 1 ? <>
                <div className='py-1 rtl  border-bottom px-3 d-flex justify-content-space-between'>
                <small>مقدار</small>
                <small>قیمت</small>
                </div>
                <div className='orderlist-container ordelist custom-overflow' id='asks-list'>
                {sortedArray.map((v,index) =>
                  <div className='d-flex justify-content-space-between px-2 pb-1'>
                  <small onClick={this.setpriceasks} data-price={v[0]*this.usdt}  className='text-success font-weight-bold'><NumberFormat displayType='text' value={v[0]*this.usdt.toString()} thousandSeparator=',' /></small>
                  <small onClick={this.setamountasks} data-amount={v[1]} >{v[1]}</small>
                  </div>
                )}
                </div>
                <div className='orderlist-header   border-bottom border-top pr-3'>
              {sortedArray.slice(0,1).map(h=><>
                <span  onClick={this.setpriceasks} data-price={this.state.marketprice} className='f14 font-weight-bold ml-2 my-auto text-primary'><NumberFormat displayType='text' value={h[0]*this.usdt.toString()} thousandSeparator=',' /></span>
                {this.quoteCurrency === 'IRR' ?
                <small onClick={this.setpriceasks} data-price={this.state.marketprice} className='mr-auto ml-2 text-primary my-auto font-weight-bold'><span className='lead mx-2 my-auto'>&#8771;</span><NumberFormat displayType='text' suffix=' (USDT)' value={h[0]} /> </small> : <></> }
                </>
              )}
                </div>

              <div className='orderlist-container ordelist custom-overflow' id='bids-list'>
                {this.state.bids.map(b=>
                    <div className='d-flex justify-content-space-between px-2 pb-1'>
                    <small onClick={this.setpriceasks} data-price={b[0]*this.usdt} className='text-danger font-weight-bold'><NumberFormat displayType='text' value={b[0]*this.usdt.toString()} thousandSeparator=',' /></small>
                    <small onClick={this.setamountasks} data-amount={b[1]} className='ltr'>{b[1].toString()}</small>
                    </div>
                )}
                </div>
                </> : <></> }
              {this.state.list === 2? <>


                <div className='orderlist-header   border-bottom pr-3'>
              {this.state.history.slice(99).map(h=>
                <span className={h.side === 'buy' ? 'text-success font-weight-bold my-auto' : 'text-danger font-weight-bold my-auto'}>{h.price}</span>
              )}
                  <span onClick={this.setpriceasks} data-price={this.state.marketprice} className='ltr text-success my-auto font-weight-bold'>{this.state.marketprice}</span>
                </div>
                <div className='orderlist-header rtl  border-bottom px-3 d-flex justify-content-space-between'>
                  <small>مقدار</small>
                  <small>قیمت</small>
                </div>
                <div className='orderlist-container-fullheight ordelist custom-overflow'>
                {sortedArray.map((n,index) =>
                  <div className='d-flex justify-content-space-between px-2 pb-1'>
                  <small onClick={this.setpriceasks} data-price={n[0]}  className='text-success font-weight-bold'>{n[0].toString()}</small>
                  <small onClick={this.setamountasks} data-amount={n[1]} >{n[1].toString()}</small>
                  </div>
                )}
                </div>

                </> : <></> }
              {this.state.list === 3? <>

                  <div className='orderlist-header   border-bottom pr-3'>
                {this.state.history.slice(99).map(h=>
                  <span className={h.side === 'buy' ? 'text-success font-weight-bold my-auto' : 'text-danger font-weight-bold my-auto'}>{h.price}</span>
                )}
                    <span onClick={this.setpriceasks} data-price={this.state.marketprice} className='ltr text-success my-auto font-weight-bold'>{this.state.marketprice}</span>
                  </div>
                                  <div className='orderlist-header rtl  border-bottom px-3 d-flex justify-content-space-between'>
                                    <small>مقدار</small>
                                    <small>قیمت</small>
                                  </div>
                <div className='orderlist-container-fullheight ordelist custom-overflow' >
                  {this.state.bids.map(ab=>
                      <div className='d-flex justify-content-space-between px-2 pb-1'>
                      <small onClick={this.setpriceasks} data-price={ab[0]} className='text-danger font-weight-bold'>{ab[0].toString()}</small>
                      <small onClick={this.setamountasks} data-amount={ab[1]} className='ltr'>{ab[1].toString()}</small>
                      </div>
                  )}
                  </div>
                  </> : <></> }
              <div className='orderlist-footer pt-2 my-auto mr-auto d-flex '>
                <span id='askbid' className='ml-3 pointer'><img onClick={()=>this.setState({list:1}) } src={askbid} width='15px' /></span>
                <span id='ask' className='ml-3 pointer'><img onClick={()=>this.setState({list:2}) }  src={ask} width='15px' /></span>
                <span id='bid' className='ml-3 pointer'><img onClick={()=>this.setState({list:3}) }  src={bid} width='15px' /></span>
              </div>
              </div>

              </Col>}

            </Row>
            <div>

            </div>
          </Col>
          <Col xs='12' className='d-block d-md-none market-header'>
          <Row xs='2' className='rtl'>

            <Col>
            <div className='d-flex rtl  text-tiny px-3'>
              <span className={this.state.order === 'market' ? 'pointer text-primary font-weight-bold my-auto' : 'my-auto pointer'} onClick={()=>this.setState({order : 'market'}) }>مارکت</span>
              <span className={this.state.order === 'limit' ? 'pointer text-primary font-weight-bold mx-3 my-auto' : 'my-auto pointer mx-3'} onClick={()=>this.setState({order : 'limit'}) } >لیمیت</span>
            </div>
            </Col>
            <Col>
            <div className='d-flex rtl text-tiny px-3'>
              <span >لیست سفارشات</span>
            </div>
            </Col>
          </Row>

          </Col>
          <Col xs='12' className='d-block d-lg-none'>
            <div className='h-400'>
            <Row xs='2' className='h-400 ordering-section'>

             <Col  className='  ltr text-tiny col order-first' xl='7' xs='5'>

             {this.state.list === 1 ? <>
               <div className='py-1 rtl  border-bottom px-3 d-flex justify-content-space-between'>
               <small className='rtl pr-2'>مقدار</small>
               <small className='rtl pr-2'>قیمت</small>
               </div>
               <div className='orderlist-container ordelist custom-overflow' id='asks-list'>
               {sortedArray.map((i,index) =>
                 <div className='d-flex justify-content-space-between px-2 pb-1'>
                 <small onClick={this.setpriceasks} data-price={i[0]*this.usdt.toString()}  className='text-success font-weight-bold'>{i[0]*this.usdt.toString()}</small>
                 <small onClick={this.setamountasks} data-amount={i[1]} >{i[1].toString()}</small>
                 </div>
               )}
               </div>
               <div className='orderlist-header   border-bottom border-top pr-3'>
               {sortedArray.slice(0,1).map(h=><>
                 <span  onClick={this.setpriceasks} data-price={this.state.marketprice} className='f14 font-weight-bold ml-2 my-auto text-primary'><NumberFormat displayType='text' value={h[0]*this.usdt.toString()} thousandSeparator=',' /></span>
                 {this.quoteCurrency === 'IRR' ?<>
                 <small onClick={this.setpriceasks} data-price={this.state.marketprice} className='mr-auto ml-2 text-primary my-auto font-weight-bold'></small>
                </> : <></> }
                 </>
               )}
                 <span onClick={this.setpriceasks} data-price={this.state.marketprice} className='ltr text-success my-auto font-weight-bold'>{this.state.marketprice}</span>
               </div>

             <div className='orderlist-container ordelist custom-overflow' id='bids-list'>
               {this.state.bids.map(b=>
                   <div className='d-flex justify-content-space-between px-2 pb-1'>
                   <small onClick={this.setpriceasks} data-price={b[0]*this.usdt} className='text-danger font-weight-bold'>{b[0]*this.usdt.toString()}</small>
                   <small onClick={this.setamountasks} data-amount={b[1]} className='ltr'>{b[1].toString()}</small>
                   </div>
               )}
               </div>
               </> : <></> }
             {this.state.list === 2? <>


               <div className='orderlist-header   border-bottom pr-3'>
             {this.state.history.slice(99).map(h=>
               <span className={h.side === 'buy' ? 'text-success font-weight-bold my-auto' : 'text-danger font-weight-bold my-auto'}>{h.price}</span>
             )}
                 <span onClick={this.setpriceasks} data-price={this.state.marketprice} className='ltr text-success my-auto font-weight-bold'>{this.state.marketprice}</span>
               </div>
               <div className='orderlist-header rtl  border-bottom px-3 d-flex justify-content-space-between'>
                 <small>مقدار</small>
                 <small>قیمت</small>
               </div>
               <div className='orderlist-container-fullheight custom-overflow'>
               {sortedArray.map((n,index) =>
                 <div className='d-flex justify-content-space-between px-2 pb-1'>
                 <small onClick={this.setpriceasks} data-price={n[0]}  className='text-success font-weight-bold'>{n[0].toString()}</small>
                 <small onClick={this.setamountasks} data-amount={n[1]} >{n[1].toString()}</small>
                 </div>
               )}
               </div>

               </> : <></> }
             {this.state.list === 3? <>

                 <div className='orderlist-header   border-bottom pr-3'>
               {this.state.history.slice(99).map(h=>
                 <span className={h.side === 'buy' ? 'text-success font-weight-bold my-auto' : 'text-danger font-weight-bold my-auto'}>{h.price}</span>
               )}
                   <span onClick={this.setpriceasks} data-price={this.state.marketprice} className='ltr text-success my-auto font-weight-bold'>{this.state.marketprice}</span>
                 </div>
                                 <div className='orderlist-header rtl  border-bottom px-3 d-flex justify-content-space-between'>
                                   <small>مقدار</small>
                                   <small>قیمت</small>
                                 </div>
               <div className='orderlist-container-fullheight custom-overflow' >
                 {this.state.bids.map(ab=>
                     <div className='d-flex justify-content-space-between px-2 pb-1'>
                     <small onClick={this.setpriceasks} data-price={ab[0]} className='text-danger font-weight-bold'>{ab[0].toString()}</small>
                     <small onClick={this.setamountasks} data-amount={ab[1]} className='ltr'>{ab[1].toString()}</small>
                     </div>
                 )}
                 </div>
                 </> : <></> }
             <div className='orderlist-footer ltr pt-2 my-auto mr-auto d-flex '>
               <span id='askbid' className='ml-3 pointer'><img onClick={()=>this.setState({list:1}) } src={askbid} width='15px' /></span>
               <span id='ask' className='ml-3 pointer'><img onClick={()=>this.setState({list:2}) }  src={ask} width='15px' /></span>
               <span id='bid' className='ml-3 pointer'><img onClick={()=>this.setState({list:3}) }  src={bid} width='15px' /></span>
             </div>
             </Col>
             <Col  className=' rtl col order-first text-tiny' xs='7' xl='7'>

              {ordering}

             </Col>
             <Col className=' col' xl='5' xs='12'>
              {activeorders}
             </Col>
            </Row>

            </div>
          <div className='market-header text-tiny rtl'>
          <span className='text-center mx-auto rtl'>لیست معاملات اخیر</span>
          </div>
            <div className='xs-historylist historylist-container custom-overflow  pr-3'>
            {history}
            </div>
          </Col>
        </Row>
      </div>
        <Modal fade={false} toggle = {()=>this.setState({marketmodal : !this.state.marketmodal})} isOpen={this.state.marketmodal}  className='tx-detail'>
            <ModalBody data-aos-duration={300} data-aos-delay={0} data-aos='fade-out'>
            <AiOutlineClose  className='icon-round-sm my-3 pointer' onClick={()=>this.setState({marketmodal : !this.state.marketmodal})}/>
            <div className='mt-2'>
             <Input  onChange={this.serachmarket} placeholder={t('serach.market')} className='form-search px-3' />

            </div>
            <div  className='scrollHider-gutter'>
          <span onClick={()=>this.setState({modalmenuitem : "all" , searchTerm : '' })} className={this.state.modalmenuitem === 'all' ? 'active-category-badge' : 'category-badge'}>All</span>
          <span onClick={()=>this.setState({modalmenuitem : "irr", searchTerm : 'irr'})} className={this.state.modalmenuitem === 'irr' ? 'active-category-badge' : 'category-badge'}>IRR</span>
          <span onClick={()=>this.setState({modalmenuitem : "usdt", searchTerm : 'usdt'})} className={this.state.modalmenuitem === 'usdt' ? 'active-category-badge' : 'category-badge'}>USDT</span>
          <span onClick={()=>this.setState({modalmenuitem : "btc" , searchTerm : 'btc'})} className={this.state.modalmenuitem === 'btc' ? 'active-category-badge' : 'category-badge'}>BTC</span>
          <span onClick={()=>this.setState({modalmenuitem : "eth", searchTerm : 'eth'})} className={this.state.modalmenuitem === 'eth' ? 'active-category-badge' : 'category-badge'}>ETH</span>
          <span onClick={()=>this.setState({modalmenuitem : "kcs", searchTerm : 'kcs'})} className={this.state.modalmenuitem === 'kcs' ? 'active-category-badge' : 'category-badge'}>KCS</span>
            </div>
            <div className='custom-overflow rtl market-container'>
                {filteredmarket2.length === 0 ? <>
                  <div className='text-center text-muted py-3 w-100'>
                    <p className='text-center'>موردی یافت نشد</p>
                  </div>
                </> : <>
                <Table  borderless size='small' className='market-table hv-100' >
                <thead>
                </thead>
                  <tbody className='ltr text-small'>
                  {filteredmarket2.slice(0,this.state.seemore).map(i=>
                    <tr className='pointer ltr hover-tr'  id={i.id}>
                      <td className='text-small rtl d-none d-md-block'>Rapitex.com</td>
                      <td className='ltr'>
                      <small className={parseFloat(i.changeRate) > 0 ? 'text-success d-flex mx-1' : 'text-danger d-flex mx-1' }>
                      <span className='text-small mx-1'>{parseFloat(i.changeRate) > 0 ? <BsGraphUp name='arrow-up' size='1rem' color='green' /> : <BsGraphDown name='arrow-down' size='1rem' color='red'/> }</span>
                      <span>{i.changeRate}</span>
                      </small>
                      </td>
                      <td><p className='rtl'><NumberFormat value={i.lastusdt} displayType="text" thousandSeparator="," suffix={'  ('+i.quoteCurrency+')'} /></p></td>
                      <td ><p className=' ml-auto text-dark' onClick={()=>this.setmarket(i)}>{i.symbolName}</p>
                      </td>
                      <td className='ltr icon-market text-dark'><p>
                        <span className={i.baseCurrency.toLowerCase()+'-icon d-block float-left small-icon mr-auto '}></span>
                        <span className={i.quoteCurrency.toLowerCase()+'-icon small-icon float-left mr-auto d-block quote-icon-td'}></span></p>
                      </td>
                      <td>
                      <div className='ml-auto my-auto pointer pr-2' onClick={()=>this.setfav(i)}>
                      {i.favorite === 'yes' ? <AiFillStar color='purple' /> : <AiOutlineStar className='fav-market-icon' />}
                      </div>
                      </td>
                    </tr>
                  )}
                  </tbody>

                </Table>
                </> }
                {filteredmarket2.length > 0 ?
                <Button color='light' block onClick={this.seemore} className='btn-light btn btn-block text-primary text-center mb-0 mt-5 '>مشاهده بیشتر</Button> :<></>}

                </div>
            </ModalBody>
            <ModalFooter>
            <Row xs='3' xl='3' className='w-100 rtl'>
            <Col xl='4'><Button size='sm'  className='px-3' outline color="dark" onClick={()=>this.setState({marketmodal : !this.state.marketmodal})}>بستن</Button></Col>
            </Row>
            </ModalFooter>
        </Modal>
        <Navbar className={this.state.navbarcl} fixed='top' expand='xs' dir='rtl'>
          <Link   to='/' className='ml-auto'>
           <div className='logo-container-home mx-2' />
          </Link>
          <div className='mt-2 d-flex'>
          <Link   className='landing-nav rtl mx-2 px-1 market-nav-icon' to='/trade/BTC-USDT'>
            {t('market')}
          </Link>
          <Link   to='/panel/dashboard' className='landing-nav rtl mx-2 ' >{t('dashboard')}</Link>
          <Link   to='/panel/Exchange/buy/BTC' className='landing-nav rtl mx-2 ' >{t('fast-buy')}</Link>
          <Link   to='/panel/depositirt' className='landing-nav rtl mx-2 ' >{t('dp/wl')}</Link>
          <Link   to='/panel/transactions' className='landing-nav rtl mx-2 ' >{t('transactions')}</Link>
          <Link   to='/panel/wallet'  className='landing-nav rtl mx-2 ' >{t('assets')}</Link>
          </div>
          <div className='mr-auto rtl d-flex my-auto'>
          {this.state.loggedin === true ?
          <Link  className='nav-link login-button' to='#'>
          <UncontrolledDropdown  inNavbar>
              <DropdownToggle nav>
                <p className='d-flex user-UL my-auto'>
                <SimpleLineIcon name='arrow-down my-auto mx-1' size='small' />
                </p>
              </DropdownToggle>
              <DropdownMenu right className='rtl'>
                <DropdownItem className='f14 custome-dropdown-item'>
                  {this.state.email}
                </DropdownItem>
                <DropdownItem className='f14 custome-dropdown-item d-block d-md-none'>
                  {this.state.x}
                </DropdownItem>
                <DropdownItem className='f14 custome-dropdown-item'>
                  <Link className='nav-link login-button f14' to='/panel/profile'>پروفایل</Link>
                </DropdownItem>
                <DropdownItem className='f14 custome-dropdown-item'>
                  <Link className='nav-link login-button f14' to='/panel/profile'>پیام ها</Link>
                </DropdownItem>

                <DropdownItem className='f14 custome-dropdown-item'>
                  <Link className='text-dark nav-link d-flex justify-content-space-between' to='/Panel/Logout'>
                  <span className='rtl text-dark my-auto'>خروج</span>
                  <SimpleLineIcon name='power'  /></Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Link> : <> <Link  className='landing-nav  mx-4 text-light login-icon' to='/auth/login'>ورود
            </Link>
            <Link  to='/Auth/signup' className='mr-auto my-auto  signup-icon'>
            <NavbarText className='mr-auto px-2'><Button style={{'font-size' : '16px'}} color='warning' className='px-5'>ثبت نام</Button></NavbarText>
          </Link></> }
          <div className='p-2 mr-3 dark-icon-round radius mr-auto' onClick={this.darkMode}>
          {this.state.darkMode === true ?
            <SimpleLineIcon name='bulb' color='cyan' />:<SimpleLineIcon name='bulb' color='yellow' /> }
          </div>
          {this.state.loggedin === true ?
          <div className='p-2 mr-3 dark-icon-round radius mr-auto' >
            <Link to='/panel/logout'>
            <SimpleLineIcon name='power' color='#a2a2a2' />
          </Link>
          </div>:
            <></>}
          </div>
        </Navbar>
      </>
      )
  }
}

  export default withRouter(withTranslation()(Wallet))
