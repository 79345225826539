import React from 'react';
import {Link} from 'react-router-dom'
import { CiCoinInsert,CiBitcoin,CiInboxOut } from "react-icons/ci";
import { useTranslation, withTranslation } from 'react-i18next';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { RiUserSettingsLine } from "react-icons/ri"
import { LuUser2 } from "react-icons/lu";
import { RiTokenSwapLine } from "react-icons/ri";
import SimpleLineIcon from 'react-simple-line-icons'
import { GoSun } from "react-icons/go";
import { BsMoonStars } from "react-icons/bs";
class Side extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      coin : 'BTC',
      coinname : 'بیت کوین',
      coinPrice : 16800,
      buysell : 'buy',
      fee : '',
      list : [],
      active : '',
      sub1 : false,
      themes : 'light' | 'dark',
      darkMode : true,
      isrtl : true,
      collapsed : true,
      hasImage : false
    }
  }
  darkMode =()=>
  {
    if (this.state.darkMode === false){
        document.body.classList.remove('light');

        document.body.classList.add('dark');
        this.setState({theme : 'dark'})
    }
    if (this.state.darkMode === true){
        document.body.classList.remove('dark');
        document.body.classList.add('light');
        this.setState({theme : 'light'})
    }


        // let bodyColor = document.body.className;
      this.setState({
        darkMode : !this.state.darkMode
      })
  }
  hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };
  render(){
    const {t} = this.props
    const themes = {
      light: {
        sidebar: {
          backgroundColor: '#161924',
          color: '#fff',
        },
        menu: {
          menuContent: '#161924',
          icon: '#59d0ff',
          hover: {
            backgroundColor: '#161924',
            color: '#ffffff',
          },
          disabled: {
            color: '#fff',
          },
        },
      },
      dark: {
        sidebar: {
          backgroundColor: '#161924',
          color: '#fff',
        },
        menu: {
          menuContent: '#161924',
          icon: '#59d0ff',
          hover: {
            backgroundColor: '#161924',
            color: '#ffffff',
          },
          disabled: {
            color: '#fff',
          },
        },
      },
    };
    const menuItemStyles: MenuItemStyles = {
      root: {
        fontSize: '13px',
        fontWeight: 400,
      },
      icon: {
        color: themes.dark.menu.icon,
      },
      SubMenuExpandIcon: {
        color: '#fff',
      },
      subMenuContent: ({ level }) => ({
        backgroundColor:
          level === 0
            ? this.hexToRgba(themes.dark.menu.menuContent, this.state.hasImage ? 1 : 1)
            : 'transparent',
      }),
      button: {

        '&:hover': {
          backgroundColor: this.hexToRgba(themes.dark.menu.hover.backgroundColor, this.state.hasImage ? 1 : 1),
          color: themes.dark.menu.hover.color,
        },
      },
      label: ({ open }) => ({
        fontWeight: open ? 600 : undefined,
      }),
    };
    const menuItemStyleslight: MenuItemStyles = {
      root: {
        fontSize: '13px',
        fontWeight: 400,
      },
      icon: {
        color: themes.light.menu.icon,
      },
      SubMenuExpandIcon: {
        color: '#fff',
      },
      subMenuContent: ({ level }) => ({
        backgroundColor:
          level === 0
            ? this.hexToRgba(themes.light.menu.menuContent, this.state.hasImage ? 1 : 1)
            : 'transparent',
      }),
      button: {
        '&:hover': {
          backgroundColor: this.hexToRgba(themes.light.menu.hover.backgroundColor, this.state.hasImage ? 1 : 1),
          color: themes.light.menu.hover.color,
        },
      },
      label: ({ open }) => ({
        fontWeight: open ? 600 : undefined,
      }),
    };
    return(
      <div className='' >
        <Sidebar
        backgroundColor={this.hexToRgba(themes.dark.sidebar.backgroundColor, this.state.hasImage ? 0.7 : 0.1)}
         collapsed ={false}>
          <Menu menuItemStyles={this.state.darkMode === true ? menuItemStyles :menuItemStyleslight }>
          {this.props.closebtn}

          {this.props.loggedin === true ? <>
            <MenuItem onClick={()=>this.setState({isOpen : false})} component={<Link to="/panel/profile" />}  className='rtl mt-3' icon={<RiUserSettingsLine size='1.4rem' />}>
              {localStorage.getItem('email')}

            </MenuItem>
            <MenuItem onClick={this.props.onClick} component={<Link to="/" />} className='rtl' icon={<SimpleLineIcon name="home" />}>
              {t('homepage')}
            </MenuItem>

            <MenuItem component={<Link to="/panel/dashboard" />} className='rtl' icon={<SimpleLineIcon name="grid" />}>
              {t('dashboard')}
            </MenuItem>
            <SubMenu className='rtl' label={t('withdraw-deposit')} icon={<SimpleLineIcon name='list' />}>
                     <MenuItem component={<Link to="/panel/depositirt" />}>{t('depositirr')}</MenuItem>
                     <MenuItem component={<Link to="/panel/withdrawirt" />}>{t('withdrawirr')}</MenuItem>
                     <MenuItem component={<Link to="/panel/depositcrypto" />}>{t('deposit-crypto')}</MenuItem>
                     <MenuItem component={<Link to="/panel/withdrawall" />}>{t('withdraw-crypto')}</MenuItem>
                   </SubMenu>
            <MenuItem component={<Link to="/trade/BTC-USDT" />} className='rtl' icon={<SimpleLineIcon name="chart" />}>
              {t('market')}
            </MenuItem>

            <MenuItem component={<Link to="/panel/exchange/buy/BTC" />} className='rtl' icon={<RiTokenSwapLine size='1.4rem'/>}>
              {t('simple-market')}
            </MenuItem>
            <MenuItem component={<Link to="/panel/profile" />} className='rtl' icon={<SimpleLineIcon name="user" />}>
              {t('profile')}
            </MenuItem>
            <MenuItem component={<Link to="/panel/wallet" />} className='rtl' icon={<SimpleLineIcon name="wallet" />}>
              {t('assets')}
            </MenuItem>
            <MenuItem component={<Link to="/panel/transactions" />} className='rtl' icon={<SimpleLineIcon name="list" />}>
              {t('transactions')}
            </MenuItem>


            <MenuItem component={<Link to="/panel/logout" />} className='rtl mt-3' icon={<SimpleLineIcon name="logout" />}>
            خروج
            </MenuItem>

            </> : <>
              <MenuItem onClick={this.props.onClick} component={<Link to="/" />} className='rtl mt-3' icon={<SimpleLineIcon name="home" />}>
                {t('homepage')}
              </MenuItem>
              <MenuItem onClick={this.props.onClick} component={<Link to="/auth/login" />} className='rtl' icon={<SimpleLineIcon name="user" />}>
                {t('login')}
              </MenuItem>

              <MenuItem  onClick={this.props.onClick}component={<Link to="/auth/signup" />} className='rtl' icon={<SimpleLineIcon name="user-follow" />}>
                {t('signup')}
              </MenuItem>

              <MenuItem onClick={this.props.onClick} component={<Link to="/fees" />} className='rtl' icon={<SimpleLineIcon name='grid' size=''/>}>
                {t('fees')}
              </MenuItem>
              <MenuItem onClick={this.props.onClick} component={<Link to="/terms_and_conditions" />} className='rtl' icon={<SimpleLineIcon name="grid" />}>
                {t('rules')}
              </MenuItem>
              <MenuItem onClick={this.props.onClick} component={<Link to="/about-us" />} className='rtl' icon={<SimpleLineIcon name="grid" />}>
                {t('about-us')}
              </MenuItem>
              <MenuItem onClick={this.props.onClick} component={<Link to="/currencies" />} className='rtl' icon={<SimpleLineIcon name="list" />}>
                {t('currencies')}
              </MenuItem>

            </> }
            <MenuItem  onClick={this.darkMode} className='rtl' icon={this.state.darkMode === true ?
            <GoSun  name='moon' size='1rem' color='#fff' /> :<BsMoonStars size='1rem' name='sun' color='#fff' /> }>
            <div className='icon-round'>
            {this.state.darkMode === true ?
              <>{t('light-mode')}</>:  <>{t('dark-mode')} </>}
            </div>
            </MenuItem>

            <div className='mt-5 mb-0 text-center'>
              <span  className='text-tiny text-gray'>
              v   1.2.0   @rapitex
              </span>
            </div>
          </Menu>
        </Sidebar>
      </div>
    )
  }
}
export default withTranslation()(Side)
