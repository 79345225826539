import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import {Link} from 'react-router-dom'
import {useTranslation,withTranslation} from 'react-i18next'
const Example = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation()
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar className='text-center main-auth  text-white'>
          <Link to='/' className='text-center logo-container mx-auto'>
          </Link>
      </Navbar>

    </div>
  );
}

export default withTranslation()(Example);
