import React from 'react'
import axios from 'axios'
import cookie from 'react-cookies'
import SimpleReactValidator from 'simple-react-validator'
import {useTranslation,withTranslation} from 'react-i18next'
import {Row,Col, Button,Container,Form,FormFeedback,FormGroup,Label,FormText,InputGroup,InputGroupText,Input} from 'reactstrap'
import NumericFormat from 'react-number-format';
import {Link,withRouter,Redirect} from  'react-router-dom'
import Toast from 'awesome-toast-component'
import {Helmet} from 'react-helmet'
class Login extends React.Component{
  constructor(props){
    super(props);
    const {t} = this.props
    this.state = {
        message : `${t('please enter code !')}`,
        email : '',
        password : '',
        mode : 'login',
        active2 :false
    }
    this.validator = new SimpleReactValidator();
  }



  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  loginuser = (e) => {
    e.preventDefault()
    const {t} = this.props;
    if (!this.validator.fieldValid('email')) {
      console.log(`${t('enter.valid.email')}`)
      return
    }else{

    }
    if (!this.validator.fieldValid('password')) {
      console.log(`${t('enter.valid.password')}`)
      return
    }else{

    }
    this.setState({active2 : true})
    var d = this.state.password;
    const formdata = new FormData()
    formdata.append('email',this.state.email)
    formdata.append('password',d )
    axios.post('/api/signin' , formdata).then(response=> {
      this.setState({active2 : false})
    let data =  response.data
    if(data.status === 'error'){
      if(response.data.content === 'wrong.password.email'){
        new Toast(`${t('wrong.password.email')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })


      }
      if(response.data.content === 'not.active'){
        new Toast(`${t('account.not.activated')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
      return
      }
      if(response.data.content === 'not.found'){
        new Toast(`${t('user.not.found')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
      return
      }
    }else{
      if(data.auth !== 'loggedin'){
        this.setState({mode : 'code' , auth : response.data.auth})
          return

      }else{

        new Toast(`${t('success.login')}`, {
          style: {
              container: [
                  ['background-color', 'green']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
          this.props.history.push('/panel/dashboard')
      }

    }
    })
  }



  submitotp =(e)=>{
    e.preventDefault();
    const { t } = this.props;
    const formdata = new FormData()
    formdata.append('otp',this.state.otp)
    formdata.append('auth',this.state.auth)
    axios.post('/api/signin/get_otp' ,formdata)
    .then(response=> {
    if(response.data.status === 'error'){
      new Toast(`${t('wrong.otp')}`, {
        style: {
            container: [
                ['background-color', 'red']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
    return
        this.setState({mode : 'login'})
    }
      if(response.data.status === 'success'){
        new Toast(`${t('success.login')}`, {
          style: {
              container: [
                  ['background-color', 'green']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
      this.props.history.push('/panel/dashboard')
      return
      }
    });
  }






  UNSAFE_componentWillMount(){
    this.redirect()
  }

  redirect =()=>{
    let uuid = cookie.load('uid')
    if(uuid){
      this.props.history.push('/panel/dashboard')
    }
  }

  render(){
    const {t} = this.props
    return(
      <div className=''>
      <Helmet>
        <title>{t('login-title')}</title>
      </Helmet>
        <div className=''>

              {this.state.mode === 'login' ?
              <div className='no-border' id='auth-login'>
                <div>
                  <h4 className='text-center hero-icon-4'>ورود کاربر</h4>

                   <div className='m-auto'>
                      <Form>
                       <FormGroup className='mt-5'>
                       <Label className='rtl ml-auto'>
                         {t('ایمیل')}
                       </Label>
                       <Input name='email'  onChange={this.onChange} />
                       {this.validator.message('email', this.state.email, 'required')}
                       <FormFeedback>

                       </FormFeedback>
                       <FormText>
                       </FormText>
                      </FormGroup>
                      <FormGroup className='mb-5 mt-4'>
                      <div className='forgot-note d-flex'>
                       <Label>
                       {t('رمز عبور')}
                      </Label>

                       </div>

                       <Input type='password' name='password'  onChange={this.onChange} />
                       {this.validator.message('password', this.state.password, 'required')}
                      </FormGroup>
                      <FormGroup className='text-center mt-5 border-radius'>
                        <Button onClick={this.loginuser} disabled={this.state.active2} block type='submit' color='primary'  className='input-addon-home pointer mt-5 py-2'>{t('login')}
                        </Button>
                      </FormGroup>


                      <Row xl='2' lg='2' md='2' sm='2' xs='1' className='mt-5 pb-10'>
                        <Col xs='12' md='6' sm='12'>
                          <div className='xs-rtl w-100 ml-auto  py-2'><Link to='/auth/signup' className='li-item rtl pointer ' >{t('have-not-account?')}<span className='rtl text-primary font-weight-bold mx-1' >{t('submit-signup')}</span></Link>
                          </div>
                        </Col>
                        <Col xs='12' md='6' sm='12'>
                          <div className='py-2 d-flex'>

                          <Link to='/auth/recover-password' className='li-item  xs-rtl w-100 xl-ltr pointer '>بازیابی رمز عبور</Link>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
              : <></>}
              {this.state.mode =='code' ?
              <div id=''>
              <div className='note-box'>
              {this.state.auth === '2fa' ?
              <h4 className='text-center'>کد امنیتی  دو مرحله ای 2fa</h4> :
              <h4 className='text-center'>کد امنیتی ارسالی به ایمیل</h4> }
              </div>
                <Form >
                  <div className='my-5'>
                    <div>
                    <Label>
                      کد امنیتی *
                    </Label>
                    <NumericFormat
                      className='ltr'

                      format="# # # # # # #"
                      name='otp'
                      inputMode="decimal"
                      value={this.state.otp}
                      onValueChange={(values, sourceInfo) => {
                       this.setState({otp : values.value})
                        }}
                       displayType="input"
                       className='font-weight-bold text-center form-control' />
                      <Button block type='submit' onClick={this.submitotp} color='success' className='my-5'>ثبت کد</Button>
                      {this.validator.message('otp', this.state.otp, 'required|min:4|max:4')}
                    </div>
                  </div>
                  <FormGroup className="mt-10">
                  <Button onClick={()=>this.setState({mode : 'login'})} color="primary" sm={2} size="md" block>بازگشت به صفحه ورود</Button>
                  </FormGroup>
                 </Form>
              </div> : <></> }
        </div>

     </div>
    )
  }
}
export default withRouter(withTranslation()(Login))
const Rd = ()=>{
  return(<Redirect path='/panle/dashboard' />)
}
