import React from 'react'
import axios from 'axios'
import {Modal,ModalBody,ModalHeader,ModalFooter, Table,TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle,CardHeader,CardBody, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import SimpleDateTime  from 'react-simple-timestamp-to-date'
import moment from 'jalali-moment'
import {Link} from 'react-router-dom'
import NumericFormat from 'react-number-format';
import SimpleLineIcon from 'react-simple-line-icons'
import SearchInput, {createFilter} from 'react-search-input'
import Loader from '../../landing/loader'
import {Helmet} from 'react-helmet'

class Dashboard extends React.Component{
  constructor(props){
    super(props);
    this.data = []
    this.state = {
      data : [],
      buy:[],
      sell : [],
      deposits : [],
      withdraws : [],
      order : [],
      message : 'در حال دریافت اطلاعات ',
      all : [],
      activetab : 0,
      modal : false,
      backdrop : false,
      keyboard : true,
      loader : true,
    }
    this.setactive = this.setactive.bind(this)
  }
  componentWillMount(){
    this.get()
  }
  setactive =(e)=>{
    this.setState({activetab : e})
    switch (e) {
      case 1:
        this.data = this.state.all.filter(createFilter('خرید' , ['side']  ))
        break;
      case 2:
        this.data = this.state.all.filter(createFilter('فروش' , ['side']  ))
        break;
      case 3:
        this.data = this.state.all.filter(createFilter('واریز' , ['type']  ))
        break;
      case 4:
        this.data = this.state.all.filter(createFilter('برداشت' , ['type']  ))
        break;
      case 5:
        this.data = this.state.all.filter(createFilter('ناموفق' , ['status']  ))
        break;
      default:
      this.data = this.state.all

    }
  }
  get = ()=>{
    axios.get('/api/transactions/').then(response=>{
      if(response.data.status === 'error-fatal'){
        this.props.history.push('/auth/login')
      }
      this.setState({loader : false})
      if(response.data.all.length === 0){
        this.setState({message : 'تراکنشی برای نمایش وجود ندارد .'})
      }
      this.data = response.data.all
      this.setState({ all : response.data.all})
    })
  }
  toggle = (i)=>{
    this.setState({activetab : i})
  }
  jdate = (x)=>{
    var x = parseInt(x * 1000)
    var today = new Date(x);
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  let Today = yyyy + '/' + mm + '/' + dd;
  return(
    <>{moment(Today, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}</>
  )

  }
numeric =(x)=>{
  return(
  <NumericFormat value={x} thousandSeparator="," displayType="text"  className='' />

  )
}

 backdrop = (e) => {

  this.setState({backdrop : !this.state.backdrop})
}
 togglemodal = (e) => {

  this.setState({modal : !this.state.modal})
}

 changeKeyboard = (e) => {
  this.setState({ keyboard : e.currentTarget.checked})
}
getx =(i)=>{
  let type = i.type
  this.setState({order : i , modal : true , backdrop : false})
  // axios.get('/api/transactions/get_detail/' +i.orderid).then(response=>{
  //   this.setState({order : response.data})
  // })
setTimeout(()=>this.setState({modal : true}), 2000);
}
setcolor =(i)=>{
  let s = i.status
  switch (s) {
    case 'تکمیل شده':
      return 'green'
      break;
    case 'در صف واریز':
      return 'blue'
      break;
    case 'در حال انجام':
      return 'orange'
      break;
    case 'لغو شده':
      return 'red'
      break;
    default:

  }
}
  render(){
    const {match} = this.props
    const activeTab = this.state.activetab
    const fields = [
	{ name: 'wid', displayName: "ID", inputFilterable: true, sortable: true },
	{ name: 'date', displayName: "تاریخ", inputFilterable: true, exactFilterable: true, sortable: true },
	{ name: 'amount', displayName: "مبلغ", inputFilterable: true, exactFilterable: true, sortable: true },
	{ name: 'slug', displayName: "ارز", inputFilterable: true, exactFilterable: true, sortable: true },
	{ name: 'to', displayName: "مقصد", inputFilterable: true, exactFilterable: true, sortable: true },
	{ name: 'type', displayName: "نوع", inputFilterable: true, exactFilterable: true, sortable: true },
	{ name: 'status', displayName: "وضعیت", inputFilterable: true, exactFilterable: true, sortable: true }
];
const i = this.state.order
    return(

    <>
    <Helmet>
       <title>رپیتکس | تراکنش ها</title>
    </Helmet>
    {this.state.modal === true ?
        <div className='main-tx-modal w-100'>
          <div data-aos="slide-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="300" contentClassName ='tx-modal float-left bg-white'   isOpen={this.state.modal} className='tx-modal' >
          <ModalHeader className='d-flex rtl bg-white' >
          <span className='ml-auto'>
          <SimpleLineIcon onClick={this.togglemodal} name='close ml-auto pointer' />
          </span>


          </ModalHeader>
            <div className=' card-body pt-5 bg-white light' >
            <h4 className='text-muted rtl my-3'>{i.type + ' '+ i.market}</h4>
              <Row xl='2' lg='2' xs='1' sm='1' md='2' className='tx-row '>
                <Col className='bg-success mt-3'>
                {i.type === 'تبدیل'  ?
                <div className='px-2'>
                <Row xl='1'>
                  <Col className='py-2  rtl d-flex'>
                  <small className={i.quotename.toLowerCase() +'-icon ml-auto'} >

                  </small>
                  <small className='text-small mr-auto float-left font-weight-bold my-auto'>
                  {i.quoteamount}
                  </small>

                  </Col>
                  <Col className='mt-3 d-flex rtl border-top' >
                  <small className={i.basename.toLowerCase() +'-icon ml-auto'} >

                  </small>
                  <small className='text-small mr-Auto mt-3 font-weight-bold'>
                  {i.baseamount}
                  </small>
                  </Col>
                </Row>
                </div> :
                <h4 className='text-middle font-weight-bold'>
                <span>
                {this.numeric(i.quoteamount)}
                </span>
                <span> : مبلغ
                </span>
                </h4>

                 }
                </Col>
                <Col className=' mt-3 '>
                <Row xl='1'>
                  <Col className='bg-light px-2 py-2 rtl d-flex'>
                  <small className='rtl ml-auto text-small' >
                  تاریخ :
                  </small>
                  <small className='text-small mr-auto'>
                  {i.date}
                  </small>
                  </Col>
                  <Col className='bg-light my-2 py-2 rtl d-flex'>
                  <small className='rtl ml-auto text-small' >
                  نوع :
                  </small>
                  <small className='text-small mr-auto'>
                  {i.type}
                  </small>
                  </Col>
                  <Col className='bg-light  py-2 rtl d-flex'>
                  <small className='rtl ml-auto text-small' >
                  وضعیت :
                  </small>
                  <small className='text-small mr-auto'>
                  {i.status}
                  </small>

                  </Col>

                </Row>

                </Col>

              </Row>
              <div className='bg-light rtl mr-3 mt-5 py-4 pl-3 pr-5 d-flex'>
              <span className='rtl'>  کد رهگیری : </span>
             <span className='mr-auto'>{i.txid}</span>
              </div>
            </div>
            <ModalFooter className='mt-auto rtl bg-white fixed-bottom' >
              <Button className='ml-auto' color="primary" onClick={this.togglemodal}>بستن</Button>
            </ModalFooter>
          </div>
       </div>
     : <></> }
     {this.state.loader === true ?
       <>
         <div className='loader-home bg-white py-5'>
           <h3 className='text-center bg-white m-auto mt-auto'><Loader /></h3>
         </div>
       </> :
       <>
      <Card className='rtl mt-3'>
      <CardHeader className='button-row rtl bg-darkblue'>
      <Link className={this.state.activetab === 0 ? 'active-tabs text-center pointer text-center ml-3 ' : ' text-center ml-3 text-white text-center pointer'} onClick={()=>this.setactive(0)} >
       <span> همه تراکنش ها </span>
      </Link>
      <Link className={this.state.activetab === 1 ? 'mx-3 active-tabs text-center pointer' : 'mx-3 text-white text-center text-center pointer'} onClick={()=>this.setactive(1)} >
         <span> خرید </span>
      </Link>
      <Link className={this.state.activetab === 2 ? 'mx-3 active-tabs text-center pointer' : 'mx-3 text-white text-center text-center pointer'} onClick={()=>this.setactive(2)} >
         <span> فروش </span>
      </Link>
      <Link className={this.state.activetab === 3 ? 'mx-3 active-tabs text-center pointer' : 'mx-3 text-white text-center text-center pointer'} onClick={()=>this.setactive(3)} >
         <span> واریز </span>
      </Link>
      <Link className={this.state.activetab === 4 ? 'mx-3 active-tabs text-center pointer' : 'mx-3 text-white text-center text-center pointer'} onClick={()=>this.setactive(4)} >
         <span> برداشت </span>
      </Link>
      <Link className={this.state.activetab === 5 ? 'mx-3 active-tabs text-center pointer' : 'mx-3 text-white text-center text-center pointer'} onClick={()=>this.setactive(5)} >
         <span> ناموفق </span>
      </Link>
      <Link className='mr-auto float-left text-info' onClick={this.get}><SimpleLineIcon name='reload mr-auto pl-2 pointer' color='white' />
      </Link>
      </CardHeader>
      <CardBody>


        <Row className='h-400'>
          <Col sm="12">
          {this.state.all.length !== 0 ? <>
            <Table borderless striped hover responsive size='sm' >
              <thead className='bg-light font-weight-bold border-bottom table-header'>
              <tr className='py-3'>
                <td>#
                </td>
                <td  ClassName='py-2'>کد تراکنش
                </td>
                <td className='text-center'>تاریخ
                </td>

                <td>مبلغ
                </td>
                <td>مارکت / ارز
                </td>
                <td>سمت
                </td>
                <td>وضعیت
                </td>
                <td>جزئیات
                </td>
              </tr>
              </thead>
              <tbody>

            {this.data.map((i,index)=>
              <tr className={i.status ===   'تکمیل شده'?'border-right-success':'trx-list border-right-fail' }>
              <td>{index +1}</td>
              <td>{i.id}</td>
              <td className='d-grid text-center'><div><span className='text-small'>{i.date}</span><br/><span className='text-small text-center'>{i.time}</span></div></td>
              {i.type === 'برداشت' || i.type=== 'واریز' ?
              <td>{this.numeric(i.amount)}
              </td>
              :
              <td className='d-grid'><span className='text-small'>{this.numeric(i.baseamount)}</span><br/><span className='text-small'>{this.numeric(i.quoteamount)}</span></td>
            }
              <td>{i.market === '' ? i.quote : i.market}</td>
              <td>
              {i.type === 'برداشت' || i.type=== 'واریز' ?

              <small>{i.side}</small>:<>
              <small>{i.type}</small><br/><small>{i.side}</small> </>}
              </td>
              <td>{i.status}</td>
              <td onClick={()=>this.getx(i)} ><SimpleLineIcon name='info mr-3 pointer' color={()=>this.setcolor(i)} /></td>
              </tr>
            )}
            </tbody>
            </Table>
            </> :
            <h6 className='bg-light py-2 px-3 text-center text-dark'>{this.state.message}</h6> }
          </Col>
        </Row>

      </CardBody>
      </Card>
      </> }

    </>
    )
  }
  }
  export default Dashboard
